export const EN = {
  TermsListData: [
  {
    Tit: `Personal Information Protection Policies`,
    Text: "By using our services, you agree to these personal information protection policies. These policies are part of our service agreement and are applied as follows. The terminology used in our service agreement is applied to these personal information protection policies as well.",
  },
  {
    Tit: `User Information`,
    Text: `To ensure that our services meet the needs of the users in the best possible way, we collect information from you. Therefore, based on the services we provide, we collect various types of information. Do not worry. We strictly process your “personal information” (i.e., information that can distinguish who you are or be used to distinguish you) to provide our services. In addition, we process your personal information with your consent.`,
  },
  {
    Tit: `Shared Information`,
    Text: `When using our services, you may provide us with any of the following pieces of personal information (this is an inclusive, but not exhaustive, list).
E-mail address used to sign up
Your first and last name, e-mail address, and mailing address when signing up
Payment information when you subscribe to our services
Your preferred language, plans for signing up, billing information, mastering history, and promotion codes related to the account (when applicable)
Basic settings for images, audio files, and production, structure data, files that include e-mail and other supplementary information (e.g., music style or type of instrument), content, component variables and other information (“data”).
Contact list if accessible
Visitor profile of our website
Conversations you have with us related to the e-mail or social media platforms
      `,
  },
  {
    Tit: `What is Shared with the Services`,
    Text: `We may collect the following information when you use our services.
We collect information related to the device you use to access our services. This information includes: your IP address, web browser and device used, websites connected to ours, and identifiers connected to your device. If you have activated the location identification service mediating variable on your device, it is possible to receive information on your geographical location.
To collect information and operate and improve our services, we use “cookies.” Cookies are small data files that are stored in electronic devices. When logging on to our services, you can use “persistent cookies” to store your e-mail address and password. We use “session ID cookies” to activate particular functions of the services, understand and improve your experience with our services, track data related to the overall use of the services (visiting time, number of users, etc.) and optimize the marketing for our services.
Most web browsers ask for your agreement before accepting cookies or provide you with a choice to refuse cookies. However, if you decide to refuse cookies, you may not be able to use all of our services.
In addition, our services allow us to combine the information we collect and information collected from another source. For example, if you log on to your WARP account with Facebook, you provide us with access to information related to your Facebook account.
      `,
  },
  {
    Tit: `Information Handling`,
    Text: `WARP takes personal information related issues very seriously. We only use your personal information when we absolutely need it to follow through on our duties to you, and only the employees that need to follow through on their duties to you can access your personal information.
Therefore, we may provide you with various types of services as listed below using your personal information we have collected:
Authenticate the user when logging on to the account;
Provide requested subscription services and make appropriate payments;
Access, store, and analyze data for you to use our services;
Activate particular functions including preview, edit, share, and search songs. For example, if you provide us with access to your contacts, we may store such information to simplify some interactions including sending e-mails or invitations to share data or use our services.
Understand and analyze your use patterns and preferences, improve services, and develop new products, services, and functions.
Enhance the mastering algorithm and the functions of the music engine based on the uploaded music. The user, also the musician, has the ownership, copyright, and publication rights of the music.
Answer questions and provide support, or provide follow-up on particular pieces of information.
Understand the needs and preferences of our customers and assess the satisfaction levels of our services.
Send updates on our products, services, news, and events, and take follow-up measures such as providing suggestions, promotions, and contest-related information (e.g., sending your recommendation credit when you recommend another user to use our services) within the legal range.
Also, we comply with legal and regulatory measures if applicable.
      `,
  },
  {
    Tit: `Sharing Information`,
    Text: `Do not worry, because your personal information is stored safely. We do not sell your personal information to a third party. You own your data. However, to provide you with our services, we may share particular pieces of information that we have collected in the following ways.
This is applied to WARP as a company, including the company, affiliates, subsidiaries, departments, and companies that we claim joint ownership of.
We only provide particular pieces of information to service providers or partners when it is necessary to provide you with particular services, or to improve and promote our services. We provide your personal information to service providers and partners and process the personal information according to our policies.
This is also applied for the restructuring of another company, M&A, sale of assets, financing, or partially or completely obtaining activities, including due diligence and finishing processes.
In all cases (e.g., court summons or situations that may threaten the life, health, or safety of an individual), sharing data may be allowed or required.
When sharing your personal information with a third party, we take rational measures to ensure that the third party follows these personal information protection policies, and they sufficiently guarantee that they will take appropriate technological and organizational measures to do so.
We may share information with third parties that has been automatically collected, or accumulated for the purpose of (i) Complying with various duties to report; (ii) Business or marketing; (iii) Assisting parties of interest to understand the interests, habits, and use patterns of c, and other purposes, or information that can be used to distinguish someone as an individual without knowing the true identity. These parties will provide you with particular programs, contents, services, and functions. To put it simply, every time we share your information with others, the purpose is to meet your demands as well as possible.
      `,
  },
  {
    Tit: `Basic User Settings`,
    Text: `We provide you with various options for you to control how you share information with us. The following are some of the examples.
You may allow third parties to like your Facebook account (i.e. “combined services”) to access or sign up for services, or allow the combined services to provide us with your personal or other information. By allowing us to connect to the combined services, you agree to giving us the right to access and store information such as your name, e-mail address, and other information shared by the combined services, and use and disclose the information based on these personal information protection policies. Check your personal information settings on each combined service to see what information is shared with us, and make changes to the basic settings if necessary. Thoroughly review the user agreements and personal information protection policies of each combined service to use them to connect it to our services.
You can change your contact information and language of communication whenever you wish. This is the best way to check if your personal information is correct and up to date.
You can always change your password.
You can sign up for and sign out from our promotions.
Click on the logout button to safely log out from your account.
Contact the Customer Support team (support@warpmastering.com) to delete your account.
This is for other websites and social media.
Our website and blog may have links to other websites including and not limited to: Facebook, YouTube, Twitter, Tumblr, Instagram Soundcloud, and others. We cannot control the personal protection customs of these third parties, or take responsibility for them. Some functions including data sharing provides a third party with access to your information. For example, this may be done through an API interface. The third parties receive your personal information according to their own personal information protection policies. Please remember that anyone can read, collect, and use the information you post on open forums including your blog or social media.`,
  },
  {
    Tit: `Recommendation Campaign`,
    Text: `We may ask you to invite your family and close friends to use our services, or share promotion information. In such cases, make sure that the people you recommend are your family members (spouse, common-law partners, parents, or children) or personal acquaintances (have conversations often, share familiarity and opinions, etc.). Please refer our services only to those who are interested.`,
  },
  {
    Tit: `Information Management`,
    Text: `WARP can store or process your personal information in other countries with our facilities or other service providers. Upon using the services, you agree to sending your information to countries other than your country of residence, including Korea. Therefore, your personal information may fall under the data protection and personal information protection laws of other countries. Such information may be outside of Korea, but according to the laws of the country in question, the information may be disclosed to the government, court, law enforcement institutions, or regulating institutions according to their laws. However, our customs related to your personal information always abide by these personal information protection policies, and when applicable, we follow the requests as listed in the General Data Protection Regulations (GDPR) that provide appropriate protection when transferring personal information from the EU/EEA to a third country. In addition, you can store the information used to access our services on a device used locally.
We store your personal information as long as we need it to provide you with services, according to related laws and regulations, or by request of the government. If you delete your account, all your personal information will be deleted as well. However,
(1) It may take time to delete your information on our server and backup server; (2) We may need to store some information according to legal liabilities. Aside from paid accounts, if you do not access our services for 12 consecutive months, we have the right to delete your account. We will notify you by e-mail before deleting your account.`,
  },
  {
    Tit: `Children`,
    Text: `Our services are not for children younger than 13 years old, and we do not purposely collect personal information from children under 13. If we realize that we have collected personal information from children under 13 without the consent of their parents or guardians, we immediately take necessary steps to delete such information.`,
  },
  {
    Tit: `Security`,
    Text: `We make all efforts to protect your personal information. We comply with the generally accepted industry standards to protect your data when it is being sent and after it is sent. We take appropriate physical, technological, and administrative measures to protect your personal information from incidental or illegal destruction, incidental loss, unauthorized changes, unauthorized disclosure or access, misuse, or any other illegal forms of action. However, delivering information or electronically storing information on the Internet is not 100% safe. We cannot and do not guarantee the security of the information that you send us or store on our services, and you must take the risk. In addition, we cannot guarantee that the information will not be accessed, shared, changed, or destroyed upon violating the physical, technological, and administrative protection policies. If you think that your personal information has been damaged, please notify us. If our security system is violated, we will notify you and the authorities according to related legislation.`,
  },
  {
    Tit: `Access and Editing Rights`,
    Text: `Your personal information belongs to you 100%, and so you have the right to ask to access or edit any part of the personal information you have shared with us in written form.
According to the GDPR, you have the following additional rights: 
(i) If your agreement is needed to process information; 
(ii) Right to access your personal information or additional information under certain conditions;
(iii) Right to refuse illegal processing of data under particular conditions
(iv) Right to delete your personal information under certain conditions;
(v) Right to ask to limit the processing of your personal information under certain conditions;
(vi) Right to ask to limit the processing of your personal information if you think that we have gone beyond the legal standards to process your personal information, do not need to process your information, or we have stored your personal information;
(vii) Right to move, copy, or send (i.e., data mobility) your data in the form where the users are structuralized, data can be used in a general sense, and can be read by machines;
(viii) Right to refuse any automated processes that can legally affect the user under certain conditions; 
(ix) Right to send c and notify the data protection authorities. To find out more about the rights according to the GDPR, please visit the European Commission Data Protection website (https://ec.europa.eu/info/law/law-topic/data-protection_en).`,
  },
  {
    Tit: `Information Updates`,
    Text: `The personal information protection policies may change according to changes to WARP. This does not mean that your personal information will be at risk. In the event of these changes, we will notify you of the changes on our website with the date when such changes will be applied. Therefore, to be notified of our updates, please pay attention to our website. When you continue to use our services after the changes to the personal information protection policies have been applied, we will consider that you have checked and accepted the changes. The most recent date of an update is the date that appears at the bottom of the page.`,
  },
  {
    Tit: `Inquiries`,
    Text: `If you have any suggestions, questions, or inquiries related to these policies or your personal information, or you wish to obtain information on our policies and customs related to another service provider, please refer to the following contact information to contact your personal information representative (or data protection representative).`,
    },
  ],

  info: `WARP Mastering Inc.<br/>
  privacy@warpmastering.com<br/>
  This Privacy Policy was last updated on Feb, 01, 2022
  `

}