//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import serverController, { ip } from '../../server/serverController';

//css
import styled from 'styled-components';

//img
import GoogleImg from '../../img/icon/icon_google.svg';
import FacebookImg from '../../img/icon/icon_facebook.svg';

export default function SnsContainer() {
    const googleFetch = () => {
        window.location.href = `${ip}oauth2/authorization/google`;
    };
    const facebookFetch = () => {
        window.location.href = `${ip}oauth2/authorization/facebook`;
    };

    return (
        <Container>
            <SnsTextWrap>
                <SnsText>or continue with</SnsText>
            </SnsTextWrap>
            <SnsBtnWrap>
                <GoogleBtn type="button" onClick={googleFetch}>
                    <GoogleBtnImg src={GoogleImg} alt="google" />
                    Google
                </GoogleBtn>
                <FacebookBtn type="button" onClick={facebookFetch}>
                    <FacebookBtnImg src={FacebookImg} alt="facebook" />
                    Facebook
                </FacebookBtn>
            </SnsBtnWrap>
        </Container>
    );
}

const Container = styled.div`
    margin-top: 35px;

    @media (max-width: 1297px) {
        margin-top: calc(100vw * (35 / 1280));
    }

    @media (max-width: 1023px) {
        margin-top: calc(100vw * (36 / 428));
    }
`;

const SnsTextWrap = styled.div`
    position: relative;
    text-align: center;

    &::before,
    ::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(50%);
        width: 149px;
        height: 1px;
        background-color: #aaaaaa;
    }

    &::after {
        left: auto;
        right: 0;
    }

    @media (max-width: 1297px) {
        &::before,
        ::after {
            width: calc(100vw * (149 / 1280));
        }
    }

    @media (max-width: 1023px) {
        &::before,
        ::after {
            width: calc(100vw * (124 / 428));
        }
    }
`;

const SnsText = styled.span`
    color: #aaaaaa;
    font-family: 'Pretendard';
    font-size: 15px;
    font-weight: 500;
    vertical-align: middle;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (14 / 428));
    }
`;

const SnsBtnWrap = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 21px;

    @media (max-width: 1297px) {
        margin-top: calc(100vw * (21 / 1280));
    }

    @media (max-width: 1023px) {
        margin-top: calc(100vw * (20 / 428));
    }
`;

const GoogleBtn = styled.button`
    width: calc(100% - 10px / 2);
    padding: 11px 0;
    font-size: 16px;
    font-weight: 500;
    color: #aaaaaa;
    border: 1px solid #dddddd;
    border-radius: 3px;

    @media (max-width: 1297px) {
        width: calc(100% - calc(100vw * (10 / 1280)) / 2);
        padding: calc(100vw * (11 / 1280)) 0;
        font-size: calc(100vw * (16 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100% - calc(100vw * (8 / 428)) / 2);
        padding: calc(100vw * (11 / 428)) 0;
        font-size: calc(100vw * (14 / 428));
    }
`;

const GoogleBtnImg = styled.img`
    width: 19px;
    height: 19px;
    margin-right: 10px;

    @media (max-width: 1297px) {
        width: calc(100vw * (19 / 1280));
        height: calc(100vw * (19 / 1280));
        margin-right: calc(100vw * (10 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (19 / 428));
        height: calc(100vw * (19 / 428));
        margin-right: calc(100vw * (13 / 428));
    }
`;

const FacebookBtn = styled(GoogleBtn)`
    color: #fff;
    border: 1px solid #375da6;
    background-color: #375da6;
    margin-left: 10px;

    @media (max-width: 1297px) {
        margin-left: calc(100vw * (10 / 1280));
    }

    @media (max-width: 1023px) {
        margin-left: calc(100vw * (8 / 428));
    }
`;

const FacebookBtnImg = styled(GoogleBtnImg)``;
