import { useEffect } from "react";
import { Mobile, PC } from "../../media/MediaQuery"
//css
import styled from "styled-components"

// component
import HeaderWhite from "../../component/common/HeaderWhite"
import HeaderMb from '../../component/common/HeaderMb'
import MasteringBody from "../../component/mastering/MasteringBody";
import Footer from "../../component/common/Footer"

export default function MasteringPage(){

    return(
        <>
            <PC>
                <HeaderWhite/>
            </PC>
            <Mobile>
                <HeaderMb/>
            </Mobile>
            <MasteringBody/>
            <Footer/>
        </>
    );

}