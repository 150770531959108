import { useEffect } from "react";
import { Mobile, PC } from "../../media/MediaQuery"
//css
import styled from "styled-components"

// component
import Header from "../../component/common/Header"
import HeaderMb from '../../component/common/HeaderMb'
import IntroBody from "../../component/intro/IntroBody"
import Footer from "../../component/common/Footer"

export default function IntroPage(){

    return(
        <>
            <PC>
                <Header/>
            </PC>
            <Mobile>
                <HeaderMb/>
            </Mobile>
            <IntroBody/>
            <Footer/>
        </>
    );

}