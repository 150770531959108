//react
import { useEffect, useState, useRef } from "react";
import { Link,useHistory, useParams } from "react-router-dom";

//css
import styled from "styled-components"

export default function TabBtn({data, urlTitle, titleText}){

    const params = useParams();
    const history = useHistory();

    const [tabIdx, setTabIdx] = useState(0);
    return(
        <>
            <Title>{titleText}</Title>
            <MenuCon>
                <MenuWrap>
                    {
                        data.map((item, index) => {
                            const {title, paramsList} = item;
                            return <MenuList key={index} active={params.listTitle === paramsList ? true : false} onClick={() => {history.push(`/${params.country}/${urlTitle}/${paramsList}`); setTabIdx(index)}}>{title}</MenuList>
                        })
                    }
                </MenuWrap>
            </MenuCon>            
        </>
    );

}

const Title = styled.h1`
    font-size: 40px;
    font-weight: bold;
    width: 1280px;
    margin: 0 auto 48px;
    text-transform: uppercase;

    @media (max-width: 1297px){
        font-size: calc(100vw * (40 / 1280));
        width: 100%;
        padding: 0 calc(100vw * (25 / 1280));
        margin: 0 auto calc(100vw * (48 / 1280));
    }

    @media (max-width: 1023px){
        font-size: calc(100vw * (25 / 428));
        padding: 0 calc(100vw * (25 / 428));
        margin: 0 auto calc(100vw * (62 / 428));
    }
`

const MenuCon = styled.div`
    border-bottom: 1px solid #C7C7C7;
    margin-bottom: 100px;

    @media(max-width: 1297px){
        margin-bottom: calc(100vw * (100 / 1280));
    }

    @media (max-width: 1023px){
        margin-bottom: calc(100vw * (50 / 428));
        padding: 0 calc(100vw * (25 / 428));
    }
`

const MenuWrap = styled.ul`
    display: flex;
    justify-content: center;
`

const MenuList = styled.li`
    width: 240px;
    padding: 16px 0;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer; 

    ${({active}) => {
        return active ?
        `border-bottom: 4px solid #005AA2;`
        :
        `border-bottom: none;`
        }
    }

    @media (max-width: 1297px){
        width: calc(100vw * (240 / 1280));
        padding: calc(100vw * (16 / 1280)) 0;
        font-size: calc(100vw * (16 / 1280));
    }

    @media (max-width: 1023px){
        width: 100%;
        padding: calc(100vw * (17 / 428)) 0;
        font-size: calc(100vw * (14 / 428));
    }

`