export const KR = {
  dataPopText: "플랜을 업그레이드했습니다.",
  dataBlueText: "*144$ 가 이전 계정 요금에서 7일 이내에서 지불합니다.",
  dataBtnText: "닫기",
  
  comDataPopText: "계정을 연간계정으로 업그레이드 하시겠습니까?",
  comDataBlueText: "*144$ 가 이전 계정 요금에서 7일 이내에서 지불합니다.",
  comDataSubBtnText: "업그레이드",
  comBtnText: "아니오",
  
  // 취소 시 날짜 변경 되도록!
  canclePopText: "구독이 취소되었습니다.",
  cancleBlueText: "*구독이 취소되었어도, 2022년 1월 28일까지 이용 가능 합니다.",
  cancleBtnText: "닫기",
  
  cancleComDataPop: "구독취소 하시겠습니까?",
  cancleComBlueText: "*구독이 취소되었어도, 2022년 1월 28일까지 이용 가능 합니다.",
  cancleComSubText: "구독 취소",
  cancleCOmBtnText : "아니오"
}