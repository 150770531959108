import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { langAction } from '../../store/actionCreator';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

export default function IndexPage() {
    const { country } = useParams();
    const lang = useSelector((state) => state.language.lang);
    useEffect(() => {
        langAction.langUpdate(country);
    }, [country]);

    return <></>;
}
