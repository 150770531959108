//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';

//css
import 'swiper/swiper-bundle.css';
import styled from 'styled-components';

//img
import YourImg from '../../img/main/slider1.png';
import YourImgMb from '../../img/main/slider1_mb.png';
import YourNum from '../../img/main/slide1_num.png';
import AudioBg from '../../img/main/slider2_bg.png';
import AudioBgMb from '../../img/main/slider2_bg_mb.png';
import AudioImg from '../../img/main/slider2.png';
import AudioImgMb from '../../img/main/slider2_mb.png';
import AudioNum from '../../img/main/slide2_num.png';
import NoiseBg from '../../img/main/slider3_bg.png';
import NoiseBgMb from '../../img/main/slider3_bg_mb.png';
import NoiseImg from '../../img/main/slider3.png';
import NoiseImgMb from '../../img/main/slider3_mb.png';
import NoiseNum from '../../img/main/slide3_num.png';
import dragdropNum from '../../img/main/slide4_num.png';
import DragdropImg from '../../img/main/dragdrop_bg.png';
import customNum from '../../img/main/slide5_num.png';
import CustomImg from '../../img/main/customize_bg.png';
import ArrowBtnImg from '../../img/icon/icon_arrow_bottom_color.svg';
import PrevImg from '../../img/icon/icon_arrow_left.svg';
import NextImg from '../../img/icon/icon_arrow_right.svg';
import wave from '../../img/main/wave.png';

// lang
import { useLangText, useLangStyle } from '../../utils/langugeController/langController';
import { KR, EN, CN, ES } from './language/MainSlider/combLang';

export default function MainSlider() {
    const langText = useLangText(KR, EN, CN, ES);
    const langStyle = useLangStyle;

    SwiperCore.use([Autoplay, Navigation]);

    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    const { country } = useParams();
    return (
        <>
            <Container className="mainSlide">
                <Swiper
                    autoplay={{
                        delay: 10000,
                        disableOnInteraction: false,
                    }}
                    navigation={{
                        prevEl: navigationPrevRef.current ? navigationPrevRef.current : undefined,
                        nextEl: navigationNextRef.current ? navigationNextRef.current : undefined,
                    }}
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current;
                        swiper.params.navigation.nextEl = navigationNextRef.current;
                        swiper.navigation.update();
                    }}
                    loop={true}
                >
                    <NavigationBtnWrap>
                        <PrevBtn ref={navigationPrevRef}>
                            <PrevBtnImg src={PrevImg} alt="prev" />
                        </PrevBtn>
                        <NextBtn ref={navigationNextRef}>
                            <NextBtnImg src={NextImg} alt="next" />
                        </NextBtn>
                    </NavigationBtnWrap>
                    {/* 01 */}
                    <SwiperSlide>
                        <YourSlider>
                            <YourSliderImg></YourSliderImg>
                            <YourSliderTextCon>
                                <YourSliderTextWrap>
                                    <YourSliderNum src={YourNum} alt="slider1_num" />
                                    <YourSliderTit>AI mastering</YourSliderTit>
                                    <YourSliderText>Your AI Audio Finalizer</YourSliderText>
                                    <YourSliderSubText>{langText.YourSliderSubText}</YourSliderSubText>
                                    <YourBtn to={`/${country}/intro`}>
                                        <ArrowBtn src={ArrowBtnImg} alt="arrowbtn" />
                                    </YourBtn>
                                </YourSliderTextWrap>
                            </YourSliderTextCon>
                        </YourSlider>
                    </SwiperSlide>
                    {/* 02 */}
                    <SwiperSlide>
                        <AudioSlider>
                            <AudioSliderImg></AudioSliderImg>
                            <AudioSliderTextCon>
                                <AudioSliderTextWrap>
                                    <AudioSliderNum src={AudioNum} alt="slider2_num" />
                                    <AudioSliderTit>Audio Mastering</AudioSliderTit>
                                    <AudioSliderText>
                                        {`Audio mastering`}
                                        <br />
                                        {`for your music`}
                                    </AudioSliderText>
                                    <AudioSliderSubText>{langText.AudioSliderSubText}</AudioSliderSubText>
                                    <AudioBtn to={`/${country}/intro#AiSection`}>
                                        <ArrowBtn src={ArrowBtnImg} alt="arrowbtn" />
                                    </AudioBtn>
                                </AudioSliderTextWrap>
                            </AudioSliderTextCon>
                        </AudioSlider>
                    </SwiperSlide>
                    {/* 03 */}
                    <SwiperSlide>
                        <NoiseSlider>
                            <NoiseSliderImg></NoiseSliderImg>
                            <NoiseSliderTextCon>
                                <NoiseSliderTextWrap>
                                    <NoiseSliderNum src={NoiseNum} alt="slider3_num" />
                                    <NoiseSliderTit>Audio Mastering on Video</NoiseSliderTit>
                                    <NoiseSliderText>
                                        {`Noise Reduction and`}
                                        <br />
                                        {`Audio mastering for`}
                                        <br />
                                        {`your creation`}
                                    </NoiseSliderText>
                                    <NoiseSliderSubText>{langText.NoiseSliderSubText}</NoiseSliderSubText>
                                    <NoiseBtn to={`/${country}/intro#AudioSection`}>
                                        <ArrowBtn src={ArrowBtnImg} alt="arrowbtn" />
                                    </NoiseBtn>
                                </NoiseSliderTextWrap>
                            </NoiseSliderTextCon>
                        </NoiseSlider>
                    </SwiperSlide>
                    {/* 04 */}
                    {/* AudioSlider랑 똑같이 만들기 */}
                    <SwiperSlide>
                        <DragdropSlider>
                            <DragdropSliderImg></DragdropSliderImg>
                            <DragdropSliderTextCon>
                                <DragdropSliderTextWrap>
                                    <DragdropSliderNum src={dragdropNum} alt="slider4_num" />
                                    <DragdropSliderTit>MUSIC, AUDIOBOOK, DIALOG <br/>AND VIDEO FILES</DragdropSliderTit>
                                    <DragdropSliderText>
                                        {`Just Drag`}
                                        <br />
                                        {`& Drop your file`}
                                    </DragdropSliderText>
                                    <DragdropSliderSubText>{langText.JustDrgDropSubText}</DragdropSliderSubText>
                                    <DragdropBtn to={`/${country}/intro`}>
                                        <ArrowBtn src={ArrowBtnImg} alt="arrowbtn" />
                                    </DragdropBtn>
                                </DragdropSliderTextWrap>
                            </DragdropSliderTextCon>
                        </DragdropSlider>
                    </SwiperSlide>
                    {/* 05 */}
                    <SwiperSlide>
                        <CustomSlider>
                            <CustomSliderImg></CustomSliderImg>
                            <CustomSliderTextCon>
                                <CustomSliderTextWrap>
                                    <CustomSliderNum src={customNum} alt="slider5_num" />
                                    <CustomSliderTit>CUSTOMIZATION</CustomSliderTit>
                                    <CustomSliderText>
                                        {`Customize`}
                                        <br />
                                        {`your master piece`}
                                    </CustomSliderText>
                                    <CustomSliderSubText>{langText.CustomizeSubText}</CustomSliderSubText>
                                    <CustomBtn to={`/${country}/intro`}>
                                        <ArrowBtn src={ArrowBtnImg} alt="arrowbtn" />
                                    </CustomBtn>
                                </CustomSliderTextWrap>
                            </CustomSliderTextCon>
                        </CustomSlider>
                    </SwiperSlide>
                </Swiper>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    position: relative;
`;

const YourSlider = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;

    @media (max-width: 1023px) {
        height: calc(100vw * (724 / 428));
    }
`;

const YourSliderImg = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1280px;
    height: 458px;
    object-fit: contain;
    z-index: -1;
    background: url(${YourImg}) no-repeat center / cover;

    @media (max-width: 1297px) {
        width: 100%;
        height: calc(100vw * (458 / 1280));
    }

    @media (max-width: 1023px) {
        height: calc(100vw * (410 / 428));
        top: calc(100vw * (82 / 428));
        transform: translateX(-50%);
        background: url(${YourImgMb}) no-repeat center / cover;
    }
`;

const YourSliderTextCon = styled.div`
    max-width: 1280px;
    padding: 0 61px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 1297px) {
        max-width: 100%;
        padding: 0 calc(100vw * (61 / 1280));
    }

    @media (max-width: 1023px) {
        align-items: flex-end;
        padding: 0 calc(100vw * (25 / 428));
    }
`;

const YourSliderTextWrap = styled.div`
    padding-bottom: 134px;
    position: relative;

    @media (max-width: 1297px) {
        padding-bottom: calc(100vw * (134 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
        padding-bottom: calc(100vw * (112 / 428));
        margin-bottom: calc(100vw * (120 / 428));
    }
`;

const YourSliderNum = styled.img`
    display: block;
    width: 108px;
    height: 74px;
    margin-left: auto;
    margin-bottom: 75px;

    @media (max-width: 1297px) {
        width: calc(100vw * (108 / 1280));
        height: calc(100vw * (74 / 1280));
        margin-bottom: calc(100vw * (75 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (58 / 428));
        height: calc(100vw * (40 / 428));
        margin-bottom: 0;
        position: absolute;
        right: 0;
        top: calc(100vw * (-34 / 428));
    }
`;

const YourSliderTit = styled.p`
    color: #008984;
    font-size: 15px;
    font-weight: bold;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
    }
`;

const YourSliderText = styled.p`
    font-size: 28px;
    font-weight: bold;
    margin: 12px 0 20px;
    line-height: 35px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (28 / 1280));
        margin: calc(100vw * (12 / 1280)) 0 calc(100vw * (20 / 1280));
        line-height: calc(100vw * (35 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (18 / 428));
        margin: calc(100vw * (10 / 428)) 0 calc(100vw * (21 / 428));
        line-height: calc(100vw * (23 / 428));
    }
`;

const YourSliderSubText = styled.p`
    font-size: 17px;
    font-weight: 500;
    line-height: 25px;
    font-family: 'Pretendard';

    @media (max-width: 1297px) {
        font-size: calc(100vw * (17 / 1280));
        line-height: calc(100vw * (25 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (14 / 428));
        line-height: calc(100vw * (23 / 428));
    }
`;

const YourBtn = styled(Link)`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 45px;
    height: 45px;
    transition: 0.3s;

    &:hover {
        height: 67px;
        padding-bottom: 22px;
    }

    @media (max-width: 1297px) {
        width: calc(100vw * (45 / 1280));
        height: calc(100vw * (45 / 1280));

        &:hover {
            height: calc(100vw * (67 / 1280));
            padding-bottom: calc(100vw * (22 / 1280));
        }
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (29 / 428));
        height: calc(100vw * (29 / 428));

        &:hover {
            height: calc(100vw * (29 / 428));
            padding-bottom: 0;
        }
    }
`;

const ArrowBtn = styled.img``;

const AudioSlider = styled(YourSlider)`
    background: url(${AudioBg}) no-repeat center / cover;

    @media (max-width: 1023px) {
        background: url(${AudioBgMb}) no-repeat center / cover;
    }
`;

const AudioSliderImg = styled(YourSliderImg)`
    background: url(${AudioImg}) no-repeat center / cover;

    @media (max-width: 1023px) {
        background: url(${AudioImgMb}) no-repeat center / cover;
    }
`;

const AudioSliderTextCon = styled(YourSliderTextCon)`
    justify-content: flex-start;
    position: relative;
`;

const AudioSliderTextWrap = styled(YourSliderTextWrap)`
    text-align: right;
    position: static;

    @media (max-width: 1023px) {
        position: relative;
        padding-bottom: calc(100vw * (81 / 428));
    }
`;

const AudioSliderNum = styled(YourSliderNum)`
    margin-left: 0;

    @media (max-width: 1023px) {
        left: 0;
        right: auto;
        top: calc(100vw * (-19 / 428));
    }
`;

const AudioSliderTit = styled(YourSliderTit)``;

const AudioSliderText = styled(YourSliderText)``;

const AudioSliderSubText = styled(YourSliderSubText)`
    @media (max-width: 1023px) {
        & br {
            display: none;
        }
    }
`;

const AudioBtn = styled(YourBtn)`
    bottom: calc(100vh - 704px);
    right: 72px;

    @media (max-width: 1297px) {
        bottom: calc(100vh - calc(100vw * (704 / 1280)));
        right: calc(100vw * (72 / 1280));
    }

    @media (max-width: 1023px) {
        bottom: 0;
    }
`;

const NoiseSlider = styled(YourSlider)`
    background: url(${NoiseBg}) no-repeat center / cover;

    @media (max-width: 1023px) {
        background: url(${NoiseBgMb}) no-repeat center / cover;
    }
`;

const NoiseSliderImg = styled(YourSliderImg)`
    background: url(${NoiseImg}) no-repeat center / cover;

    @media (max-width: 1023px) {
        background: url(${NoiseImgMb}) no-repeat center / cover;
    }
`;
const NoiseSliderTextCon = styled(YourSliderTextCon)``;

const NoiseSliderTextWrap = styled(YourSliderTextWrap)`
    @media (max-width: 1023px) {
        padding-bottom: calc(100vw * (58 / 428));
    }
`;

const NoiseSliderNum = styled(YourSliderNum)`
    @media (max-width: 1023px) {
        top: calc(100vw * (-18 / 428));
    }
`;

const NoiseSliderTit = styled(YourSliderTit)``;

const NoiseSliderText = styled(YourSliderText)``;

const NoiseSliderSubText = styled(YourSliderSubText)`
    @media (max-width: 1023px) {
        & br {
            display: none;
        }
    }
`;

const NoiseBtn = styled(YourBtn)``;

const DragdropSlider = styled(YourSlider)`
    background: url(${AudioBg}) no-repeat center / cover;

    @media (max-width: 1023px) {
        background: url(${AudioBgMb}) no-repeat center / cover;
    }
`;

const DragdropSliderImg = styled(YourSliderImg)`
    background: url(${DragdropImg}) no-repeat 80% / 40%;

    @media (max-width: 1023px) {
        background: url(${DragdropImg}) no-repeat 60% 30% / 80%;
    }
`;

const DragdropSliderTextCon = styled(YourSliderTextCon)`
    justify-content: flex-start;
    position: relative;
`;

const DragdropSliderTextWrap = styled(YourSliderTextWrap)`
    text-align: right;
    position: static;

    @media (max-width: 1023px) {
        position: relative;
        padding-bottom: calc(100vw * (75 / 428));
    }
`;

const DragdropSliderNum = styled(YourSliderNum)`
    margin-left: 0;

    @media (max-width: 1023px) {
        left: 0;
        right: auto;
        top: calc(100vw * (-19 / 428));
    }
`;

const DragdropSliderTit = styled(YourSliderTit)`
    @media (max-width: 1023px) {
    }
`;

const DragdropSliderText = styled(YourSliderText)`
    height: 50px;
    background: url(${wave}) no-repeat center / 100% 100%;
`
const DragdropSliderSubText = styled(YourSliderSubText)`
    @media (max-width: 1023px) {
        & br {
            display: none;
        }
    }
`;

const DragdropBtn = styled(YourBtn)`
    bottom: calc(100vh - 704px);
    right: 72px;

    @media (max-width: 1297px) {
        bottom: calc(100vh - calc(100vw * (704 / 1280)));
        right: calc(100vw * (72 / 1280));
    }

    @media (max-width: 1023px) {
        bottom: 0;
    }
`;

const CustomSlider = styled(YourSlider)`
    /* background: url(${NoiseBg}) no-repeat center / cover; */

    @media (max-width: 1023px) {
        /* background: url(${NoiseBgMb}) no-repeat center / cover; */
    }
`;

const CustomSliderImg = styled(YourSliderImg)`
    background: url(${CustomImg}) no-repeat 25% / 40%;

    @media (max-width: 1023px) {
        background: url(${CustomImg}) no-repeat 60% 35% / 80%;
    }
`;
const CustomSliderTextCon = styled(YourSliderTextCon)``;

const CustomSliderTextWrap = styled(YourSliderTextWrap)`
    @media (max-width: 1023px) {
        padding-bottom: calc(100vw * (58 / 428));
    }
`;

const CustomSliderNum = styled(YourSliderNum)`
    @media (max-width: 1023px) {
        top: calc(100vw * (-18 / 428));
    }
`;

const CustomSliderTit = styled(YourSliderTit)``;

const CustomSliderText = styled(YourSliderText)`
    height: 50px;
    background: url(${wave}) no-repeat center / 100% 100%;
`;

const CustomSliderSubText = styled(YourSliderSubText)`
    @media (max-width: 1023px) {
        & br {
            display: none;
        }
    }
`;

const CustomBtn = styled(YourBtn)``;

const NavigationBtnWrap = styled.div`
    width: 1280px;
    height: 45px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    @media (max-width: 1297px) {
        width: 100%;
        height: calc(100vw * (45 / 1280));
    }
`;

const PrevBtn = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 22px;
    height: 45px;
    cursor: pointer;

    @media (max-width: 1297px) {
        width: calc(100vw * (22 / 1280));
        height: calc(100vw * (45 / 1280));
        left: calc(100vw * (25 / 1280));
    }

    @media (max-width: 1023px) {
        display: none;
    }
`;

const PrevBtnImg = styled.img``;

const NextBtn = styled(PrevBtn)`
    left: auto;
    right: 0;

    @media (max-width: 1297px) {
        right: calc(100vw * (25 / 1280));
    }
`;

const NextBtnImg = styled.img``;
