//react
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

//css
import styled from 'styled-components';

import { imgURL } from '../../server/awsURL';
import Parser from 'html-react-parser/dist/html-react-parser';

import serverController from '../../server/serverController';

import BellMusicImg from '../../img/icon/icon_music.svg';
import BellNewsImg from '../../img/icon/icon_news.svg';
import BellSubscriptionImg from '../../img/icon/icon_subscription.svg';

export const BellListComp = (props) => {
    const { item, setReadClick, readClick } = props;
    const { country } = useParams();
    var itemDate = item?.prDate?.split(' ')[0];
    const tokenData = sessionStorage.getItem('TOKEN');

    const history = useHistory();

    // 읽음 카운트
    const pushReadFunc = () => {
        serverController.connectFetchController(
            `api/push/pushRead?prId=${item.prId}`,
            'POST',
            null,
            {
                'Content-Type': `application/json`,
                Authorization: `Bearer ${tokenData}`,
            },
            (res) => {
                if (res.result === 1) {
                    setReadClick(!readClick);
                    if (item.pdId === '2') {
                        history.push(`/${country}/support/detail/news/${item.notice_id}`);
                    }
                }
            },
        );
    };

    return (
        <BellList onClick={pushReadFunc}>
            <BellListImgWrap>
                <BellListImg src={imgURL + item.pdImg} alt="bell_list_img" />
            </BellListImgWrap>
            <BellListTextWrap>
                {item?.prId === 1 ? (
                    <BellListTit>{item.pr_mastering_title}</BellListTit>
                ) : (
                    <BellListTit>{item.notice_title && Parser(item.notice_title)}</BellListTit>
                )}
                <BellListText>
                    {item.notice_content && (item.notice_content.length < 40 ? Parser(item.notice_content) : Parser(item.notice_content.slice(0, 40) + '...'))}
                </BellListText>
            </BellListTextWrap>
            <BellListDate> {itemDate.split('-')[0] + '.' + itemDate.split('-')[1] + '.' + itemDate.split('-')[2]}</BellListDate>
        </BellList>
    );
};

const BellList = styled.li`
    display: flex;
    align-items: center;
    padding: 19px;
    background-color: #fff;
    font-family: 'Pretendard';

    @media (max-width: 1297px) {
        place-content: space-between;
        padding: calc(100vw * (19 / 1280));
    }
`;

const BellListImgWrap = styled.div`
    width: 62px;
    height: 62px;

    @media (max-width: 1297px) {
        width: calc(100vw * (62 / 1280));
        height: calc(100vw * (62 / 1280));
    }
`;

const BellListImg = styled.img``;

const BellListTextWrap = styled.div`
    width: 193px;
    margin-left: 18px;
    margin-right: 6px;

    @media (max-width: 1297px) {
        margin-left: calc(100vw * (18 / 1280));
        margin-right: calc(100vw * (6 / 1280));
    }
`;

const BellListTit = styled.div`
    color: #005aa2;
    font-size: 13px;
    margin-bottom: 6px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (13 / 1280));
        margin-bottom: calc(100vw * (6 / 1280));
    }
`;

const BellListText = styled.div`
    color: #444444;
    font-size: 12px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (12 / 1280));
    }
`;

const BellListDate = styled.div`
    text-align: center;
    color: #aaa;
    font-size: 10px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (10 / 1280));
    }
`;
