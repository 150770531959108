//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';

import serverController from '../../server/serverController';

//css
import styled from 'styled-components';

//img
import IconLogo from '../../img/icon/logo.svg';
import GoBackImg from '../../img/icon/icon_goback_button.svg';
import IconBell from '../../img/icon/icon_bell.svg';
import IconBellOn from '../../img/icon/icon_bell_on.svg';
import HamImg from '../../img/icon/icon_ham_btn.svg';
import MenuBtn from '../../img/icon/icon_arrow_right2.svg';
import BellMusicImg from '../../img/icon/icon_music.svg';
import BellNewsImg from '../../img/icon/icon_news.svg';
import BellSubscriptionImg from '../../img/icon/icon_subscription.svg';

import { BellListComp } from './BellListComp';

import { imgURL } from '../../server/awsURL';

export default function HeaderMb() {
    // 이전 버튼
    const history = useHistory();
    const location = useLocation();
    const { country } = useParams();
    // 알림
    const [bellState, setBellState] = useState(false);
    const [isOnClick, setIsOnClick] = useState(false);
    const [bellListData, setBellListData] = useState([]);
    const [readClick, setReadClick] = useState(false);
    // 메뉴 오픈
    const [menuState, setMenuState] = useState(true);

    function deleteCookie() {
        sessionStorage.clear();
    }
    // 로그인
    const [getToken, setGetToken] = useState(null);
    const tokenData = sessionStorage.getItem('TOKEN');

    const burger = () => {
        const body = document.body;
        if (menuState === false) {
            body.style.overflow = 'hidden';
        } else {
            body.style.overflow = 'auto';
        }
    };

    useEffect(() => {
        if (tokenData) {
            // 토큰 세팅
            setGetToken(tokenData);
            // 알림 count
            serverController.connectFetchController(
                `api/push/notReadCount`,
                'POST',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                    }
                },
            );
            //알림 api
            serverController.connectFetchController(
                `api/push/list?offset=${1}&limit=${5}`,
                'GET',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                        if (res.pushList.length > 0) {
                            setBellState(true);
                            setBellListData(res.pushList.reverse());
                        } else {
                            setBellState(false);
                        }
                    }
                },
            );
        }
    }, []);

    useEffect(() => {
        burger();
    }, [menuState]);

    // background
    const [BgOn, setBgOn] = useState(false);

    useEffect(() => {
        /* 컴포넌트가 마운트 되면 window에 focus 이벤트 부여 */
        let lastPathName = document.location.pathname.split('/')[2];

        if (lastPathName === 'mastering') {
            setBgOn(true);
        } else {
            setBgOn(false);
        }
    }, []);

    // URL 변경될 때마다 헤더 컴포넌트에서 logout 여부 체크
    useEffect(() => {
        console.log('HeaderMb', location);
        checkLoginSession();
    }, [location]);

    const logoutHandle = () => {
        logout();
        window.location.href = `/${country}/main`;
    };

    const logout = () => {
        if (tokenData) {
            sessionStorage.clear();
        }

        serverController.connectFetchController(
            `api/logout`,
            'POST',
            null,
            {
                'Content-Type': `application/json`,
                Authorization: `Bearer ${tokenData}`,
            },
            (res) => {},
        );

        setGetToken(null);
        localStorage.removeItem('completeData');
        localStorage.removeItem('uploadFiles');
    };

    const checkLoginSession = () => {
        // 사용자 로그인 후 60분(JWT 유효 시간)이 지나면 라우팅 변경 시 로그아웃 시킵니다.
        const [now, logged] = [Date.now(), Number(sessionStorage.getItem('LOGGED'))];
        if (now - logged > 1000 * 60 * 60) {
            logout();
        }
    };

    const goLink = (link) => {
        checkLoginSession();
        const linkTo = link.includes('mypage') && !sessionStorage.getItem('TOKEN') ? 'login' : link;
        history.push(`/${country}/${linkTo}`);
    };

    return (
        <Container active={BgOn}>
            <HeaderWrap>
                <GoBackBtnWrap>
                    <GoBackBtn
                        type="button"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <GoBackBtnImg src={GoBackImg} alt="gobackbtn" />
                    </GoBackBtn>
                </GoBackBtnWrap>
                <LogoWrap>
                    <LogoLink to={`/${country}/main`} onClick={checkLoginSession}>
                        <LogoImg src={IconLogo} alt="logo" />
                    </LogoLink>
                </LogoWrap>
                <UtilWrap>
                    {/* 로그인 했을때만 */}
                    {getToken ? (
                        <BellStatus>
                            <BellIconWrap
                                onClick={() => {
                                    setIsOnClick(!isOnClick);
                                }}
                            >
                                {bellState ? <BellIcon src={IconBellOn} alt="bellicon" /> : <BellIcon src={IconBell} alt="bellicon" />}
                            </BellIconWrap>
                            {bellState ? (
                                <BellListCon isOnClick={isOnClick}>
                                    <BellListWrap>
                                        {bellListData.map((item, value) => {
                                            return <BellListComp item={item} key={value} setReadClick={setReadClick} readClick={readClick} />;
                                        })}
                                    </BellListWrap>
                                </BellListCon>
                            ) : (
                                <BellListCon isOnClick={isOnClick}>
                                    <Link to={`/${country}/support/news`} onClick={checkLoginSession}>
                                        <BellListWrap>
                                            <BellListNo>
                                                No new alarm
                                                <br />
                                                <span style={{ fontSize: 12 }}>Move to Newsletter</span>
                                            </BellListNo>
                                        </BellListWrap>
                                    </Link>
                                </BellListCon>
                            )}
                        </BellStatus>
                    ) : null}
                    <HamBtn
                        onClick={() => {
                            setMenuState(!menuState);
                            burger();
                        }}
                    >
                        <HamBtnImg src={HamImg} alt="hambtn" />
                    </HamBtn>
                </UtilWrap>
            </HeaderWrap>
            <NavCon menuState={menuState}>
                <NavWrap menuState={menuState}>
                    <MenuWrap>
                        <MenuList>
                            <MenuButton
                                onClick={() => {
                                    goLink('mastering/upload');
                                }}
                            >
                                Mastering
                            </MenuButton>
                        </MenuList>
                        <MenuList>
                            <MenuButton
                                onClick={() => {
                                    goLink('library');
                                }}
                            >
                                Library
                            </MenuButton>
                        </MenuList>
                        <MenuList>
                            <MenuButton
                                onClick={() => {
                                    goLink('tutorial');
                                }}
                            >
                                Tutorial
                            </MenuButton>
                        </MenuList>
                        <MenuList>
                            {/* <Sales>Sale</Sales> */}
                            <MenuButton
                                onClick={() => {
                                    goLink('payment/terms');
                                }}
                            >
                                Plan
                            </MenuButton>
                        </MenuList>
                        {/* <MenuList>
                            <MenuButton
                                onClick={() => {
                                    goLink('intro');
                                }}
                            >
                                About us
                            </MenuButton>
                        </MenuList> */}
                    </MenuWrap>
                    <LoginWrap>
                        {getToken ? (
                            <LoginStatus>
                                <MenuButton onClick={logoutHandle}>Log Out</MenuButton>
                            </LoginStatus>
                        ) : (
                            <LoginStatus>
                                <MenuButton
                                    onClick={() => {
                                        goLink('login');
                                    }}
                                >
                                    Log in
                                </MenuButton>
                            </LoginStatus>
                        )}
                        {getToken ? (
                            <MypageBtnWrap>
                                <MenuButton
                                    onClick={() => {
                                        goLink('mypage/myinfo');
                                    }}
                                >
                                    My page
                                </MenuButton>
                            </MypageBtnWrap>
                        ) : (
                            <MypageBtnWrap>
                                <MenuButton
                                    onClick={() => {
                                        goLink('signup');
                                    }}
                                >
                                    Sign Up
                                </MenuButton>
                            </MypageBtnWrap>
                        )}
                    </LoginWrap>
                </NavWrap>
                <Background
                    menuState={menuState}
                    onClick={() => {
                        setMenuState(true);
                        burger();
                    }}
                ></Background>
            </NavCon>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    height: calc(100vw * (63 / 428));
    position: fixed;
    z-index: 99;
    //background-color: #fff;

    ${({ active }) => (active ? 'background-color: rgba(255, 255, 255, 0.72);' : 'background-color: #fff;')}
`;
const HeaderWrap = styled.div`
    padding: 0 calc(100vw * (25 / 428));
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;

const GoBackBtnWrap = styled.div`
    width: calc(100% - calc(100vw * (25 / 428)) / 3);
`;

const GoBackBtn = styled.button`
    width: calc(100vw * (19 / 428));
`;

const GoBackBtnImg = styled.img``;

const LogoWrap = styled(GoBackBtnWrap)``;

const LogoLink = styled(Link)`
    display: block;
    margin: 0 auto;
    width: calc(100vw * (116 / 428));
    height: calc(100vw * (19 / 428));
`;

const LogoImg = styled.img`
    width: 100%;
    object-fit: contain;
`;

const MenuButton = styled.div`
    cursor: pointer;
    position: relative;
`;

const UtilWrap = styled.ul`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% - calc(100vw * (25 / 428)) / 3);
`;

const BellStatus = styled.li`
    cursor: pointer;
`;

const BellIconWrap = styled.div``;

const BellIcon = styled.img`
    width: calc(100vw * (15 / 428));
    height: calc(100vw * (17 / 428));
    object-fit: contain;
`;

const BellListCon = styled.div`
    position: absolute;
    left: 0;
    top: calc(100vw * (63 / 428));
    width: 100vw;
    background-color: #ffffff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding-right: calc(100vw * (25 / 428));

    ${({ isOnClick }) => {
        return isOnClick ? `display: block;` : `display: none;`;
    }}
`;

const BellListWrap = styled.ul`
    width: 100vw;
    max-height: calc(100vw * (301 / 428));
    overflow-y: auto;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    padding: 10px 0;
    &::-webkit-scrollbar {
        width: calc(100vw * (12 / 428));
    }

    &::-webkit-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: #eee;
    }
`;

const BellList = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calc(100vw * (25 / 428)) calc(100vw * (27 / 428)) calc(100vw * (25 / 428)) calc(100vw * (41 / 428));
    font-family: 'Pretendard';
    background-color: #fff;

    &:hover {
        background-color: #f8fafd;
    }
`;

const BellListImgWrap = styled.div`
    width: calc(100vw * (49 / 428));
    height: calc(100vw * (49 / 428));
`;

const BellListImg = styled.img``;

const BellListTextWrap = styled.div`
    width: calc(100vw * (191 / 428));
    margin-left: calc(100vw * (30 / 428));
    margin-right: calc(100vw * (15 / 428));
`;

const BellListTit = styled.p`
    color: #005aa2;
    font-size: calc(100vw * (13 / 428));
    margin-bottom: calc(100vw * (6 / 428));
`;

const BellListText = styled.p`
    color: #444444;
    font-size: calc(100vw * (13 / 428));
`;

const BellListDate = styled.p`
    font-size: calc(100vw * (10 / 428));
    color: #aaaaaa;
`;

const HamBtn = styled.li`
    cursor: pointer;
    margin-left: calc(100vw * (25 / 428));
`;

const HamBtnImg = styled.img`
    width: calc(100vw * (15 / 428));
    height: calc(100vw * (14 / 428));
    object-fit: contain;
`;

const NavCon = styled.div`
    position: absolute;
    top: calc(100vw * (63 / 428));
    right: 0;
    width: calc(100vw * (300 / 428));
    height: calc(100vh - calc(100vw * (63 / 428)));

    ${({ menuState }) => {
        return menuState ? `display: none;` : `display: block;`;
    }}
`;

const NavWrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: calc(100vh * (50 / 869)) calc(100vw * (25 / 428)) calc(100vh * (61 / 869)) calc(100vw * (43 / 428));
    font-family: 'Pretendard';
    font-size: calc(100vh * (17 / 869));
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s;

    ${({ menuState }) => {
        return menuState ? `right: calc(100vw * (-300 / 428)); display:none` : `right: 0;display:block`;
    }}
`;

const MenuWrap = styled.ul``;

const MenuList = styled.li`
    position: relative;

    & a {
        display: block;
    }

    &:not(:last-child) {
        margin-bottom: calc(100vh * (30 / 869));
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background: url(${MenuBtn}) no-repeat center / contain;
        width: calc(100vw * (5 / 428));
        height: calc(100vw * (9 / 428));
    }
`;

// 할인
const Sales = styled.div`
    position: absolute;
    font-size: 14px;
    font-weight: bold;
    color: #aa0000;
    top: -13px;
    left: 25px;
`;
// 할인

const LoginWrap = styled.ul`
    padding-top: calc(100vh * (150 / 869));
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LoginStatus = styled.li`
    cursor: pointer;
`;

const MypageBtnWrap = styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw * (117 / 428));
    height: calc(100vh * (50 / 869));
    border-radius: 25px;
    background-image: linear-gradient(to bottom, #0054a6, #008c82);
    cursor: pointer;
    color: #fff;
`;
const BellListNo = styled.li`
    padding: 19px;
    background-color: #fff;
    color: #000;
    text-align: center;

    &:hover {
        background-color: #f8fafd;
    }

    @media (max-width: 1297px) {
        padding: calc(100vw * (19 / 1280));
    }
`;

const Background = styled.div`
    position: fixed;
    top: calc(100vw * (63 / 428));
    left: 0;
    width: 100vw;
    height: calc(100vh - calc(100vw * (63 / 428)));
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;

    ${({ menuState }) => {
        return menuState ? `display: none;` : `display: block;`;
    }}
`;
