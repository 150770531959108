//react
import { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

//css
import styled from "styled-components";

//component
import TermsList from "../../component/terms/TermsList";

//lang
import {
  useLangList,
  useLangText,
  useLangAPI
} from "../../utils/langugeController/langController";
import { KR, EN, CN, ES } from "./language/TermsBody/combLang";

export default function TermsBody() {
  const langText = useLangText(KR, EN, CN, ES);
  const termList = useLangList("TermsListData", { KR, EN, CN, ES });
  const langAPI = useLangAPI({path:`api/policy/terms/list`});
  return (
    <Container>
      <TermsTit>Terms and conditions</TermsTit>
      <TermsList item={langAPI} />
      <CopyRight>{langText.CopyRight}</CopyRight>
    </Container>
  );
}

const Container = styled.div`
  padding: 200px 0;
  width: 1280px;
  margin: 0 auto;

  @media (max-width: 1297px) {
    width: 100%;
    padding: calc(100vw * (200 / 1280)) calc(100vw * (25 / 1280));
  }

  @media (max-width: 1023px) {
    padding: calc(100vw * (111 / 428)) calc(100vw * (25 / 428))
      calc(100vw * (100 / 428));
  }
`;

const TermsTit = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 100px;
  text-transform: uppercase;

  @media (max-width: 1297px) {
    font-size: calc(100vw * (40 / 1280));
    margin-bottom: calc(100vw * (100 / 1280));
  }

  @media (max-width: 1023px) {
    font-size: calc(100vw * (20 / 428));
    margin-bottom: calc(100vw * (55 / 428));
  }
`;

const CopyRight = styled.p`
  color: #aaa;
  font-size: 15px;
  margin-top: 45px;
  font-family: "Pretendard";
  line-height: 23px;
  white-space: pre-wrap;

  @media (max-width: 1297px) {
    font-size: calc(100vw * (15 / 1280));
    margin-top: calc(100vw * (45 / 1280));
    line-height: calc(100vw * (23 / 1280));
  }

  @media (max-width: 1023px) {
    font-size: calc(100vw * (11 / 428));
    margin-top: calc(100vw * (57 / 428));
    line-height: calc(100vw * (18 / 428));

    & br {
      display: none;
    }
  }
`;
