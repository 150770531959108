export const ES = {
    MainText: 'La mejor solución para audio',
    MainSubText:
        'Viva la experiencia de utilizar soluciones de audio excelentes y de alta <br/>calidad para todos los contenidos de audio, como música, audiolibros y videos.',
    NoiseText: 'Viva la experiencia de la<br/> cancelación de ruidos y la<br/> masterización integradas<br/>',
    NoiseSubText:
        'Viva la experiencia de un flujo de trabajo conveniente<br/> con un análisis del audio del contenido en cuanto lo suba,<br/> junto con la masterización.',
    EveryoneText: 'Conveniencia que<br/> todos pueden disfrutar',
    EveryoneSubText:
        'Viva la experiencia de un flujo de trabajo conveniente<br/> con un análisis del audio del contenido en cuanto lo suba,<br/> junto con la masterización.',
    IdeaText: 'La imaginación del creador<br/> cobra vida',
    IdeaSubText: 'Viva la experiencia de la reproducción de audio con IA que WARP<br/> ofrece a todos los creadores del mundo. ',
    AiText: 'Masterización de audio con IA para profesionales',
    AiSubText:
        'La masterización con IA de WARP cumple con los estándares de la industria para que su masterización esté al nivel de los expertos. Experimente servicios de masterización con IA para profesionales.',
    UserText: 'Sus ideas frente a usted',
    UserSubText:
        'Puede elegir entre varias opciones para cambiar las diferentes partes de los resultados.<br/> Tiene una selección para elegir, REFUERZO de altos, equilibrado y GOLPE.<br/> Además, puede usar la opción de masterización con referencia de WARP<br/> para personalizar sus resultados de audio con más detalle.<br/> ',
    EasyText: 'Rápido, simple y barato',
    EasySubText:
        'Ya no tiene que gastar tiempo y dinero para masterizar en un estudio.<br/> Reciba los resultados inmediatamente sin tener que pasar por<br/> etapas complicadas. Es fácil y todos lo pueden usar.<br/> Todo lo que necesita hacer es subir su archivo.<br/>',
    MasterText: 'Su OBRA MAESTRA en un solo lugar ',
    MasterSubText: 'WARP le provee una biblioteca para sus obras maestras.<br/> Puede administrar y compartir sus obras en la biblioteca. ',
    AudioText: 'Cualquier contenido con audio',
    AudioSubText: 'Le proveemos la solución de audio óptima para todos los tipos de audio,<br/> incluyendo videos, podcasts y audiolibros.',
    LevelText: 'Es una experiencia fantástica para eliminar<br/> los sonidos indeseados y ajustar los niveles ',
    LevelSubText:
        'Elimina los sonidos innecesarios y hace que los sonidos importantes suenen más claro para que el audio tenga un nivel óptimo.<br/><br/> Esto se hace de una sola vez.<br/> ',
    OvercomeText: 'Basta de equipos de<br/> audio complicados',
    OvercomeSubText:
        'Ya no tiene que comprar equipo caro ni estudiar<br/> programas complicados. Pruébelo desde el teléfono.<br/> WARP le dará los mejores resultados posibles.',
};
