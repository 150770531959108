//react
import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import serverController from '../../server/serverController';
//css
import styled from 'styled-components';
//img
import Close from '../../img/main/close_btn.png';
import Play from '../../img/main/play_btn.png';
import PrevImg from '../../img/icon/icon_left.svg';
import NextImg from '../../img/icon/icon_right.svg';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function MainSection5() {
    const { country } = useParams();
    const [videoPopup, setVideoPopup] = useState(null);
    const [videoList, setVideoList] = useState(null);

    SwiperCore.use([Autoplay, Navigation]);

    const navigationPrevPCRef = useRef(null);
    const navigationNextPCRef = useRef(null);
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    const [windowSize, setWindowSize] = useState('pc');
    const [isPC, setIsPC] = useState(false);

    const ResizeControl = () => {
        if (window.innerWidth < 767) {
            setWindowSize('mobile');
        } else if (window.innerWidth < 1279) {
            setWindowSize('tablet');
        } else {
            setWindowSize('pc');
        }
    };
    function divisionArray(data = [], size = 1) {
        const arr = [];

        for (let i = 0; i < data.length; i += size) {
            arr.push(data.slice(i, i + size));
        }
        return arr;
    }
    const getVideos = () => {
        try {
            serverController.connectFetchController(`api/showcase/list`, 'GET', null, null, (res) => {
                if (res.result === 1) {
                    console.log('RES', res);
                    const data = res.list;
                    setVideoList(data);
                }
            });
        } catch (e) {
            console.error(e);
        }
    };
    useEffect(() => {
        getVideos();
        ResizeControl();
        const agent = window.navigator.userAgent;
        if ((agent.indexOf('iPhone') > -1) | (agent.indexOf('iPad') > -1) | (agent.indexOf('iPod') > -1) | (agent.indexOf('Android') > -1)) {
            setIsPC(false);
        } else {
            setIsPC(true);
        }
        // 태블릿,모바일의 경우 Swiper 다르게 구성
        window.addEventListener('resize', ResizeControl);
        return () => {
            window.removeEventListener('resize', ResizeControl);
        };
    }, []);
    return (
        <>
            <Container>
                <SectionWrap>
                    <SectionTitle>Unleash Your Creativity</SectionTitle>
                    <SectionSubTitle>See review videos posted by professionals</SectionSubTitle>
                    {windowSize === 'pc' ? (
                        <VideoWrap>
                            <PrevBtn ref={navigationPrevPCRef} isPC={isPC}>
                                <PrevBtnImg src={PrevImg} alt="prev" />
                            </PrevBtn>
                            <NextBtn ref={navigationNextPCRef} isPC={isPC}>
                                <NextBtnImg src={NextImg} alt="next" />
                            </NextBtn>
                            <Swiper
                                autoplay={{
                                    delay: 10000,
                                    disableOnInteraction: false,
                                }}
                                navigation={{
                                    prevEl: navigationPrevPCRef.current ? navigationPrevPCRef.current : undefined,
                                    nextEl: navigationNextPCRef.current ? navigationNextPCRef.current : undefined,
                                }}
                                onInit={(swiper) => {
                                    swiper.params.navigation.prevEl = navigationPrevPCRef.current;
                                    swiper.params.navigation.nextEl = navigationNextPCRef.current;
                                    swiper.navigation.update();
                                }}
                                loop={true}
                            >
                                {videoList &&
                                    divisionArray(videoList, 3).map((v, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <SlideSectionWrap>
                                                    {v.map((v) => {
                                                        return (
                                                            <VideoBox key={v.id}>
                                                                <ThumBox
                                                                    style={{
                                                                        backgroundImage: v.showcase_img
                                                                            ? `url(${v.showcase_img})`
                                                                            : `url(https://img.youtube.com/vi/${v.showcase_video}/maxresdefault.jpg)`,
                                                                    }}
                                                                >
                                                                    {/* https://img.youtube.com/vi/유튜브고유코드/maxresdefault.jpg */}
                                                                    <PlayBtn
                                                                        src={Play}
                                                                        alt={'play button'}
                                                                        onClick={() => {
                                                                            setVideoPopup(`https://www.youtube.com/embed/${v.showcase_video}`);
                                                                        }}
                                                                    />
                                                                </ThumBox>
                                                                <VideoTitle>{v.showcase_title}</VideoTitle>
                                                                <VideoContent>{v.showcase_content}</VideoContent>
                                                            </VideoBox>
                                                        );
                                                    })}
                                                </SlideSectionWrap>
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </VideoWrap>
                    ) : (
                        <VideoWrap>
                            <span style={{ display: 'none' }}>{windowSize}</span>
                            <PrevBtn ref={navigationPrevRef} isPC={isPC}>
                                <PrevBtnImg src={PrevImg} alt="prev" />
                            </PrevBtn>
                            <NextBtn ref={navigationNextRef} isPC={isPC}>
                                <NextBtnImg src={NextImg} alt="next" />
                            </NextBtn>
                            <Swiper
                                slidesPerView={windowSize === 'tablet' ? 2.5 : 1.5}
                                spaceBetween={24}
                                autoplay={{
                                    delay: 10000,
                                    disableOnInteraction: false,
                                }}
                                navigation={{
                                    prevEl: navigationPrevRef.current ? navigationPrevRef.current : undefined,
                                    nextEl: navigationNextRef.current ? navigationNextRef.current : undefined,
                                }}
                                onInit={(swiper) => {
                                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                                    swiper.params.navigation.nextEl = navigationNextRef.current;
                                    swiper.navigation.update();
                                }}
                                loop={true}
                            >
                                {videoList &&
                                    videoList.map((v, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <VideoBox>
                                                    <ThumBox
                                                        style={{
                                                            backgroundImage: v.showcase_img
                                                                ? `url(${v.showcase_img})`
                                                                : `url(https://img.youtube.com/vi/${v.showcase_video}/maxresdefault.jpg)`,
                                                        }}
                                                    >
                                                        {/* https://img.youtube.com/vi/유튜브고유코드/maxresdefault.jpg */}
                                                        <PlayBtn
                                                            src={Play}
                                                            alt={'play button'}
                                                            onClick={() => {
                                                                setVideoPopup(`https://www.youtube.com/embed/${v.showcase_video}`);
                                                            }}
                                                        />
                                                    </ThumBox>

                                                    <VideoTitle>{v.showcase_title}</VideoTitle>
                                                    <VideoContent>{v.showcase_content}</VideoContent>
                                                </VideoBox>
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </VideoWrap>
                    )}
                    <TrialBtn to={`/${country}/mastering/upload`}>Start Free-Trial</TrialBtn>
                </SectionWrap>
            </Container>
            {videoPopup && (
                <VideoBGWrap
                    onClick={() => {
                        setVideoPopup(null);
                    }}
                >
                    <Video>
                        <CloseBtn src={Close} alt="close button"></CloseBtn>
                        <VideoFrame
                            src={videoPopup}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></VideoFrame>
                    </Video>
                </VideoBGWrap>
            )}
        </>
    );
}

const Container = styled.div`
    background-color: white;
    position: relative;
    width: 100%;
    z-index: 5;
`;

const SectionWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding-bottom: 160px;
    color: #018487;

    @media (max-width: 1279px) {
        padding-bottom: 120px;
        padding-left: 40px;
    }

    @media (max-width: 767px) {
        padding-bottom: 100px;
        padding-left: 20px;
    }
`;

const SectionTitle = styled.div`
    color: #018487;
    font-size: 32px;
    font-weight: 700;

    @media (max-width: 1279px) {
        font-size: 24px;
    }

    @media (max-width: 767px) {
        font-size: 20px;
    }
`;
const SectionSubTitle = styled.div`
    color: #121212;
    font-size: 24px;
    font-weight: 400;
    margin-top: 24px;

    @media (max-width: 1279px) {
        font-size: 18px;
        margin-top: 8px;
    }

    @media (max-width: 767px) {
        font-size: 16px;
    }
`;
const VideoWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 52px;
    margin-bottom: 80px;
    @media (max-width: 1279px) {
        margin-bottom: 60px;
    }
    @media (max-width: 767px) {
        margin-top: 32px;
        margin-bottom: 40px;
    }
`;
const SlideSectionWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    width: 100%;
    height: 100%;
`;
const VideoBox = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media (max-width: 1279px) {
    }
    @media (max-width: 767px) {
    }
`;
const ThumBox = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 100%;
    min-height: 218px;
    max-height: 218px;
    display: flex;
    @media (max-width: 1279px) {
    }
    @media (max-width: 767px) {
        min-height: 156px;
    }
`;
const VideoTitle = styled.div`
    font-family: 'Pretendard';
    color: #121212;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin-top: 12px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: keep-all; // 문단으로 끊어져서 줄바꿈 됨
    @media (max-width: 1279px) {
        font-size: 16px;
        line-height: 22px;
    }
    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
    }
`;
const VideoContent = styled.div`
    font-family: 'Pretendard';
    color: #121212;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    height: 108px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: keep-all; // 문단으로 끊어져서 줄바꿈 됨
    @media (max-width: 1279px) {
        height: 66px;
        font-size: 16px;
        line-height: 22px;
    }
    @media (max-width: 767px) {
        height: 60px;
        font-size: 14px;
        line-height: 20px;
    }
`;
const PrevBtn = styled.div`
    ${({ isPC }) => {
        return isPC ? `display: flex;` : `display: none;`;
    }}
    position: absolute;
    top: 23%;
    left: -36px;
    transform: translateY(-50%);
    width: 72px;
    height: 72px;
    border: 1px solid #0055a4;
    background-color: white;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    z-index: 3;
    cursor: pointer;

    @media (max-width: 1279px) {
        ${({ isPC }) => {
            return isPC ? `display: flex;` : `display: none;`;
        }}
        width: 34px;
        height: 34px;
        top: 25%;
        left: 0px;
    }
`;

const PrevBtnImg = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 2px;
    @media (max-width: 1279px) {
        width: 24px;
        height: 24px;
    }
`;

const NextBtn = styled.div`
    ${({ isPC }) => {
        return isPC ? `display: flex;` : `display: none;`;
    }}
    position: absolute;
    right: -36px;
    top: 23%;
    transform: translateY(-50%);
    width: 72px;
    height: 72px;
    border: 1px solid #0055a4;
    background-color: white;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    z-index: 3;
    cursor: pointer;
    @media (max-width: 1279px) {
        ${({ isPC }) => {
            return isPC ? `display: flex;` : `display: none;`;
        }}
        width: 34px;
        height: 34px;
        top: 25%;
        right: 0px;
    }
`;

const NextBtnImg = styled.img`
    width: 40px;
    height: 40px;
    margin-left: 2px;
    @media (max-width: 1279px) {
        width: 24px;
        height: 24px;
    }
`;

const PlayBtn = styled.img`
    width: 48px;
    height: 48px;
    cursor: pointer;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 767px) {
        width: 34px;
        height: 34px;
    }
`;
const TrialBtn = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: fit-content;
    height: fit-content;
    padding: 16px 40px;
    color: white;
    border-radius: 40px;
    background: linear-gradient(180deg, #ff544a 0%, #f6756e 100%);

    font-weight: 700;
    font-size: 24px;
    line-height: 142%;
    @media (max-width: 1279px) {
        padding: 14px 36px;
        font-size: 18px;
        line-height: 24px;
    }
    @media (max-width: 767px) {
        padding: 12px 28px;
        font-size: 16px;
        line-height: 22px;
    }
`;
const VideoBGWrap = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1279px) {
    }

    @media (max-width: 767px) {
    }
`;
const Video = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56%;
`;
const CloseBtn = styled.img`
    position: absolute;
    top: calc(100% - 604px - ((100% - 604px) / 2) - 66px);
    right: calc(100% - 1080px - ((100% - 1080px) / 2));
    width: 48px;
    height: 48px;
    z-index: 99;
    cursor: pointer;

    @media (max-width: 1279px) {
        position: absolute;
        width: 32px;
        height: 32px;
        top: -48px;
        right: 0px;
    }
    @media (max-width: 767px) {
        width: 32px;
        height: 32px;
        top: -48px;
        right: 0px;
    }
`;
const VideoFrame = styled.iframe`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    max-width: 1080px;
    max-height: 604px;
    transform: translate(-50%, -50%);
    @media (max-width: 1279px) {
        max-width: 100%;
    }
`;
