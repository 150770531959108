//react
import { useState } from 'react';
//css
import styled from 'styled-components';
//img
import Play from '../../img/main/play_btn.png';
import Close from '../../img/main/close_btn.png';
import BG from '../../img/main/p9_bg.jpg';

export default function MainSection9() {
    const [videoPopup,setVideoPopup] = useState(false)
    return (
        <>
            <Container>
                <SectionWrap>
                    <SectionTitle>Stand Up Your Audiobook, Video Sounds</SectionTitle>
                    <SectionSubTitle>AI Voice Separation</SectionSubTitle>
                    <VideoWrap style={{backgroundImage:`url(${BG})`}}>
                        <PlayBtn src={Play} alt={'play button'} onClick={()=>{setVideoPopup(!videoPopup)}} />
                        <InfoBox><span>AI audio denoiser & Mastering</span></InfoBox>
                    </VideoWrap>
                    <VideoText>
                    We provide the optimal audio solution for all types of content with audio, including videos, podcasts, audio recording and audiobooks.
                    </VideoText>
                </SectionWrap>
            </Container>
            {
                videoPopup&&
                <VideoBGWrap onClick={()=>{setVideoPopup(!videoPopup)}} >
                    <Video>
                        <CloseBtn src={Close} alt='close button'></CloseBtn>
                        <VideoFrame src="https://www.youtube.com/embed/uFialWN5Pew?si=6dD2FRM-o5luuTd-" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></VideoFrame>
                    </Video>
                </VideoBGWrap>
            }
        </>
    )
}

const Container = styled.div`
    background-color: white;
    position: relative;
    width: 100%;
    z-index: 3;
`;

const SectionWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    
    padding: 200px 0px 176px;

    color: #018487;
    font-size: 32px;
    font-weight: 700;

    @media (max-width: 1279px) {
        padding: 80px 40px;
        
        font-size: 18px;
        font-weight: 400;
    }

    @media (max-width: 767px) {
        padding: 100px 20px;
        
        font-size: 20px;
        font-weight: 700;
    }
`;

const SectionTitle = styled.div`
    color: #018487;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
 
    @media (max-width: 1279px) {
        font-size: 24px;
        line-height: 32px;
    }

    @media (max-width: 767px) {
        font-size: 20px;
        line-height: 24px;
    }
`;
const SectionSubTitle = styled.div`
    color: #121212;
    font-size: 24px;
    font-weight: 700;
    margin-top: 12px;

    @media (max-width: 1279px) {
        font-size: 18px;
        margin-top: 8px;
    }

    @media (max-width: 767px) {
        font-size: 16px;
    }
`;
const VideoWrap = styled.div`
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 56%;
    margin-top: 60px;
    margin-bottom: 16px;
    
    @media (max-width: 1279px) {
        margin-top: 40px;
        margin-bottom: 16px;
    }

    @media (max-width: 767px) {
        margin-top: 40px;
        margin-bottom: 16px;
    }
`;
const PlayBtn = styled.img`
    width: 160px;
    height: 160px;
    z-index: 99;
    cursor: pointer;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 1279px) {
        width: 114px;
        height: 114px;
    }
    
    @media (max-width: 767px) {
        width: 54px;
        height: 54px;
    }
    
    `;
const VideoBGWrap = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1279px) {
    }
    
    @media (max-width: 767px) {
    }
    
    `;
const Video = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56%;
    `;
const CloseBtn = styled.img`
    position: absolute;
    top: calc(100% - 604px - ((100% - 604px) / 2) - 66px);
    right: calc(100% - 1080px - ((100% - 1080px) / 2));
    width: 48px;
    height: 48px;
    z-index: 99;
    cursor: pointer;
    
    @media (max-width: 1279px) {
        position: absolute;
        width: 32px;
        height: 32px;
        top: -48px;
        right: 0px;
    }
    @media (max-width: 767px) {
        width: 32px;
        height: 32px;
        top: -48px;
        right: 0px;
    }
    `;
const VideoFrame = styled.iframe`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    max-width: 1080px;
    max-height: 604px;
    transform: translate(-50%, -50%);
    @media (max-width: 1279px) {
        max-width: 100%;
    }
`;
const VideoText = styled.p`
    color: #121212;
    font-size: 24px;
    font-weight: 400;
    font-family: 'Pretendard';
    margin-bottom: 16px;
    line-height: 150%;

    @media (max-width: 1279px) {
        font-size: 16px;
    }

    @media (max-width: 767px) {
        font-size: 14px;
    }
`;
const InfoBox = styled.div`
    position: absolute;
    bottom: 120px;
    right: 0px;
    background: linear-gradient(0deg, rgba(25, 169, 178, 0.12) 0%, rgba(25, 169, 178, 0.12) 100%), #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 80px;

    font-size: 54px;
    font-weight: 600;
    font-family: 'Pretendard';
    span {
        background: linear-gradient(to bottom, #0054a6, #008c82);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    @media (max-width: 1279px) {
        bottom: 55px;
        padding: 24px 48px;
        font-size: 28px;
    }

    @media (max-width: 767px) {
        bottom: 26px;
        padding: 14px 24px;
        font-size: 12px;
        font-weight: 600;
    }
`;