import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';

const UPLOAD_FILES_TYPE = 'warp/files/update';
const UPLOAD_FILES_MODIFY = 'warp/files/modify';

const MASTERED_FILES = 'warp/files/update/masteredFiles';

export const upFiles = createAction(UPLOAD_FILES_TYPE);
export const upFilesModify = createAction(UPLOAD_FILES_MODIFY);
export const masteredFile = createAction(MASTERED_FILES);
const initialState = {
    file: {
        reCheck: null,
        typeArray: [],
    },
    uploadFile: {
        fileArray: [],
    },
    masteredFile: {},
};

export default handleActions(
    {
        [UPLOAD_FILES_TYPE]: (state, action) => {
            return produce(state, (draft) => {
                // console.log("(파일TYPE업데이트)",action, draft)
                draft.file = action.payload ? action.payload : draft.file;
            });
        },
        [UPLOAD_FILES_MODIFY]: (state, action) => {
            return produce(state, (draft) => {
                // console.log("UPLOAD_FILES_MODIFY",state, action, draft)
                draft.uploadFile = action.payload ? action.payload : draft.uploadFile;
            });
        },
        [MASTERED_FILES]: (state, action) => {
            return produce(state, (draft) => {
                // console.log("(완료된파일Redux저장)",state, action, draft)
                draft.masteredFile = action.payload ? action.payload : draft.masteredFile;
            });
        },
    },
    initialState,
);
