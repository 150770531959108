export const ES = {
  TermsListData: [
    {
      Tit: `Política de protección de datos personales`,
      Text: `Al usar nuestros servicios usted está aceptando esta política de protección de datos. Esta política es parte de nuestro contrato de servicios y se aplica del modo descripto a continuación. La terminología utilizada en este contrato de servicios también tiene validez sobre la política de protección de datos personales.`,
    },
    {
      Tit: `Información del usuario`,
      Text: `Para asegurarnos de que nuestros servicios cumplan con las necesidades de los usuarios de la mejor manera posible, reunimos información sobre usted. Por lo tanto, según los servicios que proveemos, reunimos una variedad de información. No se preocupe. Procesamos sus datos personales con rigurosidad (por ejemplo, información que pueda determinar quién es o que se pueda utilizar para estos fines) para proveerle nuestros servicios. Además, procesamos su información personal con su consentimiento.`,
    },
    {
      Tit: `Sobre la información que nos brinda`,
      Text: `Al usar nuestros servicios, es posible que nos provea algunos de los datos personales descriptos a continuación. Esta lista es a título enunciativo.
Dirección de correo electrónico con la que se registró
Su nombre completo, dirección de correo y dirección postal al registrarse
Información de pago al momento de suscribirse a nuestros servicios
El idioma que seleccionó, los planes en los que se registró, su información de facturación, el historial de masterización y códigos promocionales relacionados con la cuenta (cuando corresponda)
La configuración básica para las imágenes, archivos de audio y producción, datos estructurales, archivos que incluyan direcciones de correo y otros datos complementarios (por ejemplo, estilo de música o instrumento), contenido, variables de componentes y otra información (datos)).
La lista de contactos si es accesible
El perfil de visitante de nuestra página web
Las conversaciones que haya tenido con nosotros sobre correos electrónicos o plataformas de redes sociales`,
    },
    {
      Tit: `Sobre lo que se comparte con los servicios`,
      Text: `Es posible que reunamos la siguiente información cuando utilice nuestros servicios.
Información relacionada con el dispositivo que utilice para acceder a nuestros servicios. Esta información incluye: su dirección de IP, navegador y dispositivo utilizado, páginas vinculadas a la nuestra e identificadores conectados a su dispositivo. Si tiene activado el servicio de variable de mediación para la identificación de la ubicación, es posible que recibamos información sobre su ubicación geográfica.
Para reunir información, operar y mejorar nuestros servicios utilizamos cookies. Estas son pequeños archivos de datos que se almacenan en los dispositivos electrónicos. Al iniciar sesión en nuestros servicios, puede activar “cookies permanentes” para almacenar su dirección de correo electrónico y contraseña. Usamos Cookies de inicio de sesión para activar funciones particulares de los servicios, entender y mejorar su experiencia con nuestros servicios, rastrear información relacionada con el uso general de los servicios (tiempo que duran las visitas, cantidad de usuarios, etc.) y optimizar el marketing de nuestros servicios.
La mayoría de los navegadores de Internet le pedirán su consentimiento antes de aceptar las cookies o le ofrecerán la opción de rechazarlas. Sin embargo, si decidiera rechazarlas, es posible que no pueda utilizar nuestros servicios.
Además, nuestros servicios nos permiten combinar la información que reunimos y la información recolectada de otras fuentes. Por ejemplo, si inicia sesión en su cuenta de WARP con Facebook, nos proveerá el acceso a la información relacionada con su cuenta de Facebook.`,
    },
    {
      Tit: `Sobre el procesamiento de los datos`,
      Text: `WARP se toma muy en serio las cuestiones relacionadas con los datos personales. Solo usamos esta información cuando realmente la necesitamos para cumplir con nuestras obligaciones hacia usted y solo los empleados que deban hacerlo debido a sus funciones podrán acceder a sus datos personales.
Por lo tanto, es posible que le proveamos la variedad de tipos de servicios con la información que reunimos sobre usted que se destalla más abajo:
Autentificar al usuario cuando inicia sesión en su cuenta.
Brindar servicios solicitados de suscripción y realizar los pagos correspondientes.
Acceder, almacenar y analizar sus datos para que pueda utilizar nuestros servicios.
Activar funciones particulares, entre las que se encuentran la vista previa, la edición, la habilidad de compartir y de buscar canciones. Por ejemplo, si nos brinda acceso a sus contactos, almacenaremos esta información para simplificar ciertas interacciones, incluyendo el envío de correos electrónicos o para compartir datos o el uso de nuestros servicios.
Entender y analizar sus patrones de uso y preferencias, mejorar los servicios y desarrollar productos, servicios y funciones nuevas.
Mejorar el algoritmo de masterización y las funciones del motor musical en base a la música que se ha subido. El usuario y el músico son los propietarios y cuentan con sus derechos de autor y de publicación sobre la música.
Responder preguntas y brindar apoyo o seguimiento sobre ciertos datos.
Entender las necesidades y preferencias de nuestros clientes y evaluar los niveles de satisfacción con nuestros servicios.
Enviar actualizaciones sobre nuestros productos, servicios, noticias y eventos y tomar medidas de seguimiento, como ofrecer sugerencias, promociones, información sobre concursos (por ejemplo, enviarle crédito por recomendaciones cuando recomiende nuestros servicios a otros usuarios), siempre dentro del marco legal vigente.
Y, por último, cumplir con las medidas legales o reglamentarias vigentes.`,
    },
    {
      Tit: `Sobre la información que se difunde`,
      Text: `No se preocupe, porque sus datos personales se guardan en un lugar seguro. No les vendemos datos personales a terceros. Usted es dueño de sus datos. Sin embargo, para proveerle nuestros servicios, debemos compartir de las siguientes maneras partes específicas de la información que hemos recolectado:
Esto se aplica a WARP como empresa, incluyendo la empresa, sus filiales, subsidiarias, departamentos y empresas de las cuales somos copropietarios.
Solo ofrecemos datos particulares a proveedores de servicios o a socios cuando sea necesario ofrecerle a usted ciertos servicios o para mejorar o promover nuestros servicios. Les ofreceremos su información personal a los proveedores de servicios y socios y procesaremos los datos personales según nuestras políticas.
Esto también se aplica a la reestructuración como otra empresa, fusiones o absorciones, venta de activos, financiamiento o actividades de adquisición parcial o completa, entre las que se incluyen la diligencia debida y las liquidaciones.
En cualquiera de los casos (por ejemplo, citaciones legales o situaciones en las que la vida, salud o seguridad de un individuo estén en riesgo) compartir información estará permitido o será nuestra obligación.
Al difundir su información personal con terceros, tomamos medidas sensatas para asegurarnos de que estos cumplan con las políticas de protección de datos personales y que garanticen adecuadamente que se tomarán las medidas tecnológicas y organizacionales necesarias para lograrlo.
Podemos difundir información con terceros que se haya recopilado automáticamente o que se haya acumulado para el propósito de (i) cumplir con obligaciones de preparación de informes, (ii) negocios o marketing, (iii) asistir a las partes de interés para entender los intereses, hábitos y los patrones de uso de c y para otros propósitos o la información que sirva para distinguir a alguien como individuo sin revelar la verdadera identidad. Estos terceros le proveerán programas particulares, contenidos, servicios y funciones. Para decirlo de manera simple, cada vez que difundamos su información personal con otros, el propósito será cumplir con sus necesidades lo mejor posible.`,
    },
    {
      Tit: `Configuración básica del usuario`,
      Text: `Le ofrecemos varias opciones para controlar cómo nos brinda su información. A continuación, podrá ver algunos ejemplos.
Puede permitir que terceros le den Me gusta a su cuenta de Facebook (también llamado “servicios combinados”) para acceder o registrarse a servicios o permitir que los servicios combinados nos brinden información personal o de otra índole. Al permitirnos conectar los servicios combinados, está aceptando darnos el derecho a acceder y almacenar información como su nombre, dirección de correo electrónico y otros datos que se comparten mediante los servicios combinados, y a usar y difundir la información según las políticas de protección de datos. Compruebe su configuración de datos personales en cada servicio combinado para ver qué información nos comparte y para realizar cambios a la configuración básica de ser necesario. Lea con detenimiento los contratos de usuario y las políticas de protección de datos personales de cada servicio combinado que utilice para conectarse a nuestros servicios.
Puede cambiar su información de contacto e idioma de comunicación cuando lo desee. Este es el mejor modo de comprobar si sus datos personales son correctos y están actualizados.
Siempre puede cambiar su contraseña.
Puede registrarse y darse de baja de nuestras promociones.
Haga clic en el botón de cerrar sesión para cerrarla.
Contacte al equipo de Atención al Cliente (support@warpmastering.com) para eliminar su cuenta.
Esto es para otras páginas y redes sociales.
Nuestra página web y nuestro blog pueden tener enlaces a otras páginas, a título enunciativo: Facebook, YouTube, Twitter, Tumblr, Instagram, Soundcloud y otras. Nosotros no podemos controlar las medidas de protección de estos terceros ni hacernos responsables por ellas. Algunas funciones, como compartir datos, les ofrecen a los terceros acceso a su información. Por ejemplo, esto se puede realizar mediante una interfaz API. Los terceros recibirán sus datos personales según sus propias políticas de protección de datos personales. Recuerde que cualquiera puede leer, recolectar y utilizar información que publique en foros abiertos, incluidos su blog o redes sociales.`,
    },
    {
      Tit: `Campañas de recomendación`,
      Text: `Tal vez le pidamos que invite a familiares y a amigos cercanos a usar nuestros servicios o a compartir información promocional. En estos casos, asegúrese de hacerlo con familiares (cónyuge, miembro del matrimonio consuetudinario, padres o hijos) o con conocidos (gente con la que charle a menudo, que comparta cierta familiaridad, opiniones, etc.) Por favor, solo recomiéndele nuestros servicios a aquellos que estén interesados en él.`,
    },
    {
      Tit: `Administración de la información`,
      Text: `Warp puede almacenar o procesar sus datos personales en otros países en nuestras instalaciones u otros proveedores de servicios. Al hacer uso de nuestros servicios, usted está dando su consentimiento para enviar su información a otros países distintos al de su residencia, incluido Corea del Sur. Por lo tanto, sus datos personales pueden quedar protegidos por las leyes de protección de datos e información personales de otros países. Esa información puede quedar fuera de Corea, pero, según las leyes del país en cuestión, la información puede ser difundida al gobierno, tribunal, institución de seguridad o de regulación según sus leyes vigentes. Sin embargo, nuestra costumbre en cuanto a sus datos personales siempre se regirá según esta política y, cuando sea necesario, cumpliremos con las solicitudes enumeradas en el Reglamento General de Protección de Datos (RGPD) que brinda la protección adecuada al transferir datos personales desde la Unión Europea (UE) o el Espacio Económico Europeo (EEE) a un tercer país. Además, puede almacenar la información utilizada para acceder a nuestros servicios en un dispositivo utilizado de modo local.
Almacenamos su información personal durante el tiempo que la necesitemos para brindarle nuestros servicios, según las leyes o regulaciones vigentes o por solicitud del gobierno. Si elimina su cuenta, también se eliminará toda su información personal. Sin embargo, (1) eliminar su información personal de nuestro servidor o del servidor de respaldo puede llevar un tiempo, (2) tal vez necesitemos almacenar algunos datos por responsabilidades legales. Excepto para las cuentas pagas, si no accede a sus servicios durante 12 meses consecutivos, nos reservamos el derecho a eliminar su cuenta. Antes de eliminar su cuenta se lo notificaremos por correo electrónico.`,
    },
    {
      Tit: `Niños`,
      Text: `Nuestros servicios no son aptos para niños menores a 13 años de edad y no tenemos intención de guardar sus datos personales. Si descubrimos que lo hemos hecho sin el consentimiento de sus padres o tutores, inmediatamente tomaremos las medidas necesarias para eliminarlos.`,
    },
    {
      Tit: `Seguridad`,
      Text: `Tomamos todas las medidas que podemos para proteger su información personal. Cumplimos con los estándares de la industria generalmente aceptados para protegerla al momento y después de que se envíe. Tomamos las medidas físicas, tecnológicas y administrativas adecuadas para proteger su información contra la destrucción accidental o ilegal, pérdida casual, cambios no autorizados, difusión o acceso no autorizado, abuso o cualquier otro curso de acción ilegal. Sin embargo, entregar información o almacenarla en Internet no es 100 % seguro. No podemos garantizar la seguridad de la información que nos envíe o que guardamos en nuestros servicios y usted debe correr ese riesgo. Además, no podemos garantizar que no se accederá, no se compartirá, no se modificará o no se destruirá esa información ante una violación a las políticas de protección administrativas, tecnológicas o físicas. Si cree que se dañó su información personal, por favor, háganos saber. Si hubiere una violación a nuestro sistema de seguridad, usted y las autoridades recibirán una notificación según la legislación vigente. `,
    },
    {
      Tit: `Derechos de acceso y edición`,
      Text: `Su información personal es 100 % suya y, por lo tanto, tiene derecho a solicitar por escrito el acceso a cualquier dato que nos haya compartido o su modificación.
Según el RGDP, además tiene los siguientes derechos:
    ㆍSe requiere de su consentimiento para procesar su información.
    ㆍTiene el derecho a acceder a su información personal o a información adicional en ciertas circunstancias.
    ㆍTiene derecho a rechazar el procesamiento de datos ilícitos en ciertas circunstancias.
    ㆍTiene el derecho a eliminar su información personal en ciertas circunstancias.
    ㆍTiene el derecho de solicitar que se limite el procesamiento de sus datos personales en ciertas circunstancias.
    ㆍTiene el derecho de solicitar que se limite el procesamiento de sus datos personales si cree que hemos sobrepasado los estándares de procesamiento de sus datos personales, si no cree que debamos procesarlos o si los hemos almacenado.
    ㆍTiene el derecho de mover, copiar o enviar (movilidad de datos) sus datos de la manera en la que están estructurados los usuarios, los datos se pueden utilizar en el sentido general y pueden ser leídos por máquinas.
    ㆍTiene el derecho de rechazar cualquier proceso automatizado que pueda afectar al usuario legalmente en ciertas circunstancias.
    ㆍTiene el derecho de enviar c y notificar a las autoridades de protección de datos. Para obtener mayor información sobre los derechos según el RGPD, visite la página de la Comisión Europa sobre la protección de datos (https://ec.europa.eu/info/law/law-topic/data-protection_es)`,
    },
    {
      Tit: `Actualizaciones sobre la información`,
      Text: `Las políticas de la protección de datos personales se pueden modificar según se realicen cambios en WARP. Esto no implica que su información personal esté en riesgo. En el supuesto de que esto suceda, le notificaremos los cambios en nuestra página web con la fecha en la cual se implementarán estos cambios.
Por lo tanto, para recibir dichas notificaciones, préstele atención a nuestra página web. Si continúa utilizando nuestros servicios luego de que se hayan implementado los cambios a las políticas de protección de datos personales, entenderemos que ha verificado y aceptado los cambios. La fecha más reciente de actualización es la que figura al final de la página.`,
    },
    {
      Tit: `Consultas`,
      Text: `Si tiene sugerencias, preguntas o consultas relacionadas con estas políticas o con sus datos personales o desea obtener información sobre nuestras políticas y costumbres relacionadas con otro proveedor de servicios, utilice la información de contacto descripta más abajo para ponerse en contacto con un representante de información personal o representante de protección de datos.`,
    },
  ],

  info: `WARP Mastering Inc.<br/>
  privacy@warpmastering.com<br/>
  Esta política de privacidad se modificó por última vez el 1.o de febrero de 2022
  `

};
