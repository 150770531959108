// localstorage 지정
export const setLocalS = (key, value) => {
    let setValue = '';
    if (typeof value === 'string' || typeof value === 'number') {
        setValue = value.toString();
    } else {
        setValue = JSON.stringify(value);
    }
    localStorage.setItem(key, setValue);
};

// localstorage 가져오기 (useRemove true이면 가져오고 해당 값 지우기)
export const getLocalS = (key, useRemove) => {
    let result = '';
    if (localStorage.getItem(key) !== null) {
        result = JSON.parse(localStorage.getItem(key));
        if (useRemove) {
            removeLocalS(key);
        }
        return result;
    } else {
        return false;
    }
};

// localstorage 지우기
export const removeLocalS = (key) => {
    localStorage.removeItem(key);
};
