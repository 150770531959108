//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { langAction } from '../../store/actionCreator';

//css
import styled from 'styled-components';

export default function Footer() {
    const { country } = useParams();
    const history = useHistory();
    const location = useLocation();
    const LANG = useSelector((state) => state.language.lang);
    // 언어 버튼 클릭시 변화
    const findLangItem = () => {
        const findLang = lanData.find((item) => {
            return item.id === country;
        });
        return findLang;
    };
    const lanData = [
        { id: 'kr', text: '한국어' },
        { id: 'en', text: 'English' },
        { id: 'cn', text: '中國語' },
        { id: 'es', text: 'español' },
    ];
    const [isSelect, setIsSelect] = useState({ id: country, text: findLangItem().text });

    const [isOn, setIsOn] = useState(false);

    // background
    const [BgOn, setBgOn] = useState(false);

    useEffect(() => {
        /* 컴포넌트가 마운트 되면 window에 focus 이벤트 부여 */
        let lastPathName = document.location.pathname.split('/')[2]

        if (lastPathName === 'mastering') {
            setBgOn(true)
        } else {
            setBgOn(false)
        }
    }, []);

    return (
        <Container active={BgOn}>
            <FooterWrap>
                <FooterLeft>
                    <FooterMenuWrap active={isSelect?.id !== 'kr'}>
                        <FooterMenu>
                            <Link to={`/${country}/privacy`}>Privacy policy</Link>
                        </FooterMenu>
                        <FooterMenu>
                            <Link to={`/${country}/terms`}>Terms and conditions</Link>
                        </FooterMenu>
                        <FooterMenu>
                            <Link to={`/${country}/support/qna`}>Support</Link>
                        </FooterMenu>
                        <FooterMenu>
                            <Link to={`/${country}/support/news`}>news letter</Link>
                        </FooterMenu>
                    </FooterMenuWrap>
                    {country !== 'kr' ? (
                        <InfoWrap>
                            <InfoText>
                                Industry-Academic Cooperation Officer 809-4 Kumoh National Institue of Tec 61, Deahak-ro, Gumi-si Gyengsangbuk-do, Republic of
                                Korea
                            </InfoText>
                            <InfoText>CHOI WON SUK | 070-4918-4979</InfoText>
                            <InfoText>Company Registration Number : 445-87-0166</InfoText>
                            <InfoText>Contact : warp@warpmastering.com</InfoText>
                        </InfoWrap>
                    ) : (
                        <InfoWrap>
                            <InfoText>경북 구미시 대학로61 금오공대 산학렵력관 809-4</InfoText>
                            <InfoText>최원석 | 070-4918-4979</InfoText>
                            <InfoText>사업자 상호명 : 주식회사 워프  | 사업자번호 : 445-87-01668</InfoText>
                            <InfoText>Contact : warp@warpmastering.com</InfoText>
                        </InfoWrap>
                    )}
                    <InfoText>WARP © 2021 All rights reserved</InfoText>
                </FooterLeft>
                <FooterRight>
                    <LangBtnCon
                        onClick={() => {
                            setIsOn(!isOn);
                        }}
                    >
                        <LangKr>{isSelect?.text}</LangKr>
                        <LangBtnWrap isOn={isOn} isSelect={isSelect?.id}>
                            {lanData.map((item, idx) => {
                                const { id, text } = item;
                                return (
                                    <LangBtnList
                                        // isSelect의 id 값과 list의 id 값이 같으면 false 다르면 true
                                        isView={(id !== isSelect?.id && true) || false}
                                        key={idx}
                                        onClick={() => {
                                            const pathOnlyUrl = location.pathname.split(`/${LANG}/`)[1];
                                            history.push(`/${item.id}/${pathOnlyUrl}`);
                                            setIsSelect(item);
                                        }}
                                    >
                                        {text}
                                    </LangBtnList>
                                );
                            })}
                        </LangBtnWrap>
                    </LangBtnCon>
                </FooterRight>
            </FooterWrap>
        </Container>
    );
}

const Container = styled.div`
    position: relative;
    width: 100%;
    background-color: #f8f7f7;
    padding: 60px 0;

    @media (max-width: 1297px) {
        padding: 40px;
    }

    @media (max-width: 767px) {
        padding: 24px 20px;
    }
`;

const FooterWrap = styled.div`
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    color: #7fadac;
    font-family: 'Pretendard';
    font-size: 14px;

    @media (max-width: 1297px) {
        flex-direction: column-reverse;
        gap: 24px;
    }

    @media (max-width: 767px) {
    }
`;

const FooterLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    
    @media (max-width: 1279px) {
        gap: 24px;
    }

    @media (max-width: 767px) {
        gap: 20px;
    }
`;

const FooterRight = styled.div`
`;

const FooterMenuWrap = styled.ul`
    display: flex;
    flex-direction: row;
    gap: 40px;
    @media (max-width: 1279px) {
        gap: 24px;
    }

    @media (max-width: 767px) {
        flex-wrap: wrap;
        gap: 12px;
    }
`;

const FooterMenu = styled.li`
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    @media (max-width: 1279px) {
        font-size: 12px;
        line-height: 16px;
    }

    @media (max-width: 767px) {
        font-size: 11px;
        line-height: 14px;
    }
`;
const InfoWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-weight: 400;
`;
const InfoText = styled.p`
`;

const LangBtnCon = styled.div`
    width: 165px;
    height: 48px;
    cursor: pointer;
    border: 1px solid #7fadac;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 24px;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        margin-top: 2px;
        top: 50%;
        transform: translateY(-50%);
        right: 24px;
        border-top: 5px solid #7fadac;
        border-bottom: 5px solid transparent;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
    }

    @media (max-width: 1297px) {
        width: 110px;
        height: 32px;
        padding-left: 20px;
        font-size: 12px;
        &::after {
            right: 20px;
            border-top: 5px solid #7fadac;
            border-bottom: 5px solid transparent;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
        }
    }

    @media (max-width: 767px) {
        width: 80px;
        height: 24px;
        font-size: 10px;
        padding-left: 12px;
        &::after {
            right: 12px;
            border-top: 5px solid #7fadac;
            border-bottom: 5px solid transparent;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
        }
    }
`;

const LangKr = styled.span``;

const LangBtnWrap = styled.ul`
    display: ${(props) => (props.isOn ? 'block' : 'none')};
    position: absolute;
    width: 100%;
    bottom: 47px;
    left: -0.5px;
    box-sizing: content-box;
    border: 1px solid #7fadac;
    z-index: 3;
    ${({ isSelect }) => isSelect === 3 && 'border-bottom: none;'}

    @media (max-width: 1297px) {
        bottom: 30px;
    }

    @media (max-width: 767px) {
        bottom: 22px;
    }
`;

const LangBtnList = styled.li`
    width: 100%;
    height: 48px;
    background-color: #fff;
    color: #7fadac;
    display: flex;
    align-items: center;
    padding-left: 24px;
    &:not(:last-child) {
        border-bottom: 1px solid #7fadac;
    }

    ${({ isView }) => (isView && `display:flex;`) || `display:none;`}

    @media (max-width: 1297px) {
        height: 32px;
        padding-left: 20px;
    }

    @media (max-width: 767px) {
        height: 24px;
        padding-left: 12px;
    }
`;
