export default function DateSplitNextBill(orderDate) {
    if (orderDate) {
        let Y = orderDate.slice(0,4);
        let M = orderDate.slice(4,6);
        let D = orderDate.slice(6);

        if (M.toString().length === 1) {
            M = '0' + M.toString();
        }
        if (D.toString().length === 1) {
            D = '0' + D.toString();
        }

        let date = Y + '-' + M + '-' + D;
        return date;
    }
}
