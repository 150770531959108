import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';

export default function IndexPage() {
    const history = useHistory();
    useEffect(() => {
        // console.log("인덱스페이지")
        fetch('https://api.pe.kr/json/') // 2024.07.25 geolocation 응답없어 대체
        // fetch('https://geolocation-db.com/json/')
            .then((res) => res.json())
            .then((res) => {
                console.log(res)
                let result = '';
                const countryCode = res.country_code;
                console.log("countryCode",countryCode)
                const countryObjList = {
                    kr: ['KR'],
                    cn: ['CN', 'MO', 'HK'],
                    us: ['US', 'GU', 'MH', 'VI', 'AS', 'MP', 'WS', 'PW', 'PR'],
                    es: ['ES'],
                };
                Object.entries(countryObjList).map((conItem) => {
                    const [conKey, conList] = conItem;
                    conList.map((conVal) => {
                        if (countryCode == conVal) {
                            result = conKey;
                        }
                    });
                });
                if (result === '') {
                    result = 'en';
                }
                if (result === 'us') {
                    result = 'en';
                }
                history.push(`/${result}/main`);
            })
            .catch((error) => {
                history.push(`/en/main`);
                // console.error(error);
            });
    }, [history]);
    return <></>;
}
