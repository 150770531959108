import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HtmlParser from 'react-html-parser';
import { useParams } from 'react-router-dom';
import serverController from '../../server/serverController';
export const useLangText = (KR, EN, CN, ES) => {
    const LANG = useSelector((state) => state.language.lang);
    let langResult = {};
    if (LANG === 'kr') {
        langResult = KR;
    } else if (LANG === 'en') {
        langResult = EN;
    } else if (LANG === 'cn') {
        langResult = CN;
    } else if (LANG === 'es') {
        langResult = ES;
    }

    let newLangResult = {};
    for (let key in langResult) {
        newLangResult[key] = HtmlParser(langResult[key]);
    }
    return newLangResult;
};
export const useLangTrans = (KR, EN, CN, ES) => {
    const LANG = useSelector((state) => state.language.lang);
    let langResult = {};
    if (LANG === 'kr') {
        langResult = KR;
    } else if (LANG === 'en') {
        langResult = EN;
    } else if (LANG === 'cn') {
        langResult = CN;
    } else if (LANG === 'es') {
        langResult = ES;
    }

    return langResult;
};

export const useLangList = (keyObj, langObj) => {
    const { KR, EN, CN, ES } = langObj || {};
    const LANG = useSelector((state) => state.language.lang);
    let langResult = [];
    if (LANG === 'kr') {
        langResult = KR;
    } else if (LANG === 'en') {
        langResult = EN;
    } else if (LANG === 'cn') {
        langResult = CN;
    } else if (LANG === 'es') {
        langResult = ES;
    }

    let newLangResult = [];
    const list = langResult[keyObj];
    for (let i = 0, len = list?.length; i < len; i++) {
        let obj = {};
        for (const [key, value] of Object.entries(list[i])) {
            obj[key] = HtmlParser(value);
        }
        newLangResult.push(obj);
    }

    return newLangResult;
};

export const useLangStyle = (styles) => {
    const LANG = useSelector((state) => state.language.lang);
    let resultStyle = '';
    if (LANG === 'kr') {
        resultStyle = styles?.kr;
    } else if (LANG === 'en') {
        resultStyle = styles?.en;
    } else if (LANG === 'cn') {
        resultStyle = styles?.cn;
    } else if (LANG === 'es') {
        resultStyle = styles?.es;
    }
    return resultStyle;
};

/* api 호출 */

export const useLangAPI = ({ path }) => {
    const [result, setResult] = useState([]);
    const { country } = useParams();
    let newLangResult = [];
    useEffect(() => {
        serverController.connectFetchController(`${path}?policy_lang=${country}`, 'GET', null, null, (res) => {
            if (res.result === 1) {
                let resList = res?.list;
                setResult(resList);
            } else {
            }
        });
    }, [country]);
    return result;
};
