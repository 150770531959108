import { bindActionCreators } from 'redux';
import * as language from './modules/language';
import * as uploadFiles from './modules/uploadFiles';

import store from './index';

const { dispatch } = store;

export const langAction = bindActionCreators(language, dispatch);
export const fileActionType = bindActionCreators(uploadFiles, dispatch);
