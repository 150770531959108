// 실서버
export const ip = 'https://warpmastering.com:8091/';

// dev서버
// export const ip = 'https://13.125.26.184:8091/';

const api = {
    connectFetchController: async (path, method, body, header, callBack, errorCallBack) => {
        return fetch(`${ip}${path}`, {
            credentials: 'include',
            method: method,
            body: body ? body : null,
            headers: header
                ? header
                : {
                      'Content-Type': `application/json`,
                  },
        })
            .then(function (res) {
                return res.json();
            })

            .then(function (data) {
                if (callBack) callBack(data);
                return data;
            })
            .catch(function (e) {
                if (errorCallBack) errorCallBack(e);
            });
    },
};

export default api;
