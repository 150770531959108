//react
import { useEffect, useState, useRef } from 'react';

//css
import styled, { css } from 'styled-components';

//img
import Vector4 from '../../img/main/vector_4.svg';
import Vector5 from '../../img/main/vector_5.svg';
import Vector6 from '../../img/main/vector_6.svg';
import Vector7 from '../../img/main/vector_7.svg';
// import Professional from '../../img/main/professional.png';
import Professional from '../../img/main/professional.jpg';
// import Most from '../../img/main/most.png';
import Most from '../../img/main/most.jpg';
// import Easy from '../../img/main/easy.png';
import Easy from '../../img/main/easy.jpg';
// import Quick from '../../img/main/quick.png';
import Quick from '../../img/main/quick.jpg';

//lang
import { useLangText } from '../../utils/langugeController/langController';
import { KR, EN, CN, ES } from './language/MainSection6/combLang';

export default function MainSection6() {
    const langText = useLangText(KR, EN, CN, ES);
    return (
        <>
            <Container>
                <BlackWrap>
                    <Vec4 src={Vector4} alt='vector image' />
                    <Vec5 src={Vector5} alt='vector image' />
                    <ContentWrap>
                        <ImageWrap>
                            <Picture src={Professional} alt={'Professional'} />
                        </ImageWrap>
                        <TextBox>
                            <ContentTitle>Professional</ContentTitle>
                            <ContentInfo>{langText.Professional}</ContentInfo>
                        </TextBox>
                    </ContentWrap>
                </BlackWrap>
                <CoralWrap>
                    <ContentWrap>
                        <ImageWrap>
                            <Picture src={Most} alt={'Most advanced'} />
                        </ImageWrap>
                        <TextBox>
                            <ContentTitle>Most advanced</ContentTitle>
                            <ContentInfo>{langText.Mostadvanced}</ContentInfo>
                        </TextBox>
                    </ContentWrap>
                </CoralWrap>
                <BlackWrap>
                    <Vec6 src={Vector6} alt='vector image' />
                    <Vec7 src={Vector7} alt='vector image' />
                    <ContentWrap>
                        <ImageWrap>
                            <Picture src={Easy} alt={'Easy and Simple'} />
                        </ImageWrap>
                        <TextBox>
                            <ContentTitle>Easy and Simple</ContentTitle>
                            <ContentInfo>{langText.EasyandSimple}</ContentInfo>
                        </TextBox>
                    </ContentWrap>
                </BlackWrap>
                <CoralWrap>
                    <ContentWrap>
                        <ImageWrap>
                            <Picture src={Quick} alt={'Quick and Reliable'} />
                        </ImageWrap>
                        <TextBox>
                            <ContentTitle>Quick and Reliable</ContentTitle>
                            <ContentInfo>{langText.QuickandReliable}</ContentInfo>
                        </TextBox>
                    </ContentWrap>
                </CoralWrap>
            </Container>
        </>
    );
}

const Container = styled.div`
    position: relative;
    width: 100%;

    @media (max-width: 767px) {
    }
`;
const BlackWrap = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background-color: black;
    color: white;
`;
const CoralWrap = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #EE6462;
    color: white;
    z-index: 4;
`;
const ContentWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 80px;
    padding: 128px 0 100px;
    max-width: 1200px;
    margin: auto;
    &:nth-child(1) {
        flex-direction: row-reverse;
    }
    &:nth-child(2) {
        flex-direction: row-reverse;
    }
    @media (max-width: 1279px) {
        padding: 80px 40px;
        gap: 32px;
    }

    @media (max-width: 767px) {
        padding: 24px 20px;
        gap: 20px;
        flex-wrap: wrap;
    }
`;
const ImageWrap = styled.div`
    z-index: 5;
`;
const Picture = styled.img`
    width: 480px;
    height: 320px;

    @media (max-width: 1279px) {
        width: 264px;
        height: 176px;
    }
    @media (max-width: 767px) {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 5;
`;
const ContentTitle = styled.h3`
    font-size: 24px;
    font-weight: 700;
    @media (max-width: 1279px) {
        font-size: 18px;
    }
    @media (max-width: 767px) {
        font-size: 16px;
    }
`;
const ContentInfo = styled.p`
    font-family: 'Pretendard';
    font-size: 24px;
    font-weight: 400;
    line-height: 142%;
    @media (max-width: 1279px) {
        font-size: 16px;
        line-height: 22px;
        /* font-size: 18px;
        line-height: 24px; */
    }
    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
        /* font-size: 16px;
        line-height: 22px; */
    }

`;
const Vec4 = styled.img`
    position: absolute;
    z-index: 2;
    bottom: -412px;
    left: -356px;
    width: 830px;
    height: 813px;
    transform: rotate(0deg);
    @media (max-width: 1279px) {
        bottom: -224px;
        left: -182px;
        width: 430px;
        height: 414px;
    }

    @media (max-width: 767px) {
        display: none;
    }
`;
const Vec5 = styled.img`
    position: absolute;
    z-index: 1;
    bottom: -76px;
    left: -186px;
    width: 732px;
    height: 714px;
    transform: rotate(-2deg);
    @media (max-width: 1279px) {
        bottom: -32px;
        left: -100px;
        width: 440px;
        height: 424px;
    }

    @media (max-width: 767px) {
        display: none;
    }
`;
const Vec6 = styled.img`
    position: absolute;
    z-index: 2;
    bottom: -412px;
    right: -356px;
    width: 830px;
    height: 813px;
    transform: rotate(0deg);
    @media (max-width: 1279px) {
    bottom: -188px;
    right: -155px;
    width: 430px;
    height: 414px;
    }

    @media (max-width: 767px) {
        display: none;
    }
`;
const Vec7 = styled.img`
    position: absolute;
    z-index: 1;
    bottom: -76px;
    right: -186px;
    width: 732px;
    height: 714px;
    transform: rotate(-2deg);
    @media (max-width: 1279px) {
        bottom: -40px;
        right: -124px;
        width: 440px;
        height: 424px;
    }

    @media (max-width: 767px) {
        display: none;
    }
`;