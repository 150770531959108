//react
import { useEffect, useState, useRef } from 'react';

// wavesurfer
import WaveSurfer from 'wavesurfer.js';

//img
import MainPlayImg from '../../../img/main/play.svg';
import MainPlayImg2 from '../../../img/main/play2.svg';
import MainPauseImg from '../../../img/main/pause.svg';
import MainPauseImg2 from '../../../img/main/pause2.svg';

//css
import styled from 'styled-components';

export default function Waveform ({ id, type, item, setAllPause, allPause=false}) {
    const [playing, setPlaying] = useState(false);
    const waveform = useRef()
    
    useEffect(() => {
        waveform.current = WaveSurfer.create({
            container: `#waveform${id}`,
            height: 70,
            barWidth: 2,
            barRadius: 3,
            barGap: 2,
            progressColor: type === 'before' ?['#014682', '#016D6A']:['white'],
            responsive: true,
            waveColor: type === 'before' ? 'rgba(216, 216, 216, 1)' : 'rgba(255, 255, 255, 0.3)',
            hideScrollbar: true,
            cursorColor:'transparent'
        });
        const track = document.querySelector(`#track${id}`);
        waveform.current.load(track);
    }, []);
    
    // all pause
    useEffect(() => {
        if (waveform.current&&allPause) {
            waveform.current.pause();
            setPlaying(false);
        }
    }, [allPause]);

    const handlePlay = () => {
        const prev = playing;
        setAllPause(true)
        setTimeout(() => {
            if(!prev) {
                waveform.current.playPause();
                setAllPause(false);
                setPlaying(!playing);
    }
        }, 100);
    };
    
    return (
        <ListCon>
            <PlayBtnCon>
                {
                    type === 'before'?
                    <PlayBtnBefore onClick={handlePlay}>
                        <PlayBtnImg src={playing && !allPause ? MainPauseImg : MainPlayImg} alt={'play pause'} />
                    </PlayBtnBefore>
                    :
                    <PlayBtnAfter onClick={handlePlay}>
                        <PlayBtnImg src={playing && !allPause ? MainPauseImg2 : MainPlayImg2} alt={'play pause'} />
                    </PlayBtnAfter>
                }
            </PlayBtnCon>
            <MusicCon>
                <MusicWrap id={`waveform${id}`}></MusicWrap>
                    <audio id={`track${id}`} crossOrigin="anonymous" autoPlay={false} muted={true} src={item} />
            </MusicCon>
        </ListCon>
    )
}


const ListCon = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const PlayBtnBefore = styled.div`
    box-sizing: border-box;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 19px 18px;
    border-radius: 50%;
    background: linear-gradient(180deg, #0055A4 0%, #008983 100%);
    cursor: pointer;
    @media (max-width: 700px) {
        padding: 14px 13px;
    }
`
const PlayBtnAfter = styled.div`
    box-sizing: border-box;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 19px 18px;
    background: linear-gradient(white, white) padding-box,
                linear-gradient(to right, rgba(0, 85, 164, 1), rgba(0, 137, 131, 1)) border-box;
    border-radius: 50em;
    border: 1px solid transparent;
    cursor: pointer;
    @media (max-width: 700px) {
        padding: 14px 13px;
    }
`
const PlayBtnCon = styled.div`
  width: 44px;
    margin: 0 26px 0 0;
    top: 38%;
  
    @media (max-width: 1297px) {
        margin: 0 calc(100vw * (27 / 1280)) 0 0;
    }

    @media (max-width: 1023px) {
        margin: 0 calc(100vw * (13 / 428)) 0 0;
    }
`;

const PlayBtnImg = styled.img`
    width: 15px;
    height: 15px;
    margin-left: 2px;
    @media (max-width: 700px) {
        width: 12px;
        height: 12px;
        margin-left: 1px;
    }
 `;

const MusicCon = styled.div`
    width: 1200px;
    overflow-x: hidden;

    @media (max-width: 1297px) {
        width: 86vw;
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (330 / 428));
    }
`;

const MusicWrap = styled.div`
    overflow: hidden;
    width: 100%;
`;
