//react
import { useEffect, useState, useRef } from 'react';
import HtmlParser from 'react-html-parser';

//css
import styled from 'styled-components';

//img
import ListArrowImg from '../../../img/icon/icon_arrow_down.svg';

export default function ListContainer({ item }) {
    return (
        <>
            {item?.map((value, index) => {
                return <ListContainerItem value={value} index={index} key={index} />;
            })}
        </>
    );
}
const ListContainerItem = ({ value, index }) => {
    const [isOnClick, setIsOnClick] = useState(false);
    const [qna, setQna] = useState(0); // 0이면 qna, 1이면 결제
    const { policy_title, policy_content, faqTitle, faqContent } = value || {};
    useEffect(() => {
        if (policy_title === undefined || policy_content === undefined) {
            setQna(0);
        } else {
            setQna(1);
        }
    }, []);
    return (
        <List
            key={index}
            onClick={() => {
                setIsOnClick(!isOnClick);
            }}
        >
            <TitleWrap isOnClick={isOnClick}>
                <Title>{HtmlParser(qna !== 0 ? policy_title : faqTitle)}</Title>
                {/* <BlueText>{SubText}</BlueText> */}
            </TitleWrap>
            {isOnClick && (
                <DetailCon>
                    <Detail>{HtmlParser(qna !== 0 ? policy_content : faqContent)}</Detail>
                </DetailCon>
            )}
        </List>
    );
};

const List = styled.li`
    background-color: #fff;
    border: 1px solid #dddddd;
    border-radius: 3px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    padding: 0 40px;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    @media (max-width: 1297px) {
        font-size: calc(100vw * (18 / 1280));
        padding: 0 calc(100vw * (40 / 1280));

        &:not(:last-child) {
            margin-bottom: calc(100vw * (20 / 1280));
        }
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (12 / 428));
        padding: 0 calc(100vw * (20 / 428));

        &:not(:last-child) {
            margin-bottom: calc(100vw * (15 / 428));
        }
    }
`;

const TitleWrap = styled.div`
    display: flex;
    padding: 41px 0;
    padding-right: 30px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 4px;
        background: url(${ListArrowImg}) no-repeat center / contain;
        width: 19px;
        height: 10px;

        ${({ isOnClick }) => {
            return isOnClick ? `transform: translateY(-50%) rotate(180deg);` : `transform: translateY(-50%) rotate(0deg);`;
        }}
    }

    @media (max-width: 1297px) {
        padding: calc(100vw * (41 / 1280)) 0;
        padding-right: calc(100vw * (30 / 1280));

        &::after {
            right: calc(100vw * (4 / 1280));
            width: calc(100vw * (19 / 1280));
            height: calc(100vw * (10 / 1280));
        }
    }

    @media (max-width: 1023px) {
        flex-direction: column;
        padding: calc(100vw * (25 / 428)) 0;
        padding-right: calc(100vw * (25 / 428));

        &::after {
            top: calc(100vw * (28 / 428));
            right: calc(100vw * (5 / 428));
            width: calc(100vw * (12 / 428));
            height: calc(100vw * (7 / 428));

            ${({ isOnClick }) => {
                return isOnClick ? `transform: translateY(0%) rotate(180deg);` : `transform: translateY(0%) rotate(0deg);`;
            }}
        }
    }
`;

const Title = styled.p`
    max-width: 1018px;

    @media (max-width: 1297px) {
        max-width: calc(100vw * (1018 / 1280));
    }

    @media (max-width: 1023px) {
        max-width: calc(100vw * (321 / 428));
        line-height: calc(100vw * (14 / 428));
    }
`;

const BlueText = styled.p`
    color: #005aa2;
    font-family: 'Pretendard';
    font-size: 15px;
    font-weight: 500;
    margin-left: 15px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        margin-left: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (12 / 428));
        margin-left: 0;
        margin-top: calc(100vw * (21 / 428));
    }
`;

const DetailCon = styled.div`
    /* ${({ isOnClick }) => {
        return isOnClick ? `display: block;` : `display: none;`;
    }} */
`;

const Detail = styled.div`
    width: 1018px;
    padding: 41px 0;
    border-top: 1px solid #dddddd;
    font-family: 'Pretendard';
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    color: #444444;

    @media (max-width: 1297px) {
        width: calc(100vw * (1018 / 1280));
        padding: calc(100vw * (41 / 1280)) 0;
        font-size: calc(100vw * (15 / 1280));
        line-height: calc(100vw * (23 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (321 / 428));
        padding: calc(100vw * (25 / 428)) 0;
        font-size: calc(100vw * (12 / 428));
        line-height: calc(100vw * (18 / 428));
    }
`;
