//css
import styled from 'styled-components';
//img
import Highest from '../../img/main/ai01.png';
import Clear from '../../img/main/ai02.png';
import Broadest from '../../img/main/ai03.png';
import Custromization from '../../img/main/ai04.png';
import Stable from '../../img/main/ai05.png';

export default function MainSection4() {
    return (
        <>
            <Container>
                <SectionWrap>
                    <SectionTitle>Professional AI audio engineer for ORIGINALITY</SectionTitle>
                    <SectionSubTitle>Deep learning-based the most advanced AI audio denoiser & mastering</SectionSubTitle>
                    <ImageBox>
                        <ProImage src={Highest} alt=''/>
                        <ProImage src={Clear} alt=''/>
                        <ProImage src={Broadest} alt=''/>
                        <ProImage src={Custromization} alt=''/>
                        <ProImage src={Stable} alt=''/>
                    </ImageBox>
                </SectionWrap>
            </Container>
        </>
    );
}

const Container = styled.div`
    position: relative;
    width: 100%;
`;

const SectionWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    
    color: #018487;
    font-size: 32px;
    font-weight: 700;
    padding-bottom: 220px;

    @media (max-width: 1279px) {
        padding: 80px 40px;
    }

    @media (max-width: 767px) {
        padding: 20px 20px 100px;
    }
`;

const SectionTitle = styled.div`
    color: #018487;
    font-size: 32px;
    font-weight: 700;

    @media (max-width: 1279px) {
        font-size: 24px;
    }

    @media (max-width: 767px) {
        font-size: 20px;
    }
`;
const SectionSubTitle = styled.div`
    color: #121212;
    font-size: 24px;
    font-weight: 400;
    margin-top: 24px;
    line-height: 142%;

    @media (max-width: 1279px) {
        font-size: 18px;
        margin-top: 8px;
    }

    @media (max-width: 767px) {
        font-size: 16px;
    }
`;
const ImageBox = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 24px;
    padding-top: 52px;
    @media (max-width: 1279px) {
    grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    }
`;
const ProImage = styled.img`
    width: 100%;
`;