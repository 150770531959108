import React, { useEffect, useState } from 'react';
import { Route, Switch, Link, useHistory, useParams, useLocation } from 'react-router-dom';

// localStorage
import { getLocalS } from './utils/localStorageController';

/* page */
import ScrollTop from './component/scrolltop/ScrollTop';

// css
import './App.css';
import styled from 'styled-components';

//index
import IndexPage from './page/index/IndexPage';
import CountryParamCheckPage from './page/index/CountryParamCheckPage';

//main
import MainPage from './page/main/MainPage';

//intro
import IntroPage from './page/intro/IntroPage';

//tutorial
import TutorialPage from './page/tutorial/TutorialPage';

//terms
import TermsPage from './page/terms/TermsPage';
import PrivacyPage from './page/terms/PrivacyPage';

//support
import SupportPage from './page/support/SupportPage';
import SupportDetailPage from './page/support/SupportDetailPage';

//login
import LoginPage from './page/login/LoginPage';
import SignupPage from './page/login/SignupPage';
import SignupCompletePage from './page/login/SignupCompletePage';
import FindPwPage from './page/login/FindPwPage';
import SocialLoginPage from './page/login/SocialLoginPage';

//mypage
import MyPage from './page/mypage/MyPage';

//payment
import PaymentTermsPage from './page/payment/PaymentTermsPage';
import PaymentPage from './page/payment/PaymentPage';
import PaymentCompletePage from './page/payment/PaymentCompletePage';

//mastering
import MasteringUploadPage from './page/mastering/MasteringUploadPage';
import MasteringPage from './page/mastering/MasteringPage';

//library
import LibraryPage from './page/library/LibraryPage';

// import component
import UploadToolBar from './component/common/UploadToolBar';

function App() {
    const history = useHistory();
    const [uploadFiles, setUploadFiles] = useState([]);

    useEffect(() => {
        return history.listen(() => {
            if (getLocalS('uploadFiles')) setUploadFiles(getLocalS('uploadFiles'));
        });
    }, [history]);

    const removeLocalStorage = () => {
        localStorage.removeItem('completeData');
        localStorage.removeItem('uploadFiles');
    };

    // useEffect(() => {
    //     console.log("HYUNHA : 마스터링 페이지 무한 로딩으로 인해 주석처리, 추후 문제 생기면 검토")
    //     window.addEventListener('beforeunload', removeLocalStorage);
    //     return () => {
    //         window.removeEventListener('beforeunload', removeLocalStorage);
    //     };
    // }, []);
    return (
        <Container className="container">
            <ScrollTop />

            <UploadToolBar uploadFiles={uploadFiles} />

            <Route path={'/:country'}>
                <CountryParamCheckPage />
            </Route>
            <Route exact path={`/`} component={IndexPage} />
            {/* main */}
            <Route exact path={`/:country/main`} component={MainPage} />

            {/* intro */}
            <Route exact path={`/:country/intro`} component={IntroPage} />

            {/* tutorial */}
            <Route exact path="/:country/tutorial" component={TutorialPage} />

            {/* terms */}
            <Route exact path="/:country/terms" component={TermsPage} />
            <Route exact path="/:country/privacy" component={PrivacyPage} />

            {/* support */}
            <Route exact path="/:country/support/:listTitle" component={SupportPage} />
            <Route exact path="/:country/support/detail/:listTitle/:noticeId" component={SupportDetailPage} />
            {/* login */}
            <Route exact path={`/:country/login`} component={LoginPage} />
            <Route exact path="/:country/signup" component={SignupPage} />
            <Route exact path="/:country/signup/complete" component={SignupCompletePage} />
            <Route exact path="/:country/findpw" component={FindPwPage} />
            <Route exact path="/logic" component={SocialLoginPage} />

            {/* mypage */}
            <Route exact path="/:country/mypage/:listTitle" component={MyPage} />

            {/* payment */}
            <Route exact path="/:country/payment/terms" component={PaymentTermsPage} />
            <Route exact path="/:country/payment/terms/:planId" component={PaymentPage} />
            <Route exact path="/:country/payment/complete" component={PaymentCompletePage} />

            {/* mastering */}
            <Route exact path="/:country/mastering/upload" component={MasteringUploadPage} />
            <Route exact path="/:country/mastering" component={MasteringPage} />

            {/* library */}
            <Route exact path="/:country/library" component={LibraryPage} />

        </Container>
    );
}
const Container = styled.div``;

export default App;
