//react
import { useEffect, useState, useRef } from 'react';
import HtmlParser from 'react-html-parser';
import { Link, useHistory } from 'react-router-dom';

//css
import styled, { css } from 'styled-components';
import { useLangStyle } from '../../utils/langugeController/langController';

export default function TermsList({ item }) {
    const [isOnClick, setIsOnClick] = useState(false);

    return (
        <ListWrap>
            {item.map((current, index) => {
                return (
                    <List
                        key={index}
                        onClick={() => {
                            setIsOnClick(!isOnClick);
                        }}
                    >
                        <ListText langStyle={useLangStyle}>{HtmlParser(current?.policy_content)}</ListText>
                    </List>
                );
            })}
        </ListWrap>
    );
}

const ListWrap = styled.ul``;

const List = styled.li`
    font-family: 'Pretendard';

    &:not(:last-child) {
        margin-bottom: 45px;
    }

    @media (max-width: 1297px) {
        &:not(:last-child) {
            margin-bottom: calc(100vw * (45 / 1280));
        }
    }

    @media (max-width: 1023px) {
        &:not(:last-child) {
            margin-bottom: calc(100vw * (45 / 428));
        }
    }
`;

const ListTit = styled.p`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (20 / 1280));
        margin-bottom: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (16 / 428));
        margin-bottom: calc(100vw * (21 / 428));
        line-height: calc(100vw * (19 / 428));
    }
`;

const ListText = styled.div`
    white-space: pre-wrap;
    & p,
    span,
    li {
        font-size: 15px;
        line-height: 23px;
    }

    @media (max-width: 1297px) {
        & p,
        span {
            font-size: calc(100vw * (15 / 1280));
            line-height: calc(100vw * (23 / 1280));
        }
    }

    @media (max-width: 1023px) {
        & p,
        span {
            font-size: calc(100vw * (12 / 428));
            line-height: calc(100vw * (20 / 428));
        }
    }

    ${({ langStyle }) =>
        langStyle({
            kr: css`
                & strong > span > span {
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 20px;
                    display: inline-block;
                }

                @media (max-width: 1297px) {
                    & strong > span > span {
                        font-size: calc(100vw * (20 / 1280));
                        margin-bottom: calc(100vw * (20 / 1280));
                    }
                }

                @media (max-width: 1023px) {
                    & strong > span > span {
                        font-size: calc(100vw * (16 / 428));
                        margin-bottom: calc(100vw * (21 / 428));
                        line-height: calc(100vw * (19 / 428));
                    }
                }
            `,
            en: css`
                & strong > span {
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 20px;
                    display: inline-block;
                }

                @media (max-width: 1297px) {
                    & strong > span {
                        font-size: calc(100vw * (20 / 1280));
                        margin-bottom: calc(100vw * (20 / 1280));
                    }
                }

                @media (max-width: 1023px) {
                    & strong > span {
                        font-size: calc(100vw * (16 / 428));
                        margin-bottom: calc(100vw * (21 / 428));
                        line-height: calc(100vw * (19 / 428));
                    }
                }
            `,
            cn: css`
                & strong > span > span {
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 20px;
                    display: inline-block;
                }

                @media (max-width: 1297px) {
                    & strong > span > span {
                        font-size: calc(100vw * (20 / 1280));
                        margin-bottom: calc(100vw * (20 / 1280));
                    }
                }

                @media (max-width: 1023px) {
                    & strong > span > span {
                        font-size: calc(100vw * (16 / 428));
                        margin-bottom: calc(100vw * (21 / 428));
                        line-height: calc(100vw * (19 / 428));
                    }
                }
            `,
            es: css`
                & strong > span {
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 20px;
                    display: inline-block;
                }

                @media (max-width: 1297px) {
                    & strong > span {
                        font-size: calc(100vw * (20 / 1280));
                        margin-bottom: calc(100vw * (20 / 1280));
                    }
                }

                @media (max-width: 1023px) {
                    & strong > span {
                        font-size: calc(100vw * (16 / 428));
                        margin-bottom: calc(100vw * (21 / 428));
                        line-height: calc(100vw * (19 / 428));
                    }
                }
            `,
        })}
`;
