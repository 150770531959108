//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

//css
import styled from 'styled-components';

// component
import BasicBtn from '../common/Btn/BasicBtn';

//img
import SignupCompleteImg from '../../img/login/signup_complete_img.png';

export default function CompleteBody() {
    //링크 이동
    const history = useHistory();
    const { country } = useParams();
    function handleClick() {
        history.push(`/${country}/main`);
    }

    return (
        <Container>
            <CompleteWrap>
                <Title>WELCOME</Title>
                <Text>Welcome to Warp</Text>
                <CompleteImg src={SignupCompleteImg} alt="complete" />
                <Text>Go try single mastering for free.</Text>
                <BasicBtn onClick={handleClick} color={false} bg={true} text="홈으로" />
            </CompleteWrap>
        </Container>
    );
}

const Container = styled.div`
    padding-top: 100px;
    height: 1045px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1297px) {
        padding-top: calc(100vw * (100 / 1280));
        height: calc(100vw * (1045 / 1280));
    }

    @media (max-width: 1023px) {
        min-height: 100vh;
        height: auto;
        align-items: flex-start;
        padding: calc(100vw * (148 / 428)) calc(100vw * (25 / 428)) calc(100vw * (344 / 428));
    }
`;

const CompleteWrap = styled.div`
    width: 442px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 1297px) {
        width: calc(100vw * (442 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
    }
`;

const Title = styled.h1`
    font-size: 60px;
    color: #005aa2;
    margin-bottom: 28px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (60 / 1280));
        margin-bottom: calc(100vw * (28 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (40 / 428));
        margin-bottom: calc(100vw * (20 / 428));
    }
`;

const Text = styled.p`
    font-family: 'Pretendard';
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 28px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        margin-bottom: calc(100vw * (28 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (15 / 428));
        margin-bottom: calc(100vw * (41 / 428));
    }
`;

const CompleteImg = styled.img`
    width: 190px;
    height: 190px;
    margin-bottom: 42px;

    @media (max-width: 1297px) {
        width: calc(100vw * (190 / 1280));
        height: calc(100vw * (190 / 1280));
        margin-bottom: calc(100vw * (42 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (154 / 428));
        height: calc(100vw * (154 / 428));
        margin-bottom: calc(100vw * (57 / 428));
    }
`;
