//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import serverController from '../../server/serverController';
import ReactHtmlParser from 'react-html-parser';
//css
import styled from 'styled-components';

//component

import DateSplit from '../../utils/DateSplit';

//img
import SearchImg from '../../img/icon/icon_search.svg';

export default function SupportNews() {
    const [keyword, setKeyword] = useState('');
    const [allCount, setAllCount] = useState(0);
    const [newsData, setNewsData] = useState([]);
    const [moreBtn, setMoreBtn] = useState(false);
    const [clickSet, setClickSet] = useState({
        offset: 1,
        limit: 5,
        keyword: 'all',
        type: 'clean',
    });

    // 페이지 이동
    const history = useHistory();
    const { country } = useParams();
    // 첫렌더링시
    useEffect(() => {
        updateClickSet(null, null, null, 'clean');
        setMoreBtn(false);
    }, []);

    useEffect(() => {
        serverController.connectFetchController(
            `api/support/notice/list?offset=${clickSet.offset}&limit=${clickSet.limit}&keyword=${clickSet.keyword}`,
            'GET',
            null,
            null,
            (res) => {
                const data = res.notice ?? [];
                if (res.result === 1) {
                    if (clickSet.type === 'clean') {
                        setNewsData(res.notice);
                    } else {
                        //more
                        setNewsData((prev) => prev.concat(data));
                    }
                    setAllCount(res.allCount);
                } else if (clickSet.type !== 'more') {
                    setNewsData([]);
                }
            },
        );
    }, [clickSet]);

    useEffect(() => {
        if (allCount > newsData.length) {
            setMoreBtn(true);
        } else {
            setMoreBtn(false);
        }
    }, [newsData, allCount]);

    const updateClickSet = (_offset, _limit, _keyword, _type) => {
        setClickSet({
            offset: _offset !== null ? _offset : clickSet.offset,
            limit: _limit !== null ? _limit : clickSet.limit,
            keyword: _keyword !== null ? _keyword : clickSet.keyword,
            type: _type !== null ? _type : clickSet.type,
        });
    };

    // 더보기
    const getMoreNewsData = () => {
        updateClickSet(clickSet.offset + 1, null, null, 'more');
    };

    // 검색
    const searchFunc = () => {
        updateClickSet(null, null, keyword, null);
    };
    //엔터 클릭 가능
    const enterSearch = (e) => {
        if (e.code === 'Enter') {
            searchFunc();
        }
    };

    return (
        <>
            <NewsTop>
                <NewTit>news letter</NewTit>
                <SearchWrap>
                    <SearchCon
                        type="search"
                        placeholder="search"
                        value={keyword}
                        onChange={(e) => {
                            setKeyword(e.target.value);
                            searchFunc();
                        }}
                        onKeyPress={enterSearch}
                    />
                    <SearchBtn src={SearchImg} />
                </SearchWrap>
            </NewsTop>
            <NewsCon>
                <NewsWrap>
                    {newsData.map((value, index) => {
                        let date = value.noticeDate;

                        return (
                            <NewsList
                                key={index}
                                onClick={() => {
                                    history.push(`/${country}/support/detail/news/${value.noticeId}`);
                                }}
                            >
                                <ListLeft>
                                    <NewsTit>{value.noticeTitle}</NewsTit>
                                    <NewsText>{ReactHtmlParser(value.noticeContent)}</NewsText>
                                </ListLeft>
                                <ListRight>
                                    <NewsWriter></NewsWriter>
                                    <NewsDate>{DateSplit(date)}</NewsDate>
                                </ListRight>
                            </NewsList>
                        );
                    })}
                    {moreBtn && <MoreBtn onClick={() => getMoreNewsData()}>more</MoreBtn>}
                </NewsWrap>
            </NewsCon>
        </>
    );
}

const NewsTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 42px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1356px;
        height: 1px;
        background-color: #666666;
    }

    @media (max-width: 1357px) {
        &::after {
            width: 100%;
        }
    }

    @media (max-width: 1297px) {
        padding-bottom: calc(100vw * (42 / 1280));
    }

    @media (max-width: 1023px) {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: calc(100vw * (30 / 428));
    }
`;

const NewTit = styled.p`
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (25 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (16 / 428));
        margin-bottom: calc(100vw * (30 / 428));
    }
`;
const SearchBtn = styled.div`
    position: absolute;
    top: 50%;
    right: 21px;
    transform: translateY(-50%);
    width: 17px;
    height: 17px;
    background: url(${SearchImg}) no-repeat center / contain;
    cursor: pointer;
`;
const SearchWrap = styled.div`
    width: 275px;
    padding: 14px 38px 14px 17px;
    border: 1px solid #dddddd;
    border-radius: 40px;
    position: relative;
    margin-right: 112px;

    @media (max-width: 1297px) {
        width: calc(100vw * (275 / 1280));
        padding: calc(100vw * (14 / 1280)) calc(100vw * (38 / 1280)) calc(100vw * (14 / 1280)) calc(100vw * (17 / 1280));
        margin-right: calc(100vw * (112 / 1280));

        &::after {
            right: calc(100vw * (21 / 1280));
            width: calc(100vw * (17 / 1280));
            height: calc(100vw * (17 / 1280));
        }
    }

    @media (max-width: 1023px) {
        width: 100%;
        padding: calc(100vw * (16 / 428)) calc(100vw * (35 / 428)) calc(100vw * (16 / 428)) calc(100vw * (18 / 428));
        margin-right: 0;

        &::after {
            right: calc(100vw * (18 / 428));
            width: calc(100vw * (17 / 428));
            height: calc(100vw * (17 / 428));
        }
    }
`;

const SearchCon = styled.input`
    font-family: 'Syncopate';
    font-size: 14px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (14 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (12 / 428));
    }
`;

const NewsCon = styled.div``;

const NewsWrap = styled.ul``;

const NewsList = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Pretendard';
    font-size: 15px;
    font-weight: 500;
    padding: 43px 0;
    position: relative;
    cursor: pointer;

    &:not(:last-child)::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1356px;
        height: 1px;
        background-color: #eeeeee;
    }

    @media (max-width: 1357px) {
        &:not(:last-child)::after {
            width: 100%;
        }
    }

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        padding: calc(100vw * (43 / 1280)) 0;
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
        padding: calc(100vw * (20 / 428)) 0;
    }
`;

const ListLeft = styled.div`
    max-width: 683px;

    @media (max-width: 1297px) {
        max-width: calc(100vw * (683 / 1280));
    }

    @media (max-width: 1023px) {
        max-width: calc(100vw * (204 / 428));
    }
`;

const NewsTit = styled.p`
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    overflow: hidden;
    isolation: isolate;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (10 / 1280));
        font-size: calc(100vw * (16 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (14 / 428));
        font-size: calc(100vw * (14 / 428));
    }
`;

const NewsText = styled.p`
    position: relative;
    overflow: hidden;
    isolation: isolate;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 37px;
    @media (max-width: 1297px) {
        height: calc(100vw * (37 / 1280));
    }
    @media (max-width: 1023px) {
        height: calc(100vw * (33 / 428));
    }

    /* max-width */
`;

const ListRight = styled.div`
    display: flex;

    @media (max-width: 1023px) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
`;

const NewsWriter = styled.p`
    color: #005aa2;
    margin-right: 60px;
    max-width: 130px;
    overflow: hidden;
    isolation: isolate;
    z-index: 0;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 1297px) {
        max-width: calc(100vw * (130 / 1280));
        margin-right: calc(100vw * (60 / 1280));
    }

    @media (max-width: 1023px) {
        max-width: calc(100vw * (110 / 428));
        margin-right: 0;
        margin-bottom: calc(100vw * (6 / 428));
    }
`;

const NewsDate = styled.p`
    color: #aaaaaa;
    max-width: 130px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    isolation: isolate;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 1297px) {
        max-width: calc(100vw * (130 / 1280));
    }

    @media (max-width: 1023px) {
        max-width: calc(100vw * (110 / 428));
    }
`;

const MoreBtn = styled.div`
    margin-top: 50px;
    cursor: pointer;

    @media (max-width: 1297px) {
        font-size: small;
        margin-top: 30px;
        max-width: calc(100vw * (130 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: small;
        margin-top: 30px;
        max-width: calc(100vw * (110 / 428));
    }
`;
