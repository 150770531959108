//react
import { useEffect, useState, useRef } from 'react';

//css
import styled, { css } from 'styled-components';

//img
import Vector6 from '../../img/main/vector_6.svg';
import Vector7 from '../../img/main/vector_7.svg';
// import Mastering from '../../img/main/mastering.png';
import Mastering from '../../img/main/mastering2.jpg';

//lang
import { useLangText } from '../../utils/langugeController/langController';
import { KR, EN, CN, ES } from './language/MainSection8/combLang';

export default function MainSection10() {
    const langText = useLangText(KR, EN, CN, ES);
    return (
        <>
            <Container>
                <GreenWrap>
                    <Vec6 src={Vector6} alt='vector image' />
                    <Vec7 src={Vector7} alt='vector image' />
                    <ContentWrap>
                        <ImageWrap>
                            <Picture src={Mastering} alt={'Mastering'} />
                        </ImageWrap>
                        <TextBox>
                            <ContentTitle>Audio Mastering on Audio & Video</ContentTitle>
                            <ContentInfo>{langText.Mastering2}</ContentInfo>
                        </TextBox>
                    </ContentWrap>
                </GreenWrap>
            </Container>
        </>
    );
}

const Container = styled.div`
    position: relative;
    width: 100%;
`;
const GreenWrap = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #19A9B2;
    color: white;
`;
const ContentWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 78px;
    padding: 140px 0;
    max-width: 1200px;
    margin: auto;
    @media (max-width: 1279px) {
        padding: 80px 40px;
        gap: 32px;
    }

    @media (max-width: 767px) {
        padding: 24px 20px;
        gap: 20px;
        flex-wrap: wrap;
    }
`;
const ImageWrap = styled.div`
    z-index: 5;
`;
const Picture = styled.img`
    width: 480px;
    height: 320px;

    @media (max-width: 1279px) {
        width: 264px;
        height: 176px;
    }
    @media (max-width: 767px) {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 5;
`;
const ContentTitle = styled.h3`
    font-size: 32px;
    font-weight: 700;
    @media (max-width: 1279px) {
    font-size: 18px;
    }
    @media (max-width: 767px) {
    font-size: 16px;
    }
`;
const ContentInfo = styled.p`
    font-family: 'Pretendard';
    font-size: 24px;
    font-weight: 400;
    color: white;
    line-height: 150%;
    
    @media (max-width: 1279px) {
    font-size: 16px;
    }
    @media (max-width: 767px) {
    font-size: 14px;
    }
`;
const Vec6 = styled.img`
    position: absolute;
    z-index: 2;
    bottom: -412px;
    right: -356px;
    width: 830px;
    height: 813px;
    transform: rotate(0deg);
    @media (max-width: 1279px) {
    }

    @media (max-width: 767px) {
        display: none;
    }
`;
const Vec7 = styled.img`
    position: absolute;
    z-index: 1;
    bottom: -42px;
    right: -186px;
    width: 732px;
    height: 714px;
    transform: rotate(-2deg);
    @media (max-width: 1279px) {
    }

    @media (max-width: 767px) {
        display: none;
    }
`;