//react
import { useEffect, useState, useRef } from 'react';

//css
import styled, { css } from 'styled-components';

//img
import Facebook from '../../img/main/facebook.svg';
import Instagram from '../../img/main/instagram.svg';
import Tiktok from '../../img/main/tiktok.svg';
import Youtube from '../../img/main/youtube.svg';
import Blog from '../../img/main/blog.svg';

//lang
import { useLangText } from '../../utils/langugeController/langController';
import { KR, EN, CN, ES } from './language/MainSection12/combLang';

export default function MainSection13() {
    const langText = useLangText(KR, EN, CN, ES);
    const movePage = (type) => {
        if (type === 'Facebook') {
            window.open('https://www.facebook.com/profile.php?id=100082236800832', "_blank", "noopener, noreferrer");
        }
        if (type === 'Instagram') {
            window.open('https://www.instagram.com/warp_aiaudio/', "_blank", "noopener, noreferrer");
        }
        if (type === 'Tiktok') {
            window.open('https://www.tiktok.com/@warpaimastering', "_blank", "noopener, noreferrer");
        }
        if (type === 'Youtube') {
            window.open('https://www.youtube.com/@WARPAIAUDIO', "_blank", "noopener, noreferrer");
        }
        if (type === 'Blog') {
            window.open('https://blog.naver.com/warpaimastering', "_blank", "noopener, noreferrer");
        }
    }

    return (
        <>
            <Container>
                <SectionSubTitle>
                    Follow us for more Information
                </SectionSubTitle>
                <IconsWrap>
                    <img src={Facebook} alt='Facebook' onClick={()=>{movePage('Facebook')}}/>
                    <img src={Instagram} alt='Instagram' onClick={()=>{movePage('Instagram')}}/>
                    <img src={Tiktok} alt='Tiktok' onClick={()=>{movePage('Tiktok')}}/>
                    <img src={Youtube} alt='Youtube' onClick={()=>{movePage('Youtube')}}/>
                    <img src={Blog} alt='Blog' onClick={()=>{movePage('Blog')}}/>
                </IconsWrap>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 72px;
    @media (max-width: 1279px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        padding: 0 40px 100px 40px;
    }

    @media (max-width: 767px) {
        padding: 0 20px 64px 20px;
    }
`;
const SectionSubTitle = styled.div`
    color: #121212;
    font-size: 24px;
    font-weight: 700;

    @media (max-width: 1279px) {
        font-size: 18px;
    }

    @media (max-width: 767px) {
        font-size: 16px;
    }
`;
const IconsWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 32px;
    > img {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    @media (max-width: 1279px) {
        gap: 32px;
        > img {
        width: 24px;
        height: 24px;
        }
    }

    @media (max-width: 767px) {
        gap: 32px;
        > img {
        width: 24px;
        height: 24px;
        }
    }
`;