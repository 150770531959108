export const CN = {
    MainText: '关于音频的最佳解决方案',
    MainSubText: '来体验针对音乐、有声读物、视频等音频内容相关的高品质、舒适的音频解决方案吧。',
    NoiseText: '去噪和母带的综合性体验',
    NoiseSubText: '来体验在上传的同时，能分析内容物的音频，消除噪音并一次性执行母带作业的愉悦工作流程吧。',
    EveryoneText: '任何人都可以操作的简易性',
    EveryoneSubText: '来体验在上传的同时，能分析内容物的音频、消除噪音并一次性执行母带作业的愉悦工作流程吧。',
    IdeaText: '创作者的想象能立即变为作品',
    IdeaSubText: '请体验一下WARP为全世界所有创作者们带来的人工智能音频表演吧。',
    AiText: '为专业人士准备的AI audio mastering<br/>(人工智能音频母带处理）',
    AiSubText: '按照业界标准来进行的母带处理的WARP的AI母带处理是一套能提供专业水平的母带工程系统。 来感受一下专业的人工智能母带处理服务吧。',
    UserText: '如用户所想',
    UserSubText:
        '通过用户选择的多种选项来调整结果的性质。请按照作品的风格来选择<br/>HIGH BOOST、BALANCED、PUNCH。<br/>另外，通过WARP支持的母带参考小样，可以实现更具体的定制。<br/>',
    EasyText: '即时，简便，低廉',
    EasySubText: '无需再为母带工程作业而浪费时间和金钱。无需复杂的步骤，即刻获得结果。<br/> 任何人都可以轻松使用，只需上传文件即可。<br/>',
    MasterText: '可一览您的MASTER PIECE<br/>（母带小样）',
    MasterSubText: 'WARP为您提供专属的MASTER PIECE（母带小样）存储库。<br/>您可以通过存储库便捷管理和分享作品。<br/>',
    AudioText: '包含音频的所有内容',
    AudioSubText: '为任何包含音频的物料（例如视频、播客和有声读物）提供最佳音频解决方案。',
    LevelText: '一步实现除燥，调节音量的惊人体验',
    LevelSubText: '它在除去音频中不需要的环境噪音和杂音的同时，将重要内容传达的更清晰，让其保持在最佳音量大小。<br/><br/> 所有的操作可以一步完成。<br/>',
    OvercomeText: '可以克服复杂的音频设备。',
    OvercomeSubText: '无需配备昂贵的设备或者学习复杂的程序。立即用您的手机测试一下。<br/><br/> WARP为您打造最好的 作品。',
};
