//react
import { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PC, Mobile } from '../../media/MediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import serverController, { ip } from '../../server/serverController';
import { fileActionType } from '../../store/actionCreator';
import { ToastContainer, toast } from 'react-toastify';

//localStorage
import { getLocalS } from '../../utils/localStorageController';

//css
import 'swiper/swiper-bundle.css';
import 'swiper/components/pagination/pagination.scss';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

//component
// import MasteringList from '../common/list/MasteringList';
import MasteringList from '../common/list/Waveform';
import DefaultPopup from '../common/popup/DefaultPopup';
import MasteringPopup from './MasteringPopup';
import DownloadPopup from '../mastering/DownloadPopup';
import Modal from '../../server/Modal';
import { audioURL } from '../../server/awsURL';

// img
// import ArrowRightOn from '../../img/icon/icon_arrow_right_on.svg';
import ArrowRightOn from '../../img/icon/icon_slide_arrow_right.svg';
// import ArrowRightOff from '../../img/icon/icon_arrow_right_off.svg';
import ArrowRightOff from '../../img/icon/icon_slide_arrow_right_off.svg';
// import ArrowLeftOn from '../../img/icon/icon_arrow_left_on.svg';
import ArrowLeftOn from '../../img/icon/icon_slide_arrow_left.svg';
// import ArrowLeftOff from '../../img/icon/icon_arrow_left_off.svg';
import ArrowLeftOff from '../../img/icon/icon_slide_arrow_left_off.svg';
import ArrowDownIcon from '../../img/icon/icon_caret_down.svg';
import ArrowDownIconGray from '../../img/icon/icon_caret_down_gray.svg';
import DownloadImg from '../../img/icon/icon_download_btn.svg';
import DownImgIcon from '../../img/icon/icon_download.svg';
import CheckImgIcon from '../../img/icon/icon_checkmark.svg';
import FolderAddIcon from '../../img/icon/icon_folder_add.svg';
import UploadImgIcon from '../../img/icon/icon_upload.svg';
import PlusImg from '../../img/icon/icon_plus2.svg';
import BarVerticalImg from '../../img/icon/icon_vertical_bar.svg';
import loading from '../../img/mastering/loading.gif';

import CheckImg from '../../img/icon/icon_checked.svg';
import CustomBgImg from '../../img/mastering/customizing_bg.png';
// import BgImg from '../../img/mastering/bg_mastering.png';
import BgUpImg from '../../img/mastering/bg_mastering_up.png';
import BgDownImg from '../../img/mastering/bg_mastering_down.png';
import CustomNoneMusicBgImg from '../../img/mastering/customizing_none_musin_bg.png';
import HighBoost from '../../img/mastering/highboost.svg';
import Balanced from '../../img/mastering/balanced.svg';
import Punch from '../../img/mastering/punch.svg';
import Hq from '../../img/mastering/hq.svg';
import Reference from '../../img/mastering/reference.svg';
import Dialogue from '../../img/mastering/dialog_1.svg';
import Music from '../../img/mastering/music.svg';

import { useSelector } from 'react-redux';

import fileDownload from './fileDownload';

import { styledMui } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { logDOM } from '@testing-library/react';

export default function MasteringBody() {
    // swiper
    SwiperCore.use([Navigation, Pagination]);

    // 토스트
    const notify = () => {
        if (sliderEqDisabled) {
            if (btnClick === 1) {
                toast('Disable on balanced option');
            } else if (btnClick === 3) {
                toast('Disable on reference option');
            }
        }
    };
    const notifyBeforeReady = () => {
        toast('Processing. Please try when it’s done.');
    };

    const history = useHistory();
    const { country } = useParams();
    const fileArr = useSelector((state) => state.uploadFiles.file); // 파일 가져오기
    const initial = useSelector((state) => state);

    const paginationRef = useRef(null);
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    const tokenData = sessionStorage.getItem('TOKEN');

    // static data
    const btnListData = ['Punch', 'Balanced', 'High boost', 'Reference'];
    const musicDownData = ['mp3', 'wav'];
    const videoDownData = ['Mp4'];

    const [btnClick, setBtnClick] = useState(1);

    // 다운로드 드롭다운
    const [downClick, setDownClick] = useState(false);
    const [customizeClick, setCustomizeClick] = useState(false);

    const [isSwiper, setIsSwiper] = useState(null);
    const [mainImageIndex, setMainImageIndex] = useState(0);

    const [resultFileList, setResultFileList] = useState([]);
    const [fileIndex, setFileIndex] = useState(0);

    const [userId, setUserId] = useState(null);

    // mainFile
    const [mainFile, setMainFile] = useState(null);
    // const [mainFile, setMainFile] = useState(null);
    // reference 파일 넣어둔 상태에서 remastering 클릭 시 사용
    const [readyReference, setReadyReference] = useState(null);

    // 마스터링 진행 팝업
    const [OptionClick, setOptionClick] = useState(false);

    // 비디오 확대 팝업
    const [VideoClick, setVideoClick] = useState(false);

    // 옵션 변경 팝업 - 버튼 인덱스 값 받아와서 밸런스 버튼 외
    // 나머지 버튼 누를 시 모달뜸.

    // trial 다운로드 버튼 팝업
    // const [trialClick, setTrialClick] = useState(false); //쓰이지 않는것같음

    // 체험권으로 마스터링 했을 경우 true
    const [isTrial, setIsTrial] = useState(true);

    const [isOnClick, setIsOnClick] = useState(false);

    const [serverErrPop, setServerErrPop] = useState(false); //서버에러팝업
    const [remasteringSameOptionErrPop, setRemasteringSameOptionErrPop] = useState(false); // 동일 옵션 재마스터링 시도 시 에러 팝업
    const [canNotDown, setCanNotDown] = useState(false); //다운불가팝업
    const [canNotRemaster, setCanNotRemaster] = useState(false); //리마스터링불가팝업

    // 지원하는 형식이 아닐경우
    const [notFormat, setNotFormat] = useState(false);

    // 최대 지원용량 초과일경우
    const [capacity, setCapacity] = useState(false);

    // 로그인 세션 끊겼을 경우 로그인 페이지로 이동 유도
    const [notUser, setNotUser] = useState(false);

    // (추측) 마스터링 된 파일 리스트
    const [masteredList, setMasteredList] = useState([]);

    // (추측) 이미 마스터링되어서 업로드된 파일
    const getUploadFiles = getLocalS('uploadFiles');
    const masteredListRef = useRef([]);

    // HYUNHA : 안쓰이는것같아서 주석처리함 2023.03.31
    // const [remasteringCheck, setRemasteringCheck] = useState(false);
    // const [remasteringArr, setRemasteringArr] = useState([]);

    const [startIndex, setStartIndex] = useState(null);

    // HQ 스위치 토글. 0=false, 1=true (0: 44100Hz, 1: 96000Hz)
    const [toggle, setToggle] = useState(false);

    // 전체 디폴트값 : 2023-11-03 전달받음
    // ***Dialog
    // Gain:Normal(1)
    // De-Noise:Very Strong(5)
    // ***Music
    // Gain: Very Loud(3)
    // Compression: Normal(1)
    // EQ: Normal(3)
    // Stereo Image: Wide(3)

    // Customize) music - Gain
    const [sliderGainMusic, setSliderGainMusic] = useState(3);
    // Customize) dialogue/video - Gain
    const [sliderGain, setSliderGain] = useState(1);
    const handleSliderGainMusic = (event, newValue) => {
        setSliderGainMusic(newValue);
    };
    const handleSliderGain = (event, newValue) => {
        setSliderGain(newValue);
    };
    // Customize) music - Compression
    const [sliderComp, setSliderComp] = useState(1);
    const handleSliderComp = (event, newValue) => {
        setSliderComp(newValue);
    };
    // Customize) music - EQ
    const [sliderEq, setSliderEq] = useState(3);
    const handleSliderEq = (event, newValue) => {
        setSliderEq(newValue);
    };
    const [sliderEqDisabled, setSliderEqDisabled] = useState(true);
    // Customize) music - Stereo
    const [sliderSter, setSliderSter] = useState(3);
    const handleSliderSter = (event, newValue) => {
        setSliderSter(newValue);
    };
    // Customize) dialogue/video - Deno
    const [sliderDeno, setSliderDeno] = useState(5);
    const handleSliderDeno = (event, newValue) => {
        setSliderDeno(newValue);
    };

    // remastering percent data
    const [percentData, setPercentData] = useState(null);

    const [loadingPop, setLoadingPop] = useState(false);

    // swipe시 all pause
    const [allPause, setAllPause] = useState(false);

    // swiper module
    const swiperModules = {
        navigation: {
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
        },
        pagination: {
            el: paginationRef.current,
            clickable: true,
        },
        onBeforeInit: (swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.activeIndex = mainImageIndex;
            swiper.navigation.update();
        },
        onSwiper: setIsSwiper,
        onSildeChange: (e) => setMainImageIndex(e.activeIndex),
    };

    // 스위치 데모 테스트
    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const marksGain = [
        {
            value: 1,
            label: 'Normal',
        },
        {
            value: 2,
            label: 'Loud',
        },
        {
            value: 3,
            label: 'Very Loud',
        },
        {
            value: 4,
            label: 'Extremely Loud',
        },
    ];
    const marksCompression = [
        {
            value: 1,
            label: 'Normal',
        },
        {
            value: 2,
            label: 'Bit Strong',
        },
        {
            value: 3,
            label: 'Strong',
        },
        {
            value: 4,
            label: 'Very Strong',
        },
        {
            value: 5,
            label: 'Extremely Strong',
        },
    ];
    const marksEq = [
        {
            value: 1,
            label: 'Very Weak',
        },
        {
            value: 2,
            label: 'Weak',
        },
        {
            value: 3,
            label: 'Normal',
        },
        {
            value: 4,
            label: 'Strong',
        },
        {
            value: 5,
            label: 'Very Strong',
        },
    ];
    const marksStereoImage = [
        {
            value: 1,
            label: 'Normal',
        },
        {
            value: 2,
            label: 'Bit Wide',
        },
        {
            value: 3,
            label: 'Wide',
        },
        {
            value: 4,
            label: 'Very Wide',
        },
        {
            value: 5,
            label: 'Extremely Wide',
        },
    ];
    const marksGainNoneMusic = [
        {
            value: 1,
            label: 'Normal',
        },
        {
            value: 2,
            label: 'Loud',
        },
        {
            value: 3,
            label: 'Very Loud',
        },
    ];
    const marksNoiseNoneMusic = [
        {
            value: 1,
            label: 'No Reduction',
        },
        {
            value: 2,
            label: 'Weak',
        },
        {
            value: 3,
            label: 'Normal',
        },
        {
            value: 4,
            label: 'Strong',
        },
        {
            value: 5,
            label: 'Very Strong',
        },
    ];

    // 슬라이더 테스트
    function valuetext(value) {
        return `${value}`;
    }

    // 1. 최초에 유저 id 값을 가져오기 (맨 처음 렌더링될 때만 실행, 업데이트될 때는 실행하지 않음)
    useEffect(() => {
        // console.log("useEffect1 : 유저 id 값 세팅")
        if (tokenData) {
            document.body.style.overflowY = 'auto';
            serverController.connectFetchController(
                `api/mypage/info`,
                'POST',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                        setUserId(res.user.userId);
                    }
                },
            );
        }
    }, []);

    // 2. 최초 페이지 접속시, 마스터링 된 파일들 바인딩 (userId 업데이트될 때만 실행)
    useEffect(() => {
        // console.log('useEffect2 : userID 업데이트 되었을 경우 실행됨');
        // console.log('useEffect2 : Local에 저장된 업로드 파일 경로 ', getUploadFiles);
        if (getUploadFiles && getUploadFiles.length > 0 && userId) {
            // redux store에 있는 fileArr 데이터 조회
            // fileArr.reCheck data 체크해서
            // redux에 type값 저장
            // default type : balanced
            if (fileArr?.reCheck?.length > 0 || fileArr?.reCheck) {
                // console.log('useEffect2 : [case 1-1] 마스터링 진행중인 파일 있음', fileArr?.reCheck);
                setMasteredList(initial?.uploadFiles?.masteredFile?.masteredFile);
                masteredListRef.current = initial?.uploadFiles?.masteredFile?.masteredFile;
            } else if (fileArr?.reCheck === false) {
                // 리마스터링 전 나갔다 들어온 경우
                // console.log('useEffect2 : [case 1-2] 마스터링 진행중인 파일 있음', fileArr?.reCheck);
                let typeArr = [];
                for (var i = 0; i < getUploadFiles?.length; i++) {
                    typeArr.push('balanced');
                }
                // tag redux에 저장 (mastering type update)
                fileActionType.upFiles({
                    typeArray: typeArr,
                    reCheck: false,
                });
                // 마스터링 결과 불러오기
                completeCheckFetch(getUploadFiles, typeArr);
            } else if (fileArr?.reCheck === null) {
                // console.log('useEffect2 : [case 2] 마스터링 진행중인 파일 없음', fileArr);
                // 첫 마스터링의 경우 (type 디폴트 값 balanced 넣어주기)
                let typeArr = [];
                for (var i = 0; i < getUploadFiles?.length; i++) {
                    typeArr.push('balanced');
                }
                // tag redux에 저장 (mastering type update)
                fileActionType.upFiles({
                    typeArray: typeArr,
                    reCheck: false,
                });
                // 마스터링 결과 불러오기
                completeCheckFetch(getUploadFiles, typeArr);
            } else {
                // console.log('getUploadFiles', getUploadFiles);
                // console.log('FILEARR', fileArr);
            }
        } else {
            // console.log('getUploadFiles', getUploadFiles);
            // console.log('FILEARR', fileArr);
        }
    }, [userId]);

    // 마스터링 결과 가져옴
    const completeCheckFetch = (files, typeName) => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${tokenData}`);
        myHeaders.append('Content-Type', 'application/json');

        var arr = [];
        for (var i = 0; i < files?.length; i++) {
            // let first_types = 'balanced';
            // if (masteredList[fileIndex]?.wavType !== 'music') {
            // if (files[i]?.indexOf('.mp4') !== -1) {
            //     first_types = 'default'
            // }

            const tempArr = files[i]?.split('/');
            var obj = { fileName: tempArr[tempArr?.length - 1], type: typeName[i] };
            // var obj = { fileName: tempArr[tempArr?.length - 1], type: first_types };
            arr.push(obj);
        }
        const raw = JSON.stringify(arr);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        // 마스터링 완료 체크
        return fetch(`${ip}api/mastering/complete_check`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                const res = JSON.parse(result);
                if (res.masteringCompleteStatuses[0].useFreePlan === 1) {
                    setIsTrial(true);
                }
                if (res.masteringCompleteStatuses[0].useFreePlan === 0) {
                    setIsTrial(false);
                }
                {
                    const resMasteredStatus = res.masteringCompleteStatuses;
                    // 데이터 예시
                    // "masteringCompleteStatuses":[
                    //      {
                    //          "liId":3051,
                    //          "fileName":"샘플_1_20220830154525.wav",
                    //          "status":true,
                    //          "wavType":"music",
                    //          "masteringType":"balanced",
                    //          "secondDuration":25
                    //      },
                    // ]
                    let arr = [...masteredListRef.current];
                    for (let i = 0; i < resMasteredStatus?.length; i++) {
                        if (resMasteredStatus[i].status) {
                            //  status가 true가 되면 마스터링 완료가 됬다는 뜻
                            if (!arr.some((x) => x.fileName === resMasteredStatus[i].fileName)) {
                                // 현재 같은 파일 이름이 저장되어있지 않은 경우 추가
                                arr.push(resMasteredStatus[i]);
                            }
                        }
                    }
                    // 완료되면 masteredListRef.current에 저장
                    // 완료되면 MasteredList에 저장
                    masteredListRef.current = arr;
                    setMasteredList(arr);
                    // 응답 받은 값으로, 마스터링된 파일의 제목, 태그 변경해주기 (이유:tag를 넣기 위함)
                    for (var a = 0; a < arr.length; a++) {
                        setNameTag(arr[a]);
                    }
                    // redux에 저장 (마스터링완료됨)
                    fileActionType.masteredFile({
                        masteredFile: arr,
                    });
                    // 마스터링 완료될 때까지 1초마다 반복
                    if (masteredListRef?.current?.length !== getUploadFiles?.length) {
                        var refetch = setTimeout(() => {
                            completeCheckFetch(getUploadFiles, typeName);
                        }, 1000);
                    } else {
                        clearTimeout(refetch);
                    }
                }
            })
            .catch((error) => {
                console.warn('ERROR :', error);
                setServerErrPop(true);
                setLoadingPop(false);
            });
    };

    // 라이브러리 제목, 태그 변경 API.
    const setNameTag = (item) => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${tokenData}`);
        myHeaders.append('Content-Type', 'application/json');
        var raw = JSON.stringify({
            liId: item.liId,
            liName: item.fileName,
            liTag: '#' + item.masteringType,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        fetch(`${ip}api/library`, requestOptions)
            .then((response) => response.text())
            .then((result) => {});
    };

    // Mastering fetch -> reference file upload
    const masteringFetch = (type) => {
        // console.log('masteringFetch++++++++', type);
        if (userId !== null) {
            let fileNameRemastered = fileNameExtract(masteredList[fileIndex]?.fileName);
            if (fileNameRemastered.lastIndexOf('.wav') === -1) {
                fileNameRemastered = fileNameRemastered + '.wav';
            }
            // console.log("*********",fileNameRemastered)
            var formdata = new FormData();
            Object.values(mainFile).map((item) => {
                formdata.append('ref_files', item);
            });
            formdata.append('user_id', userId);
            formdata.append('work_file', `http://10.0.10.59:5002/static/upload/${userId}/` + fileNameRemastered);
            formdata.append('is_HQ', '0');

            formdata.append('wav_type', masteredList[fileIndex]?.wavType);

            const myHeaders = new Headers();
            myHeaders.append('Authorization', `Bearer ${tokenData}`);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                headers: myHeaders,
                redirect: 'follow',
            };

            fetch('https://warpaiaudio.com:5002/remastering2?', requestOptions)
                .then((response) => {
                    response.json();
                    // console.log(response, 'response');
                    if (response.ok === true) {
                        setOptionClick(false);
                    }
                })
                .catch((error) => {
                    console.warn('ERROR :', error);
                    setServerErrPop(true);
                });
        }
    };

    const checkBoxFunc = () => {
        setToggle(!toggle);
    };

    // 불필요
    // const convertFileFetch = () => {
    //     console.log("convertFileFetch함수")
    //     if (userId !== null) {
    //         var myHeaders = new Headers();
    //         myHeaders.append('Authorization', `Bearer ${tokenData}`);

    //         var formdata = new FormData();
    //         formdata.append('user_id', userId);
    //         formdata.append('target_ext', 'mp3');
    //         formdata.append('src_url', audioURL + masteredList[fileIndex]?.fileName);

    //         var requestOptions = {
    //             method: 'POST',
    //             headers: myHeaders,
    //             body: formdata,
    //             redirect: 'follow',
    //         };

    //         fetch('https://warpaiaudio.com:5002/convert_file2', requestOptions)
    //             .then((response) => response.text())
    //             .then((result) => {
    //                 const res = JSON.parse(result);

    //                 setLoadingPop(true);
    //                 fileDownload(res?.result_url, 'mp3', setLoadingPop);
    //             })
    //             .catch((error) => {
    //                 console.log('error', error);
    //                 setServerErrPop(true)
    //                 alert('[mastering error] please refresh the page and try again');
    //             });
    //     }
    // };
    // 불필요

    // s : popup-----------------------------------------------
    // trial 데이터
    // const trialData = {
    //     isState: trialClick,
    //     setIsState: setTrialClick,
    //     popText: 'Trial은 결과물 다운로드가 불가능합니다.',
    //     blueText: '*다운로드는 플랜이용 고객만 가능합니다.',
    //     btnText: '닫기',
    // };
    // HYUNHA 안쓰이는것같아서 주석처리

    // 팝업 데이터
    const notFormatData = {
        isState: notFormat,
        setIsState: setNotFormat,
        popText: 'File format is not supported.',
        blueText: '*Please try with wav, aiff, mp4 or mov file.',
        btnText: 'close',
    };

    // 다운 불가 팝업 데이터
    const canNotDownPopupData = {
        isState: canNotDown,
        setIsState: setCanNotDown,
        popText: 'Unable to download the trial file.',
        btnText: 'close',
    };
    // 리마스터링 불가 팝업 데이터
    const canNotMasterPopupData = {
        isState: canNotRemaster,
        setIsState: setCanNotRemaster,
        popText: 'Unable to reamstering the trial file.',
        btnText: 'close',
    };

    const capacityData = {
        isState: capacity,
        setIsState: setCapacity,
        popText: 'File size has been exceeded.',
        blueText: (
            <div>
                Individual file sizes are up to 200mb
                <br /> for audio files and 700mb for video files.
            </div>
        ),
        btnText: 'close',
    };
    // HYUNHA: 서버 에러 팝업
    const serverErr = {
        isState: serverErrPop,
        setIsState: setServerErrPop,
        popText: '[server error]please refresh the page and try again.',
        btnText: 'close',
    };
    // HYUNHA: 서버 에러 팝업
    const remasteringSameOptionErr = {
        isState: remasteringSameOptionErrPop,
        setIsState: setRemasteringSameOptionErrPop,
        popText: 'Mastering cannot proceed in the same setup.\nPlease change the settings.',
        btnText: 'close',
    };
    // 로그인 세션 끊겼을 경우 로그인 페이지로 이동 유도
    const notUserData = {
        isState: notUser,
        setIsState: setNotUser,
        popText: 'You need to log in.',
        // blueText: '* You need to log in.',
        btnText: 'LOGIN',
        url: '/login',
    };
    // e : popup-----------------------------------------------

    useEffect(() => {
        // console.log("useEffect-fileIndex,masteredList",masteredList[fileIndex])
        // masteredList 값에 따른 music type 값 바인딩
        if (masteredList && masteredList[fileIndex]?.wavType === 'music') {
            // if (masteredList[fileIndex]?.masteringType.indexOf('_') !== -1) {
            if (masteredList[fileIndex]?.isHq === '1') {
                setToggle(true);
            } else {
                setToggle(false);
            }
            if (masteredList[fileIndex]?.masteringType.includes('balanced')) {
                setBtnClick(1);
                setSliderEqDisabled(true);
            } else if (masteredList[fileIndex]?.masteringType.includes('punch')) {
                setBtnClick(0);
                setSliderEqDisabled(false);
            } else if (masteredList[fileIndex]?.masteringType.includes('high')) {
                setBtnClick(2);
                setSliderEqDisabled(false);
            } else if (masteredList[fileIndex]?.masteringType.includes('reference')) {
                setBtnClick(3);
                setSliderEqDisabled(true);
            }
            if (masteredList[fileIndex]?.lufsStep) {
                setSliderGainMusic(masteredList[fileIndex]?.lufsStep);
            }
            if (masteredList[fileIndex]?.compStep) {
                setSliderComp(masteredList[fileIndex]?.compStep);
            }
            if (masteredList[fileIndex]?.eqStep) {
                setSliderEq(masteredList[fileIndex]?.eqStep);
            }
            if (masteredList[fileIndex]?.stereoStep) {
                setSliderSter(masteredList[fileIndex]?.stereoStep);
            }
        } else {
            if (masteredList[fileIndex]?.denoiseStep) {
                setSliderDeno(masteredList[fileIndex]?.denoiseStep);
            }
            if (masteredList[fileIndex]?.lufsStep) {
                setSliderGain(masteredList[fileIndex]?.lufsStep);
            }
        }
    }, [fileIndex, masteredList]);

    // fileIndex(swiper) 움직이면 all pause
    useEffect(() => {
        // console.log("useEffect-fileIndex")
        let video = document.querySelectorAll('#videoContainer');
        if (video) {
            video.forEach((vid) => vid.pause());
            setAllPause(true);
        }
    }, [fileIndex]);

    // s : reference file upload
    const dragRef = useRef(null);

    // HYUNHA : 불필요한것같아서 일단 주석처리함
    // background
    // const [BgOn, setBgOn] = useState(false);
    // const onClickInput = useRef(false); // 창이 켜졌는지 관리

    // function action_open() {
    //     console.log("action_open")
    //     onClickInput.current = true;
    /* file dialog 켜졌을때 동작 함수 */
    // setBgOn(true);
    // }
    // function action_close() {
    // console.log("action_close")
    // onClickInput.current = false;
    /* file dialog 꺼졌을때 동작 함수 */
    // setBgOn(false);
    // }
    // function onFocusWin(e) {
    // console.log(e)
    // console.log("onFocusWin함수------",onClickInput.current)
    /* 윈도우에 포커스가 갈때 이벤트 발생 함수 */
    // if (onClickInput.current) {
    // console.log("onClickInput:",onClickInput.current)
    // action_close();
    // } else {
    // console.log("onClickInput:",onClickInput.current)
    // window.addEventListener('focus', onFocusWin);
    // window.removeEventListener('focus', onFocusWin);
    // }
    // }

    // useEffect(() => {
    // console.log("useEffect5-focus관련-----addevent")
    /* 컴포넌트가 마운트 되면 window에 focus 이벤트 부여 */
    // window.addEventListener('focus', onFocusWin);
    // return () => {
    // console.log("useEffect5-focus관련2------removeevent")
    /* 컴포넌트가 마운트 해제되면 window에 이벤트 제거 */
    // window.removeEventListener('focus', onFocusWin);
    // };
    // }, []);
    // HYUNHA : 불필요한것같아서 일단 주석처리함

    const handleDrag = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        for (var t = 0; t < e.dataTransfer.files.length; t++) {
            if (e.dataTransfer.files[t].type !== 'audio/wav' && e.dataTransfer.files[t].type !== 'audio/aiff') {
                // 파일 용량 검사
                return setNotFormat(true); // 포맷 경고 문구 팝업 노출
            } else if (
                (e.dataTransfer.files[t].type === 'audio/wav' && e.dataTransfer.files[t].size > 2147483648) ||
                (e.dataTransfer.files[t].type === 'audio/aiff' && e.dataTransfer.files[t].size > 209715200)
            ) {
                return setCapacity(true); // 용량 경고 문구 팝업 노출
            } else {
                e.type === 'drop' && setMainFile(e.dataTransfer.files); // 포맷, 용량이 기준에 맞을 경우, drag drop으로 파일 업로드를 진행했을 경우, 해당 파일을 mainFile에 저장
            }
        }
    }, []);

    useEffect(() => {
        // console.log("useEffect-mainFile")
        if (mainFile !== false && mainFile !== undefined && mainFile !== null && mainFile.length > 0) {
            // 파일 용량 검사
            for (let i = 0; i < mainFile.length; i++) {
                if (
                    mainFile[i].type !== 'audio/wav' &&
                    mainFile[i].type !== 'audio/aiff' &&
                    mainFile[i].type !== 'audio/x-wav' &&
                    mainFile[i].type !== 'audio/x-aiff'
                ) {
                    // mainFile 초기화
                    setMainFile(null);
                    return setNotFormat(true); // 포맷 경고 문구 팝업 노출
                } else if (
                    (mainFile[i].type === 'audio/wav' && mainFile[i].size > 2147483648) ||
                    (mainFile[i].type === 'audio/aiff' && mainFile[i].size > 209715200) ||
                    (mainFile[i].type === 'audio/x-wav' && mainFile[i].size > 2147483648) ||
                    (mainFile[i].type === 'audio/x-aiff' && mainFile[i].size > 209715200)
                ) {
                    // mainFile 초기화
                    setMainFile(null);
                    return setCapacity(true); // 용량 경고 문구 팝업 노출
                }
            }
            masteringFetch('balanced');
        }
    }, [mainFile]);

    const initDragEvents = useCallback(() => {
        if (dragRef.current !== null) {
            dragRef.current.addEventListener('dragenter', handleDrag);
            dragRef.current.addEventListener('dragleave', handleDrag);
            dragRef.current.addEventListener('dragover', handleDrag);
            dragRef.current.addEventListener('drop', handleDrop);
        }
    }, [handleDrag, handleDrop]);

    const resetDragEvents = useCallback(() => {
        if (dragRef.current !== null) {
            dragRef.current.removeEventListener('dragenter', handleDrag);
            dragRef.current.removeEventListener('dragleave', handleDrag);
            dragRef.current.removeEventListener('dragover', handleDrag);
            dragRef.current.removeEventListener('drop', handleDrop);
        }
    }, [handleDrag, handleDrop]);

    useEffect(() => {
        // console.log("useEffect-initDragEvents,resetDragEvents")
        initDragEvents();

        return () => resetDragEvents();
    }, [initDragEvents, resetDragEvents]);

    // const uploadHandler = (e) => {
    //     let file = e.target.files;
    //     if (file.length + resultFileList.length > 100) {
    //         return alert('최대 데이터는 총 100개입니다.');
    //     }

    //     if (isOnClick) {
    //         setMainFile(file);
    //     } else {
    //         return;
    //     }
    // };
    // HYUNHA 안쓰이는것같아서 일단 주석처리

    // TODO: masteringType 이 balanced_Customized 로 오면, side effect 이 일어날지 확인할 것
    const fileNameExtract = (fileNameRemastered) => {
        // fileName 확인해서 확장자 & 기존에 있던 mastering type이 있으면 빼줌.
        if (fileNameRemastered.indexOf('_punch') !== -1) {
            fileNameRemastered = fileNameRemastered.split('_punch')[0];
        } else if (fileNameRemastered.indexOf('_punch_HQ') !== -1) {
            fileNameRemastered = fileNameRemastered.split('_punch_HQ')[0];
        } else if (fileNameRemastered.indexOf('_balanced') !== -1) {
            fileNameRemastered = fileNameRemastered.split('_balanced')[0];
        } else if (fileNameRemastered.indexOf('_balanced_HQ') !== -1) {
            fileNameRemastered = fileNameRemastered.split('_balanced_HQ')[0];
        } else if (fileNameRemastered.indexOf('_high') !== -1) {
            fileNameRemastered = fileNameRemastered.split('_high')[0];
        } else if (fileNameRemastered.indexOf('_high_HQ') !== -1) {
            fileNameRemastered = fileNameRemastered.split('_high_HQ')[0];
        }
        // exists API 호출 시 오류나기 때문에 추가. AI 서버 측에서 파일명 수정한 시점
        else if (fileNameRemastered.indexOf('_reference') !== -1) {
            fileNameRemastered = fileNameRemastered.split('_reference')[0];
        } else if (fileNameRemastered.indexOf('_default') !== -1) {
            fileNameRemastered = fileNameRemastered.split('_default')[0];
        } else if (fileNameRemastered.lastIndexOf('.wav') !== -1) {
            fileNameRemastered = fileNameRemastered.split('.wav')[0];
        } else if (fileNameRemastered.lastIndexOf('.mp4') !== -1) {
            fileNameRemastered = fileNameRemastered.split('.mp4')[0];
        } else if (fileNameRemastered.lastIndexOf('.mp3') !== -1) {
            fileNameRemastered = fileNameRemastered.split('.mp3')[0];
        }
        return fileNameRemastered;
    };

    // s : 추가 마스터링 진행 - remastering
    const moreMasteringFetch = (type, is_reference) => {
        setOptionClick(true); // 리마스터링 진행 팝업 띄움
        document.body.style.overflow = 'hidden';

        // 현재 db에 중복값 여부 확인
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', `Bearer ${tokenData}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        let typeBinding = toggle ? type + '_HQ' : type;
        typeBinding += '_customizing';
        fetch(
            `${ip}api/mastering/exists?fileName=${
                fileNameExtract(encodeURIComponent(masteredList[fileIndex]?.fileNameMastering)) + '_' + typeBinding + '.wav'
            }&masteringType=${typeBinding}`,
            requestOptions,
        )
            .then((response) => response.text())
            .then((result) => {
                if (result === 'check') {
                    // 중복 데이터 없는 경우 : 응답값 check
                    if (getUploadFiles && getUploadFiles.length) {
                        // let fileNameRemastered = fileNameExtract(masteredList[fileIndex]?.fileNameMastering);
                        // .wav 없는 경우 붙여주기. video인 경우 .mp4 붙여주기
                        // if (masteredList[fileIndex]?.wavType === 'video') {
                        //     if (fileNameRemastered.indexOf('.') === -1) {
                        //         fileNameRemastered = fileNameRemastered + '.mp4';
                        //     }
                        // } else {
                        //     if (fileNameRemastered.indexOf('.wav') === -1) {
                        //         fileNameRemastered = fileNameRemastered + '.wav';
                        //     }
                        // }
                        let fileNameRemastered = masteredList[fileIndex]?.fileName;
                        var formdata = new FormData();
                        const myHeaders = new Headers();
                        myHeaders.append('Authorization', `Bearer ${tokenData}`);

                        formdata.append('user_id', userId);
                        formdata.append('work_file', `http://10.0.10.59:5002/static/upload/${userId}/` + fileNameRemastered);
                        // formdata.append('work_file', `http://10.0.10.59:5002/static/upload/${userId}/` + fileNameRemastered);
                        if (toggle) {
                            formdata.append('is_HQ', '1');
                        } else {
                            formdata.append('is_HQ', '0');
                        }

                        // music이 아닌경우 mastering_type을 받지 않음
                        if (masteredList[fileIndex]?.wavType === 'music') {
                            formdata.append('mastering_type', type);
                            // lufs_step: 3 // Gain
                            formdata.append('lufs_step', sliderGainMusic);
                        } else {
                            formdata.append('mastering_type', 'default');
                            // lufs_step: 3 // Gain
                            formdata.append('lufs_step', sliderGain);
                        }

                        // req 값 추가
                        if (is_reference) {
                            let file = readyReference.target.files[0];
                            // Object.values(mainFile).map((item) => {
                            //     console.log("ref_files map")
                            //     formdata.append('ref_files', item);
                            // });
                            formdata.append('ref_files', file);

                            type = 'reference';
                        }
                        formdata.append('wav_type', masteredList[fileIndex]?.wavType);
                        // ref_files: files
                        //
                        // comp_step: 1 // Compression
                        formdata.append('comp_step', sliderComp);
                        // eq_step: 3 // EQ
                        formdata.append('eq_step', sliderEq);
                        // stereo_step: 3 // Stereo Image
                        formdata.append('stereo_step', sliderSter);
                        // (denoise_step: 3)
                        formdata.append('denoise_step', sliderDeno);

                        var requestOptions = {
                            method: 'POST',
                            body: formdata,
                            headers: myHeaders,
                            redirect: 'follow',
                        };

                        // POST AI server : remastering
                        fetch('https://warpaiaudio.com:5002/remastering2?', requestOptions)
                            .finally(() => {
                                // 2023.08.17 HYUNHA : log 저장 로직 추가
                                var object = {};
                                requestOptions.body.forEach(function (value, key) {
                                    object[key] = value;
                                });
                                var now = new Date();
                                var newOBJ = {
                                    time: now,
                                    data: object,
                                };
                                var json = JSON.stringify(newOBJ);
                                myHeaders.append('Content-Type', 'application/json');
                                const requestOPT = {
                                    method: 'POST',
                                    body: JSON.stringify({ msg: json }),
                                    headers: myHeaders,
                                    redirect: 'follow',
                                    timeout: 10 * 60 * 1000,
                                };
                                fetch('https://warpmastering.com:8091/api/mastering/log', requestOPT).then((response) => response.json());
                            })
                            .then((response) => response.text())
                            .then((result) => {
                                // 2023.08.17 HYUNHA : log 저장 로직 추가
                                var now = new Date();
                                var newOBJ = {
                                    time: now,
                                    data: result,
                                };
                                var json = JSON.stringify(newOBJ);
                                const requestOPT = {
                                    method: 'POST',
                                    body: JSON.stringify({ msg: json }),
                                    headers: myHeaders,
                                    redirect: 'follow',
                                    timeout: 10 * 60 * 1000,
                                };
                                fetch('https://warpmastering.com:8091/api/mastering/log', requestOPT).then((response) => response.json());
                                // const res = JSON.parse(result); 안쓰는것같아서주석처리
                                if (toggle) {
                                    remasteringProgress(
                                        `http://10.0.10.59:5002/static/upload/${userId}/` + fileNameExtract(masteredList[fileIndex]?.fileName),
                                        type + '_HQ',
                                    );
                                } else {
                                    remasteringProgress(
                                        `http://10.0.10.59:5002/static/upload/${userId}/` + fileNameExtract(masteredList[fileIndex]?.fileName),
                                        type,
                                    );
                                }
                            })
                            .catch((error) => {
                                // 2023.08.17 HYUNHA : log 저장 로직 추가
                                console.warn('ERROR :', error);
                                var now = new Date();
                                var newOBJ = {
                                    time: now,
                                    data: error,
                                };
                                var json = JSON.stringify(newOBJ);
                                const requestOPT = {
                                    method: 'POST',
                                    body: JSON.stringify({ msg: json }),
                                    headers: myHeaders,
                                    redirect: 'follow',
                                    timeout: 10 * 60 * 1000,
                                };
                                fetch('https://warpmastering.com:8091/api/mastering/log', requestOPT).then((response) => response.json());
                                setServerErrPop(true);
                            });
                    }
                } else {
                    // console.log("DB에 중복 데이터 있는지 확인 : 중복 데이터 있음",)
                    // 중복 데이터 있는 경우
                    // 응답값 예시
                    // {
                    //     "result": 1,
                    //     "msg": "성공",
                    //     "data": {
                    //         "liId": 4236,
                    //         "liName": "샘플_2_20221206175436.wav",
                    //         "liDate": [
                    //             2022,
                    //             12,
                    //             6,
                    //             8,
                    //             55,
                    //             10
                    //         ],
                    //         "liTag": "#balanced",
                    //         "liFileOrigin": "샘플_2_20221206175436.wav",
                    //         "liType": null,
                    //         "wavType": "dialog",
                    //         "masteringType": "balanced",
                    //         "secondDuration": 30,
                    //         "lufsStep": null,
                    //         "compStep": null,
                    //         "eqStep": null,
                    //         "stereoStep": null,
                    //         "denoiseStep": null,
                    //         "isHq": null,
                    //         "isCustomized": null,
                    //         "isMasteringType": "balanced"
                    //     }
                    // }
                    const res = JSON.parse(result);
                    // console.log("DB에 있던 마스터링 데이터",res)

                    // 로그인 세션 종료되었을 경우 컨펌만 출력
                    if (res.msg === 'Access is denied' || res.msg === '접근이 거부되었습니다.') {
                        setNotUser(true);
                    } else {
                        setOptionClick(false);
                        document.body.style.overflow = 'auto';

                        // start
                        let arr = [...masteredList];
                        const dataArr = {
                            fileName: res.data.liName,
                            liId: res.data.liId,
                            masteringType: res.data.masteringType,
                            secondDuration: res.data.secondDuration,
                            status: true,
                            wavType: res.data.wavType,
                            // TODO: complete_check API 와 마찬가지로 exits API 에서도 다음과 같은 응답값 추가
                        };
                        arr[fileIndex] = dataArr;
                        // 응답값 masteredListRef.current에 저장
                        // 응답값 MasteredList에 저장
                        setMasteredList(arr);
                        // redux에 저장
                        fileActionType.masteredFile({
                            masteredFile: arr,
                        });

                        var reFileArr = [...fileArr?.typeArray];
                        reFileArr[fileIndex] = arr[fileIndex].masteringType;
                        // tag redux에 저장 (mastering type update)
                        fileActionType.upFiles({
                            typeArray: reFileArr,
                            reCheck: true,
                        });

                        // HYUNHA : 안쓰이는것같아서 주석처리함 2023.03.31
                        // setRemasteringArr(reFileArr);
                    }
                }
            })
            .catch((error) => {
                console.log('error', error);
                if (false) {
                    // TODO: 로그인 팝업 출력
                    setNotUser(true);
                } else {
                    setRemasteringSameOptionErrPop(true);
                }
                // alert('[mastering error] please refresh the page and try again');
            });
        return;
    };

    // 마스터링 다시 함
    const remasteringProgress = (remasteringFile, types) => {
        // console.log("리마스터링시작(remasteringProgress함수)", remasteringFile, types)
        const timer = setInterval(async () => {
            const ress = await getProgress();
            if (!ress?.work_files.length) {
                // console.log("리마스터링 완료1")
                // work_files가 없으면 마스터링 다된거임. 바로 여기가 실행된다.
                // *******************************
                let arrr = [...masteredList];
                // console.log(arrr)
                setOptionClick(false);
                document.body.style.overflow = 'auto';
                clearInterval(timer);

                // HYUNHA : 안쓰이는것같아서 주석처리함 2023.03.31
                // setRemasteringCheck(true);

                var fileNameSplit = arrr[fileIndex].fileName;
                // video인 경우 파일명에 기존 확장자 빼주기
                if (arrr[fileIndex].fileName.lastIndexOf('.wav') > -1) {
                    fileNameSplit = arrr[fileIndex].fileName.split('.wav')[0];
                } else if (arrr[fileIndex].fileName.lastIndexOf('.mov') > -1) {
                    fileNameSplit = arrr[fileIndex].fileName.split('.mov')[0];
                }
                // complete fetch -> 새롭게 저장된 liId 가져오기 위함
                var myHeaders = new Headers();
                myHeaders.append('Authorization', `Bearer ${tokenData}`);
                myHeaders.append('Content-Type', 'application/json');
                if (masteredList[fileIndex]?.wavType !== 'music') {
                    types = 'default';
                }
                let req_type = types + '_customizing'; // remastering 할 때는 type 값에 __customizing 이 붙음

                var arr = [];
                arr = [{ fileName: masteredList[fileIndex]?.fileName, type: req_type }];
                // let req_type = types + '_customizing' // remastering 할 때는 type 값에 __customizing 이 붙음

                // var arr = [];
                // // 확장자 붙여주기
                // if (masteredList[fileIndex]?.wavType === 'video') {
                //     if(masteredList[fileIndex]?.fileName.slice(masteredList[fileIndex]?.fileName.indexOf('.')) === '.mp4'){
                //         arr =[{ fileName: fileNameExtract(fileNameSplit) + '_' + req_type + '.mp4', type: req_type}];
                //     } else if(masteredList[fileIndex]?.fileName.slice(masteredList[fileIndex]?.fileName.indexOf('.')) === '.mov'){
                //         arr = [{ fileName: fileNameExtract(fileNameSplit) + '_' + req_type + '.mov', type: req_type}];
                //     }
                // } else {
                //     arr = [{ fileName: fileNameExtract(fileNameSplit) + '_' + req_type + '.wav', type: req_type}];
                // }
                const raw = JSON.stringify(arr);
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow',
                };

                // 리마스터링 완료 체크
                return fetch(`${ip}api/mastering/complete_check`, requestOptions)
                    .then((response) => response.text())
                    .then((result) => {
                        const res = JSON.parse(result);
                        // console.log("리마스터링 완료2",res);
                        // 기존 masteredList의 해당 파일 위치에 remastering 된 값으로 바꿔치기
                        // redux에 저장
                        arrr[fileIndex] = res?.masteringCompleteStatuses[0];
                        setMasteredList(arrr);
                        // redux에 저장 (마스터링완료됨)
                        fileActionType.masteredFile({
                            masteredFile: arrr,
                        });
                        // tag 저장
                        setNameTag(arrr[fileIndex]);

                        var reFileArr = [...fileArr?.typeArray];
                        if (arrr[fileIndex].masteringType.indexOf('_')) {
                            reFileArr[fileIndex] = arrr[fileIndex].masteringType.split('_')[0];
                        } else {
                            reFileArr[fileIndex] = arrr[fileIndex].masteringType;
                        }

                        // tag redux에 저장 (mastering type update)
                        fileActionType.upFiles({
                            typeArray: reFileArr,
                            reCheck: true,
                        });
                        setPercentData(null);
                        // HYUNHA : 안쓰이는것같아서 주석처리함 2023.03.31
                        // setRemasteringArr(reFileArr);
                        document.location.reload();
                    })
                    .catch((error) => {
                        console.warn('ERROR :', error);
                        setServerErrPop(true);
                    });
            } else {
                // console.log("리마스터링 작업중------", ress?.work_files)
                setPercentData(ress?.work_files);
            }
        }, 1000);
    };

    const getProgress = () => {
        // console.log("getProgress작동")
        const formData = new FormData();
        formData.append('user_id', userId);
        // formData.append('work_file', remasteringFile); // 기존 API에서도 불필요한 요청값이었고, 신규 API에서는 없어야 하는 요청값임

        const requestOptions = {
            method: 'POST',
            body: formData,
            redirect: 'follow',
        };

        return fetch('https://warpaiaudio.com:5002/get_progress2', requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // console.log("get_progress2 응답값",result)
                return result;
            })
            .catch((error) => {
                console.warn('ERROR :', error);
                setServerErrPop(true);
            });
    };

    const remasterAudio = (type) => {
        setOptionClick(true); // 리마스터링 진행 팝업 띄움
        document.body.style.overflow = 'hidden';
        if (getUploadFiles && getUploadFiles.length) {
            let fileNameRemastered = masteredList[fileIndex]?.fileName;
            var formdata = new FormData();
            const myHeaders = new Headers();
            myHeaders.append('Authorization', `Bearer ${tokenData}`);
            formdata.append('user_id', userId);
            formdata.append('work_file', `http://10.0.10.59:5002/static/upload/${userId}/` + fileNameRemastered);

            // // music이 아닌경우 mastering_type을 받지 않음
            // if (masteredList[fileIndex]?.wavType === 'music') {
            //     formdata.append('mastering_type', type);
            // } else {
            //     formdata.append('mastering_type', 'default');
            // }

            formdata.append('wav_type', type);
            // ref_files: files
            //
            // // lufs_step: 3 // Gain
            // formdata.append('lufs_step', sliderGain);
            // // comp_step: 1 // Compression
            // formdata.append('comp_step', sliderComp);
            // // eq_step: 3 // EQ
            // formdata.append('eq_step', sliderEq);
            // // stereo_step: 3 // Stereo Image
            // formdata.append('stereo_step', sliderSter);
            // // (denoise_step: 3)
            // formdata.append('denoise_step', sliderDeno);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                headers: myHeaders,
                redirect: 'follow',
            };

            // POST AI server : remastering
            fetch('https://warpaiaudio.com:5002/remastering2?', requestOptions)
                .finally(() => {
                    // 2023.08.17 HYUNHA : log 저장 로직 추가
                    var object = {};
                    requestOptions.body.forEach(function (value, key) {
                        object[key] = value;
                    });
                    var now = new Date();
                    var newOBJ = {
                        time: now,
                        data: object,
                    };
                    var json = JSON.stringify(newOBJ);
                    myHeaders.append('Content-Type', 'application/json');
                    const requestOPT = {
                        method: 'POST',
                        body: JSON.stringify({ msg: json }),
                        headers: myHeaders,
                        redirect: 'follow',
                        timeout: 10 * 60 * 1000,
                    };
                    fetch('https://warpmastering.com:8091/api/mastering/log', requestOPT).then((response) => response.json());
                })
                .then((response) => response.text())
                .then((result) => {
                    // 2023.08.17 HYUNHA : log 저장 로직 추가
                    var now = new Date();
                    var newOBJ = {
                        time: now,
                        data: result,
                    };
                    var json = JSON.stringify(newOBJ);
                    const requestOPT = {
                        method: 'POST',
                        body: JSON.stringify({ msg: json }),
                        headers: myHeaders,
                        redirect: 'follow',
                        timeout: 10 * 60 * 1000,
                    };
                    fetch('https://warpmastering.com:8091/api/mastering/log', requestOPT).then((response) => response.json());
                    // const res = JSON.parse(result); 안쓰는것같아주석처리
                    remasterAudioProgress(`http://10.0.10.59:5002/static/upload/${userId}/` + fileNameExtract(masteredList[fileIndex]?.fileName), type);
                })
                .catch((error) => {
                    // 2023.08.17 HYUNHA : log 저장 로직 추가
                    console.warn('ERROR :', error);
                    var now = new Date();
                    var newOBJ = {
                        time: now,
                        data: error,
                    };
                    var json = JSON.stringify(newOBJ);
                    const requestOPT = {
                        method: 'POST',
                        body: JSON.stringify({ msg: json }),
                        headers: myHeaders,
                        redirect: 'follow',
                        timeout: 10 * 60 * 1000,
                    };
                    fetch('https://warpmastering.com:8091/api/mastering/log', requestOPT).then((response) => response.json());
                    setServerErrPop(true);
                });
        }
    };

    // 마스터링 다시 함
    const remasterAudioProgress = (remasteringFile, types) => {
        // console.log('remasterAudioProgress', remasteringFile, types);
        const timer = setInterval(async () => {
            const ress = await getProgress();
            if (!ress?.work_files.length) {
                // console.log("리마스터링 완료1")
                // work_files가 없으면 마스터링 다된거임. 바로 여기가 실행된다.
                // *******************************
                let arrr = [...masteredList];
                setOptionClick(false);
                document.body.style.overflow = 'auto';
                clearInterval(timer);

                var fileNameSplit = arrr[fileIndex].fileName;
                // video인 경우 파일명에 기존 확장자 빼주기
                if (arrr[fileIndex].fileName.lastIndexOf('.wav') > -1) {
                    fileNameSplit = arrr[fileIndex].fileName.split('.wav')[0];
                }
                // complete fetch -> 새롭게 저장된 liId 가져오기 위함
                var myHeaders = new Headers();
                myHeaders.append('Authorization', `Bearer ${tokenData}`);
                myHeaders.append('Content-Type', 'application/json');
                var arr = [];
                arr = [{ fileName: masteredList[fileIndex]?.fileName, type: 'balanced' }];

                const raw = JSON.stringify(arr);
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow',
                };

                // 리마스터링 완료 체크
                return fetch(`${ip}api/mastering/complete_check`, requestOptions)
                    .then((response) => response.text())
                    .then((result) => {
                        const res = JSON.parse(result);
                        // console.log('remasterAudio', res);
                        // 기존 masteredList의 해당 파일 위치에 remastering 된 값으로 바꿔치기
                        // redux에 저장
                        arrr[fileIndex] = res?.masteringCompleteStatuses[0];
                        setMasteredList(arrr);
                        // redux에 저장 (마스터링완료됨)
                        fileActionType.masteredFile({
                            masteredFile: arrr,
                        });
                        // tag 저장
                        setNameTag(arrr[fileIndex]);

                        var reFileArr = [...fileArr?.typeArray];
                        if (arrr[fileIndex].masteringType.indexOf('_')) {
                            reFileArr[fileIndex] = arrr[fileIndex].masteringType.split('_')[0];
                        } else {
                            reFileArr[fileIndex] = arrr[fileIndex].masteringType;
                        }

                        // tag redux에 저장 (mastering type update)
                        fileActionType.upFiles({
                            typeArray: reFileArr,
                            reCheck: true,
                        });
                        setPercentData(null);
                        document.location.reload();
                    })
                    .catch((error) => {
                        console.warn('ERROR :', error);
                        setServerErrPop(true);
                    });
            } else {
                // console.log('remasterAudioProgress------', ress?.work_files);
                setPercentData(ress?.work_files);
            }
        }, 1000);
    };

    const TurnAudioMastering = (type) => {
        if (type === 'dialogue') {
            remasterAudio('music');
        } else if (type === 'music') {
            remasterAudio('dialog');
        }
    };

    // e : 추가 마스터링 진행

    return (
        <>
            {loadingPop && <DownloadPopup />}
            {OptionClick && <MasteringPopup setIsState={setOptionClick} data={percentData === null ? 0 : percentData} />}
            {notFormat && <DefaultPopup data={notFormatData} />}
            {canNotRemaster && <DefaultPopup data={canNotMasterPopupData} />}
            {canNotDown && <DefaultPopup data={canNotDownPopupData} />}
            {capacity && <DefaultPopup data={capacityData} />}
            {/* {trialClick && <DefaultPopup data={trialData} />} */}
            {serverErrPop && <DefaultPopup data={serverErr} />}
            {remasteringSameOptionErrPop && <DefaultPopup data={remasteringSameOptionErr} />}
            {notUser && <DefaultPopup data={notUserData} />}

            {/* <Background active={BgOn} /> */}
            {/*<BackgroundImg active={true} />*/}
            <BackgroundUpImg active={true} />
            <Container className="container" active={isOnClick}>
                <ToastContainer
                    style={{ width: '400px' }}
                    position="bottom-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    rtl={false}
                    pauseOnFocusLoss
                    limit={1}
                />
                <Wrap>
                    <MasteringTit>Mastering</MasteringTit>

                    {masteredList[fileIndex]?.wavType === 'music' ? (
                        <Tit className="music">
                            Music{' '}
                            <TurnButton
                                index={0}
                                onClick={() => {
                                    TurnAudioMastering('music');
                                }}
                            >
                                Not Music?
                            </TurnButton>
                        </Tit>
                    ) : masteredList[fileIndex]?.wavType === 'dialog' ? (
                        <Tit>
                            Dialogue{' '}
                            <TurnButton
                                index={1}
                                onClick={() => {
                                    TurnAudioMastering('dialogue');
                                }}
                            >
                                Not Dialogue?
                            </TurnButton>
                        </Tit>
                    ) : masteredList[fileIndex]?.wavType === 'video' ? (
                        <Tit className="video">Video</Tit>
                    ) : null}

                    {masteredList.length > 0 ? (
                        <MasteringCon className={masteredList[fileIndex]?.wavType === 'music' ? 'music' : 'video'}>
                            {masteredList[fileIndex]?.wavType === 'music' ? (
                                // 파일명, 태그 속성 값
                                <MasteringHeader>
                                    <MasteringMeta>
                                        <MasteringName>{masteredList[fileIndex]?.fileName}</MasteringName>
                                        <MasteringChipWrap>
                                            <MasteringChip>#{masteredList[fileIndex]?.isMasteringType}</MasteringChip>
                                            {masteredList[fileIndex]?.isCustomized !== null ? (
                                                <MasteringChip className="colorCustomized">#Customized</MasteringChip>
                                            ) : null}
                                            {masteredList[fileIndex]?.isHq === '1' && <MasteringChip className="colorHq">#HQ</MasteringChip>}
                                        </MasteringChipWrap>
                                    </MasteringMeta>
                                    <MasteringDownload>
                                        {musicDownData.map((item, value) => {
                                            // 다운로드 버튼
                                            let extension = 'mp3';
                                            if (item === 'wav') {
                                                extension = 'wav';
                                            }
                                            return (
                                                <MasteringPill
                                                    key={value}
                                                    onClick={() => {
                                                        if (isTrial) {
                                                            setCanNotDown(true);
                                                        } else {
                                                            if (item === 'mp3') {
                                                                // HYUNHA : 다운로드 로직 변경
                                                                // convertFileFetch();
                                                                fileDownload(masteredList[fileIndex]?.fileNameLow, 'mp3', setLoadingPop);
                                                            } else {
                                                                setLoadingPop(true);
                                                                fileDownload(masteredList[fileIndex]?.fileNameMastering, 'wav', setLoadingPop);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <DownImg src={DownImgIcon} />
                                                    {extension} Download
                                                </MasteringPill>
                                            );
                                        })}
                                    </MasteringDownload>
                                </MasteringHeader>
                            ) : masteredList[fileIndex]?.wavType === 'dialog' ? (
                                // 파일명, 태그 속성 값
                                <MasteringHeader>
                                    <MasteringMeta>
                                        <MasteringName>{masteredList[fileIndex]?.fileName}</MasteringName>
                                        <MasteringChipWrap>
                                            {masteredList[fileIndex]?.isCustomized !== null ? (
                                                <MasteringChip className="colorCustomized">#Customized</MasteringChip>
                                            ) : null}
                                        </MasteringChipWrap>
                                    </MasteringMeta>
                                    <MasteringDownload>
                                        {musicDownData.map((item, value) => {
                                            // 다운로드 버튼
                                            let extension = 'mp3';
                                            if (item === 'wav') {
                                                extension = 'wav';
                                            }
                                            return (
                                                <MasteringPill
                                                    key={value}
                                                    onClick={() => {
                                                        if (isTrial) {
                                                            setCanNotDown(true);
                                                        } else {
                                                            if (item === 'mp3') {
                                                                // HYUNHA : 다운로드 로직 변경
                                                                // convertFileFetch();
                                                                fileDownload(masteredList[fileIndex]?.fileNameLow, 'mp3', setLoadingPop);
                                                            } else {
                                                                setLoadingPop(true);
                                                                fileDownload(masteredList[fileIndex]?.fileNameMastering, 'wav', setLoadingPop);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <DownImg src={DownImgIcon} />
                                                    {extension} Download
                                                </MasteringPill>
                                            );
                                        })}
                                    </MasteringDownload>
                                </MasteringHeader>
                            ) : masteredList[fileIndex]?.wavType === 'video' ? (
                                // 파일명, 태그 속성 값
                                <MasteringHeader>
                                    <MasteringMeta>
                                        <MasteringName>{masteredList[fileIndex]?.fileName}</MasteringName>
                                        <MasteringChipWrap>
                                            {masteredList[fileIndex]?.isCustomized !== null ? (
                                                <MasteringChip className="colorCustomized">#Customized</MasteringChip>
                                            ) : null}
                                        </MasteringChipWrap>
                                    </MasteringMeta>
                                    <MasteringDownload>
                                        {videoDownData.map((item, value) => {
                                            // 다운로드 버튼
                                            return (
                                                <MasteringPill
                                                    onClick={() => {
                                                        if (isTrial) {
                                                            setCanNotDown(true);
                                                        } else {
                                                            setLoadingPop(true);
                                                            fileDownload(
                                                                masteredList[fileIndex]?.fileNameMastering,
                                                                masteredList[fileIndex]?.fileNameMastering.slice(
                                                                    masteredList[fileIndex]?.fileNameMastering.lastIndexOf('.') + 1,
                                                                ),
                                                                setLoadingPop,
                                                            );
                                                            // fileDownload(masteredList[fileIndex]?.fileName, masteredList[fileIndex]?.fileName.slice(masteredList[fileIndex]?.fileName.indexOf('.')+1), setLoadingPop);
                                                        }
                                                    }}
                                                >
                                                    <DownImg src={DownImgIcon} />
                                                    {masteredList[fileIndex]?.fileNameMastering.slice(
                                                        masteredList[fileIndex]?.fileNameMastering.lastIndexOf('.') + 1,
                                                    )}{' '}
                                                    Download
                                                </MasteringPill>
                                            );
                                        })}
                                    </MasteringDownload>
                                </MasteringHeader>
                            ) : null}

                            <SlideCon className={masteredList[fileIndex]?.wavType === 'video' ? 'videoSlider' : null}>
                                <Swiper
                                    {...swiperModules}
                                    ref={setIsSwiper}
                                    modules={[Navigation, Pagination]}
                                    spaceBetween={30}
                                    onSlideChange={(e) => {
                                        setFileIndex(e.activeIndex);
                                    }}
                                >
                                    {masteredList[fileIndex]?.wavType === 'music' || masteredList[fileIndex]?.wavType === 'dialog' ? (
                                        masteredList.length > 0 ? (
                                            masteredList.map((item, index) => {
                                                return (
                                                    <SwiperSlide key={index}>
                                                        <SlideContentWrap>
                                                            <MasteringList
                                                                bg={masteredList[fileIndex]?.wavType === 'music' ? true : false}
                                                                item={item}
                                                                fileIndex={fileIndex}
                                                                setAllPause={setAllPause}
                                                                allPause={allPause}
                                                                index={index}
                                                                startIndex={startIndex}
                                                                setStartIndex={setStartIndex}
                                                                upload={true}
                                                            />
                                                        </SlideContentWrap>
                                                    </SwiperSlide>
                                                );
                                            })
                                        ) : (
                                            <LoadingImgWrap>
                                                <LoadingImg src={loading} alt="loading"></LoadingImg>
                                            </LoadingImgWrap>
                                        )
                                    ) : (
                                        masteredList.map((item, index) => {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <SlideContentWrap>
                                                        <VideoCon
                                                            id="videoContainer"
                                                            src={audioURL + encodeURIComponent(item?.fileNameMastering)}
                                                            controls
                                                            playsinline
                                                            onClick={() => {
                                                                setVideoClick(true);
                                                            }}
                                                        ></VideoCon>
                                                    </SlideContentWrap>
                                                </SwiperSlide>
                                            );
                                        })
                                    )}
                                </Swiper>
                                <NavigationBtnWrap className={masteredList[fileIndex]?.wavType !== 'video' ? 'videoSlider' : null}>
                                    <PrevBtn ref={navigationPrevRef} className="swiper-button-prev"></PrevBtn>
                                    <PaginationBox ref={paginationRef} className="swiper-pagination"></PaginationBox>
                                    <NextBtn ref={navigationNextRef} className="swiper-button-next"></NextBtn>
                                </NavigationBtnWrap>
                            </SlideCon>

                            {/* customize 옵션 */}
                            <ConfigContainer>
                                <BtnCon>
                                    <BtnWrap>
                                        {masteredList[fileIndex]?.wavType === 'music' &&
                                            // TODO: btnListData 배열에 reference 값을 추가해도 괜찮을지 로직 파악.
                                            //  index로 버튼을 생성하고 있기에, 해당 배열에 값을 추가해야 할 것 같음.
                                            //  그러나, 예상되는 side effect) mastering type 이 reference로 갈 것 같음.
                                            //  일단 작업해 보고 오류나면 되돌리기
                                            btnListData.map((item, index) => {
                                                return (
                                                    <BtnList
                                                        key={index}
                                                        active={btnClick === index}
                                                        index={index}
                                                        onClick={() => {
                                                            if (index !== 3) {
                                                                setBtnClick(index);
                                                                setAllPause(true);
                                                            }
                                                            // balanced 이거나 reference 이면 disabled로 보이게 하기
                                                            if (index === 1 || index === 3) {
                                                                setSliderEqDisabled(true);
                                                            } else {
                                                                setSliderEqDisabled(false);
                                                            }
                                                        }}
                                                    >
                                                        {btnClick === index && index !== 3 ? (
                                                            // 선택된 버튼에 체크 아이콘 표시
                                                            <DownImg src={CheckImgIcon} />
                                                        ) : null}
                                                        {index === 3 ? (
                                                            // Reference 버튼에 폴더 아이콘 추가
                                                            <DownImg src={FolderAddIcon} />
                                                        ) : null}
                                                        {item}

                                                        {index === 3 ? (
                                                            // Reference 업로드 버튼
                                                            <ReferenceRight ref={dragRef}>
                                                                <UploadInput
                                                                    type="file"
                                                                    id="upload"
                                                                    multiple
                                                                    accept=".wav, .aiff"
                                                                    onClick={() => setIsOnClick(true)}
                                                                    onChange={(e) => {
                                                                        // setOptionClick(true);
                                                                        // reference 파일 선택 후 active 되도록 하기 위함(버튼 클릭 후, 파일 선택 안 한 경우는 활성화 안 됨)
                                                                        setBtnClick(index);
                                                                        setAllPause(true);

                                                                        // reference mastering 기능
                                                                        // uploadHandler(e);
                                                                        setReadyReference(e);

                                                                        // action_close();
                                                                        // console.log(e)
                                                                    }}
                                                                />
                                                                <UploadLabel
                                                                    htmlFor="upload"
                                                                    onClick={() => {
                                                                        setIsOnClick(true);
                                                                        // action_open();
                                                                    }}
                                                                />
                                                            </ReferenceRight>
                                                        ) : null}
                                                    </BtnList>
                                                );
                                            })}
                                    </BtnWrap>
                                    {masteredList[fileIndex]?.wavType === 'music' && (
                                        <ListCheckBox index={4}>
                                            <BarImg src={BarVerticalImg} alt="막대" /> HQ
                                            <Switch
                                                {...label}
                                                checked={toggle}
                                                onClick={checkBoxFunc}
                                                sx={{
                                                    width: 53,
                                                    height: 32,
                                                    padding: 0,
                                                    marginLeft: '8px',
                                                    '& .MuiSwitch-switchBase': {
                                                        padding: 0,
                                                        margin: '5px',
                                                        transitionDuration: '300ms',
                                                        '&.Mui-checked': {
                                                            transform: 'translateX(21px)',
                                                            color: '#fff',
                                                            '& + .MuiSwitch-track': {
                                                                background: 'linear-gradient(180deg, #0055A4 0%, #008983 100%)',
                                                                opacity: 1,
                                                                border: 0,
                                                            },
                                                            '&.Mui-disabled + .MuiSwitch-track': {
                                                                opacity: 0.5,
                                                            },
                                                        },
                                                        '&.Mui-focusVisible .MuiSwitch-thumb': {
                                                            color: '#0055A4',
                                                            border: '6px solid #fff',
                                                        },
                                                        '&.Mui-disabled .MuiSwitch-thumb': {
                                                            color: '#FFFFFF',
                                                        },
                                                        '&.Mui-disabled + .MuiSwitch-track': {
                                                            opacity: 0.7,
                                                        },
                                                    },
                                                    '& .MuiSwitch-thumb': {
                                                        boxSizing: 'border-box',
                                                        width: 22,
                                                        height: 22,
                                                    },
                                                    '& .MuiSwitch-track': {
                                                        borderRadius: 26 / 2,
                                                        backgroundColor: '#E9E9EA',
                                                        opacity: 1,
                                                    },
                                                }}
                                            />
                                        </ListCheckBox>
                                    )}
                                </BtnCon>
                                <CustomizeCon onClick={() => setCustomizeClick(!customizeClick)}>
                                    Customize <ArrowDownImg active={customizeClick} src={ArrowDownIcon} alt="아래 화살표" />
                                    <ArrowDownImgMo active={customizeClick} src={ArrowDownIconGray} alt="아래 화살표" />
                                </CustomizeCon>
                            </ConfigContainer>

                            {masteredList[fileIndex]?.wavType === 'music' ? (
                                <CustomizeBoxCon active={customizeClick}>
                                    <CustomizeBoxWrap>
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                width: {
                                                    xs: 'calc(100vw * (250 / 428))',
                                                    sm: 'calc(100vw * (250 / 428))',
                                                    md: '388px',
                                                    lg: '388px',
                                                    xl: '388px',
                                                },
                                                height: {
                                                    xs: '106px',
                                                    sm: '106px',
                                                    md: '106px',
                                                    lg: '150px',
                                                    xl: '150px',
                                                },
                                                display: 'flex',
                                            }}
                                        >
                                            <SliderTitle style={{ left: '-15px' }}>Gain</SliderTitle>
                                            <Slider
                                                value={sliderGainMusic}
                                                onChange={handleSliderGainMusic}
                                                aria-label="Gain"
                                                defaultValue={3}
                                                getAriaValueText={valuetext}
                                                // valueLabelDisplay="auto"
                                                step={1}
                                                min={1}
                                                marks={marksGain}
                                                max={4}
                                                sx={{
                                                    marginLeft: {
                                                        xs: '3px!important',
                                                        sm: '3px!important',
                                                        md: '42px!important',
                                                        lg: '42px!important',
                                                        xl: '42px!important',
                                                    },
                                                    height: '7px',
                                                    '& .MuiSlider-track': {
                                                        color: 'linear-gradient(180deg, #0055A4 0%, #008983 100%)',
                                                        background: 'linear-gradient(180deg, #0055A4 0%, #008983 100%)',
                                                    },
                                                    '& .MuiSlider-rail': {
                                                        color: '#cacaca',
                                                        background: '#cacaca',
                                                    },
                                                    '& .MuiSlider-thumb': {
                                                        background: '#ffffff',
                                                        width: '33px',
                                                        borderRadius: '40px',
                                                    },
                                                    '& .MuiSlider-thumb::before': {
                                                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                                    },
                                                    '& .MuiSlider-markLabel': {
                                                        fontFamily: 'Pretendard',
                                                        color: '#777777',
                                                        top: '-27px',
                                                        fontSize: {
                                                            xs: '8px',
                                                            sm: '8px',
                                                            md: '12px',
                                                            lg: '12px',
                                                            xl: '12px',
                                                        },
                                                        letterSpacing: {
                                                            xs: '-0.7px',
                                                            sm: '0',
                                                            md: '0',
                                                            lg: '0',
                                                            xl: '0',
                                                        },
                                                        transform: 'translateX(0%)',
                                                    },
                                                    '& .MuiSlider-mark': {
                                                        backgroundColor: '#cacaca',
                                                        height: '8px',
                                                        top: '-7px',
                                                        transform: 'translateX(0%)',
                                                    },
                                                    '& .MuiSlider-markLabelActive': {
                                                        color: '#121212',
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                width: {
                                                    xs: 'calc(100vw * (250 / 428))',
                                                    sm: 'calc(100vw * (250 / 428))',
                                                    md: '388px',
                                                    lg: '388px',
                                                    xl: '388px',
                                                },
                                                height: {
                                                    xs: '106px',
                                                    sm: '106px',
                                                    md: '106px',
                                                    lg: '150px',
                                                    xl: '150px',
                                                },
                                                display: 'flex',
                                            }}
                                        >
                                            <SliderTitle>Compression</SliderTitle>
                                            <Slider
                                                value={sliderComp}
                                                onChange={handleSliderComp}
                                                aria-label="Compression"
                                                defaultValue={1}
                                                getAriaValueText={valuetext}
                                                // valueLabelDisplay="auto"
                                                step={1}
                                                min={1}
                                                marks={marksCompression}
                                                max={5}
                                                sx={{
                                                    marginLeft: {
                                                        xs: '3px!important',
                                                        sm: '3px!important',
                                                        md: '42px!important',
                                                        lg: '42px!important',
                                                        xl: '42px!important',
                                                    },
                                                    height: '7px',
                                                    '& .MuiSlider-track': {
                                                        color: 'linear-gradient(180deg, #0055A4 0%, #008983 100%)',
                                                        background: 'linear-gradient(180deg, #0055A4 0%, #008983 100%)',
                                                    },
                                                    '& .MuiSlider-rail': {
                                                        color: '#cacaca',
                                                        background: '#cacaca',
                                                    },
                                                    '& .MuiSlider-thumb': {
                                                        background: '#ffffff',
                                                        width: '33px',
                                                        borderRadius: '40px',
                                                    },
                                                    '& .MuiSlider-thumb::before': {
                                                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                                    },
                                                    '& .MuiSlider-markLabel': {
                                                        fontFamily: 'Pretendard',
                                                        color: '#777777',
                                                        top: '-27px',
                                                        fontSize: {
                                                            xs: '8px',
                                                            sm: '8px',
                                                            md: '12px',
                                                            lg: '12px',
                                                            xl: '12px',
                                                        },
                                                        letterSpacing: {
                                                            xs: '-0.7px',
                                                            sm: '0',
                                                            md: '0',
                                                            lg: '0',
                                                            xl: '0',
                                                        },
                                                        transform: 'translateX(0%)',
                                                    },
                                                    '& .MuiSlider-mark': {
                                                        backgroundColor: '#cacaca',
                                                        height: '8px',
                                                        top: '-7px',
                                                        transform: 'translateX(0%)',
                                                    },
                                                    '& .MuiSlider-markLabelActive': {
                                                        color: '#121212',
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </CustomizeBoxWrap>
                                    <CustomizeBoxWrap>
                                        <Box
                                            onClick={notify}
                                            sx={{
                                                position: 'relative',
                                                width: {
                                                    xs: 'calc(100vw * (250 / 428))',
                                                    sm: 'calc(100vw * (250 / 428))',
                                                    md: '388px',
                                                    lg: '388px',
                                                    xl: '388px',
                                                },
                                                height: {
                                                    xs: '106px',
                                                    sm: '106px',
                                                    md: '106px',
                                                    lg: '150px',
                                                    xl: '150px',
                                                },
                                                display: 'flex',
                                            }}
                                        >
                                            <SliderTitleEQ active={sliderEqDisabled} style={{ left: '0' }}>
                                                EQ
                                            </SliderTitleEQ>
                                            <Slider
                                                value={sliderEq}
                                                onChange={handleSliderEq}
                                                aria-label="EQ"
                                                disabled={sliderEqDisabled}
                                                defaultValue={3}
                                                getAriaValueText={valuetext}
                                                // valueLabelDisplay="auto"
                                                step={1}
                                                min={1}
                                                marks={marksEq}
                                                max={5}
                                                sx={{
                                                    marginLeft: {
                                                        xs: '3px!important',
                                                        sm: '3px!important',
                                                        md: '42px!important',
                                                        lg: '42px!important',
                                                        xl: '42px!important',
                                                    },
                                                    height: '7px',
                                                    '.Mui-disabled& .MuiSlider-track': {
                                                        color: '#bdbdbd',
                                                        background: '#bdbdbd',
                                                    },
                                                    '& .MuiSlider-track': {
                                                        color: 'linear-gradient(180deg, #0055A4 0%, #008983 100%)',
                                                        background: 'linear-gradient(180deg, #0055A4 0%, #008983 100%)',
                                                    },
                                                    '& .MuiSlider-rail': {
                                                        color: '#cacaca',
                                                        background: '#cacaca',
                                                    },
                                                    '& .MuiSlider-thumb': {
                                                        background: '#ffffff',
                                                        width: '33px',
                                                        borderRadius: '40px',
                                                    },
                                                    '& .MuiSlider-thumb::before': {
                                                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                                    },
                                                    '& .MuiSlider-markLabel': {
                                                        fontFamily: 'Pretendard',
                                                        color: '#777777',
                                                        top: '-27px',
                                                        fontSize: {
                                                            xs: '8px',
                                                            sm: '8px',
                                                            md: '12px',
                                                            lg: '12px',
                                                            xl: '12px',
                                                        },
                                                        letterSpacing: {
                                                            xs: '-0.7px',
                                                            sm: '0',
                                                            md: '0',
                                                            lg: '0',
                                                            xl: '0',
                                                        },
                                                        transform: 'translateX(0%)',
                                                    },
                                                    '& .MuiSlider-mark': {
                                                        backgroundColor: '#cacaca',
                                                        height: '8px',
                                                        top: '-7px',
                                                        transform: 'translateX(0%)',
                                                    },
                                                    '& .MuiSlider-markLabelActive': {
                                                        color: '#121212',
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                width: {
                                                    xs: 'calc(100vw * (250 / 428))',
                                                    sm: 'calc(100vw * (250 / 428))',
                                                    md: '388px',
                                                    lg: '388px',
                                                    xl: '388px',
                                                },
                                                height: {
                                                    xs: '106px',
                                                    sm: '106px',
                                                    md: '106px',
                                                    lg: '150px',
                                                    xl: '150px',
                                                },
                                                display: 'flex',
                                            }}
                                        >
                                            <SliderTitle>Stereo Image</SliderTitle>
                                            <Slider
                                                value={sliderSter}
                                                onChange={handleSliderSter}
                                                aria-label="Stereo Image"
                                                defaultValue={3}
                                                getAriaValueText={valuetext}
                                                // valueLabelDisplay="auto"
                                                step={1}
                                                min={1}
                                                marks={marksStereoImage}
                                                max={5}
                                                sx={{
                                                    marginLeft: {
                                                        xs: '3px!important',
                                                        sm: '3px!important',
                                                        md: '42px!important',
                                                        lg: '42px!important',
                                                        xl: '42px!important',
                                                    },
                                                    height: '7px',
                                                    '& .MuiSlider-track': {
                                                        background: 'linear-gradient(90deg, #0055A4 0%, #008983 100%)',
                                                    },
                                                    '& .MuiSlider-rail': {
                                                        color: '#cacaca',
                                                        background: '#cacaca',
                                                    },
                                                    '& .MuiSlider-thumb': {
                                                        background: '#ffffff',
                                                        width: '33px',
                                                        borderRadius: '40px',
                                                    },
                                                    '& .MuiSlider-thumb::before': {
                                                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                                    },
                                                    '& .MuiSlider-markLabel': {
                                                        fontFamily: 'Pretendard',
                                                        color: '#777777',
                                                        top: '-27px',
                                                        fontSize: {
                                                            xs: '8px',
                                                            sm: '8px',
                                                            md: '12px',
                                                            lg: '12px',
                                                            xl: '12px',
                                                        },
                                                        letterSpacing: {
                                                            xs: '-0.7px',
                                                            sm: '0',
                                                            md: '0',
                                                            lg: '0',
                                                            xl: '0',
                                                        },
                                                        transform: 'translateX(0%)',
                                                    },
                                                    '& .MuiSlider-mark': {
                                                        backgroundColor: '#cacaca',
                                                        height: '8px',
                                                        top: '-7px',
                                                        transform: 'translateX(0%)',
                                                    },
                                                    '& .MuiSlider-markLabelActive': {
                                                        color: '#121212',
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </CustomizeBoxWrap>
                                </CustomizeBoxCon>
                            ) : (
                                <CustomizeBoxNoneMusicCon active={customizeClick}>
                                    <CustomizeBoxWrap>
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                width: {
                                                    xs: 'calc(100vw * (250 / 428))',
                                                    sm: 'calc(100vw * (250 / 428))',
                                                    md: '388px',
                                                    lg: '388px',
                                                    xl: '388px',
                                                },
                                                height: {
                                                    xs: '106px',
                                                    sm: '106px',
                                                    md: '106px',
                                                    lg: '150px',
                                                    xl: '150px',
                                                },
                                                display: 'flex',
                                            }}
                                        >
                                            <SliderTitle style={{ left: '-15px' }}>Gain</SliderTitle>
                                            <Slider
                                                value={sliderGain}
                                                onChange={handleSliderGain}
                                                aria-label="Gain"
                                                defaultValue={1}
                                                getAriaValueText={valuetext}
                                                // valueLabelDisplay="auto"
                                                step={1}
                                                min={1}
                                                marks={marksGainNoneMusic}
                                                max={3}
                                                sx={{
                                                    marginLeft: {
                                                        xs: '3px!important',
                                                        sm: '3px!important',
                                                        md: '42px!important',
                                                        lg: '42px!important',
                                                        xl: '42px!important',
                                                    },
                                                    height: '7px',
                                                    '& .MuiSlider-track': {
                                                        color: 'linear-gradient(180deg, #0055A4 0%, #008983 100%)',
                                                        background: 'linear-gradient(180deg, #0055A4 0%, #008983 100%)',
                                                    },
                                                    '& .MuiSlider-rail': {
                                                        color: '#cacaca',
                                                        background: '#cacaca',
                                                    },
                                                    '& .MuiSlider-thumb': {
                                                        background: '#ffffff',
                                                        width: '33px',
                                                        borderRadius: '40px',
                                                    },
                                                    '& .MuiSlider-thumb::before': {
                                                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                                    },
                                                    '& .MuiSlider-markLabel': {
                                                        fontFamily: 'Pretendard',
                                                        color: '#777777',
                                                        top: '-27px',
                                                        fontSize: {
                                                            xs: '8px',
                                                            sm: '8px',
                                                            md: '12px',
                                                            lg: '12px',
                                                            xl: '12px',
                                                        },
                                                        letterSpacing: {
                                                            xs: '-0.7px',
                                                            sm: '0',
                                                            md: '0',
                                                            lg: '0',
                                                            xl: '0',
                                                        },
                                                        transform: 'translateX(0%)',
                                                    },
                                                    '& .MuiSlider-mark': {
                                                        backgroundColor: '#cacaca',
                                                        height: '8px',
                                                        top: '-7px',
                                                        transform: 'translateX(0%)',
                                                    },
                                                    '& .MuiSlider-markLabelActive': {
                                                        color: '#121212',
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                width: {
                                                    xs: 'calc(100vw * (250 / 428))',
                                                    sm: 'calc(100vw * (250 / 428))',
                                                    md: '388px',
                                                    lg: '388px',
                                                    xl: '388px',
                                                },
                                                height: {
                                                    xs: '106px',
                                                    sm: '106px',
                                                    md: '106px',
                                                    lg: '150px',
                                                    xl: '150px',
                                                },
                                                display: 'flex',
                                            }}
                                        >
                                            <SliderTitle>De-Noise</SliderTitle>
                                            <Slider
                                                value={sliderDeno}
                                                onChange={handleSliderDeno}
                                                aria-label="De-Noise"
                                                defaultValue={5}
                                                getAriaValueText={valuetext}
                                                // valueLabelDisplay="auto"
                                                step={1}
                                                min={1}
                                                marks={marksNoiseNoneMusic}
                                                max={5}
                                                sx={{
                                                    marginLeft: {
                                                        xs: '3px!important',
                                                        sm: '3px!important',
                                                        md: '42px!important',
                                                        lg: '42px!important',
                                                        xl: '42px!important',
                                                    },
                                                    height: '7px',
                                                    '& .MuiSlider-track': {
                                                        color: 'linear-gradient(180deg, #0055A4 0%, #008983 100%)',
                                                        background: 'linear-gradient(180deg, #0055A4 0%, #008983 100%)',
                                                    },
                                                    '& .MuiSlider-rail': {
                                                        color: '#cacaca',
                                                        background: '#cacaca',
                                                    },
                                                    '& .MuiSlider-thumb': {
                                                        background: '#ffffff',
                                                        width: '33px',
                                                        borderRadius: '40px',
                                                    },
                                                    '& .MuiSlider-thumb::before': {
                                                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                                    },
                                                    '& .MuiSlider-markLabel': {
                                                        fontFamily: 'Pretendard',
                                                        color: '#777777',
                                                        top: '-27px',
                                                        fontSize: {
                                                            xs: '8px',
                                                            sm: '8px',
                                                            md: '12px',
                                                            lg: '12px',
                                                            xl: '12px',
                                                        },
                                                        letterSpacing: {
                                                            xs: '-0.7px',
                                                            sm: '0',
                                                            md: '0',
                                                            lg: '0',
                                                            xl: '0',
                                                        },
                                                        transform: 'translateX(0%)',
                                                    },
                                                    '& .MuiSlider-mark': {
                                                        backgroundColor: '#cacaca',
                                                        height: '8px',
                                                        top: '-7px',
                                                        transform: 'translateX(0%)',
                                                    },
                                                    '& .MuiSlider-markLabelActive': {
                                                        color: '#121212',
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </CustomizeBoxWrap>
                                </CustomizeBoxNoneMusicCon>
                            )}

                            {masteredList[fileIndex]?.wavType === 'music' ? (
                                <MasteringDownloadMo>
                                    {musicDownData.map((item, value) => {
                                        let extension = 'mp3';
                                        if (item === 'wav') {
                                            extension = 'wav';
                                        }
                                        return (
                                            <MasteringPill
                                                key={value}
                                                onClick={() => {
                                                    if (item === 'mp3') {
                                                        // HYUNHA : 다운로드 로직 변경
                                                        // convertFileFetch();
                                                        fileDownload(masteredList[fileIndex]?.fileNameLow, 'mp3', setLoadingPop);
                                                    } else {
                                                        setLoadingPop(true);
                                                        fileDownload(masteredList[fileIndex]?.fileNameMastering, 'wav', setLoadingPop);
                                                    }
                                                }}
                                            >
                                                <DownImg src={DownImgIcon} />
                                                {extension} Download
                                            </MasteringPill>
                                        );
                                    })}
                                </MasteringDownloadMo>
                            ) : masteredList[fileIndex]?.wavType === 'dialog' ? null : masteredList[fileIndex]?.wavType === 'video' ? (
                                <MasteringDownloadMo>
                                    {videoDownData.map((item, value) => {
                                        return (
                                            <MasteringPill
                                                onClick={() => {
                                                    setLoadingPop(true);
                                                    setLoadingPop(true);
                                                    fileDownload(
                                                        masteredList[fileIndex]?.fileNameMastering,
                                                        masteredList[fileIndex]?.fileNameMastering.slice(
                                                            masteredList[fileIndex]?.fileNameMastering.lastIndexOf('.') + 1,
                                                        ),
                                                        setLoadingPop,
                                                    );
                                                }}
                                            >
                                                <DownImg src={DownImgIcon} />
                                                {masteredList[fileIndex]?.fileNameMastering.slice(
                                                    masteredList[fileIndex]?.fileNameMastering.lastIndexOf('.') + 1,
                                                )}{' '}
                                                Download
                                            </MasteringPill>
                                        );
                                    })}
                                </MasteringDownloadMo>
                            ) : null}

                            {/* 리마스터링 버튼 */}
                            {masteredListRef.current.length === getUploadFiles.length ? (
                                <BackCon>
                                    <BackButton
                                        onClick={() => {
                                            if (isTrial) {
                                                setCanNotRemaster(true);
                                            } else {
                                                // reference 모드는 moreMasteringFetch 함수를 사용하지 않기 때문에,
                                                // index 3이 아닌 때로 조건을 달음
                                                if (btnClick !== 3) {
                                                    if (btnClick === 0) {
                                                        moreMasteringFetch('punch');
                                                    } else if (btnClick === 1) {
                                                        moreMasteringFetch('balanced');
                                                    } else if (btnClick === 2) {
                                                        moreMasteringFetch('high');
                                                    }
                                                } else if (btnClick === 3) {
                                                    // reference 파일이 있는 경우
                                                    if (readyReference) {
                                                        // console.log(readyReference)
                                                        // setOptionClick(true);
                                                        // uploadHandler(readyReference);
                                                        // console.log("레퍼런스 기능")
                                                        moreMasteringFetch('balanced', true, readyReference);
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        Remastering
                                    </BackButton>

                                    <FileButton
                                        onClick={() => {
                                            // 기존의 restart 버튼과 동일한 동작. 마스터링하기 위한 새로운 파일 올리는 페이지로 넘어감
                                            localStorage.removeItem('completeData');
                                            localStorage.removeItem('uploadFiles');
                                            history.push(`/${country}/mastering/upload`);
                                            // tag redux에 저장 (mastering type update)
                                            fileActionType.upFiles({
                                                typeArray: null,
                                                reCheck: null,
                                            });
                                            // redux에 저장 (마스터링완료됨)
                                            fileActionType.masteredFile({
                                                masteredFile: null,
                                            });
                                        }}
                                    >
                                        <UploadImg src={UploadImgIcon} />
                                        New file
                                    </FileButton>
                                </BackCon>
                            ) : (
                                <DisabledWrap>
                                    <DisabledBox onClick={notifyBeforeReady} />
                                </DisabledWrap>
                            )}
                        </MasteringCon>
                    ) : (
                        <MasteringConLoading>
                            <TitLoading>Mastering...</TitLoading>
                            <SlideCon>
                                <Swiper {...swiperModules} ref={setIsSwiper} modules={[Navigation, Pagination]} spaceBetween={30}>
                                    <LoadingImgWrap>
                                        <LoadingImg src={loading} alt="loading"></LoadingImg>
                                    </LoadingImgWrap>
                                </Swiper>
                            </SlideCon>
                        </MasteringConLoading>
                    )}
                </Wrap>
            </Container>

            <BackgroundDownWrap>
                <BackgroundDownImg active={true} />
            </BackgroundDownWrap>
        </>
    );
}

const Container = styled.div`
    padding-top: 200px;
    //margin-top: 200px;
    position: relative;
    z-index: 1;

    @media (max-width: 1297px) {
        padding: calc(100vw * (200 / 1280)) 0;
        width: 100%;
    }

    @media (max-width: 1023px) {
        min-height: 100vh;
        padding: calc(100vw * (107 / 428)) 0 0;
    }
`;
const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    content: '';
    background-color: #000;
    opacity: 0.5;
    z-index: 999;
    ${({ active }) => (active ? 'display:block;' : 'display:none;')}
`;
// const BackgroundImg = styled.div`
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100vw;
//     height: 100vh;
//     content: '';
//     //background: url(${BgImg}) no-repeat center / contain;
//     background: url(${BgImg}) no-repeat center / cover;
//     //opacity: 0.5;
//     z-index: 0;
//     ${({ active }) => (active ? 'display:block;' : 'display:none;')}
// `;
const BackgroundUpImg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 260px;
    content: '';
    //background: url(${BgUpImg}) no-repeat center / contain;
    background: url(${BgUpImg}) no-repeat center / cover;
    //opacity: 0.5;
    z-index: 0;
    ${({ active }) => (active ? 'display:block;' : 'display:none;')}
`;
const BackgroundDownWrap = styled.div`
    height: 100%;
    position: relative;
`;
const BackgroundDownImg = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 340px;
    content: '';
    //background: url(${BgDownImg}) no-repeat center / contain;
    background: url(${BgDownImg}) no-repeat center / cover;
    //opacity: 0.5;
    z-index: 0;
    ${({ active }) => (active ? 'display:block;' : 'display:none;')}
`;

const Wrap = styled.div`
    width: 1280px;
    margin: 0 auto;

    @media (max-width: 1297px) {
        padding: 0 calc(100vw * (25 / 1280));
        width: 100%;
    }

    @media (max-width: 1023px) {
        padding: 0 calc(100vw * (25 / 428));
    }
`;

const MasteringTit = styled.p`
    font-size: 36px;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 12px;
    border-bottom: 1px solid #e0e0e0;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (40 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (25 / 428));
    }
`;

const MasteringCon = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    //align-items: center;
    align-items: start;
    //margin-bottom: 554px;
    //margin-top: 8px;
    margin-top: 24px;

    &.music {
        margin-bottom: 0px;
    }

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (554 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (349 / 428));
    }
`;
const MasteringConLoading = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 554px;
    margin-top: 32px;

    &.music {
        margin-bottom: 0px;
    }

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (554 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (349 / 428));
    }
`;

const Tit = styled.div`
    display: flex;
    align-items: center;
    font-size: 26px;
    color: #121212;
    margin-top: 56px;
    font-weight: bold;
    padding-bottom: 24px;
    border-bottom: 1px solid #e0e0e0;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (30 / 1280));
    }

    @media (max-width: 1023px) {
        margin-top: calc(100vw * (34 / 428));
        font-size: calc(100vw * (20 / 428));
    }
`;

const TitLoading = styled.div`
    font-size: 26px;
    color: #121212;
    margin-top: 56px;
    font-weight: bold;
    padding-bottom: 32px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (30 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (20 / 428));
    }
`;

const MasteringHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const MasteringMeta = styled.div`
    max-width: 939px;
    display: flex;
    @media (max-width: 1297px) {
        max-width: 650px;
    }
    @media (max-width: 1023px) {
        max-width: 87vw;
        display: block;
    }
`;

const MasteringName = styled.div`
    text-align: left;
    //padding: calc(100vw * (22 / 1280));
    font-weight: 600;
    font-size: 24px;
    color: #121212;
    font-family: Pretendard;
    margin-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media (max-width: 1023px) {
        max-width: 87vw;
        font-size: calc(100vw * (18 / 428));
        padding: calc(100vw * (10 / 428)) 0;
    }
`;

const MasteringChipWrap = styled.div`
    display: flex;
`;
const MasteringChip = styled.span`
    display: flex;
    align-items: center;
    background: #088c7f1f;
    color: #088c7f;
    text-align: center;
    padding: 6px 9px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 13px;
    font-family: Pretendard;
    margin-right: 8px;
    margin-top: 2px;
    margin-bottom: 2px;
    text-transform: capitalize;
`;
const MasteringDownload = styled.div`
    display: flex;
    @media (max-width: 1023px) {
        display: none;
    }
`;
const MasteringDownloadMo = styled.div`
    display: none;
    @media (max-width: 1023px) {
        display: block;
        width: 100%;
        margin-top: 32px;
        margin-bottom: 60px;
    }

    & span {
        width: 100%;
        display: flex;
        flex-grow: 1;
        margin-left: 0;
        margin-bottom: 8px;
        justify-content: center;
        padding: 15px 0;
    }
`;
const MasteringPill = styled.span`
    cursor: pointer;
    color: #018487;
    border: 1px solid #018487;
    text-align: center;
    padding: 8px 20px;
    border-radius: 100px;
    font-weight: 400;
    font-size: 14px;
    font-family: Pretendard;
    margin-left: 8px;
    background-color: #ffffff;
`;
const DownImg = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 6px;
`;

const ConfigContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 26px 0 46px;
    @media (max-width: 1023px) {
        display: block;
        margin: 0px 0 0px;
    }

    ${({ active }) => {
        return active ? `justify-content: center;` : `justify-content: space-between;`;
    }}
`;

const CustomizeBoxCon = styled.div`
    width: 1280px;
    padding-right: 80px;
    height: 194px;
    display: none;
    flex-direction: column;
    align-items: center;
    background: url(${CustomBgImg}) no-repeat center / contain;
    margin-bottom: 32px;

    @media (max-width: 1023px) {
        width: 100%;
        height: 466px;
        background: #f4f4f4;
        padding-left: calc(100vw * (41 / 428));
    }

    ${({ active }) => {
        return active ? `display: flex;` : `display: none;`;
    }}
`;

const CustomizeBoxNoneMusicCon = styled.div`
    width: 1280px;
    padding-right: 80px;
    height: 119px;
    display: none;
    flex-direction: column;
    align-items: center;
    background: url(${CustomNoneMusicBgImg}) no-repeat center / contain;
    margin-bottom: 32px;

    @media (max-width: 1023px) {
        width: 100%;
        height: 266px;
        background: #f4f4f4;
        padding-left: calc(100vw * (41 / 428));
    }

    ${({ active }) => {
        return active ? `display: flex;` : `display: none;`;
    }}
`;

const CustomizeBoxWrap = styled.div`
    padding-top: 55px;
    width: 980px;
    height: 80px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1023px) {
        padding-top: 85px;
        width: 100%;
        display: block;
        height: 200px;
    }
`;

const SliderTitle = styled.div`
    position: absolute;
    top: -27px;
    left: -82px;
    font-size: 18px;
    font-family: Pretendard;
    font-weight: 600;
    color: #121212;
    @media (max-width: 1023px) {
        font-size: 14px;
        top: -46px;
        left: -12px !important;
    }
`;
const SliderTitleEQ = styled.div`
    position: absolute;
    top: -27px;
    left: -82px;
    font-size: 18px;
    font-family: Pretendard;
    font-weight: 600;
    @media (max-width: 1023px) {
        font-size: 14px;
        top: -46px;
        left: -12px !important;
    }
    ${({ active }) => (active ? 'color:#777;' : 'color: #121212;')}
`;

const BtnCon = styled.div`
    font-family: Pretendard;
    height: 43px;
    text-align: center;
    align-items: center;
    position: relative;
    display: flex;
    //gap: 20px;
    //margin: 26px 0 46px;

    @media (max-width: 1297px) {
        height: calc(100vw * (45 / 1280));
        //margin: calc(100vw * (26 / 1280)) 0 calc(100vw * (46 / 1280));
        //gap: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        display: block;
        height: auto;
        //margin: calc(100vw * (41 / 428)) 0 calc(100vw * (60 / 428));
        //gap: calc(100vw * (11 / 428));
    }
`;

const BtnWrap = styled.ul`
    font-family: Pretendard;
    height: 43px;
    text-align: center;
    align-items: center;
    position: relative;
    display: flex;

    @media (max-width: 1297px) {
        height: calc(100vw * (45 / 1280));
        //margin: calc(100vw * (26 / 1280)) 0 calc(100vw * (46 / 1280));
        //gap: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        height: auto;
        //margin: calc(100vw * (41 / 428)) 0 calc(100vw * (60 / 428));
        //gap: calc(100vw * (11 / 428));
    }
`;

const CustomizeCon = styled.div`
    display: flex;
    cursor: pointer;
    font-size: 16px;
    font-family: Pretendard;
    font-weight: 500;
    text-decoration: underline;
    color: #018487;

    @media (max-width: 1023px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;
        color: #121212;
        padding: 12px 0;
        text-decoration: unset;
        font-size: calc(100vw * (13 / 428));
    }
`;
const ArrowDownImg = styled.img`
    display: block;
    width: 16px;
    height: 16px;

    @media (max-width: 1023px) {
        display: none;
    }

    ${({ active }) => {
        return active ? null : `transform: rotate(180deg);`;
    }}
`;
const ArrowDownImgMo = styled.img`
    display: none;
    @media (max-width: 1023px) {
        display: block;
        width: 20px;
        height: 20px;
        ${({ active }) => {
            return active ? null : `transform: rotate(180deg);`;
        }}
    }
`;

const BtnList = styled.li`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 16px;
    color: #121212;
    padding: 0 22px;
    //border-radius: 50px;
    cursor: pointer;
    border-left: 1px solid #8b8f96;
    border-top: 1px solid #8b8f96;
    border-bottom: 1px solid #8b8f96;
    width: auto;
    background-color: #ffffff;

    &:hover::after {
        ${({ index }) => {
            return index === 0
                ? `
            content:'';
            position: absolute;
            top: 35px;
            z-index: 10;
            width: 200px;
            height: 35px;
            background: url(${Punch}) no-repeat center / contain;
        `
                : index === 1
                ? `
            content:'';
            position: absolute;
            top: 35px;
            z-index: 10;
            width: 130px;
            height: 35px;
            background: url(${Balanced}) no-repeat center / contain;
        `
                : index === 2
                ? `
            content:'';
            position: absolute;
            top: 35px;
            z-index: 10;
            width: 200px;
            height: 35px;
            background: url(${HighBoost}) no-repeat center / contain;
        `
                : index === 3
                ? `
            content:'';
            position: absolute;
            top: 35px;
            z-index: 10;
            width: 280px;
            height: 35px;
            background: url(${Reference}) no-repeat center / contain;
        `
                : `
            content:'';
            position: absolute;
            top: 35px;
            z-index: 10;
            width: 120px;
            height: 35px;
            background: url(${Hq}) no-repeat center / contain;
        `;
        }};
    }
    ${({ active }) => {
        return active
            ? `
        background: #EBF5F5;
        color: #018487;
        `
            : `
        color: #121212;
        `;
    }}

    &:first-child {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }
    &:last-of-type {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        border-right: 1px solid #8b8f96;
    }

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        padding: 0 calc(100vw * (23 / 1280));
    }

    @media (max-width: 1023px) {
        flex-grow: 1;
        height: calc(100vw * (30 / 428));
        white-space: nowrap;
        font-size: calc(100vw * (13 / 428));
        //padding: 0 calc(100vw * (16 / 428));
    }
`;

const SlideCon = styled.div`
    //width: 901px;
    //width: 100%;
    width: 1280px;
    //min-height: 250px;
    min-height: 228px;
    //margin: 15px 44px 0;
    margin: 15px 0;
    position: relative;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;

    /* & > div.videoSlider .swiper-button-prev,
    > div.videoSlider .swiper-button-next {
        top: 43px;
        margin-bottom: 55px;
    } */

    @media (max-width: 1297px) {
        //width: calc(100vw * (701 / 1280));
        //width: calc(1280px * (701 / 1280));
        //width: calc(100vw);
        width: calc(96vw);
        min-height: calc(100vw * (250 / 1280));
        margin: calc(100vw * (44 / 1280)) 0;

        /* & > div.videoSlider .swiper-button-prev,
        > div.videoSlider .swiper-button-next {
            top: calc(100vw * (43 / 1280));
            margin-bottom: calc(100vw * (55 / 1280));
        } */
    }

    @media (max-width: 1023px) {
        width: 100%;
        margin: calc(100vw * (20 / 428)) 0;
        min-height: auto;

        /* & > div.videoSlider .swiper-button-prev,
        > div.videoSlider .swiper-button-next {
            width: 100%;
            margin-bottom: calc(100vw * (57 / 428));
        } */
    }
`;
const SlideContentWrap = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const VideoCon = styled.video`
    //width: 902px;
    width: 1280px;
    margin-bottom: 56px;
    //height: 236px;
    height: 608px;
    background-color: #000;

    @media (max-width: 1297px) {
        width: 100%;
        height: calc(100vw * (236 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
        height: calc(100vw * (116 / 428));
    }
`;

const NavigationBtnWrap = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    /* width: 1095px;
    height: 57px;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    z-index: 6;

    @media (max-width: 1297px) {
        width: calc(100vw * (1095 / 1280));
        height: calc(100vw * (57 / 1280));
    }

    @media (max-width: 1023px) {
        display: none;
    } */
`;

const PaginationBox = styled.div`
    height: 100%;
    bottom: 18px;
    display: flex;
    justify-content: center;
    gap: 8px;
    position: relative;
    align-items: end;
`;
const PrevBtn = styled.div`
    position: relative;
    //float: left;
    //top: 38%;
    //top: 99%;
    top: auto;
    //bottom: 16px;
    bottom: 2px;
    //left: 44%;
    left: -10px;
    //transform: translateY(-50%);
    //width: 57px;
    //height: 57px;
    width: 20px;
    height: 20px;
    background: url(${ArrowLeftOn}) no-repeat center / contain;
    cursor: pointer;

    &.swiper-button-disabled {
        opacity: 1;
        //background: url(${ArrowLeftOff}) no-repeat center / contain;
        background: unset;
    }

    @media (max-width: 1297px) {
        //left: calc(100vw * (-150 / 1280));
        //width: calc(100vw * (12 / 1280));
        //height: calc(100vw * (12 / 1280));
    }
    @media (max-width: 1023px) {
        display: none;
    }
`;

const NextBtn = styled(PrevBtn)`
    left: auto;
    //float: right;
    //right: -150px;
    //right: 44%;
    right: -10px;
    background: url(${ArrowRightOn}) no-repeat center / contain;

    &.swiper-button-disabled {
        //background: url(${ArrowRightOff}) no-repeat center / contain;
        background: unset;
    }
    @media (max-width: 1297px) {
        //right: calc(100vw * (-150 / 1280));
    }
`;

const DownloadCon = styled.div`
    cursor: pointer;
    width: 222px;
    padding: 13px 20px;
    border: 1px solid #dddddd;
    border-radius: 50px;
    font-size: 15px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 22px;
        width: 10px;
        height: 5px;
        background: url(${DownloadImg}) no-repeat center / contain;

        ${({ active }) => {
            return active ? `transform: translateY(-50%);` : `transform: translateY(-50%) rotate(180deg);`;
        }}
    }

    ${({ active }) => {
        return (
            active &&
            `
        border-bottom: none;
        border-radius: 23px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        `
        );
    }}

    @media(max-width: 1297px) {
        width: calc(100vw * (222 / 1280));
        padding: calc(100vw * (13 / 1280)) calc(100vw * (20 / 1280));
        font-size: calc(100vw * (15 / 1280));

        &::after {
            right: calc(100vw * (22 / 1280));
            width: calc(100vw * (10 / 1280));
            height: calc(100vw * (5 / 1280));
        }
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (222 / 428));
        padding: calc(100vw * (10 / 428)) calc(100vw * (20 / 428));
        font-size: calc(100vw * (13 / 428));

        &::after {
            right: calc(100vw * (20 / 428));
            width: calc(100vw * (10 / 428));
            height: calc(100vw * (5 / 428));
        }
    }
`;

const DownLoadText = styled.span``;

const DownloadWrap = styled.ul`
    position: absolute;
    top: 44px;
    left: -1px;
    width: 100%;
    box-sizing: content-box;
    border: 1px solid #ddd;
    border-top: none;
    border-bottom-right-radius: 23px;
    border-bottom-left-radius: 23px;
    overflow: hidden;
    display: none;

    @media (max-width: 1297px) {
        top: calc(100vw * (42 / 1280));
    }

    @media (max-width: 1023px) {
        top: calc(100vw * (33 / 428));
    }

    ${({ active }) => {
        return active ? `display: block;` : `display: none;`;
    }}
`;

const DownloadList = styled.li`
    background-color: #fff;
    padding: 13px 20px;

    &:hover {
        background-color: #fafafa;
    }

    @media (max-width: 1297px) {
        padding: calc(100vw * (13 / 1280)) calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (10 / 428)) calc(100vw * (20 / 428));
    }
`;

const DownloadLink = styled.button`
    font-family: 'Syncopate', 'Pretendard';
`;

const ReferenceRight = styled.div`
    position: absolute;

    @media (max-width: 1023px) {
        //width: calc(100vw * (57 / 428));
        width: 0;
        height: calc(100vw * (57 / 428));
        //margin-left: calc(100vw * (38 / 428));
        margin-left: 0;
    }
`;

const UploadInput = styled.input`
    //width: 57px;
    width: 0px;
    //height: 57px;
    height: 0px;
    opacity: 0;

    @media (max-width: 1297px) {
        //width: calc(100vw * (57 / 1280));
        //height: calc(100vw * (57 / 1280));
        width: 0px;
        height: 0px;
    }

    @media (max-width: 1023px) {
        //width: calc(100vw * (57 / 428));
        //height: calc(100vw * (57 / 428));
        width: 0px;
        height: 0px;
    }
`;

const UploadLabel = styled.label`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    //transform: translate(-84%, -50%);
    //width: 57px;
    //height: 57px;
    //width: 141px;
    //height: 43px;
    width: 141px;
    height: 43px;
    cursor: pointer;

    @media (max-width: 1297px) {
        width: calc(141px * (1070 / 1280));
        //width: calc( 141px * (57 / 1280));
        height: calc(43px);
    }

    @media (max-width: 1023px) {
        width: calc(141px * (400 / 428));
        //width: calc( 141px * (57 / 428));
        height: calc(43px);
    }
`;

const UploadBtnImg = styled.img``;

const UploadingCon = styled.div`
    position: fixed;
    visibility: visible;
    z-index: 2;
    right: calc(100vw * (404 / 1920));
    bottom: 0;
    width: 499px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #f5f5f5;
    background-color: #fff;

    &.hide {
        visibility: hidden;
    }

    @media (max-width: 1297px) {
        right: calc(100vw * (81 / 1280));
        width: calc(100vw * (499 / 1280));
    }

    @media (max-width: 1023px) {
        right: calc(100vw * (25 / 428));
        width: calc(100% - calc(100vw * (50 / 428)));
    }
`;

const UploadingTitCon = styled.div`
    font-size: 15px;
    padding: 17px 42px 17px 20px;
    font-weight: bold;
    color: #005aa2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        padding: calc(100vw * (17 / 1280)) calc(100vw * (42 / 1280)) calc(100vw * (17 / 1280)) calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
        padding: calc(100vw * (17 / 428)) calc(100vw * (20 / 428));
    }
`;

const UploadingTit = styled.p`
    text-transform: uppercase;
`;

const UploadingNum = styled.span`
    margin-left: 8px;

    @media (max-width: 1297px) {
        margin-left: calc(100vw * (8 / 1280));
    }

    @media (max-width: 1023px) {
        margin-left: calc(100vw * (10 / 428));
    }
`;

const UploadingImg = styled.img`
    width: 24px;
    height: 24px;

    ${({ active }) => {
        return active ? `transform: rotate(180deg);` : `transform: rotate(0deg);`;
    }}

    @media(max-width: 1297px) {
        width: calc(100vw * (24 / 1280));
        height: calc(100vw * (24 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (24 / 428));
        height: calc(100vw * (24 / 428));
    }
`;

const UploadingListCon = styled.div`
    padding: 0 20px 22px;

    @media (max-width: 1297px) {
        padding: 0 calc(100vw * (20 / 1280)) calc(100vw * (22 / 1280));
    }

    @media (max-width: 1023px) {
        padding: 0 calc(100vw * (20 / 428)) calc(100vw * (19 / 428));
    }

    ${({ active }) => {
        return active ? `display: block;` : `display: none;`;
    }}
`;

const UploadingListWrap = styled.ul`
    max-height: 310px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        border-radius: 10px;
    }

    @media (max-width: 1297px) {
        max-height: calc(100vw * (310 / 1280));

        &::-webkit-scrollbar {
            width: calc(100vw * (5 / 1280));
        }
    }

    @media (max-width: 1023px) {
        max-height: calc(100vw * (310 / 428));

        &::-webkit-scrollbar {
            width: calc(100vw * (5 / 428));
        }
    }
`;

const UpladingList = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    &:not(:last-child) {
        margin-bottom: 38px;
    }

    @media (max-width: 1297px) {
        padding-right: calc(100vw * (20 / 1280));

        &:not(:last-child) {
            margin-bottom: calc(100vw * (38 / 1280));
        }
    }

    @media (max-width: 1023px) {
        padding-right: calc(100vw * (17 / 428));

        &:not(:last-child) {
            margin-bottom: calc(100vw * (38 / 428));
        }
    }
`;

const ListName = styled.p`
    font-size: 14px;
    text-transform: uppercase;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (14 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
    }
`;

const ListStateCon = styled.div`
    display: flex;
    align-items: center;
`;

const ListLoading = styled.p`
    font-family: 'Pretendard';
    font-size: 11px;
    color: #666666;
    margin-right: 27px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (11 / 1280));
        margin-right: calc(100vw * (27 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (11 / 428));
        margin-right: calc(100vw * (29 / 428));
    }
`;

const ListPer = styled.p`
    font-size: 13px;
    font-weight: bold;
    color: #25599d;
    margin-right: 18px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (13 / 1280));
        margin-right: calc(100vw * (18 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
        margin-right: calc(100vw * (18 / 428));
    }
`;

const TrashBtn = styled.button`
    width: 18px;
    height: 20px;

    @media (max-width: 1297px) {
        width: calc(100vw * (18 / 1280));
        height: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (18 / 428));
        height: calc(100vw * (20 / 428));
    }
`;

const ListTrash = styled.img``;

const LoadingImg = styled.img`
    width: calc(100vw * (70 / 1280));
    margin: auto;
    display: flex;
    align-items: center;

    @media (max-width: 1297px) {
        width: calc(100vw * (70 / 1280));
        margin: auto;
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (115 / 428));
        margin: auto;
    }
`;
const LoadingImgWrap = styled.div`
    margin: 20px 0;
    @media (max-width: 1297px) {
        margin: calc(100vw * (20 / 428)) 0;
    }

    @media (max-width: 1023px) {
        margin: calc(100vw * (20 / 428)) 0;
    }
`;

const BackCon = styled.div`
    //margin-top: 140px;
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
    //margin-top: 64px;
    margin-bottom: 179px;
    @media (max-width: 1023px) {
        display: block;
    }
`;
const BackButton = styled.button`
    font-family: 'Pretendard';
    cursor: pointer;
    //width: 222px;
    width: 414px;
    padding: 13px 20px;
    border: 1px solid #dddddd;
    border-radius: 50px;
    font-size: 16px;
    position: relative;
    color: #fff;
    background: linear-gradient(180deg, #0055a4 0%, #008983 100%);
    font-weight: 500;

    &:hover {
        background: linear-gradient(180deg, #014682 0%, #016d6a 100%);
    }

    @media (max-width: 1297px) {
        width: calc(100vw * (222 / 1280));
        padding: calc(100vw * (13 / 1280)) calc(100vw * (20 / 1280));
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        //width: calc(100vw * (222 / 428));
        width: 100%;
        padding: 16px 0;
        font-size: 17px;
    }
`;
const TurnButton = styled.button`
    font-family: 'Pretendard';
    cursor: pointer;
    padding: 10px 16px;
    border: 1px solid #dddddd;
    border-radius: 50px;
    font-size: 16px;
    position: relative;
    color: #fff;
    background: linear-gradient(180deg, #0055a4 0%, #008983 100%);
    font-weight: 500;
    margin-left: 15px;
    /* &:hover {
        background: linear-gradient(180deg, #014682 0%, #016d6a 100%);
    } */

    &:hover::after {
        ${({ index }) => {
            return index === 0
                ? `
                content:'';
                position: absolute;
                top: 40px;
                left: 2px;
                z-index: 10;
                width: 200px;
                height: 35px;
                background: url(${Dialogue}) no-repeat center / contain;
            `
                : index === 1
                ? `
                content:'';
                position: absolute;
                top: 40px;
                left: 2px;
                z-index: 10;
                width: 130px;
                height: 35px;
                background: url(${Music}) no-repeat center / contain;
            `
                : `
                display: none;
            `;
        }};
    }
    @media (max-width: 1297px) {
        padding: calc(100vw * (10 / 1280)) calc(100vw * (20 / 1280));
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (10 / 1280)) calc(100vw * (20 / 1280));
        font-size: calc(100vw * (20 / 1280));
    }
`;
const FileButton = styled.button`
    right: 0;
    position: absolute;
    height: auto;
    color: #018487;
    border: 1px solid #018487;
    text-align: center;
    padding: 8px 20px;
    border-radius: 100px;
    font-weight: 400;
    font-size: 14px;
    font-family: Pretendard;
    margin-left: 8px;

    @media (max-width: 1023px) {
        position: relative;
        border: 0;
        width: 100%;
        margin-left: 0;
        margin-top: 32px;
        //padding: calc(100vw * (10 / 428)) calc(100vw * (20 / 428));
        //font-size: calc(100vw * (13 / 428));
    }
`;
const UploadImg = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 4px;
`;

const DisabledWrap = styled.div`
    position: relative;
    width: 1280px;
    height: 300px;
    padding-bottom: 300px;
`;
const DisabledBox = styled.div`
    position: absolute;
    top: -130px;
    background: rgba(255, 255, 255, 0.5);
    width: 1280px;
    height: 150px;
`;

const ListCheckBox = styled.div`
    align-self: center;
    position: absolute;
    display: flex;
    align-items: center;
    //right: -92px;
    right: -132px;
    font-size: 16px;
    font-weight: 500;
    font-family: Pretendard;
    &:hover::after {
        ${({ index }) => {
            return index === 4
                ? `
            content:'';
            position: absolute;
            top: 35px;
            z-index: 10;
            left: -32px;
            width: 200px;
            height: 35px;
            background: url(${Hq}) no-repeat center / contain;
        `
                : `
            display: none;
        `;
        }};
    }
    @media (max-width: 1023px) {
        width: 100%;
        position: relative;
        justify-content: space-between;
        font-size: calc(100vw * (13 / 428));
        //bottom: calc(100vw * (81 / 428));
        margin-top: 12px;
        right: 0px;
    }
`;

const BarImg = styled.img`
    width: 1px;
    height: 18px;
    margin-right: 24px;

    @media (max-width: 1023px) {
        display: none;
    }
`;
