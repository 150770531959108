//react
import { useEffect, useState, useRef } from 'react';
import HtmlParser from 'react-html-parser';

//css
import styled, { css } from 'styled-components';

//img
import Vector1 from '../../img/main/vector_1.svg';
import Vector2 from '../../img/main/vector_2.svg';
import Vector3 from '../../img/main/vector_3.svg';

//lang
import { useLangTrans } from '../../utils/langugeController/langController';
import { KR, EN, CN, ES } from './language/MainSection2/combLang';

export default function MainSection2() {
    const langData = useLangTrans(KR, EN, CN, ES);

    return (
        <>
            <Container>
                <Vec1 src={Vector1} alt='vector image' />
                <Vec2 src={Vector2} alt='vector image' />
                <Vec3 src={Vector3} alt='vector image' />
                <BoxWrap>
                    <TextBox><span>{HtmlParser(langData.Text01)}</span></TextBox>
                    <TextBox><span>{HtmlParser(langData.Text02)}</span></TextBox>
                    <TextBox><span>{HtmlParser(langData.Text03)}</span></TextBox>
                    <TextBox><span>{HtmlParser(langData.Text04)}</span></TextBox>
                </BoxWrap>
            </Container>
        </>
    );
}

const Container = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
`;
const BoxWrap = styled.div`
    width: 100%;
    background-color: #19A9B2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 72px;
    padding: 308px 140px;
    @media (max-width: 1279px) {
        display: grid;
        grid-template-columns: 1fr;
        padding: 236px 114px;
        gap: 40px;
    }
    @media (max-width: 767px) {
        padding: 157px 20px;
        gap: 24px;
    }

`
const TextBox = styled.div`
    font-family: 'Pretendard';
    background-color: white;
    border: 1px solid white;
    border-radius: 24px;
    display: flex;
    align-items: flex-start;
    padding: 32px 52px;
    z-index: 7;

    color: black;
    font-size: 24px;
    font-weight: 400;

    @media (max-width: 1279px) {
        padding: 24px 32px;
        width: 100%;
        font-size: 18px;
        span > b {
            display: inline-block;
            margin-top: 2px;
        }
    }

    @media (max-width: 767px) {
        border-radius: 12px;
        padding: 16px 24px;
        width: 100%;
        font-size: 16px;
    }
`
const Vec1 = styled.img`
    position: absolute;
    z-index: 2;
    bottom: -240px;
    left: -140px;
    width: 786px;
    height: 1054px;
    transform: rotate(0deg);
    @media (max-width: 1279px) {
        bottom: -130px;
        left: -108px;
        width: 600px;
        height: 710px;
    }

    @media (max-width: 767px) {
        width: 272px;
        height: 365px;
        bottom: -66px;
        left: -40px;
        transform: rotate(0deg);
    }
`;
const Vec2 = styled.img`
    position: absolute;
    z-index: 1;
    bottom: 50px;
    left: -80px;
    width: 564px;
    height: 358px;
    transform: rotate(0deg);
    @media (max-width: 1279px) {
        bottom: -30px;
        left: -110px;
        width: 500px;
        height: 358px;
    }

    @media (max-width: 767px) {
        width: 200px;
        height: 148px;
        bottom: 18px;
        left: -12px;
        transform: rotate(0deg);
    }
`;
const Vec3 = styled.img`
    position: absolute;
    z-index: 2;
    top: -220px;
    right: -320px;
    width: 1009px;
    height: 598px;
    transform: rotate(0deg);
    @media (max-width: 1279px) {
        top: -230px;
        right: -68px;
        width: 410px;
        height: 598px;
    }

    @media (max-width: 767px) {
        top: -34px;
        right: -50px;
        width: 262px;
        height: 156px;
        transform: rotate(0deg);
    }
`;