import { useEffect } from 'react';
import { Mobile, PC } from '../../media/MediaQuery';
//css
import styled from 'styled-components';

// component
import HeaderWhite from '../../component/common/HeaderWhite';
import HeaderMb from '../../component/common/HeaderMb';
import MasteringUploadBody from '../../component/mastering/MasteringUploadBody';
import Footer from '../../component/common/Footer';

export default function MasteringUploadPage() {
    return (
        <>
            <PC>
                <HeaderWhite />
            </PC>
            <Mobile>
                <HeaderMb />
            </Mobile>
            <MasteringUploadBody />
            <Footer />
        </>
    );
}
