//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import serverController from '../../server/serverController';

//css
import styled from 'styled-components';

//component
import ListContainerQnA from '../common/list/ListContainerQnA';

//img
import ListArrowImg from '../../img/icon/icon_arrow_down.svg';

export default function SupportQnA() {
    const trigger = 1;
    const [isOnClick, setIsOnClick] = useState(false);
    const [isSelect, setIsSelect] = useState(false);

    const [faq, setFaq] = useState([]);
    // const trigger = 1;

    useEffect(() => {
        serverController.connectFetchController('api/support/faq/list', 'GET', null, null, (res) => {
            if (res.result === 1) {
                setFaq(res.faq);
            } else {
            }
        });
    }, []);

    return (
        <ListWrap>
            <ListContainerQnA item={faq} />
        </ListWrap>
    );
}

const ListWrap = styled.ul``;
