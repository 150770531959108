//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

//css
import styled from 'styled-components';

//component
import BasicBtn from '../common/Btn/BasicBtn';
import DefaultPopup from '../common/popup/DefaultPopup';
import BridgePopup from '../common/popup/BridgePopup';
import Modal from '../../server/Modal';

//img
import UserImg from '../../img/icon/icon_user.svg';
import CameraImg from '../../img/icon/icon_camera.svg';
import AlertImg from '../../img/icon/icon_alert.svg';

import { imgURL } from '../../server/awsURL';

import serverController from '../../server/serverController';

export default function MyInfo() {
    const { country } = useParams();
    const tokenData = sessionStorage.getItem('TOKEN');

    // 기존 정보
    const [userInfo, setUserInfo] = useState({});
    // 유효성
    const [newCaution, setNewCaution] = useState(false);
    const [confirmCaution, setConfirmCaution] = useState(false);
    const [currentCaution, setCurrentCaution] = useState(false);

    // 변경 state
    const [editOnClick, setEditOnClick] = useState(false);

    // 탈퇴?
    const [deleteOnClick, setDeleteOnClick] = useState(false);

    // 탈퇴!
    const [deleteComOnClick, setDeleteComOnClick] = useState(false);

    // 상태관리
    const [newName, setNewName] = useState('');
    const [newPwd, setNewPwd] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');
    const [currentPwd, setCurrentPwd] = useState('');

    // 프로필 사진
    const [fileUrl, setFileUrl] = useState(null);

    const history = useHistory();

    // 로그아웃
    function deleteCookie() {
        sessionStorage.clear();
    }

    //초기 상태값 관리
    useEffect(() => {
        setNewName('');
        setCurrentPwd('');
        setNewPwd('');
        setConfirmPwd('');
        // 최초 user info 가져오기 실행
        userInfoFetch();
    }, []);

    //user info 가져오기
    const userInfoFetch = () => {
        serverController.connectFetchController(
            `api/mypage/info`,
            'POST',
            null,
            {
                'Content-Type': `application/json`,
                Authorization: `Bearer ${tokenData}`,
            },
            (res) => {
                if (res.result === 1) {
                    setUserInfo(res.user);
                    if (res.user?.userProfile !== null) {
                        if (res.user?.userProfile.indexOf('googleusercontent') > 0) {
                            // HYUNHA: 구글 URL인 경우, s3 URL 빼주기
                            setFileUrl(res.user.userProfile);
                        } else {
                            setFileUrl(imgURL + res.user.userProfile);
                        }
                    }
                }
            },
        );
    };

    // 팝업 데이터
    const editData = {
        isState: editOnClick,
        setIsState: setEditOnClick,
        popText: 'It has been completed.',
        btnText: 'CLOSE',
    };

    const deleteData = {
        mainState: deleteOnClick,
        setMainState: setDeleteOnClick,
        isSub: deleteComOnClick,
        setIsSub: setDeleteComOnClick,
        popText: 'Do you want to delete your account?',
        SubBtnText: `YES`,
        btnText: 'NO',
    };

    const deleteComData = {
        isState: deleteComOnClick,
        setIsState: setDeleteComOnClick,
        popText: 'Your account has been deleted.',
        btnText: 'CLOSE',
        url: `/main`,
    };

    // 회원 탈퇴 시 자동 로그아웃
    useEffect(() => {
        if (deleteComOnClick) {
            serverController.connectFetchController(
                `api/mypage/delete`,
                'POST',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                        deleteCookie('TOKEN');
                    } else {
                        alert('please try again');
                    }
                },
            );
        }
    }, [deleteComOnClick]);
    function handleClick() {
        if (tokenData) {
            deleteCookie('TOKEN');
        }
        serverController.connectFetchController(
            `api/logout`,
            'POST',
            null,
            {
                'Content-Type': `application/json`,
                Authorization: `Bearer ${tokenData}`,
            },
            (res) => {},
        );
        history.push(`/${country}/main`);
    }

    // 이름 수정
    const modifyNameFunc = () => {
        if (newName) {
            serverController.connectFetchController(
                `api/mypage/update/name?newName=${newName}`,
                'POST',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                        setEditOnClick(true);
                        Modal.modalOpen();

                        setNewName('');
                    }
                },
            );
        } else {
            alert('Insert New Name ');
        }
    };

    // 비밀번호 변경
    const updatePwdFunc = () => {
        serverController.connectFetchController(
            `api/mypage/update/pwd?oldPwd=${currentPwd}&newPwd=${newPwd}`,
            'POST',
            null,
            {
                'Content-Type': `application/json`,
                Authorization: `Bearer ${tokenData}`,
            },
            (res) => {
                if (res.result === 1) {
                    if (res.msg === '기존 비밀번호와 일치하지만 변경에는 실패') {
                        alert(res.msg);
                    } else {
                        // console.log(res);
                        setEditOnClick(true);
                        Modal.modalOpen();
                    }
                } else {
                    console.log(res);
                    alert('error');
                }
            },
        );
    };

    // 프로필 이미지 변경 패치
    const updateImgFunc = (file) => {
        if (file !== undefined && file !== null && file !== '') {
            const formData = new FormData();
            formData.append('user_img', file);
            serverController.connectFetchController(
                `api/mypage/update/img`,
                'POST',
                formData,
                {
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                    } else {
                        alert(`please try again`);
                    }
                },
            );

            // 프로필 미리보기
            const imageUrl = URL.createObjectURL(file);
            setFileUrl(imageUrl);
            file = null;
        }
    };

    // 회원 탈퇴 api
    const deleteFunc = () => {
        setDeleteOnClick(true);
        Modal.modalOpen();
    };

    // 비밀 번호 func
    const pwdController = (e, type) => {
        if (type === 'newPwd') {
            setNewPwd(e.target.value);
        } else if (type === 'confirmPwd') {
            setConfirmPwd(e.target.value);
        } else if (type === 'currentPwd') {
            setCurrentPwd(e.target.value);
        }
    };

    useEffect(() => {
        // 수정하면 경고문구 꺼주기
        if (confirmPwd.length > 0) {
            setConfirmCaution(false);
        }
        if (currentPwd.length > 0) {
            setCurrentCaution(false);
        }
        if (newPwd.length > 0) {
            setNewCaution(false);
        }
    }, [newPwd, confirmPwd, currentPwd]);

    useEffect(() => {
        if (editOnClick || deleteOnClick || deleteComOnClick) {
            userInfoFetch();
        }
    }, [editOnClick, deleteOnClick, deleteComOnClick]);

    return (
        <>
            {editOnClick && <DefaultPopup data={editData} />}
            {deleteOnClick && <BridgePopup data={deleteData} />}
            {deleteComOnClick && <DefaultPopup data={deleteComData} />}
            <InfoTop>
                <ProfileCon>
                    <ProfileImgCon>
                        <ProfileImg src={fileUrl || UserImg} alt="profile" />
                        <UpLoadInput
                            type="file"
                            id="Upload"
                            onChange={(e) => {
                                let file = e.target.files[0];
                                updateImgFunc(file);
                            }}
                        />
                        <UpLoadBtn htmlFor="Upload">
                            <UpLoadImg src={CameraImg} alt="upload" />
                        </UpLoadBtn>
                    </ProfileImgCon>
                    <UserNameCon>
                        <UserName>{userInfo?.userName}</UserName>
                        <UserEmail>{userInfo?.userEmail}</UserEmail>
                    </UserNameCon>
                </ProfileCon>
                <AccountCon>
                    <AccountWrap>
                        <LogoutList>
                            <LogoutBtn type="button" onClick={handleClick}>
                                Log out
                            </LogoutBtn>
                        </LogoutList>
                        <DeleteList>
                            <DeleteBtn
                                type="button"
                                onClick={() => {
                                    deleteFunc();
                                }}
                            >
                                Delete account
                            </DeleteBtn>
                        </DeleteList>
                    </AccountWrap>
                </AccountCon>
            </InfoTop>

            <InfoBottom>
                <EditCon>
                    <Title>Edit name</Title>
                    <EditInput
                        type="text"
                        placeholder="New Name"
                        value={newName}
                        onChange={(e) => {
                            setNewName(e.target.value);
                        }}
                    />
                    <BasicBtn
                        bg={true}
                        color={false}
                        text={'SAVE'}
                        onClick={() => {
                            modifyNameFunc();
                        }}
                    />
                </EditCon>
                <ChangeCon>
                    <Title>Change password</Title>
                    <ChangeWrap>
                        <InputWrap>
                            <InputTit>New password</InputTit>
                            <ChangeInput
                                type="Password"
                                placeholder="New password"
                                value={newPwd}
                                onChange={(e) => {
                                    pwdController(e, 'newPwd');
                                }}
                            />
                            {newCaution && (
                                <MsgText>
                                    <AlertIcon src={AlertImg} alt="alert" />
                                    <NotMatchMsg>Password does not matched.</NotMatchMsg>
                                </MsgText>
                            )}
                        </InputWrap>
                        <InputWrap>
                            <InputTit>Confirm Password</InputTit>
                            <ChangeInput
                                type="Password"
                                placeholder="Confirm Password"
                                value={confirmPwd}
                                onChange={(e) => {
                                    pwdController(e, 'confirmPwd');
                                }}
                            />
                            {confirmCaution && (
                                <MsgText>
                                    <AlertIcon src={AlertImg} alt="alert" />
                                    <NotMatchMsg>Password does not matched.</NotMatchMsg>
                                </MsgText>
                            )}
                        </InputWrap>
                    </ChangeWrap>
                    <ChangeWrap>
                        <InputWrap>
                            <InputTit>Current Password</InputTit>
                            <ChangeInput
                                type="Password"
                                placeholder="Current Password"
                                value={currentPwd}
                                onChange={(e) => {
                                    pwdController(e, 'currentPwd');
                                }}
                            />
                            {currentCaution ? (
                                <MsgText>
                                    <AlertIcon src={AlertImg} alt="alert" />
                                    <NotMatchMsg>Password does not matched.</NotMatchMsg>
                                </MsgText>
                            ) : currentCaution && newPwd.length < 8 ? (
                                <MsgText>
                                    <AlertIcon src={AlertImg} alt="alert" />
                                    <NotSatisfyMsg>The password conditions cannot be satisfied.</NotSatisfyMsg>
                                </MsgText>
                            ) : null}
                        </InputWrap>
                        <ButtonWrap>
                            <BasicBtn
                                bg={true}
                                text={'Change password'}
                                onClick={() => {
                                    if (newPwd.length === 0 || newPwd.length < 8) {
                                        setNewCaution(true);
                                    }
                                    if (confirmPwd.length === 0 || confirmPwd.length < 8) {
                                        setConfirmCaution(true);
                                    }
                                    if (currentPwd.length === 0) {
                                        setCurrentCaution(true);
                                    }
                                    if (confirmPwd !== newPwd) {
                                        setCurrentCaution(true);
                                    }
                                    if (newPwd.length > 7 && confirmPwd.length > 7 && currentPwd.length > 0 && confirmPwd === newPwd) {
                                        updatePwdFunc();
                                    }
                                }}
                            />
                        </ButtonWrap>
                    </ChangeWrap>
                </ChangeCon>
            </InfoBottom>
        </>
    );
}

const InfoTop = styled.div`
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 60px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (60 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (54 / 428));
        padding: 0 calc(100vw * (25 / 428));
    }
`;

const ProfileCon = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (12 / 1280));
    }

    @media (max-width: 1023px) {
        flex-direction: column;
        margin-bottom: calc(100vw * (31 / 428));
    }
`;

const ProfileImgCon = styled.div`
    width: 209px;
    height: 209px;
    position: relative;
    margin-right: 53px;
    overflow: hidden;

    @media (max-width: 1297px) {
        width: calc(100vw * (209 / 1280));
        height: calc(100vw * (209 / 1280));
        margin-right: calc(100vw * (53 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (127 / 428));
        height: calc(100vw * (127 / 428));
        margin-right: 0;
        margin-bottom: calc(100vw * (30 / 428));
    }
`;

const ProfileImg = styled.img``;

const UpLoadInput = styled.input`
    display: none;
`;

const UpLoadBtn = styled.label`
    position: absolute;
    bottom: -18px;
    right: -16px;
    width: 80px;
    height: 80px;
    cursor: pointer;

    @media (max-width: 1297px) {
        bottom: calc(100vw * (-18 / 1280));
        right: calc(100vw * (-18 / 1280));
        width: calc(100vw * (80 / 1280));
        height: calc(100vw * (80 / 1280));
    }

    @media (max-width: 1023px) {
        bottom: calc(100vw * (-11 / 428));
        right: calc(100vw * (-10 / 428));
        width: calc(100vw * (49 / 428));
        height: calc(100vw * (49 / 428));
    }
`;

const UpLoadImg = styled.img``;

const UserNameCon = styled.div`
    @media (max-width: 1023px) {
        text-align: center;
    }
`;

const UserName = styled.p`
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (40 / 1280));
        margin-bottom: calc(100vw * (10 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (30 / 428));
        margin-bottom: calc(100vw * (13 / 428));
    }
`;

const UserEmail = styled.p`
    font-family: 'Pretendard';
    font-size: 15px;
    font-weight: 500;
    color: #444444;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (14 / 428));
    }
`;

const AccountCon = styled.div``;

const AccountWrap = styled.ul`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        justify-content: center;
        margin-bottom: calc(100vw * (50 / 428));
    }
`;

const LogoutList = styled.li`
    margin-right: 30px;

    @media (max-width: 1297px) {
        margin-right: calc(100vw * (30 / 1280));
    }

    @media (max-width: 1023px) {
        margin-right: calc(100vw * (30 / 428));
    }
`;

const LogoutBtn = styled.button`
    color: #005aa2;
    font-family: 'Pretendard';
    font-size: 15px;
    font-weight: 500;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
    }
`;

const DeleteList = styled.li``;

const DeleteBtn = styled(LogoutBtn)`
    color: #dddddd;
`;

const InfoBottom = styled.div`
    display: flex;
    margin-bottom: 200px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (200 / 1280));
    }

    @media (max-width: 1023px) {
        flex-direction: column;
        margin-bottom: 0;
        padding: 0 calc(100vw * (25 / 428));
    }
`;

const EditCon = styled.div`
    width: 364px;
    margin-right: 161px;

    @media (max-width: 1297px) {
        width: calc(100vw * (364 / 1280));
        margin-right: calc(100vw * (100 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: calc(100vw * (50 / 428));
    }
`;

const Title = styled.h2`
    font-size: 20px;
    font-weight: bold;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (14 / 428));
    }
`;

const EditInput = styled.input`
    width: 100%;
    padding: 11px 20px;
    font-size: 15px;
    background-color: #fafafa;
    border-radius: 3px;
    margin: 41px 0 59px;

    &::placeholder {
        color: #aaaaaa;
    }

    @media (max-width: 1297px) {
        padding: calc(100vw * (11 / 1280)) calc(100vw * (20 / 1280));
        font-size: calc(100vw * (15 / 1280));
        margin: calc(100vw * (41 / 1280)) 0 calc(100vw * (59 / 1280));
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (12 / 428)) calc(100vw * (15 / 428));
        font-size: calc(100vw * (14 / 428));
        margin: calc(100vw * (20 / 428)) 0;
    }
`;

const ChangeCon = styled.div`
    flex-grow: 1;
`;

const ChangeWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 17px;

    &:last-child {
        margin-top: calc(100vw * (26 / 1280));
    }

    @media (max-width: 1297px) {
        margin-top: calc(100vw * (17 / 1280));

        &:last-child {
            margin-top: calc(100vw * (26 / 1280));
        }
    }

    @media (max-width: 1023px) {
        flex-direction: column;
        margin-top: calc(100vw * (0 / 1280));

        &:last-child {
            margin-top: calc(100vw * (10 / 428));
        }
    }
`;

const InputWrap = styled.div`
    width: 363px;
    position: relative;

    @media (max-width: 1297px) {
        width: calc(100vw * (363 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
        margin-top: calc(100vw * (30 / 428));

        &:first-child {
            margin-top: calc(100vw * (20 / 428));
        }
    }
`;

const InputTit = styled.p`
    font-size: 15px;
    font-weight: 500;
    font-family: 'Pretendard';
    color: #444444;
    margin-bottom: 10px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        margin-bottom: calc(100vw * (10 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (15 / 428));
        margin-bottom: calc(100vw * (10 / 428));
    }
`;

const ChangeInput = styled(EditInput)`
    margin: 0;

    ${({ caution }) => {
        return caution ? `border: 1px solid #FF0058;` : 'border: 1px solid transparent;';
    }}
`;

const ButtonWrap = styled(InputWrap)`
    @media (max-width: 1023px) {
        margin-top: calc(100vw * (50 / 428));
    }
`;

const MsgText = styled.div`
    position: absolute;
    left: 0;
    bottom: -18px;
    display: flex;
    align-items: center;
    font-family: 'Pretendard';
    font-size: 13px;
    color: #ff0058;

    @media (max-width: 1297px) {
        bottom: calc(100vw * (-18 / 1280));
        font-size: calc(100vw * (13 / 1280));
    }

    @media (max-width: 1023px) {
        bottom: calc(100vw * (-22 / 428));
        font-size: calc(100vw * (13 / 428));
    }
`;

const AlertIcon = styled.img`
    width: 12px;
    height: 12px;
    margin-right: 10px;

    @media (max-width: 1297px) {
        width: calc(100vw * (12 / 1280));
        height: calc(100vw * (12 / 1280));
        margin-right: calc(100vw * (10 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (12 / 428));
        height: calc(100vw * (12 / 428));
        margin-right: calc(100vw * (10 / 428));
    }
`;

const NotMatchMsg = styled.span``;

const NotSatisfyMsg = styled(NotMatchMsg)``;
