//css
import styled from 'styled-components';

// component
import loading from '../../img/mastering/loading.gif';
import UploadImg from '../../img/icon/icon_uploading.svg';

export default function MasteringPopup({ setIsState, data }) {
    // console.log(data)
    return (
        // <Container>
        //     <PopWrap>
        //         <BlueText>FILE MASTERING</BlueText>
        //         <LoadingImg src={loading} alt="loding"></LoadingImg>
        //         <BlueText>Loading …</BlueText>
        //         {data && <BlueText>{data}%</BlueText>}
        //     </PopWrap>
        // </Container>
        // HYUNHA : 2023.03.30팝업 변경
        <Container>
            {data && (
                <UploadingCon scrollOn={true} className="uploadingCon">
                    <UploadingTitCon>
                        <UploadingTit active={true}>REMASTERING</UploadingTit>
                    </UploadingTitCon>
                    <UploadingListCon active={true}>
                        <UploadingListWrap>
                            <UpladingList key={1}>
                                <ListName>{data[0][0].slice(0, 20)}...</ListName>
                                <ListStateCon>
                                    <ListLoading>remastering…</ListLoading>
                                    <ListPer>{data[0][2]}%</ListPer>
                                    {/* <TrashBtn type="button">
                                                    <ListTrash src={trashImg} alt="trash" />
                                                </TrashBtn> */}
                                </ListStateCon>
                            </UpladingList>
                        </UploadingListWrap>
                    </UploadingListCon>
                </UploadingCon>
            )}
        </Container>
    );
}

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;

const PopWrap = styled.div`
    width: 408px;
    height: 591px;
    padding: 90px 0;
    text-align: center;
    border-radius: 10px;
    background-color: #fff;

    & p:nth-child(3) {
        font-size: 16px;

        @media (max-width: 1297px) {
            font-size: calc(100vw * (16 / 1280));
        }

        @media (max-width: 1023px) {
            font-size: calc(100vw * (11 / 428));
        }
    }
    & p:nth-child(4) {
        font-size: 30px;
        margin-top: 4px;

        @media (max-width: 1297px) {
            font-size: calc(100vw * (30 / 1280));
        }

        @media (max-width: 1023px) {
            font-size: calc(100vw * (21 / 428));
        }
    }

    @media (max-width: 1297px) {
        width: calc(100vw * (408 / 1280));
        height: calc(100vw * (591 / 1280));
        padding: calc(100vw * (90 / 1280)) 0;
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (65 / 428)) 0;
        width: calc(100vw * (285 / 428));
        height: calc(100vw * (413 / 428));
    }
`;
const BlueText = styled.p`
    font-family: 'Syncopate';
    font-size: 20px;
    font-weight: bold;
    color: #005aa2;
    line-height: 1.7;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (13 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (11 / 428));
    }
`;
const LoadingImg = styled.img`
    width: 170px;
    margin: 50px 0 70px;

    @media (max-width: 1297px) {
        width: calc(100vw * (170 / 1280));
        margin: calc(100vw * (50 / 1280)) 0 calc(100vw * (70 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (115 / 428));
        margin: calc(100vw * (40 / 428)) 0 calc(100vw * (50 / 428));
    }
`;

const UploadingListCon = styled.div`
    padding: 0 20px 22px;

    ${({ active }) => {
        return active ? `display: block;` : `display: none;`;
    }}

    @media(max-width: 1297px) {
        padding: 0 calc(100vw * (20 / 1280)) calc(100vw * (22 / 1280));
    }

    @media (max-width: 1023px) {
        padding: 0 calc(100vw * (20 / 428)) calc(100vw * (19 / 428));
    }
`;

const UploadingListWrap = styled.ul`
    max-height: 310px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        border-radius: 10px;
    }

    @media (max-width: 1297px) {
        max-height: calc(100vw * (310 / 1280));

        &::-webkit-scrollbar {
            width: calc(100vw * (5 / 1280));
        }
    }

    @media (max-width: 1023px) {
        max-height: calc(100vw * (310 / 428));

        &::-webkit-scrollbar {
            width: calc(100vw * (5 / 428));
        }
    }
`;

const UpladingList = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 38px;
    }

    @media (max-width: 1297px) {
        &:not(:last-child) {
            margin-bottom: calc(100vw * (38 / 1280));
        }
    }

    @media (max-width: 1023px) {
        &:not(:last-child) {
            margin-bottom: calc(100vw * (38 / 428));
        }
    }
`;

const ListName = styled.p`
    font-size: 14px;
    text-transform: uppercase;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (14 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
    }
`;

const ListStateCon = styled.div`
    display: flex;
    align-items: center;
`;

const ListLoading = styled.p`
    font-family: 'Pretendard';
    font-size: 11px;
    color: #666666;
    margin-right: 27px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (11 / 1280));
        margin-right: calc(100vw * (27 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (11 / 428));
        margin-right: calc(100vw * (29 / 428));
    }
`;

const ListPer = styled.p`
    font-size: 13px;
    font-weight: bold;
    color: #25599d;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (13 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
    }
`;

const TrashBtn = styled.button`
    width: 18px;
    height: 20px;

    @media (max-width: 1297px) {
        width: calc(100vw * (18 / 1280));
        height: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (18 / 428));
        height: calc(100vw * (20 / 428));
    }
`;

const ListTrash = styled.img``;

const UploadingCon = styled.div`
    transition: 0.3s;
    position: fixed;
    visibility: visible;
    z-index: 999;
    //right: calc(100vw * (404 / 1920));
    right: calc(100vw * (104 / 1920));
    bottom: 0;
    width: 499px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #f5f5f5;
    background-color: #fff;

    ${({ scrollOn }) => {
        return scrollOn
            ? `opacity: 1;
        `
            : `opacity: 0;
        `;
    }}

    &.hide {
        visibility: hidden;
    }

    @media (max-width: 1297px) {
        right: calc(100vw * (81 / 1280));
        width: calc(100vw * (499 / 1280));
    }

    @media (max-width: 1023px) {
        right: calc(100vw * (25 / 428));
        width: calc(100% - calc(100vw * (50 / 428)));
    }
`;

const UploadingTitCon = styled.div`
    font-size: 15px;
    padding: 17px 20px;
    font-weight: bold;
    color: #005aa2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        padding: calc(100vw * (17 / 1280)) calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
        padding: calc(100vw * (17 / 428)) calc(100vw * (20 / 428));
    }
`;

const UploadingTit = styled.p`
    text-transform: uppercase;
`;

const UploadingNum = styled.span`
    margin-left: 8px;

    @media (max-width: 1297px) {
        margin-left: calc(100vw * (8 / 1280));
    }

    @media (max-width: 1023px) {
        margin-left: calc(100vw * (10 / 428));
    }
`;

const UploadingImg = styled.img`
    width: 24px;
    height: 24px;

    ${({ active }) => {
        return active ? `transform: rotate(180deg);` : `transform: rotate(0deg);`;
    }}

    @media(max-width: 1297px) {
        width: calc(100vw * (24 / 1280));
        height: calc(100vw * (24 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (24 / 428));
        height: calc(100vw * (24 / 428));
    }
`;
