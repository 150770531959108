import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';

const LANG_UPDATE = 'warp/language/update';

export const langUpdate = createAction(LANG_UPDATE);

const initialState = {
    lang:"",
};

export default handleActions({
    [LANG_UPDATE]: (state, action) => {
        return produce(state, draft => {
            draft.lang = action.payload ? action.payload : draft.lang;
        });
      },
}, initialState);