//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Mobile, PC } from '../../media/MediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';

import serverController from '../../server/serverController';
import { useLangAPI } from '../../utils/langugeController/langController';

//css
import 'swiper/swiper-bundle.css';
import styled from 'styled-components';

//component
import ListContainer from '../common/list/ListContainer';
import BasicBtn from '../common/Btn/BasicBtn';
import DefaultPopup from '../common/popup/DefaultPopup';

//img
import OnePlanImg from '../../img/mypage/one_plan_img.png';
import MonthPlanImg from '../../img/mypage/month_plan_img.png';
import YearPlanImg from '../../img/mypage/year_plan_img.png';

// dicount img
import DiscountImg from '../../img/mypage/discount.png';
import DiscountOnePlanKr from '../../img/mypage/one_plan_kr.png';
import DiscountMonthPlanKr from '../../img/mypage/month_plan_kr.png';
import DiscountYearPlanKr from '../../img/mypage/year_plan_kr.png';
import DiscountOnePlanEn from '../../img/mypage/one_plan_en.png';
import DiscountMonthPlanEn from '../../img/mypage/month_plan_en.png';
import DiscountYearPlanEn from '../../img/mypage/year_plan_en.png';

export default function PaymentTermsBody() {
    const termsData = useLangAPI({ path: `api/policy/plan/list` });
    const [planData, setPlanData] = useState([]);
    const { country } = useParams();
    const history = useHistory();
    const tokenData = sessionStorage.getItem('TOKEN');

    // login 유무 확인
    const [notUser, setNotUser] = useState(false);
    // 구독 여부 확인
    const [isSubscribe, setIsSubscribe] = useState(false);

    const notUserData = {
        isState: notUser,
        setIsState: setNotUser,
        popText: 'You need to log in.',
        btnText: 'LOGIN',
        url: '/login',
    };
    const subScribePopup = {
        isState: isSubscribe,
        setIsState: setIsSubscribe,
        popText: 'Please contact us if you want to change your plan.',
        btnText: 'OK',
        url: '',
    };

    const userInfoFetch = (item) => {
        // console.log(item)
        if (tokenData) {
            serverController.connectFetchController(
                `api/mypage/checkSub`,
                'POST',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    // console.log(res);
                    if (res.result === 1) {
                        // checkSub API 응답값 정보
                        // :: 0 구매불가
                        // :: 1 구매가능
                        history.push(`/${country}/payment/terms/${item.plan_id}`);
                    } else {
                        // console.log("HYUNHA: 구독여부에 따른 팝업 or Router 설정 > 구독중인경우 상품 구매 불가(1회권포함)")
                        setIsSubscribe(true);
                    }
                },
            );
        } else {
            setNotUser(true);
        }
    };

    useEffect(() => {
        serverController.connectFetchController(`api/plan/list`, 'GET', null, null, (res) => {
            // res.list 예시
            // [
            //     {
            //         "plan_id": 3,
            //         "plan_title": "월간 플랜",
            //         "plan_en_title": "MONTHLY PLAN",
            //         "plan_price": 27,
            //         "plan_unit": 2,
            //         "plan_kr": 32900
            //     },
            //     {
            //         "plan_id": 2,
            //         "plan_title": "연간 플랜",
            //         "plan_en_title": "ANNUAL PLAN",
            //         "plan_price": 100,
            //         "plan_unit": 1,
            //         "plan_kr": 119000
            //     },
            //     {
            //         "plan_id": 1,
            //         "plan_title": "1회 사용",
            //         "plan_en_title": "ONE PIECE PRICE",
            //         "plan_price": 6,
            //         "plan_unit": 0,
            //         "plan_kr": 7000
            //     }
            // ]
            setPlanData(res.list ?? []);
        });
    }, []);

    return (
        <Container>
            {notUser && <DefaultPopup data={notUserData} />}
            {isSubscribe && <DefaultPopup data={subScribePopup} />}
            <Wrap>
                <PlanCon>
                    <PlanTit>PLAN</PlanTit>
                    <PC>
                        <PlanListCon>
                            {planData.map((item, index) => {
                                const { plan_en_title, plan_title, plan_kr, plan_price } = item;
                                return (
                                    <PlanList key={index}>
                                        <Title>{plan_en_title}</Title>
                                        <ImgCon>
                                            <PlanImg src={index == 0 ? MonthPlanImg : index == 1 ? YearPlanImg : index == 2 ? OnePlanImg : null} alt="plan" />
                                        </ImgCon>
                                        {/* 할인 img */}
                                        {/* <DiscountBg src={DiscountImg} alt="discount" /> */}
                                        {/* {country === 'kr' ? (
                                            <Discount
                                                src={index == 0 ? DiscountMonthPlanKr : index == 1 ? DiscountYearPlanKr : index == 2 ? DiscountOnePlanKr : null}
                                                alt="plan"
                                            />
                                        ) : (
                                            <Discount
                                                src={index == 0 ? DiscountMonthPlanEn : index == 1 ? DiscountYearPlanEn : index == 2 ? DiscountOnePlanEn : null}
                                                alt="plan"
                                            />
                                        )} */}
                                        <Price>{country === 'kr' ? '₩' + plan_kr : '$' + plan_price}</Price>
                                        <BasicBtn
                                            bg={true}
                                            color={false}
                                            text={`Subscribe now`}
                                            onClick={() => {
                                                userInfoFetch(item);
                                            }}
                                        />
                                    </PlanList>
                                );
                            })}
                        </PlanListCon>
                    </PC>
                    <Mobile>
                        <Swiper className="paymentSliderCon" slidesPerView={1.7} spaceBetween={30} centeredSlides={true}>
                            {planData.map((item, index) => {
                                const { plan_en_title, plan_title, plan_kr, plan_price } = item;
                                return (
                                    <SwiperSlide className="paymentSlider" key={index}>
                                        <Title>{plan_en_title}</Title>
                                        {/* <Text>{item.ListText}</Text> */}
                                        <ImgCon>
                                            <PlanImg src={index == 0 ? MonthPlanImg : index == 1 ? YearPlanImg : index == 2 ? OnePlanImg : null} alt="plan" />
                                        </ImgCon>
                                        {/* 할인 img */}
                                        {/* <DiscountBg src={DiscountImg} alt="discount" /> */}
                                        {/* {country === 'kr' ? (
                                            <Discount
                                                src={index == 0 ? DiscountMonthPlanKr : index == 1 ? DiscountYearPlanKr : index == 2 ? DiscountOnePlanKr : null}
                                                alt="plan"
                                            />
                                        ) : (
                                            <Discount
                                                src={index == 0 ? DiscountMonthPlanEn : index == 1 ? DiscountYearPlanEn : index == 2 ? DiscountOnePlanEn : null}
                                                alt="plan"
                                            />
                                        )} */}
                                        <Price>{country === 'kr' ? '₩' + plan_kr : '$' + plan_price}</Price>
                                        <BasicBtn
                                            bg={true}
                                            text={`Subscribe now`}
                                            onClick={() => {
                                                userInfoFetch(item);
                                            }}
                                        />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </Mobile>
                </PlanCon>

                <TermsCon>
                    <TermsTit>Plan Terms and conditions</TermsTit>
                    <ListWrap>
                        <ListContainer item={termsData} />
                    </ListWrap>
                </TermsCon>
            </Wrap>
        </Container>
    );
}

const Container = styled.div`
    padding: 200px 0;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 73%;
        background-color: #fafafa;
        z-index: -1;
    }

    @media (max-width: 1297px) {
        padding: calc(100vw * (200 / 1280)) 0;
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (107 / 428)) 0 calc(100vw * (100 / 428));
    }
`;

const Wrap = styled.div`
    width: 1280px;
    margin: 0 auto;

    @media (max-width: 1297px) {
        width: 100%;
        padding: 0 calc(100vw * (25 / 1280));
    }

    @media (max-width: 1023px) {
        padding: 0;
    }
`;

const PlanCon = styled.div`
    margin-bottom: 239px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (239 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (150 / 428));
    }
`;

const PlanTit = styled.p`
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 173px;
    text-transform: uppercase;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (40 / 1280));
        margin-bottom: calc(100vw * (173 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (25 / 428));
        margin-bottom: calc(100vw * (70 / 428));
        padding: 0 calc(100vw * (25 / 428));
    }
`;

const PlanListCon = styled.ul`
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 1000px;
    margin: 0 auto;

    @media (max-width: 1297px) {
        width: calc(100vw * (1000 / 1280));
    }
`;

const PlanList = styled.li`
    width: 363px;
    height: 554px;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    padding: 37px 44px;
    transform: scale(0.85);
    text-align: center;

    position: relative;

    &:nth-of-type(2) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        // z-index: 1;
    }

    @media (max-width: 1297px) {
        width: calc(100vw * (363 / 1280));
        height: calc(100vw * (554 / 1280));
        padding: calc(100vw * (37 / 1280)) calc(100vw * (44 / 1280));
    }
`;

const Title = styled.p`
    font-size: 20px;
    font-weight: bold;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
    }
`;

const Text = styled.p`
    margin: 6px 0 21px;
    font-size: 15px;
    color: #444444;

    @media (max-width: 1297px) {
        margin: calc(100vw * (6 / 1280)) 0 calc(100vw * (21 / 1280));
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        margin: calc(100vw * (5 / 428)) 0 calc(100vw * (21 / 428));
        font-size: calc(100vw * (13 / 428));
    }
`;

const ImgCon = styled.div`
    width: 249px;
    height: 249px;
    margin: 0 auto;

    @media (max-width: 1297px) {
        width: calc(100vw * (249 / 1280));
        height: calc(100vw * (249 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (160 / 428));
        height: calc(100vw * (160 / 428));
    }
`;

const PlanImg = styled.img``;

// 할인
const DiscountBg = styled.img`
    position: absolute;
    width: 100px;
    top: -35px;
    left: -30px;
    z-index: 9;

    @media (max-width: 1023px) {
        width: calc(100vw * (70 / 428));
        top: -5px;
        left: -30px;
    }
`;
// const Discount = styled.span`
//     @media (max-width: 1023px) {
//         width: calc(100vw * (100 / 428));
//     }
// `;
// 할인
// const Price = styled.p`
//     font-size: 45px;
//     font-weight: bold;
//     color: #aa0000;
//     margin: 0px 0 57px;

//     @media (max-width: 1297px) {
//         font-size: calc(100vw * (45 / 1280));
//         margin: 0ㄴ 0 calc(100vw * (57 / 1280));
//     }

//     @media (max-width: 1023px) {
//         font-size: calc(100vw * (16 / 428));
//         margin: 0 0 calc(100vw * (14 / 428));
//     }
// `;
const Price = styled.p`
    font-size: 45px;
    font-weight: bold;
    color: #005aa2;
    margin: 28px 0 57px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (45 / 1280));
        margin: calc(100vw * (28 / 1280)) 0 calc(100vw * (57 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (16 / 428));
        margin: calc(100vw * (27 / 428)) 0 calc(100vw * (14 / 428));
    }
`;

const TermsCon = styled.div``;

const TermsTit = styled(PlanTit)`
    margin-bottom: 70px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (70 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (60 / 428));
        line-height: calc(100vw * (24 / 428));
        font-size: calc(100vw * (20 / 428));
    }
`;

const ListWrap = styled.ul`
    @media (max-width: 1023px) {
        padding: 0 calc(100vw * (25 / 428));
    }
`;
