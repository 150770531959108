//react
import { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

//css
import styled from 'styled-components';

// component
import SnsContainer from './SnsContainer';
import BasicBtn from '../common/Btn/BasicBtn';

//img
import SignupBg from '../../img/login/signup_bg.png';
import AlertImg from '../../img/icon/icon_alert.svg';

import serverController from '../../server/serverController';
import { ip } from '../../server/serverController';

export default function SignupBody() {
    // section
    const [name, setName] = useState(''); // 이름
    const [email, setEmail] = useState(''); // 이메일
    const [code, setCode] = useState(''); // 코드
    const [codeNum, setCodeNum] = useState(null); // api에서 전달받은 코드
    const [password, setPassword] = useState(''); // 비밀번호
    const [codeArea, setCodeArea] = useState(false); // codeArea
    const [correct, setCorrect] = useState(false); // 코드 일치 여부

    // focus
    const [isOnFocusN, setIsOnFocusN] = useState(false);
    const [isOnFocusE, setIsOnFocusE] = useState(false);
    const [isOnFocusC, setIsOnFocusC] = useState(false);
    const [isOnFocusP, setIsOnFocusP] = useState(false);

    // //caution
    const [cautionN, setCautionN] = useState(false);
    const [cautionE, setCautionE] = useState(false);
    const [cautionC, setCautionC] = useState(false);
    const [cautionP, setCautionP] = useState(false);

    // timer
    const [timer, setTimer] = useState(false);
    const [minutes, setMinutes] = useState(5);
    const [seconds, setSeconds] = useState(0);

    const [nan, setNan] = useState(false);

    //링크 이동
    const history = useHistory();
    const { country } = useParams();

    function handleClick() {
        if (name.length === 0 || name.length <= 2) {
            setCautionN(true);
            return;
        }
        if (email.length === 0 || email.length <= 2) {
            setCautionE(true);
            return;
        }
        if (password.length === 0 || password.length <= 2) {
            setCautionP(true);
            return;
        }
        if (code.length === 0 || code !== codeNum) {
            setCautionC(true);
            return;
        }
        if (!codeArea) {
            return alert('Please check your email. (Click Certificed Button)');
        }
        if (
            // 유효성 검사 체크
            cautionN === false &&
            cautionE === false &&
            cautionC === false &&
            cautionP === false
        ) {
            // 수정중
            const rawData = JSON.stringify({
                userName: name,
                userPwd: password,
                userEmail: email,
            });

            var myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: rawData,
                redirect: 'follow',
            };

            fetch(`${ip}api/signup`, requestOptions)
                .then((response) => {
                    if (response.headers.get('Authorization')) {
                        const authToken = response.headers.get('Authorization').split(' ')[1];
                        if (authToken) {
                            sessionStorage.setItem('TOKEN', authToken);
                        }
                    }
                    return response.json();
                })
                .then((result) => {
                    if (result.result === 1) {
                        history.push(`/${country}/signup/complete`);
                    } else if (result.result === 0) {
                        alert(result.msg);
                    }
                })
                .catch((error) => console.log('error', error));
        }
    }
    // 입력시 false로 돌려주기
    useEffect(() => {
        if (name.length > 0) {
            setCautionN(false);
        }
        if (email.length > 0) {
            setCautionE(false);
        }
        if (password.length > 0 && password.length > 7) {
            setCautionP(false);
        }
    }, [name, email, password]);

    //certificate
    const certifiedHandle = () => {
        const rawData = JSON.stringify({
            address: email,
        });
        serverController.connectFetchController(`api/signup/certificate`, 'POST', rawData, null, (res) => {
            if (res?.result === 0 && res?.msg === 'You are already signed up.') {
                alert('This is a duplicate email.');
                setCautionE(true);
                return;
            }
            if (res?.result === 1) {
                setCodeNum(res.data);
                setCautionE(false);
                setCodeArea(true);
                setTimer(true);
                setMinutes(5);
                setSeconds(0);
                return;
            }
        });
    };

    // 코드 일치 여부 확인
    const codeHandle = () => {
        if (codeNum == code && code.length !== 0) {
            setCautionC(false);
            setCorrect(true);
            setTimer(false);
            setMinutes(0);
            setSeconds(0);
        } else {
            setCautionC(true);
            setCorrect(false);
        }
    };

    // timer
    useEffect(() => {
        if (minutes === undefined || minutes === NaN) {
            setMinutes('00');
        }
        if (seconds === undefined || seconds === NaN) {
            setSeconds('00');
        }
        if (seconds == '0' && minutes == '0' && nan === false) {
            // 첫번째 00:00은 예외처리
            setNan(true);
        }
        if (seconds == '0' && minutes == '0' && nan === true) {
            // 두번째 00:00은 막기
            alert('please try it again');
            setCodeArea(false);
            setEmail('');
            setCode('');
            setNan(false);
        }
        if (timer) {
            const countdown = setInterval(() => {
                if (parseInt(seconds) > 0) {
                    setSeconds(parseInt(seconds) - 1);
                }
                if (parseInt(seconds) === 0) {
                    if (parseInt(minutes) === 0) {
                        clearInterval(countdown);
                    } else {
                        setMinutes(parseInt(minutes) - 1);
                        setSeconds(59);
                    }
                }
            }, 1000);
            return () => clearInterval(countdown);
        }
    }, [timer, minutes, seconds]);

    return (
        <Container>
            <Left></Left>
            <Right>
                <Title>SIGN UP</Title>
                <Form>
                    <NameWrap>
                        <NameInput
                            type="text"
                            placeholder="Name"
                            value={name}
                            caution={cautionN}
                            isOnFocus={isOnFocusN}
                            onFocus={() => setIsOnFocusN(true)}
                            onBlur={() => setIsOnFocusN(false)}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                        {cautionN ? (
                            <NameMsgText>
                                <AlertIcon src={AlertImg} alt="alert" />
                                <RequiredMsg> {name.length === 0 ? 'Name is required' : 'Name should be at least 3 character'}</RequiredMsg>
                            </NameMsgText>
                        ) : null}
                    </NameWrap>
                    <EmailWrap>
                        <EmailInput
                            type="email"
                            placeholder="Email"
                            value={email}
                            caution={cautionE}
                            isOnFocus={isOnFocusE}
                            onFocus={() => setIsOnFocusE(true)}
                            onBlur={() => setIsOnFocusE(false)}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                        <CertifiedBtn
                            onClick={() => {
                                if (email.length === 0) {
                                    setCautionE(true);
                                } else {
                                    certifiedHandle();
                                }
                            }}
                        >
                            Certified
                        </CertifiedBtn>
                        {cautionE ? (
                            <EmailMsgText>
                                <AlertIcon src={AlertImg} alt="alert" />
                                <RequiredMsg>Email is required</RequiredMsg>
                            </EmailMsgText>
                        ) : null}
                    </EmailWrap>
                    {codeArea ? (
                        <CodeWrap>
                            <CodeInput
                                type="text"
                                placeholder="Code"
                                value={code}
                                caution={cautionC}
                                isOnFocus={isOnFocusC}
                                onFocus={() => setIsOnFocusC(true)}
                                onBlur={() => setIsOnFocusC(false)}
                                onChange={(e) => {
                                    setCode(e.target.value);
                                }}
                            />
                            <Time>
                                {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                            </Time>
                            <SendBtn correct={correct} onClick={codeHandle}>
                                Verify
                            </SendBtn>
                            {cautionC ? (
                                <CodeMsgText>
                                    <AlertIcon src={AlertImg} alt="alert" />
                                    {code.length === 0 ? (
                                        <ConfirmMsg>Please, put the code and confirm</ConfirmMsg>
                                    ) : code !== codeNum ? (
                                        <NotMatchMsg>Code does not matched.</NotMatchMsg>
                                    ) : null}
                                </CodeMsgText>
                            ) : null}
                        </CodeWrap>
                    ) : null}
                    <PasswordWrap>
                        <PasswordInput
                            type="password"
                            placeholder="Password (min. 8 char)"
                            value={password}
                            caution={cautionP}
                            isOnFocus={isOnFocusP}
                            onFocus={() => setIsOnFocusP(true)}
                            onBlur={() => setIsOnFocusP(false)}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            onKeyPress={(e) => {
                                if (e.key == 'Enter') {
                                    handleClick();
                                }
                            }}
                        />
                        {cautionP ? (
                            <PasswordMsgText>
                                <AlertIcon src={AlertImg} alt="alert" />
                                {password.length === 0 ? (
                                    <RequiredMsg>Password is required</RequiredMsg>
                                ) : password.length <= 7 ? (
                                    <NotSatisfyMsg>The password conditions cannot be satisfied.</NotSatisfyMsg>
                                ) : null}
                            </PasswordMsgText>
                        ) : null}
                    </PasswordWrap>
                    <BasicBtn onClick={handleClick} color={false} bg={true} text="SIGN UP" />
                </Form>
                <SnsContainer />
            </Right>
        </Container>
    );
}

const Container = styled.div`
    padding-top: 100px;
    height: 1045px;
    display: flex;
    align-items: center;

    @media (max-width: 1297px) {
        padding-top: calc(100vw * (100 / 1280));
        height: calc(100vw * (1045 / 1280));
    }

    @media (max-width: 1023px) {
        min-height: 100vh;
        height: auto;
        justify-content: center;
        align-items: flex-start;
        padding-top: calc(100vw * (132 / 428));
        padding-bottom: calc(100vw * (182 / 428));
    }
`;

const Left = styled.div`
    width: calc(100vw * (950 / 1920));
    height: 100%;
    background: url(${SignupBg}) no-repeat center / cover;

    @media (max-width: 1023px) {
        display: none;
    }
`;

const Right = styled.div`
    padding-left: calc(100vw * (127 / 1920));
    text-align: center;

    @media (max-width: 1297px) {
        padding-left: calc(100vw * (127 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
        padding-left: calc(100vw * (25 / 428));
        padding-right: calc(100vw * (25 / 428));
    }
`;

const Title = styled.div`
    color: #005aa2;
    font-size: 60px;
    margin-bottom: 48px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (60 / 1280));
        margin-bottom: calc(100vw * (48 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (40 / 428));
        margin-bottom: calc(100vw * (56 / 428));
    }
`;

const Form = styled.div`
    width: 428px;

    @media (max-width: 1297px) {
        width: calc(100vw * (428 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
    }
`;

const NameWrap = styled.div`
    position: relative;
`;

const NameInput = styled.input`
    width: 100%;
    padding: 11px 20px;
    border-radius: 3px;
    font-size: 15px;

    &::placeholder {
        color: #aaaaaa;
    }

    ${({ isOnFocus }) => {
        return isOnFocus ? `border: 1px solid #005AA2; box-shadow: 0 0 6px 0 #bad3fb; background-color: #fff;` : `box-shadow: none; background-color: #FAFAFA;`;
    }}

    ${({ caution }) => {
        return caution ? `border: 1px solid #FF0058; margin-bottom: 30px;` : `margin-bottom: 20px;`;
    }}

    @media (max-width: 1297px) {
        padding: calc(100vw * (11 / 1280)) calc(100vw * (20 / 1280));
        font-size: calc(100vw * (15 / 1280));

        ${({ caution }) => {
            return caution ? `margin-bottom: calc(100vw * (30 / 1280));` : `margin-bottom: calc(100vw * (20 / 1280));`;
        }}
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (11 / 428)) calc(100vw * (15 / 428));
        font-size: calc(100vw * (15 / 428));

        ${({ caution }) => {
            return caution ? `margin-bottom: calc(100vw * (30 / 428));` : `margin-bottom: calc(100vw * (20 / 428));`;
        }}
    }
`;

const NameMsgText = styled.p`
    position: absolute;
    left: 0;
    bottom: 8px;
    display: flex;
    align-items: center;
    font-family: 'Pretendard';
    font-size: 13px;
    color: #ff0058;

    @media (max-width: 1297px) {
        bottom: calc(100vw * (8 / 1280));
        font-size: calc(100vw * (13 / 1280));
    }

    @media (max-width: 1023px) {
        bottom: calc(100vw * (8 / 428));
        font-size: calc(100vw * (13 / 428));
    }
`;

const AlertIcon = styled.img`
    width: 12px;
    height: 12px;
    margin-right: 10px;

    @media (max-width: 1297px) {
        width: calc(100vw * (12 / 1280));
        height: calc(100vw * (12 / 1280));
        margin-right: calc(100vw * (10 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (12 / 428));
        height: calc(100vw * (12 / 428));
        margin-right: calc(100vw * (10 / 428));
    }
`;

const RequiredMsg = styled.span``;

const EmailWrap = styled(NameWrap)`
    display: flex;
`;

const EmailInput = styled(NameInput)`
    width: calc(100% - 136px);

    @media (max-width: 1297px) {
        width: calc(100% - calc(100vw * (136 / 1280)));
    }

    @media (max-width: 1023px) {
        width: calc(100% - calc(100vw * (134 / 428)));
    }
`;

const CertifiedBtn = styled.button`
    width: 126px;
    height: 40px;
    margin-left: 10px;
    border: 1px solid #005aa2;
    border-radius: 3px;
    color: #005aa2;
    font-size: 15px;

    @media (max-width: 1297px) {
        width: calc(100vw * (126 / 1280));
        height: calc(100vw * (40 / 1280));
        margin-left: calc(100vw * (10 / 1280));
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (126 / 428));
        height: calc(100vw * (40 / 428));
        margin-left: calc(100vw * (8 / 428));
        font-size: calc(100vw * (15 / 428));
    }
`;

const EmailMsgText = styled(NameMsgText)``;

const CodeWrap = styled(EmailWrap)``;

const CodeInput = styled(EmailInput)``;

const Time = styled.span`
    font-family: 'Pretendard';
    color: #005aa2;
    font-size: 15px;
    position: absolute;
    top: 11px;
    right: 146px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        top: calc(100vw * (11 / 1280));
        right: calc(100vw * (146 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (15 / 428));
        top: calc(100vw * (11 / 428));
        right: calc(100vw * (144 / 428));
    }
`;

const SendBtn = styled(CertifiedBtn)`
    ${({ correct }) => {
        return correct ? `color: #DDDDDD; border : solid 1px #DDDDDD` : null;
    }}
`;

const CodeMsgText = styled(NameMsgText)``;

const NotMatchMsg = styled(RequiredMsg)``;

const ConfirmMsg = styled(RequiredMsg)``;

const PasswordWrap = styled(NameWrap)``;

const PasswordInput = styled(NameInput)`
    ${({ caution }) => {
        return caution && `margin-bottom: 40px;`;
    }}

    @media (max-width: 1297px) {
        ${({ caution }) => {
            return caution && `margin-bottom: calc(100vw * (40 / 1280));`;
        }}
    }

    @media (max-width: 1023px) {
        ${({ caution }) => {
            return caution ? `margin-bottom: calc(100vw * (50 / 428));` : `margin-bottom: calc(100vw * (40 / 428));`;
        }}
    }
`;

const PasswordMsgText = styled(NameMsgText)`
    bottom: 13px;

    @media (max-width: 1297px) {
        bottom: calc(100vw * (13 / 1280));
    }

    @media (max-width: 1023px) {
        bottom: calc(100vw * (27 / 428));
    }
`;

const NotSatisfyMsg = styled(RequiredMsg)``;
