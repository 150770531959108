export const CN = {
  TermsListData: [
    {
      Tit: `个人信息保护政策`,
      Text: `您在使用本公司服务的过程中，同意本个人信息保护政策中明示的条款。 本个人信息保护政策已并入本公司服务条款，并据此适用。 本个人信息保护政策将参照本公司服务条款所定义的术语。`,
    },
    {
      Tit: `用户信息`,
      Text: `为了更好地提供我们的服务，需要从您那里收集信息，以便我们能够最好地满足您的需求。 因此，我们可能会通过我们提供的服务收集各种类型的信息。 但您可以放心，我们将严格使用您的“个人信息”（即可以识别您的个人身份或可用于识别您个人身份的信息）来提供服务。 我们也可能会在您同意的情况下处理您的个人数据。`,
    },
    {
      Tit: `共享信息`,
      Text: `当您使用我们的服务时，我们可能会收集您可能与我们分享的个人信息，包括但不限于：
用于注册的电子邮件地址
创建帐户时用户的姓氏、名字、电子邮件地址和邮件地址
购买付费订阅时的付款信息
用户的首选语言、订阅计划、帐单信息、掌握历史记录和与帐户关联的促销代码（如果适用）
文件、内容、配置参数和其他信息，包括照片、音频文件、制作偏好、结构数据和电子邮件，以及辅助信息（如音乐风格或乐器类型）（“数据”）
联系人列表（如果您有访问权限）
我们网站上的访客资料
您和我们之间关于电子邮件或社交媒体平台的对话`,
    },
    {
      Tit: `通过服务来分享`,
      Text: `当您使用我们的服务时，我们可能会收集以下信息：
我们收集有关您用于访问我们服务的设备的信息。这些信息包括您的IP地址、您的网络浏览器和您使用的设备，以及与连接到我们网页和设备相关的标识符。如果您在设备上启用了定位服务参数，它还可以发送有关您的地理位置的信息。
我们使用“cookie”来收集信息并运营和改进我们的服务。 Cookie是我们存储在电子设备上的小型数据文件。当您以后登录服务时，我们可能会使用“持久性cookie”来记录您的电子邮件和密码。我们使用“会话标识符Cookie”来启用服务的某些功能，以更好地了解和改善您对服务的体验，跟踪有关您对服务的整体使用（访问时间、用户数量等）的数据，以及可以优化服务的营销。
大多数网络浏览器会在您在拒绝cookie或接收cookie的时候征求您的同意。但是，如果您不接受 cookie，您可能无法使用我们服务的所有功能。
另外，我们还可能将通过服务收集的信息与我们从其他来源收集的信息结合起来。例如，如果您通过 Facebook访问您的WARP帐户，则视为您允许我们访问与您的Facebook个人资料相关的信息。`,
    },
    {
      Tit: `信息获取`,
      Text: `WARP非常重视隐私问题。我司仅在我们对您履行义务的绝对必要时使用您的个人信息，并且确保只有那些需要访问它来履行我司对您的义务的员工才能访问它。
因此，我司可能通过使用我们收集的信息，并且以多种方式提供服务，包括：
登录帐户时对用户进行身份验证。
提供请求的订阅并继续付款。
通过访问、存储和分析数据，使您能够使用我司的服务。
启用某些功能，例如预览、编辑、共享和搜索歌曲。例如，如果您授予联系人访问权限，我司可能会存储该信息以共享数据或简化某些交换，例如向您发送电子邮件或邀请使用我司的服务。
我司通过了解和分析用户的使用趋势和偏好，改进我司的服务，并开发新产品、服务、功能和特性。
以上传音乐的为基础来提高母带算法和音乐引擎的性能。作为音乐家的用户保留音乐的所有权、版权和出版权。
解决问题，提供支援，以及信息的后续处理。
了解客户的需求和偏好，并评估顾客对我司服务的满意度。
向您发送有关我司产品、服务、新闻和活动的更新，并在相关法律允许的范围内，向您发送我司的优惠、促销或竞赛（例如，向您推荐他人使用我司的服务而获得积分）。
遵守适用的法律法规要求。`,
    },
    {
      Tit: `信息分享`,
      Text: `请放心，我司会安全管理您的信息。 不会将您的个人信息出售给第三方，数据属于用户自己。但为了向您提供服务，我们可能会通过以下方式共享我们从您那里收集的特定信息：
包括企业、分公司、子公司、事业部及共同所有的其他公司在内的WARP公司内部也是如此。
虽有助于向服务提供者或合作伙伴提供改善及宣传服务，但只有在服务过程中需要这些信息时才会提供相应服务。 我司可以向提供方和合作伙伴提供个人信息， 按照我司的指示处理个人信息。
包括实地调查以及进行截止程序在内的其他公司的组织改编、合并、资产出售、资金筹措或活动全部或部分取得关联的事业交易情况。
在所有情况下允许或要求共享信息（例如法院命令、危及个人生命、健康或安全的情况）。
当我们与第三方共享您的个人信息时，我们会采取合理措施确保此类第三方遵守本隐私政策的规则，并提供足够的保证以实施适当的技术和组织措施。
我司出于下列各种目的，包括 (i) 遵守不同的报告义务，(ii) 用于商业或营销，(iii) 帮助这些想了解关于C的关心、习惯和使用模式，来收集、统计以及可能与第三方共享部分特定的其他非个人身份信息。提供通过服务来获得的某些程序、内容、服务或功能。简而言之，每当我们与他人共享您的信息时，都是为了最好地满足您的需求。
        `,
    },
    {
      Tit: `用户基本设定`,
      Text: `我司为您提供多种选择，以便您可以控制与我司共享信息的方式。以下举几个例子。
提供多种选项以控制如何与本公司共享信息 。 下面是几个例子。
您可以通过类似Facebook账户等第三方（各自为"综合服务"）访问或注册服务，或允许在综合服务中向本公司提供个人信息或其他信息。 您允许我司连接到综合服务，您授予我们访问和存储您的姓名、电子邮件地址, 以及综合服务与我们共享的其他信息的权利，并根据本隐私政策授予我司使用和披露这些信息的权利。 在各综合服务中确认好个人信息设置后，确认与本公司共享的信息，并根据基本设置进行变更。 在使用服务器链接之前，请仔细审核各综合服务的使用条款和个人信息保护政策。
可随时修改联系方式信息和通讯语言。这是确认个人信息是否准确和最新状态的最好方法。
可以随时更改密码 。
可以加入我司的促销活动或取消订阅。
点击注销按钮可安全退出账户。
联系客服支援组（support@warpmastering.com），随时都可以删除账号。
其他网站和社交媒体。
我们的网站和博客可能包含第三方网站的链接，例如 Facebook、YouTube、Twitter、Tumblr、Instagram、Soundcloud或其他网站。我们无法控制或对这些第三方的隐私惯例负责。类似数据共享的部分功能可以让第三方访问您的信息，比如像通过API接口。 这类有关第三方处理您的信息的方式受该第三方隐私政策的约束。此外，请记住任何人都可以阅读、收集和使用您在博客或社交媒体等公共论坛上发布的信息。`,
    },
    {
      Tit: `推荐活动`,
      Text: `我司可能会不定时邀请您分享促销优惠或向您的家人或密友介绍我们的服务。 在这些情况下，请确保您提到的人是您的家人（配偶、同居伴侣、父母或孩子）或与您有私人关系的人（经常交谈、分享亲密关系、意见等）。 如果您能分享给对我们的服务感兴趣的客户，我司将不胜感激。`,
    },
    {
      Tit: `信息管理法`,
      Text: `WARP可能会在我们拥有设施或拥有第三方服务提供商的其他国家/地区存储或处理您的个人数据。使用此服务，即表示您同意将信息传输到包含大韩民国在内的您居住国以外的国家。因此，用户的个人信息可能会受到与各自国家、地区不同的数据保护和隐私法律的约束。尽管这些信息位于大韩民国境外，但可能会根据该国家、地区的法律向该国家、地区的任何政府、法院或执法或监管机构公开。但我司在对您的个人数据方面的处理上将一贯适用于本个人信息保护政策，并且遵守为将个人信息从EU、EEA转移到第三国时提供的适当保护的一般数据保护规定（GDPR）要求事项。 此外，您还可以将信息保存至访问我司的服务时使用的本地设备。
我司在为您提供服务过程中依据所需的时间限定，或适用的法律、法规以及政府命令需要存储您的数据。当您删除帐户时，所有个人信息也将被删除。但，（1）在从我们的服务器和备份服务器中删除这些信息时可能会有延迟，（2）根据法律义务，可能需要保留一些信息。除付费账户外，如果您连续 12 个月无法访问该服务，我司保留删除您账户的权利。在我司删除您的帐户之前，我们会向您提供的电子邮件地址发送通知。`,
    },
    {
      Tit: `儿童`,
      Text: `此服务不适用于 13 岁以下儿童，我们从未有意收集 13 岁以下儿童的个人信息。 如果我们发现在未经父母或监护人许可的情况下收集13岁以下儿童个人信息情况的话，我们将采取必要措施确保立即删除该信息。`,
    },
    {
      Tit: `保密`,
      Text: `我司致力于保护您的个人信息。遵循公认的行业标准来保护您在传输过程中和接收后提交的信息。我们采取适当的物理、技术和管理措施来保护您的个人数据免受意外或非法破坏、意外丢失、未经授权的更改，未经授权的披露或访问、滥用和其他非法形式的个人数据处理。但没有一种通过Internet传输或电子存储的方法100%安全的。我们不能保证您传输给我们或存储在服务上的任何信息的安全性，您需自行承担风险。同时我们无法保证此类信息不会在违反物理、技术或管理保护措施的情况下被访问、披露、更改或销毁。如果您认为您的个人信息已被泄露，请联系我们。当我们发现违反安全系统的行为时，我们会根据适用法律将违规行为通知您和当局机关。`,
    },
    {
      Tit: `访问和编辑权限`,
      Text: `您的个人信息100%归您所有，您有权随时以书面形式访问或要求更正您与我司共享的任何个人信息。
根据GDPR，您可能拥有以下额外权利：(i) 在处理需要同意的情况下。(ii) 在特定条件下访问您的个人数据和某些附加信息的权利。(iv)在e特定条件下删除有关您的个人数据的权利。(v) 在特定情况下要求的权利限制处理您的个人数据的条件。(v) 当您认为已超出处理个人信息的合法标准，或认为无需处理个人信息，或认为个人信息已得到保存时，可以要求根据相应条件限制您处理个人信息的权力。(vi) 以结构化、常用形式以及机器可读的格式（即数据可移植性）来移动、复制或传输用户提供的个人信息的权利。(vii) 反对在特定条件下可能对用户产生法律影响的自动化程序的权利。
(vii) 向c发送的权利。通知数据保护机构。如要详细了解您在 GDPR 下的权利，请访问欧盟委员会数据保护页面：https://ec.europa.eu/info/law/law-topic/data-protection_en 。`,
    },
    {
      Tit: `信息更新`,
      Text: `根据WARP的变化，隐私政策可能会不定时更改。 这不会使您的个人信息面临风险。如果发生任何此类更改，我司将在我们的网站上发布此类内容的通知，以通知您这些修订的生效日期。因此，我们希望您密切关注我们的网站，以便我们及时通知您可能的更新。 如果您在我们的隐私政策变更生效后继续使用我们的服务，代表您理解我们的服务，承认并接受这些变更。 上次更新日期指的是显示在下一页底部的日期。`,
    },
    {
      Tit: `沟通`,
      Text: `如果您对本政策或您的个人信息有任何意见、问题或疑问，或者想获取有关我们与其他服务提供商相关的政策和做法的信息，请使用以下联系方式联系我司的个人信息保护负责人（或者数据保护负责人）。`,
    },
  ],

  info: `WARP Mastering Inc.<br/>
  privacy@warpmastering.com<br/>
  This Privacy Policy was last updated on Feb, 01, 2022
  
  `

};
