import { useEffect, useState } from 'react';
import { Mobile, PC } from '../../media/MediaQuery';
//css
import styled from 'styled-components';

// component
import HeaderWhite from '../../component/common/HeaderWhite';
import HeaderMb from '../../component/common/HeaderMb';
import LibraryBody from '../../component/library/LibraryBody';
import Footer from '../../component/common/Footer';

export default function LibraryPage() {
    const [footerAppear, setFooterAppear] = useState(false);
    return (
        <>
            <PC>
                <HeaderWhite />
                <LibraryBody setFooterAppear={setFooterAppear} />
                {/* {footerAppear && <Footer />} */}
            </PC>
            <Mobile>
                <HeaderMb />
                <LibraryBody setFooterAppear={setFooterAppear} />
                <Footer />
            </Mobile>
        </>
    );
}
