//react
import { useEffect, useState, useRef } from "react";
import { Link,useHistory,useParams } from "react-router-dom";

//css
import 'swiper/swiper-bundle.css';
import styled from "styled-components"

//component
import BasicBtn from '../common/Btn/BasicBtn'

//img

export default function PaymentCompleteBody(){

    const history = useHistory();
    const {country} = useParams();
    return(

        <Container>
            <PlanTit>
                PLAN
            </PlanTit>
            <TextCon>
                <Tit>Payment <br/> successful!</Tit>
                <Text>결제가 완료되었습니다! 이제 마스터링을 즐겨보세요.</Text>
                <GoToBtn><BasicBtn text={'Go to mastering!'} bg={true} onClick={() => {history.push(`/${country}/main`)}}/></GoToBtn>
            </TextCon>
        </Container>

    );

}

const Container = styled.div`
    padding: 200px 0 371px;
    width: 1280px;
    margin: 0 auto;
    position: relative;
    min-height: 100vh;

    @media (max-width: 1297px){
        padding: calc(100vw * (200 / 1280)) calc(100vw * (25 / 1280)) calc(100vw * (371 / 1280));
        width: 100%;
    }

    @media (max-width: 1023px){
        padding: calc(100vw * (107 / 428)) calc(100vw * (25 / 428)) calc(100vw * (499 / 428));
    }
`

const PlanTit = styled.p`
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 93px;
    text-transform: uppercase;

    @media(max-width: 1297px){
        font-size: calc(100vw * (40 / 1280));
        margin-bottom: calc(100vw * (93 / 1280));
    }

    @media(max-width: 1023px){
        font-size: calc(100vw * (25 / 428));
        margin-bottom: calc(100vw * (227 / 428));
    }
`

const TextCon = styled.div`
    text-align: center;
`

const Tit = styled.div`
    font-size: 60px;
    line-height: 79px;
    color: #005AA2;
    text-transform: uppercase;
    margin-bottom: 20px;

    @media(max-width: 1297px){
        font-size: calc(100vw * (60 / 1280));
        margin-bottom: calc(100vw * (20 / 1280));
        line-height: calc(100vw * (79 / 1280));
    }

    @media(max-width: 1023px){
        font-size: calc(100vw * (25 / 428));
        margin-bottom: calc(100vw * (20 / 428));
        line-height: calc(100vw * (40 / 428));
    }
`

const Text = styled.p`
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 99px;

    @media(max-width: 1297px){
        font-size: calc(100vw * (15 / 1280));
        margin-bottom: calc(100vw * (99 / 1280));
    }

    @media(max-width: 1023px){
        font-size: calc(100vw * (14 / 428));
        margin-bottom: calc(100vw * (66 / 428));
    }
`

const GoToBtn = styled.div`
    width: 442px;
    margin: 0 auto;

    @media(max-width: 1297px){
        width: calc(100vw * (442 / 1280));
    }

    @media(max-width: 1023px){
        width: 100%;
    }
`