//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

//css
import styled from 'styled-components';

// component
import SnsContainer from './SnsContainer';
import BasicBtn from '../common/Btn/BasicBtn';

//img
import LoginBg from '../../img/login/login_bg.png';
import AlertImg from '../../img/icon/icon_alert.svg';

import { ip } from '../../server/serverController';

export default function LoginBody() {
    const { country } = useParams();
    // section
    const [email, setEmail] = useState(''); // 이메일
    const [password, setPassword] = useState(''); // 비밀번호
    const [correct, setCorrect] = useState(null); // 일치여부
    // focus
    const [isOnFocus, setIsOnFocus] = useState(false);
    const [isOnFocusB, setIsOnFocusB] = useState(false);

    //caution
    const [cautionE, setCautionE] = useState(null);
    const [cautionP, setCautionP] = useState(null);
    //링크 이동
    const history = useHistory();

    const loginClick = () => {
        if (email.length === 0) {
            setCautionE(true);
        } else if (password.length === 0) {
            setCautionP(true);
        }
        // 유효성 검사 성공 시 login fetch
        if (cautionE === false && cautionP === false) {
            const rawData = JSON.stringify({
                userEmail: email,
                userPwd: password,
            });

            var myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: rawData,
                redirect: 'follow',
            };

            fetch(`${ip}api/login`, requestOptions)
                .then((response) => {
                    if (response.headers.get('Authorization')) {
                        const authToken = response.headers.get('Authorization').split(' ')[1];
                        if (authToken) {
                            // JWT 저장
                            sessionStorage.setItem('TOKEN', authToken);
                            sessionStorage.setItem('LOGGED', Date.now()); // 토큰 발행 시간
                        }
                    }
                    return response.json();
                })
                .then((result) => {
                    if (result.result === 1) {
                        history.push(`/`);
                    } else if (result.result === 0) {
                        console.log(result);
                        setCorrect(false);
                    }
                })
                .catch((error) => alert('server error'));
        }
    };

    useEffect(() => {
        if (email.length > 0) {
            setCautionE(false);
        }
        if (password.length > 0) {
            setCautionP(false);
        }
    }, [email, password]);

    // 각각 상황에 따른 메세지를 출력해주세요!
    //(1) RequiredMsg = 사용자가 input 에 아무것도 입력하지 않고 버튼을 눌렀을 때 나타나는 문구
    //(2) NotMatchMsg = 사용자가 입력한 값이 틀릴 때 나타나는 문구

    return (
        <Container>
            <Left></Left>
            <Right>
                <Title>LOGIN</Title>
                <SignUpWrap>
                    Not a member? <SignUpBtn to={`/${country}/signup`}>Sign up</SignUpBtn>
                </SignUpWrap>
                <Form>
                    <EmailWrap>
                        <EmailInput
                            type="email"
                            placeholder="Email"
                            caution={cautionE}
                            value={email}
                            isOnFocus={isOnFocus}
                            onFocus={() => setIsOnFocus(true)}
                            onBlur={() => setIsOnFocus(false)}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                        {cautionE ? (
                            <EmailMsgText>
                                <AlertIcon src={AlertImg} alt="alert" />
                                <RequiredMsg>Email is required</RequiredMsg>
                            </EmailMsgText>
                        ) : null}
                    </EmailWrap>
                    <PasswordWrap>
                        <PasswordInput
                            type="password"
                            placeholder="Password"
                            value={password}
                            caution={cautionP}
                            isOnFocus={isOnFocusB}
                            onFocus={() => setIsOnFocusB(true)}
                            onBlur={() => setIsOnFocusB(false)}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    loginClick();
                                }
                            }}
                        />
                        {cautionP ? (
                            <PasswordMsgText>
                                <AlertIcon src={AlertImg} alt="alert" />
                                {password.length === 0 ? <RequiredMsg>Password is required</RequiredMsg> : null}
                            </PasswordMsgText>
                        ) : null}
                        {correct === false ? (
                            <PasswordMsgText>
                                <NotMatchMsg>Your email and password don’t match</NotMatchMsg>
                            </PasswordMsgText>
                        ) : null}
                    </PasswordWrap>
                    <BasicBtn
                        color={true}
                        bg={false}
                        text="LOGIN"
                        onClick={() => {
                            loginClick();
                        }}
                    />
                    <ForgotBtnWrap>
                        <ForgotBtn to={`/${country}/findpw`}>Forgot Password?</ForgotBtn>
                    </ForgotBtnWrap>
                </Form>
                <SnsContainer />
            </Right>
        </Container>
    );
}

const Container = styled.div`
    padding-top: 100px;
    height: 100vh;
    display: flex;
    align-items: center;

    @media (max-width: 1297px) {
        padding-top: calc(100vw * (100 / 1280));
    }

    @media (max-width: 1023px) {
        min-height: 100vh;
        height: auto;
        justify-content: center;
        align-items: flex-start;
        padding-top: calc(100vw * (132 / 428));
        padding-bottom: calc(100vw * (295 / 428));
    }
`;

const Left = styled.div`
    width: calc(100vw * (950 / 1920));
    height: 100%;
    background: url(${LoginBg}) no-repeat center / cover;

    @media (max-width: 1023px) {
        display: none;
    }
`;

const Right = styled.div`
    padding-left: calc(100vw * (127 / 1920));
    text-align: center;

    @media (max-width: 1297px) {
        padding-left: calc(100vw * (127 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
        padding-left: calc(100vw * (25 / 428));
        padding-right: calc(100vw * (25 / 428));
    }
`;

const Title = styled.div`
    color: #005aa2;
    font-size: 60px;
    margin-bottom: 19px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (60 / 1280));
        margin-bottom: calc(100vw * (19 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (40 / 428));
        margin-bottom: calc(100vw * (15 / 428));
    }
`;

const SignUpWrap = styled.p`
    font-size: 15px;
    font-weight: 500;
    color: #aaaaaa;
    font-family: 'Pretendard';

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (14 / 428));
    }
`;

const SignUpBtn = styled(Link)`
    color: #005aa2;
    margin-left: 10px;

    @media (max-width: 1297px) {
        margin-left: calc(100vw * (10 / 1280));
    }

    @media (max-width: 1023px) {
        margin-left: calc(100vw * (5 / 428));
    }
`;

const Form = styled.form`
    margin-top: 21px;
    width: 428px;

    @media (max-width: 1297px) {
        margin-top: calc(100vw * (21 / 1280));
        width: calc(100vw * (428 / 1280));
    }

    @media (max-width: 1023px) {
        margin-top: calc(100vw * (35 / 428));
        width: 100%;
    }
`;

const EmailWrap = styled.div`
    position: relative;
`;

const EmailInput = styled.input`
    width: 100%;
    padding: 11px 20px;
    border-radius: 3px;
    font-size: 15px;

    &::placeholder {
        color: #aaaaaa;
    }

    ${({ isOnFocus }) => {
        return isOnFocus ? `border: 1px solid #005AA2; box-shadow: 0 0 6px 0 #bad3fb; background-color: #fff;` : `box-shadow: none; background-color: #FAFAFA;`;
    }}

    ${({ caution }) => {
        return caution ? `border: 1px solid #FF0058; margin-bottom: 30px;` : `margin-bottom: 20px;`;
    }}

    @media (max-width: 1297px) {
        padding: calc(100vw * (11 / 1280)) calc(100vw * (20 / 1280));
        font-size: calc(100vw * (15 / 1280));

        ${({ caution }) => {
            return caution ? `margin-bottom: calc(100vw * (30 / 1280));` : `margin-bottom: calc(100vw * (20 / 1280));`;
        }}
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (11 / 428)) calc(100vw * (15 / 428));
        font-size: calc(100vw * (15 / 428));

        ${({ caution }) => {
            return caution ? `margin-bottom: calc(100vw * (30 / 428));` : `margin-bottom: calc(100vw * (20 / 428));`;
        }}
    }
`;

const EmailMsgText = styled.p`
    position: absolute;
    left: 0;
    bottom: 8px;
    display: flex;
    align-items: center;
    font-family: 'Pretendard';
    font-size: 13px;
    color: #ff0058;

    @media (max-width: 1297px) {
        bottom: calc(100vw * (8 / 1280));
        font-size: calc(100vw * (13 / 1280));
    }

    @media (max-width: 1023px) {
        bottom: calc(100vw * (8 / 428));
        font-size: calc(100vw * (13 / 428));
    }
`;

const AlertIcon = styled.img`
    width: 12px;
    height: 12px;
    margin-right: 10px;

    @media (max-width: 1297px) {
        width: calc(100vw * (12 / 1280));
        height: calc(100vw * (12 / 1280));
        margin-right: calc(100vw * (10 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (12 / 428));
        height: calc(100vw * (12 / 428));
        margin-right: calc(100vw * (10 / 428));
    }
`;

const RequiredMsg = styled.span``;

const PasswordWrap = styled(EmailWrap)``;

const PasswordInput = styled(EmailInput)`
    margin-bottom: 40px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (40 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (40 / 428));
    }
`;

const PasswordMsgText = styled(EmailMsgText)`
    bottom: 13px;

    @media (max-width: 1297px) {
        bottom: calc(100vw * (13 / 1280));
    }

    @media (max-width: 1023px) {
        bottom: calc(100vw * (13 / 428));
    }
`;

const NotMatchMsg = styled(RequiredMsg)``;

const SubmitBtn = styled.button`
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    font-family: 'Syncopate';
    padding: 13px 0;
    color: #005aa2;
    border: 1px solid #005aa2;
    border-radius: 3px;
    margin-bottom: 11px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        padding: calc(100vw * (13 / 1280)) 0;
        margin-bottom: calc(100vw * (11 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (14 / 428));
        padding: calc(100vw * (13 / 428)) 0;
        margin-bottom: calc(100vw * (16 / 428));
    }
`;

const ForgotBtnWrap = styled.div`
    text-align: right;
    margin-top: 11px;
`;

const ForgotBtn = styled(Link)`
    font-family: 'Pretendard';
    font-size: 13px;
    font-weight: 500;
    text-align: right;
    color: #005aa2;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (13 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (14 / 428));
    }
`;
