//react
import { useEffect, useState, useRef } from 'react';

// wavesurfer
import WaveSurfer from 'wavesurfer.js';

//img
import PlayImg from '../../../img/icon/icon_play.svg';
import PauseImg from '../../../img/icon/icon_pause_color.svg';

import { audioURL } from '../../../server/awsURL';

//css
import styled from 'styled-components';

export default function Waveform ({ color, item, setAllPause, allPause, startIndex, index, upload = false }) {
    // 현재 running time
    const [timer, setTimer] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [runningTime, setRunningTime] = useState(0);
    const [barPercent, setBarPercent] = useState(0);
    const timerMethod = useRef(null);
    const [playing, setPlaying] = useState(false);

    const [time, setTime] = useState('');

    const contents = document.querySelectorAll('#contents');
    const waveform = useRef()
    
    useEffect(() => {
        // 시간 세팅
        if (item) {
            // console.log("item",item)
            setTime(timeFunc(item.secondDuration));
        }
    }, []);
    useEffect(() => {
        if(runningTime!==0){
            const track = document.querySelector(`#track${index}`);
            setRunningTime(0)
            waveform.current.load(track);
        }
    }, [item]);
    const timeFunc = (seconds) => {
        var hour = parseInt(seconds / 3600) < 10 ? '0' + parseInt(seconds / 3600) : parseInt(seconds / 3600);
        var min = parseInt((seconds % 3600) / 60) < 10 ? '0' + parseInt((seconds % 3600) / 60) : parseInt((seconds % 3600) / 60);
        var sec = seconds % 60 < 10 ? '0' + (seconds % 60) : seconds % 60;
        return hour === '00' ? min + ':' + sec : hour + ':' + min + ':' + sec;
    };
    
    // wavesurfer setting
    useEffect(() => {
        const track = document.querySelector(`#track${index}`);
        
        waveform.current = WaveSurfer.create({
            container: `#waveform${index}`,
            barWidth: 4,
            barRadius: 3,
            barGap: 2,
            barMinHeight: 1,
            progressColor: ['#014682', '#016D6A'],
            responsive: true,
            waveColor: '#eeeeee',
        });
        
        waveform.current.load(track);
    }, []);
    
    
    useEffect(() => {
        if(waveform.current){
            let getTime = waveform.current.getCurrentTime();
            let timeDigit = (item.secondDuration / 100) * 1000;
            let millSecDigit = timeDigit / 1000;
            if (isRunning && getTime <= item.secondDuration) {
                timerMethod.current = setTimeout(() => {
                    setTimer(getTime + millSecDigit);
                    setBarPercent(Math.round((getTime / item.secondDuration) * 100));
                }, 1000);
                setRunningTime(timeFunc(Math.round(getTime)));
            } else if (isRunning && getTime >= item.secondDuration) {
                clearTimeout(timerMethod.current);
                setIsRunning(false);
                setPlaying(false);
                setBarPercent(100);
                setRunningTime(timeFunc(Math.round(getTime)));
            }
        }
        return () => {
            clearTimeout(timerMethod.current);
        };
    }, [timer, isRunning, barPercent]);
    

    const handlePlay = () => {
        const prev = playing;
        setAllPause(true)
        if (contents) {
            contents.forEach((con) => con.pause());
        }
        setTimeout(() => {
            if(!prev) {
                setPlaying(!playing);
                waveform.current.playPause();
            }
        }, 100);
    };
    
    // play pause control
    useEffect(() => {
        if(waveform.current){
            if (!playing) {
                setIsRunning(false);
                setAllPause(false)
            } else {
                setRunningTime(timeFunc(waveform.current.getCurrentTime()));
                setIsRunning(true);
                setAllPause(false)
            }
        }
    }, [playing]);

    // all pause
    useEffect(() => {
        if (waveform.current&&allPause) {
            waveform.current.pause();
            setPlaying(false);
        }
    }, [allPause]);

    // 다른 페이지로 이동 시 모든 음원 멈추기
    useEffect(() => {
        return () => {
            if(waveform.current){
            waveform.current.pause();
            setPlaying(false);
            }
        };
    }, []);

    useEffect(() => {
        if (startIndex !== null) {
            if (index !== startIndex) {
                waveform.current.pause();
                setPlaying(false);
            }
        }
    }, [startIndex]);

    
    return (
        <ListCon>
            <PlayBtnCon>
                <PlayBtn
                    type="button"
                    onClick={handlePlay}
                >
                    <PlayBtnImg src={playing && !allPause ? PauseImg : PlayImg} alt="play" />
                </PlayBtn>
            </PlayBtnCon>
            <MusicCon>
                <MusicWrap id={`waveform${index}`}></MusicWrap>
                    <audio id={`track${index}`} crossOrigin="anonymous" autoPlay={false} muted={true} src={upload ? audioURL + encodeURIComponent(item?.fileNameMastering) : audioURL + encodeURIComponent(item?.liFileLow)} />
                <TimeCon>
                    <NowTime color={color}>{runningTime === 0 ? '00:00' : runningTime}</NowTime>
                    <EndTime>{time}</EndTime>
                </TimeCon>
            </MusicCon>
        </ListCon>
    )
}


const ListCon = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
`;

const PlayBtnCon = styled.div`
  width: 44px;
    margin: 0 26px 0 0;
    top: 38%;
  
    @media (max-width: 1297px) {
        margin: calc(100vw * (17 / 1280)) calc(100vw * (27 / 1280)) 0 0;
    }

    @media (max-width: 1023px) {
        margin: calc(100vw * (6 / 428)) calc(100vw * (13 / 428)) 0 0;
    }
`;

const PlayBtn = styled.button`
    width: 44px;
    height: 44px;
    position: absolute;
    left: 0%;
    top: 25%;
  
    @media (max-width: 1297px) {
        width: calc(100vw * (44 / 1280));
        height: calc(100vw * (44 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (30 / 428));
        height: calc(100vw * (30 / 428));
    }
`;

const PlayBtnImg = styled.img``;

const MusicCon = styled.div`
    width: 1200px;
    overflow-x: hidden;

    @media (max-width: 1297px) {
        width: 86vw;
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (330 / 428));
    }
`;

const MusicWrap = styled.div`
    overflow: hidden;
    width: 100%;
`;

const Bar = styled.div`
    width: 4px;
    flex-shrink: 0;
    background-color: #eeeeee;
    height: 100%;
    border-radius: 50px;

    &:nth-of-type(even) {
        height: 70%;
        transform: translateY(30%);
    }

    &:nth-of-type(3n) {
        height: 40%;
        transform: translateY(100%);
    }

    &:nth-of-type(4n) {
        height: 40%;
        transform: translateY(70%);
    }

    &:not(:last-child) {
        margin-right: 2.3px;
    }

    ${({ active, bg, percent }) => {
        if (active < percent) {
            if (bg) {
                return `background-color: #005AA2;`;
            } else {
                return `background-color: #008984;`;
            }
        } else {
            return `background-color: #EEEEEE;`;
        }
    }}

    @media(max-width: 1297px) {
        &:not(:last-child) {
            margin-right: calc(100vw * (2.3 / 1280));
            width: calc(100vw * (4 / 1280));
        }
    }

    @media (max-width: 1023px) {
        width: 3px;

        &:not(:last-child) {
            margin-right: calc(100vw * (2.2 / 480));
        }
    }
`;

const TimeCon = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-family: 'Pretendard';
    margin-top: 20px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        margin-top: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (11 / 428));
        margin-top: calc(100vw * (11 / 428));
    }
`;

const NowTime = styled.span`
    ${({ color }) => {
        return color ? `color: #005AA2;` : `color: #008984;`;
    }}
`;

const EndTime = styled.span`
    color: #666666;
`;
