//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

//css
import styled, { css } from 'styled-components';

//img
import MainSectionBg from '../../img/intro/main_bg.jpg';
import MainSectionBgMb from '../../img/intro/main_bg_mb.jpg';
import MainIconOne from '../../img/intro/main_icon1.png';
import MainIconTwo from '../../img/intro/main_icon2.png';
import MainIconThree from '../../img/intro/main_icon3.png';
import NoiseSectionBg from '../../img/intro/noise_bg.jpg';
import NoiseSectionImg from '../../img/intro/noise_img.png';
import EveryoneSectionImg from '../../img/intro/everyone_img.png';
import IdeaSectionImg from '../../img/intro/idea_img.png';
import AiSectionBg from '../../img/intro/ai_bg.jpg';
import AiSectionImg from '../../img/intro/ai_img.png';
import UserSectionImg from '../../img/intro/user_img.png';
import EasySectionImg from '../../img/intro/easy_img.png';
import MasterSectionImg from '../../img/intro/master_img.png';
import AudioSectionBg from '../../img/intro/audio_bg.jpg';
import LevelSectionImg from '../../img/intro/level_img.png';
import OvercomeSectionImg from '../../img/intro/overcome_img.png';

// lang
import { useLangText, useLangStyle } from '../../utils/langugeController/langController';
import { KR, EN, ES, CN } from './language/IntroBody/combLang';

export default function IntroBody() {
    const history = useHistory();
    // 아이콘
    const MainIconData = [MainIconOne, MainIconTwo, MainIconThree];

    useEffect(() => {
        let url = history.location.hash;
        let idText = url.substring(1);

        const AiSection = document.querySelector('#AiSection');
        const AudioSection = document.querySelector('#AudioSection');

        if (idText === 'AiSection') {
            window.scrollTo(0, AiSection.offsetTop);
        } else if (idText === 'AudioSection') {
            window.scrollTo(0, AudioSection.offsetTop);
        }
    }, []);

    const langText = useLangText(KR, EN, CN, ES);
    const langStyle = useLangStyle;

    return (
        <Container>
            {/* section1 */}
            <MainSection>
                <MainTextWrap>
                    <MainIconWrap>
                        {MainIconData.map((value, index) => {
                            return (
                                <MainIconList key={index}>
                                    <MainIconImg src={value} alt="main_icon" />
                                </MainIconList>
                            );
                        })}
                    </MainIconWrap>
                    <MainText>{langText.MainText}</MainText>
                    <MainSubText>{langText.MainSubText}</MainSubText>
                </MainTextWrap>
            </MainSection>
            {/* section2 */}
            <NoiseSection>
                <NoiseImgWrap>
                    <NoiseImg src={NoiseSectionImg} alt="noise_section_img" />
                </NoiseImgWrap>
                <NoiseText langStyle={langStyle}>
                    {langText.NoiseText}
                    <NoiseSubText>{langText.NoiseSubText}</NoiseSubText>
                </NoiseText>
            </NoiseSection>
            {/* section3 */}
            <EveryoneSection>
                <EveryoneText>
                    {langText.EveryoneText}
                    <EveryoneSubText>{langText.EveryoneSubText}</EveryoneSubText>
                </EveryoneText>
                <EveryoneImgWrap>
                    <EveryoneImg src={EveryoneSectionImg} alt="Everyone_section_img" />
                </EveryoneImgWrap>
            </EveryoneSection>
            {/* section4 */}
            <IdeaSection>
                <IdeaText>
                    {langText.IdeaText}
                    <IdeaSubText>{langText.IdeaSubText}</IdeaSubText>
                </IdeaText>
                <IdeaImgWrap>
                    <IdeaImg src={IdeaSectionImg} alt="Idea_section_img" />
                </IdeaImgWrap>
            </IdeaSection>
            {/* section5 */}
            <AiSection id="AiSection">
                <AiText>
                    {langText.AiText}
                    <AiSubText>{langText.AiSubText}</AiSubText>
                </AiText>
                <AiImgWrap>
                    <AiImg src={AiSectionImg} alt="Ai_section_img" />
                </AiImgWrap>
            </AiSection>
            {/* section6 */}
            <UserSection>
                <UserText>
                    {langText.UserText}
                    <UserSubText>{langText.UserSubText}</UserSubText>
                </UserText>
                <UserImgWrap>
                    <UserImg src={UserSectionImg} alt="User_section_img" />
                </UserImgWrap>
            </UserSection>
            {/* section8 */}
            <EasySection>
                <EasyText>
                    {langText.EasyText}
                    <EasySubText>{langText.EasySubText}</EasySubText>
                </EasyText>
                <EasyImgWrap>
                    <EasyImg src={EasySectionImg} alt="Easy_section_img" />
                </EasyImgWrap>
            </EasySection>
            {/* section9 */}
            <MasterSection>
                <MasterText>
                    {langText.MasterText}
                    <MasterSubText>{langText.MasterSubText}</MasterSubText>
                </MasterText>
                <MasterImgWrap>
                    <MasterImg src={MasterSectionImg} alt="Master_section_img" />
                </MasterImgWrap>
            </MasterSection>
            {/* section10 */}
            <AudioSection id="AudioSection">
                <AudioText>
                    {langText.AudioText}
                    <AudioSubText>{langText.AudioSubText}</AudioSubText>
                </AudioText>
            </AudioSection>
            {/* section11 */}
            <LevelSection>
                <LevelText langStyle={langStyle}>
                    {langText.LevelText}
                    <LevelSubText>{langText.LevelSubText}</LevelSubText>
                </LevelText>
                <LevelImgWrap>
                    <LevelImg src={LevelSectionImg} alt="Level_section_img" />
                </LevelImgWrap>
            </LevelSection>
            {/* section12 */}
            <OvercomeSection>
                <OvercomeText>
                    {langText.OvercomeText}
                    <OvercomeSubText>{langText.OvercomeSubText}</OvercomeSubText>
                </OvercomeText>
                <OvercomeImgWrap>
                    <OvercomeImg src={OvercomeSectionImg} alt="Overcome_section_img" />
                </OvercomeImgWrap>
            </OvercomeSection>
        </Container>
    );
}

const Container = styled.div`
    font-family: 'Pretendard';
`;

//section1

const MainSection = styled.div`
    width: 100%;
    height: 100vh;
    background: url(${MainSectionBg}) no-repeat center center / cover;
    color: #fff;
    position: relative;

    @media (max-width: 1023px) {
        background: url(${MainSectionBgMb}) no-repeat center center / cover;
    }
`;

const MainTextWrap = styled.div`
    position: absolute;
    left: 50%;
    bottom: 135px;
    transform: translateX(-50%);
    width: 100%;
    font-weight: 500;
    text-align: center;

    @media (max-width: 1297px) {
        bottom: calc(100vw * (135 / 1280));
    }

    @media (max-width: 1023px) {
        bottom: calc(100vh * (95 / 867));
    }
`;

const MainIconWrap = styled.ul`
    display: flex;
    justify-content: center;
`;

const MainIconList = styled.li`
    &:not(:last-child) {
        margin-right: 28px;
    }

    @media (max-width: 1297px) {
        &:not(:last-child) {
            margin-right: calc(100vw * (28 / 1280));
        }
    }

    @media (max-width: 1023px) {
        &:not(:last-child) {
            margin-right: calc(100vw * (28 / 428));
        }
    }
`;

const MainIconImg = styled.img`
    width: 45px;
    height: 45px;
    object-fit: contain;

    @media (max-width: 1297px) {
        width: calc(100vw * (45 / 1280));
        height: calc(100vw * (45 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (45 / 428));
        height: calc(100vw * (45 / 428));
    }
`;

const MainText = styled.h1`
    font-size: 50px;
    margin-top: 37px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (50 / 1280));
        margin-top: calc(100vw * (37 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (25 / 428));
        margin-top: calc(100vw * (30 / 428));
    }
`;

const MainSubText = styled.div`
    font-size: 16px;
    line-height: 23px;
    margin-top: 20px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (16 / 1280));
        line-height: calc(100vw * (23 / 1280));
        margin-top: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (14 / 428));
        line-height: calc(100vw * (23 / 428));
        margin-top: calc(100vw * (16 / 428));
    }
`;

//section2

const NoiseSection = styled.div`
    position: relative;
    height: 797px;
    background: url(${NoiseSectionBg}) no-repeat center / cover;

    @media (max-width: 1297px) {
        height: calc(100vw * (797 / 1280));
    }

    @media (max-width: 1023px) {
        background: url(${NoiseSectionBg}) no-repeat right / cover;
        height: calc(100vw * (600 / 428));
    }
`;

const NoiseImgWrap = styled.div`
    position: absolute;
    left: 25px;
    top: 7px;
    width: 769px;
    height: 770px;

    @media (max-width: 1297px) {
        left: calc(100vw * (25 / 1280));
        top: calc(100vw * (7 / 1280));
        width: calc(100vw * (769 / 1280));
        height: calc(100vw * (770 / 1280));
    }

    @media (max-width: 1023px) {
        left: 0;
        top: calc(100vw * (145 / 428));
        width: calc(100vw * (224 / 428));
        height: calc(100vw * (221 / 428));
    }
`;

const NoiseImg = styled.img``;

const NoiseText = styled.div`
    max-width: 1094px;
    height: 100%;
    margin: 0 auto;
    font-size: 40px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    text-align: right;
    flex-direction: column;

    @media (max-width: 1297px) {
        max-width: calc(100vw * (1094 / 1280));
        font-size: calc(100vw * (40 / 1280));
    }

    @media (max-width: 1023px) {
        max-width: 100%;
        padding: 0 calc(100vw * (25 / 428)) calc(100vw * (150 / 428));
        font-size: calc(100vw * (20 / 428));
        justify-content: flex-end;

        ${({ langStyle }) =>
            langStyle &&
            langStyle({
                kr: css``,
                en: css``,
                cn: css``,
                es: css`
                    & br {
                        display: none;
                    }
                `,
            })}
    }
`;

const NoiseSubText = styled(MainSubText)`
    margin-top: 18px;
    text-align: right;

    @media (max-width: 1297px) {
        margin-top: calc(100vw * (18 / 1280));
    }

    @media (max-width: 1023px) {
        margin-top: calc(100vw * (15 / 428));
        & br {
            display: none;
        }
    }
`;

//section3

const EveryoneSection = styled.div`
    position: relative;
    height: 800px;

    @media (max-width: 1297px) {
        height: calc(100vw * (800 / 1280));
    }

    @media (max-width: 1023px) {
        height: calc(100vw * (600 / 428));
    }
`;

const EveryoneText = styled(NoiseText)`
    text-align: left;
    font-family: 'Pretendard';

    @media (max-width: 1023px) {
        padding-bottom: calc(100vw * (150 / 428));
    }
`;

const EveryoneSubText = styled(MainSubText)`
    text-align: left;

    @media (max-width: 1023px) {
        & br {
            display: none;
        }
    }
`;

const EveryoneImgWrap = styled.div`
    width: 744px;
    height: 692px;
    position: absolute;
    right: 0;
    bottom: 0;

    @media (max-width: 1297px) {
        width: calc(100vw * (744 / 1280));
        height: calc(100vw * (692 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (199 / 428));
        height: calc(100vw * (214 / 428));
        right: 0;
        bottom: calc(100vw * (256 / 428));
    }
`;

const EveryoneImg = styled.img``;

//section4

const IdeaSection = styled(EveryoneSection)`
    background-color: #fcfcfc;
`;

const IdeaText = styled(NoiseText)`
    justify-content: flex-end;
    text-align: center;
    align-items: center;
    position: relative;
    z-index: 1;
    padding-bottom: 115px;

    @media (max-width: 1297px) {
        padding-bottom: calc(100vw * (115 / 1280));
    }

    @media (max-width: 1023px) {
        padding-bottom: calc(100vw * (150 / 428));
    }
`;

const IdeaSubText = styled(MainSubText)`
    text-align: center;
`;

const IdeaImgWrap = styled.div`
    width: 786px;
    height: 502px;
    position: absolute;
    top: 103px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 1297px) {
        width: calc(100vw * (786 / 1280));
        height: calc(100vw * (502 / 1280));
        top: calc(100vw * (103 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (270 / 428));
        height: calc(100vw * (160 / 428));
        top: calc(100vw * (152 / 428));
    }
`;

const IdeaImg = styled.img``;

//section5

const AiSection = styled(EveryoneSection)`
    background: url(${AiSectionBg}) no-repeat center / cover;
    height: 1077px;
    position: relative;

    @media (max-width: 1297px) {
        height: calc(100vw * (1077 / 1280));
    }

    @media (max-width: 1023px) {
        height: 100vh;
    }
`;

const AiText = styled(IdeaText)`
    padding-bottom: 155px;
    color: #fff;
    font-family: 'Pretendard';

    @media (max-width: 1297px) {
        padding-bottom: calc(100vw * (155 / 1280));
    }

    @media (max-width: 1023px) {
        padding-bottom: calc(100vh * (249 / 867));
        & br {
            display: none;
        }
    }
`;

const AiSubText = styled(IdeaSubText)``;

const AiImgWrap = styled.div`
    width: 447px;
    height: 149px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 1297px) {
        width: calc(100vw * (447 / 1280));
        height: calc(100vw * (149 / 1280));
    }

    @media (max-width: 1023px) {
        top: calc(100vh * (297 / 867));
        width: calc(100vw * (296 / 428));
        height: calc(100vw * (99 / 428));
        transform: translateX(-50%);
    }
`;

const AiImg = styled.img``;

//section6

const UserSection = styled(EveryoneSection)`
    background-color: #000;
    overflow-x: hidden;
`;

const UserImgWrap = styled.div`
    position: absolute;
    left: 78px;
    top: 50%;
    transform: translateY(-50%);
    width: 789px;
    height: 800px;

    @media (max-width: 1297px) {
        left: calc(100vw * (25 / 1280));
        width: calc(100vw * (789 / 1280));
        height: calc(100vw * (800 / 1280));
    }

    @media (max-width: 1023px) {
        top: calc(100vw * (9 / 428));
        left: auto;
        right: calc(100vw * (-79 / 428));
        width: calc(100vw * (403 / 428));
        height: calc(100vw * (409 / 428));
        transform: translateY(0%);
    }
`;

const UserImg = styled.img``;

const UserText = styled(NoiseText)`
    font-family: 'Pretendard';
    position: relative;
    z-index: 1;
    color: #fff;
    word-break: keep-all;
    & br:last-child {
        display: none;
    }

    @media (max-width: 1023px) {
        padding-bottom: calc(100vw * (57 / 428));
        & br:not(:first-child) {
            display: none;
        }
        & br:last-child {
            display: block;
        }
    }
`;

const UserSubText = styled(NoiseSubText)``;

//section7

const EasySection = styled(IdeaSection)``;

const EasyText = styled(NoiseText)`
    align-items: flex-start;

    & br:last-child {
        display: none;
    }

    @media (max-width: 1023px) {
        padding-bottom: calc(100vw * (123 / 428));

        & br {
            display: none;
        }
        & br:last-child {
            display: block;
        }
    }
`;

const EasySubText = styled(EveryoneSubText)`
    position: relative;
    z-index: 1;
    word-break: keep-all;
`;

const EasyImgWrap = styled.div`
    width: 457px;
    height: 456px;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);

    @media (max-width: 1297px) {
        width: calc(100vw * (457 / 1280));
        height: calc(100vw * (456 / 1280));
        right: calc(100vw * (25 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (239 / 428));
        height: calc(100vw * (238 / 428));
        top: calc(100vw * (98 / 428));
        right: calc(100vw * (13 / 428));
        transform: translateY(0%);
    }
`;

const EasyImg = styled.img``;

//section8

const MasterSection = styled(EveryoneSection)``;

const MasterImgWrap = styled.div`
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    width: 606px;
    height: 359px;

    @media (max-width: 1297px) {
        left: calc(100vw * (25 / 1280));
        width: calc(100vw * (606 / 1280));
        height: calc(100vw * (359 / 1280));
    }

    @media (max-width: 1023px) {
        left: calc(100vw * (8 / 428));
        top: calc(100vw * (173 / 428));
        transform: translateY(0%);
        width: calc(100vw * (252 / 428));
        height: calc(100vw * (149 / 428));
    }
`;

const MasterImg = styled.img``;

const MasterText = styled(NoiseText)`
    font-family: 'Pretendard';
    position: relative;
    z-index: 1;
`;

const MasterSubText = styled(NoiseSubText)``;

//section9

const AudioSection = styled(AiSection)`
    background: url(${AudioSectionBg}) no-repeat center / cover;
`;

const AudioText = styled(IdeaText)`
    padding-bottom: 178px;
    color: #fff;

    @media (max-width: 1297px) {
        padding-bottom: calc(100vw * (178 / 1280));
    }

    @media (max-width: 1023px) {
        padding-bottom: calc(100vw * (212 / 428));
    }
`;

const AudioSubText = styled(IdeaSubText)``;

//section10

const LevelSection = styled(IdeaSection)``;

const LevelText = styled(MasterText)`
    text-align: right;

    @media (max-width: 1023px) {
        padding-bottom: calc(100vw * (100 / 428));

        ${({ langStyle }) =>
            langStyle({
                kr: css``,
                en: css``,
                cn: css``,
                es: css`
                    & br {
                        display: none;
                    }
                `,
            })}
    }
`;

const LevelSubText = styled(NoiseSubText)`
    & br:first-child,
    br:last-child {
        display: none;
    }

    @media (max-width: 1023px) {
        & br {
            display: none;
        }
    }
`;

const LevelImgWrap = styled.div`
    width: 440px;
    height: 544px;
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);

    @media (max-width: 1297px) {
        width: calc(100vw * (440 / 1280));
        height: calc(100vw * (544 / 1280));
        left: calc(100vw * (25 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (192 / 428));
        height: calc(100vw * (238 / 428));
        left: calc(100vw * (5 / 428));
        top: calc(100vw * (145 / 428));
        transform: translateY(0%);
    }
`;

const LevelImg = styled.img``;

//section11

const OvercomeSection = styled(EveryoneSection)``;

const OvercomeText = styled(NoiseText)`
    text-align: left;
    position: relative;
    z-index: 1;

    @media (max-width: 1023px) {
        letter-spacing: -0.5px;
        word-break: keep-all;
        padding-bottom: calc(100vw * (123 / 428));
    }
`;

const OvercomeSubText = styled(EveryoneSubText)``;

const OvercomeImgWrap = styled.div`
    width: 549px;
    height: 503px;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);

    @media (max-width: 1297px) {
        width: calc(100vw * (549 / 1280));
        height: calc(100vw * (503 / 1280));
        right: calc(100vw * (25 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (239 / 428));
        height: calc(100vw * (219 / 428));
        right: calc(100vw * (13 / 428));
        top: calc(100vw * (98 / 428));
        transform: translateY(0%);
    }
`;

const OvercomeImg = styled.img``;
