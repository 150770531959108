import { useEffect } from "react";
import { Mobile, PC } from "../../media/MediaQuery"
//css
import styled from "styled-components"

// component
import Header from "../../component/common/Header"
import HeaderMb from "../../component/common/HeaderMb"
import MainBigBanner from "../../component/main/MainBigBanner"
import MainSection2 from "../../component/main/MainSection2"
import MainSection3 from "../../component/main/MainSection3"
import MainSection4 from "../../component/main/MainSection4"
import MainSection5 from "../../component/main/MainSection5"
import MainSection6 from "../../component/main/MainSection6"
import MainSection7 from "../../component/main/MainSection7"
import MainSection8 from "../../component/main/MainSection8"
import MainSection9 from "../../component/main/MainSection9"
import MainSection10 from "../../component/main/MainSection10"
import MainSection12 from "../../component/main/MainSection12"
import MainSection13 from "../../component/main/MainSection13"
import MainSlider from "../../component/main/MainSlider"
import MainBeforeAfter from "../../component/main/MainBeforeAfter"
import MainBannerSmall from "../../component/main/MainBannerSmall"
// import MainOfficialBanner from "../../component/main/MainOfficialBanner"
import Footer from "../../component/common/Footer"

export default function MainPage(){
    
    return(
        <>
            <PC>
                <Header/>
            </PC>
            <Mobile>
                <HeaderMb/>
            </Mobile>
            <MainBigBanner/>
            <MainSection2/>
            <MainSection3/>
            <MainSection4/>
            <MainSection5/>
            <MainSection6/>
            <MainSection7/>
            <MainSection8/>
            <MainSection9/>
            <MainSection10/>
            <MainSection12/>
            <MainSection13/>
            {/* <MainSlider/>
            <MainBeforeAfter/>
            <MainBannerSmall/> */}

            {/* 정식서비스 배너 */}
            {/* <MainOfficialBanner/> */}
            <Footer/>
        </>
    );

}