//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

//css
import styled from 'styled-components';

//component
import TabBtn from '../common/Btn/TabBtn';
import BasicBtn from '../common/Btn/BasicBtn';
import MyInfo from './MyInfo';
import MySubsctiption from './MySubscription';

// lang
import { useLangText } from '../../utils/langugeController/langController';
import { KR, EN, CN, ES } from './language/MyPageBody/combLang';

export default function MyPageBody() {
    // 언어 리덕스 받아옴
    const LANG = useSelector((state) => state.language.lang);
    const langText = useLangText(KR, EN, CN, ES);

    //tab
    const [titleList, setTitleList] = useState([]);

    const params = useParams();
    const history = useHistory();

    useEffect(() => {
        if(LANG !== langText){
            setTitleList([
                { title: `${langText.infoTitle}`, paramsList: 'myinfo' },
                { title: `${langText.subscripTitle}`, paramsList: 'mysubscription' },
            ]);
        }
    }, [LANG]);

    const render = () => {
        if (params.listTitle === 'myinfo') return <MyInfo />;
        if (params.listTitle === 'mysubscription') return <MySubsctiption />;
    };

    return (
        <Container>
            <TabBtn titleText={`MY PAGE`} data={titleList} urlTitle={'mypage'} />
            <MyPageCon>{render()}</MyPageCon>
        </Container>
    );
}

const Container = styled.div`
    padding-top: 200px;

    @media (max-width: 1297px) {
        padding: calc(100vw * (200 / 1280)) 0;
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (107 / 428)) 0 calc(100vw * (100 / 428));
    }
`;

const MyPageCon = styled.div`
    width: 1280px;
    margin: 0 auto;

    @media (max-width: 1297px) {
        width: 100%;
        padding: 0 calc(100vw * (25 / 1280));
    }

    @media (max-width: 1023px) {
        padding: 0;
    }
`;
