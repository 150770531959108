//react
import { useEffect, useRef, useState } from 'react';

//css
import styled, { withTheme } from 'styled-components';

//component
import DefaultPopup from '../common/popup/DefaultPopup';
import MasteringList from '../common/list/Waveform';
import { ip } from '../../server/serverController';
import { audioURL } from '../../server/awsURL';

// img
import CheckImg from '../../img/icon/icon_checked.svg';
import SearchImg from '../../img/icon/icon_search.svg';
import TrashImg from '../../img/icon/icon_trash2.svg';
import OptionImg from '../../img/icon/icon_option.svg';
import DownImg from '../../img/icon/icon_down.svg';
import EditImg from '../../img/icon/icon_edit.svg';
import PlusImg from '../../img/icon/icon_plus3.svg';
import MinusImg from '../../img/icon/icon_minus.svg';
import AcceptImg from '../../img/icon/icon_accept.svg';

import { debounce } from 'lodash';

// fileDownload
import fileDownload from '../mastering/fileDownload';

import serverController from '../../server/serverController';
import DownloadPopup from '../mastering/DownloadPopup';

export default function LibraryBody({ setFooterAppear }) {
    const scrollRef = useRef();
    // 이용 불가한 사용자일 때 false로 바꿔주세요!
    const [available, setAvailable] = useState(false);

    // 라이브러리 팝업
    const [LibraryClick, setLibraryClick] = useState(false);

    // 라이브러리 리스트 stateF
    const [isData, setIsData] = useState([]);
    const [allData, setAllData] = useState([]);

    // 로그인 하지 않은 경우
    const [notUser, setNotUser] = useState(false);

    // 체크한 리스트 liId
    const [array, setArray] = useState([]);

    //audio 컨트롤 하는 state
    const [allPause, setAllPause] = useState(false);

    // 내가 클릭한 리스트의 index를 담는 state
    const [startIndex, setStartIndex] = useState(null);

    const [searchText, setSearchText] = useState('');

    const [userId, setUserId] = useState(null);

    const [lodingPop, setLoadingPop] = useState(false);

    // render는 한번만 되게
    let contents = document.querySelectorAll('#contents');

    const tokenData = sessionStorage.getItem('TOKEN');

    // menu
    const menuData = ['all', 'music', 'video', 'dialogue'];
    const [menuClick, setMenuClick] = useState(0);

    // scroll
    const [lastLiId, setLastLiId] = useState(0);
    const [lastSearchLiId, setLastSearchLiId] = useState(0);
    const lastSearchLiIdRef = useRef(0);

    const [isLoading, setIsLoading] = useState(false);

    const [searchState, setSearchState] = useState(false);

    // 최초에 유저 id 값을 가져오기
    useEffect(() => {
        if (tokenData) {
            document.body.style.overflowY = 'auto';
            serverController.connectFetchController(
                `api/mypage/info`,
                'POST',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                        setUserId(res.user.userId);
                    }
                },
            );
        }
    }, []);

    // trial 라이브러리 데이터
    const LibraryData = {
        isState: LibraryClick,
        setIsState: setLibraryClick,
        popText: 'Trial은 라이브러리 이용이 불가능 합니다.',
        blueText: '*라이브러리 사용은 플랜이용 고객만 가능합니다.',
        btnText: '닫기',
    };

    // 로그인 하지 않고 라이브러리 페이지로 왔을 경우, 로그인 페이지로 이동 유도
    const notUserData = {
        isState: notUser,
        setIsState: setNotUser,
        popText: 'You need to log in.',
        btnText: 'LOGIN',
        url: '/login',
    };

    const getLibraryList = async () => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        try {
            serverController.connectFetchController(
                `api/library?lastLiId=${lastLiId}`,
                'GET',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                        if (res.page.content.length > 0 && lastLiId === res.page.content[res.page.content.length - 1].liId) {
                            return setIsLoading(false);
                        } else if (res.page.content.length === 0) {
                            return setIsLoading(false);
                        } else {
                            setIsData((prev) => [...prev, ...res.page.content]);
                            setAvailable(true);
                            setAllData((prev) => [...prev, ...res.page.content]);
                            setLastLiId(res.page.content[res.page.content.length - 1].liId);
                            setIsLoading(false);
                        }
                        setSearchState(false);
                        lastSearchLiIdRef.current = 0;
                    }
                },
            );
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (isData && isData.length < 5) {
            setFooterAppear(true);
        }
    }, [isData]);
    useEffect(() => {
        lastSearchLiIdRef.current = lastLiId;
    }, [lastLiId]);

    const infiniteScroll = (e) => {
        const { scrollHeight, offsetHeight, scrollTop } = e.target;

        const digitBottom = 100;

        const isBottom = scrollHeight <= offsetHeight + scrollTop + digitBottom;
        if (isBottom) {
            if (!searchState) {
                getLibraryList();
            } else {
                if (menuClick === 1 || menuClick === 2) {
                    searchController(searchText, menuData[menuClick]);
                } else if (menuClick === 0 || menuClick === null) {
                    searchController(searchText, 'all');
                } else if (menuClick === 3) {
                    searchController(searchText, 'dialog');
                }
            }
        }
    };

    // 라이브러리 리스트
    useEffect(() => {
        if (tokenData) {
            getLibraryList();
        } else {
            setNotUser(true);
            setAvailable(false);
        }
    }, [tokenData]);

    // all check
    const handleAllCheck = (checked) => {
        if (checked) {
            setIsData(
                isData.map((el) => {
                    return { ...el, toggle: true };
                }),
            );
        } else {
            setIsData(
                isData.map((el) => {
                    return { ...el, toggle: false };
                }),
            );
        }
    };

    // single 클릭 시
    const handleSingleCheck = (index) => {
        setIsData(isData.map((el, idx) => (idx === index ? { ...el, toggle: !el.toggle } : el)));
    };

    // 체크한 liId들 setArray에 넣어주기
    useEffect(() => {
        let arr = [];
        isData.map((el, idx) => (el.toggle === true ? arr.push(el.liId) : null));
        setArray(arr);
    }, [isData]);

    // search onChange
    const searchController = debounce((searchText, type) => {
        // console.log('searchController:lastSearchLiIdRef.current', lastSearchLiIdRef.current);
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        try {
            serverController.connectFetchController(
                `api/library/search?fileName=${searchText}&wavType=${type}&lastLiId=${lastSearchLiIdRef.current}`,
                'GET',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                        if (res.page.content.length > 0 && lastSearchLiIdRef.current === res.page.content[res.page.content.length - 1].liId) {
                            return setIsLoading(false);
                        } else if (res.page.content.length === 0) {
                            return setIsLoading(false);
                        } else {
                            setIsData((prev) => [...prev, ...res.page.content]);
                            setAvailable(true);
                            lastSearchLiIdRef.current = res.page.content[res.page.content.length - 1].liId;
                            setIsLoading(false);
                        }
                        setLastLiId(0);
                        setSearchState(true);
                    }
                },
            );
        } catch (e) {
            console.error(e);
        }
    }, 200);

    // delete
    const trashFetch = () => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${tokenData}`);
        myHeaders.append('Content-Type', 'application/json');

        var raw = JSON.stringify({
            liIds: array,
        });

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        fetch(`${ip}api/library`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                localStorage.removeItem('uploadFiles');
                lastSearchLiIdRef.current = 0;
                if (menuClick === 1 || menuClick === 2) {
                    searchController(searchText, menuData[menuClick]);
                } else if (menuClick === 0 || menuClick === null) {
                    searchController(searchText, 'all');
                } else if (menuClick === 3) {
                    searchController(searchText, 'dialog');
                }
            })
            .catch((error) => console.log('error', error));
    };

    const menuClickFunc = (menuClickP = 0) => {
        lastSearchLiIdRef.current = 0;
        setAllPause(true);
        setIsData([]);
        // all video pause
        if (contents) {
            contents.forEach((con) => con.pause());
        }

        if (menuClickP === 1 || menuClickP === 2) {
            searchController(searchText, menuData[menuClickP]);
        } else if (menuClickP === 0 || menuClick === undefined) {
            searchController(searchText, 'all');
        } else if (menuClickP === 3) {
            searchController(searchText, 'dialog');
        }
    };

    return (
        <>
            {/* trial 라이브러리 이용 불가 팝업입니다!*/}
            {LibraryClick && <DefaultPopup data={LibraryData} />}
            {notUser && <DefaultPopup data={notUserData} />}
            {lodingPop && <DownloadPopup />}
            <Container ref={scrollRef} onScroll={infiniteScroll}>
                <div style={{ maxWidth: 1280, margin: 'auto', height: '100%' }}>
                    <LibraryTit>Library</LibraryTit>
                    <LibraryTop>
                        <Left>
                            <CheckBox>
                                {/* 해당 체크 박스 체크 시 전체 체크 */}
                                <CheckBoxInput type="checkbox" onChange={(e) => handleAllCheck(e.target.checked)} />
                            </CheckBox>
                            <MenuCon>
                                <MenuWrap>
                                    {menuData.map((item, index) => {
                                        return (
                                            <MenuList
                                                key={index}
                                                active={menuClick === null && index === 0 ? true : menuClick === index}
                                                onClick={() => {
                                                    lastSearchLiIdRef.current = 0;
                                                    menuClickFunc(index);
                                                    setMenuClick(index);
                                                }}
                                            >
                                                {item}
                                            </MenuList>
                                        );
                                    })}
                                </MenuWrap>
                            </MenuCon>
                        </Left>
                        <Right>
                            <SearchCon>
                                <SearchInput
                                    type="search"
                                    placeholder="search"
                                    search
                                    onChange={(e) => {
                                        lastSearchLiIdRef.current = 0;
                                        setSearchText(e.target.value);
                                        setIsData([]);
                                        searchController(e.target.value, menuData[menuClick === null ? 0 : menuClick]);
                                    }}
                                />
                                <SearchIconBox />
                            </SearchCon>
                            <TrashBtn
                                type="button"
                                onClick={() => {
                                    setIsData([]);
                                    trashFetch();
                                }}
                            >
                                <TrashBtnImg src={TrashImg} alt="trash" />
                            </TrashBtn>
                        </Right>
                    </LibraryTop>

                    <LibraryCon>
                        {available ? (
                            <ListWrap>
                                {isData.length > 0 ? (
                                    isData.map((item, index) => {
                                        // console.log('아이템', item, index);
                                        const { liName, liType, liDate, liTag, toggle, liId, masteringType, wavType, useFreePlan } = item;
                                        return (
                                            <DataComp
                                                key={index}
                                                name={item.liName}
                                                type={liType}
                                                date={liDate}
                                                tag={liTag}
                                                useFreePlan={useFreePlan}
                                                liIdInput={liId}
                                                index={index}
                                                isData={isData}
                                                setIsData={setIsData}
                                                toggle={toggle}
                                                handleSingleCheck={handleSingleCheck}
                                                token={tokenData}
                                                getLibraryListFuc={getLibraryList}
                                                allPause={allPause}
                                                setAllPause={setAllPause}
                                                masteringType={masteringType}
                                                wavType={wavType}
                                                startIndex={startIndex}
                                                setStartIndex={setStartIndex}
                                                userId={userId}
                                                setLoadingPop={setLoadingPop}
                                                menuClickFunc={menuClickFunc}
                                                setLastLiId={setLastLiId}
                                                menuClick={menuClick}
                                            />
                                        );
                                    })
                                ) : (
                                    <AvailableText>The library is empty.</AvailableText>
                                )}
                            </ListWrap>
                        ) : (
                            <AvailableText>The library is not available.</AvailableText>
                        )}
                    </LibraryCon>
                </div>
            </Container>
        </>
    );
}

// <DataComp /> 컴포넌트
const DataComp = (props) => {
    const {
        name,
        type,
        liIdInput,
        date,
        tag,
        useFreePlan,
        index,
        isData,
        setIsData,
        toggle,
        handleSingleCheck,
        token,
        getLibraryListFuc,
        allPause,
        setAllPause,
        masteringType,
        wavType,
        startIndex,
        setStartIndex,
        userId,
        setLoadingPop,
        menuClickFunc,
        setLastLiId,
        menuClick,
    } = props;

    // download
    // check
    const [listCheck, setListCheck] = useState(false);
    const [downloadClick, setDownloadClick] = useState(false);

    const [tagClick, setTagClick] = useState(false);

    // 라이브러리  제목, 태그 변경 useState
    const [liNameInput, setLiNameInput] = useState('');
    const [liTagInput, setLiTagInput] = useState('');

    // Trial 다운 불가 팝업
    const [downErrorPopup, setDownErrorPopup] = useState(false);
    const downErrorData = {
        isState: downErrorPopup,
        setIsState: setDownErrorPopup,
        popText: 'Unable to download the trial file.',
        btnText: 'CLOSE',
    };

    const [downloadUrl, setDownloadUrl] = useState(null);

    // render는 한번만 되게
    let contents = document.querySelectorAll('#contents');

    const checkHandle = () => {
        setListCheck(!listCheck);
        handleSingleCheck(index);
    };

    // tag
    const tagClickFuc = () => {
        setTagClick(!tagClick);
    };

    // option
    const optionClick = (index) => {
        let getList = [...isData];
        getList[index].state = !getList[index].state;
        setIsData(getList);
    };
    // edit
    const EditClick = (index) => {
        // console.log('EditClick-isData', isData);
        // console.log('EditClick-index', index);
        // console.log('EditClick', isData[index]);
        if (isData[index].state) {
            setLastLiId(0);
            let getList = [...isData];
            getList[index].state = false;
            setIsData(getList);
            getList[index].inputState = !getList[index].inputState;
        }
    };

    // edit close
    const acceptClick = (index) => {
        let getList = [...isData];
        getList[index].inputState = false;
        setIsData(getList);
    };
    useEffect(() => {
        // console.log("데이터",isData[index])
        // 최초 바인딩
        setLiNameInput(name);
        if (wavType === 'music') {
            if (tag?.length > 0) {
                if (useFreePlan) {
                    setLiTagInput(tag + ' #freetrial');
                } else {
                    setLiTagInput(tag);
                }
            } else {
                if (useFreePlan) {
                    setLiTagInput('#' + masteringType + ' #freetrial');
                } else {
                    setLiTagInput('#' + masteringType);
                }
            }
        } else {
            if (useFreePlan) {
                setLiTagInput(tag + ' #freetrial');
            } else {
                setLiTagInput(tag);
            }
        }
    }, [isData]);

    // 라이브러리 제목, Tag 변경 Fetch
    const modifyNameTag = () => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + token);
        myHeaders.append('Content-Type', 'application/json');

        var raw = JSON.stringify({
            liId: liIdInput,
            liName: liNameInput,
            liTag: liTagInput,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        fetch(`${ip}api/library`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                setLastLiId(0);
                setTimeout(() => {
                    menuClickFunc(menuClick);
                }, 100);
            })
            .catch((error) => console.log('error', error));
    };

    // convert fetch - mp3  HYUNHA : 2023.04.11 API 변경으로 불필요
    const convertFileFetch = async () => {
        setLoadingPop(true);
        if (userId !== null) {
            var myHeaders = new Headers();
            myHeaders.append('Authorization', `Bearer ` + token);

            var formdata = new FormData();
            formdata.append('user_id', userId);
            formdata.append('target_ext', 'mp3');
            formdata.append('src_url', audioURL + name);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow',
            };

            const data = await fetch(`https://warpaiaudio.com:5002/convert_file`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    const res = JSON.parse(result);
                    setDownloadUrl(res.result_url);
                    return res;
                })
                .catch((error) => console.log('error', error));

            await fileDownload(data.result_url, 'mp3', setLoadingPop);
        }
    };

    // video handler
    // video click, tab click시 실행
    const videoClickHandler = (e, index) => {
        if (e.type === 'play') {
            contents.forEach((con) => {
                if (con.className.slice(17) !== `contents${index}`) {
                    con.pause();
                    setAllPause(true);
                }
            });
        }
    };

    const handleOptionClick = () => {
        setDownloadClick(false);
        if (useFreePlan) {
            setDownErrorPopup(true);
        } else {
            optionClick(index);
        }
    };

    return (
        <List key={index}>
            {downErrorPopup && <DefaultPopup data={downErrorData} />}
            <ListLeft>
                <ListCheckBox>
                    <ListCheckBoxInput type="checkbox" checked={toggle} onChange={checkHandle} />
                </ListCheckBox>
                {wavType === 'video' ? (
                    <VideoCon
                        className={`contents${index}`}
                        id={`contents`}
                        src={audioURL + encodeURIComponent(name)}
                        controls
                        playsInline
                        onPlay={(e) => {
                            videoClickHandler(e, index);
                        }}
                        // HYUNHA > mac에서는 video onFocus Event 가 먹지 않아 이벤트 변경
                    ></VideoCon>
                ) : (
                    <MusicCon>
                        <MasteringList
                            item={isData[index]}
                            fileIndex={index}
                            setAllPause={setAllPause}
                            allPause={allPause}
                            index={index}
                            startIndex={startIndex}
                            setStartIndex={setStartIndex}
                        />
                    </MusicCon>
                )}
            </ListLeft>
            <ListRight>
                <ListInfoWrap>
                    <ListTit>Name</ListTit>
                    <ListCon>
                        {isData[index].inputState ? (
                            <NameInput
                                type="text"
                                value={liNameInput}
                                placeholder={name}
                                active={isData[index].inputState}
                                onChange={(e) => {
                                    setLiNameInput(e.target.value);
                                }}
                            />
                        ) : (
                            <ListText className="name">{name}</ListText>
                        )}
                    </ListCon>
                    <OptionCon>
                        <OptionBtn
                            onClick={() => {
                                handleOptionClick();
                            }}
                        >
                            <OptionBtnImg src={OptionImg} alt="option" />
                        </OptionBtn>
                        {isData[index].inputState && (
                            <AcceptBtn
                                onClick={() => {
                                    acceptClick(index);
                                    modifyNameTag();
                                }}
                            >
                                <AcceptBtnImg src={AcceptImg} alt="accept" />
                            </AcceptBtn>
                        )}
                        <OptionListCon active={isData[index].state}>
                            <OptionListWrap>
                                <OptionList onClick={() => setDownloadClick(!downloadClick)}>
                                    <OptionListImg src={DownImg} alt="down" />
                                    Download
                                    {downloadClick && wavType !== 'video' ? (
                                        <DownloadCon>
                                            <DownloadWrap>
                                                <DownloadList
                                                    onClick={() => {
                                                        fileDownload(name, 'mp3', setLoadingPop);
                                                        // convertFileFetch();
                                                        setLoadingPop(true);
                                                    }}
                                                >
                                                    <DownloadLink>Mp3</DownloadLink>
                                                </DownloadList>
                                                <DownloadList
                                                    onClick={() => {
                                                        fileDownload(name, 'wav', setLoadingPop);
                                                        setLoadingPop(true);
                                                    }}
                                                >
                                                    <DownloadLink download="Wav">Wav</DownloadLink>
                                                </DownloadList>
                                            </DownloadWrap>
                                        </DownloadCon>
                                    ) : downloadClick && wavType === 'video' ? (
                                        <DownloadCon>
                                            <DownloadWrap>
                                                <DownloadList
                                                    onClick={() => {
                                                        fileDownload(name, name.slice(name.lastIndexOf('.') + 1), setLoadingPop);
                                                        setLoadingPop(true);
                                                    }}
                                                >
                                                    <DownloadLink>{name.slice(name.lastIndexOf('.') + 1)}</DownloadLink>
                                                </DownloadList>
                                            </DownloadWrap>
                                        </DownloadCon>
                                    ) : null}
                                </OptionList>
                                <OptionList
                                    onClick={() => {
                                        EditClick(index);
                                    }}
                                >
                                    <OptionListImg src={EditImg} alt="edit" />
                                    Edit
                                </OptionList>
                            </OptionListWrap>
                        </OptionListCon>
                    </OptionCon>
                </ListInfoWrap>

                {/* {type && (
                    <ListType>
                        <ListTit>Type</ListTit>
                        <ListCon>{type}</ListCon>
                    </ListType>
                )} */}
                {/* HYUNHA 사용되지않는것같아 주석처리함 */}

                <ListInfoWrap>
                    <ListTit>Date</ListTit>
                    <ListCon>{date[0] + '-' + date[1] + '-' + date[2]}</ListCon>
                </ListInfoWrap>

                <ListInfoWrap active={tagClick}>
                    <ListTit>Tag</ListTit>
                    <ListCon>
                        {!isData[index].inputState ? (
                            <ListText className="tag" active={tagClick}>
                                {!tagClick && liTagInput?.length > 35 ? liTagInput.substring(0, 35) + '...' : liTagInput}
                            </ListText>
                        ) : (
                            <TagInput placeholder={tag} active={isData[index].inputState} value={liTagInput} onChange={(e) => setLiTagInput(e.target.value)} />
                        )}
                    </ListCon>
                    {liTagInput?.length > 35 && !isData[index]?.inputState ? (
                        <TagBtn
                            type="button"
                            active={tagClick}
                            onClick={() => {
                                tagClickFuc();
                            }}
                        >
                            <TagMoreImg src={tagClick ? MinusImg : PlusImg} alt="more" />
                        </TagBtn>
                    ) : null}
                </ListInfoWrap>
            </ListRight>
        </List>
    );
};

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    /* margin: 0 auto; */
    padding: 200px 0 179px;
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    @media (max-width: 1297px) {
        padding: calc(100vw * (200 / 1280)) calc(100vw * (25 / 1280)) calc(100vw * (179 / 1280));
        width: 100%;
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (107 / 428)) calc(100vw * (25 / 428)) calc(100vw * (179 / 428));
    }
`;

const LibraryTit = styled.p`
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 101px;
    text-transform: uppercase;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (40 / 1280));
        margin-bottom: calc(100vw * (101 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (25 / 428));
        margin-bottom: calc(100vw * (48 / 428));
    }
`;

const LibraryTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1023px) {
        flex-direction: column-reverse;
    }
`;

const Left = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Right = styled(Left)`
    @media (max-width: 1023px) {
        align-self: flex-end;
        margin-bottom: calc(100vw * (20 / 428));
    }
`;

const CheckBox = styled.div`
    margin-right: 29px;

    @media (max-width: 1297px) {
        margin-right: calc(100vw * (29 / 1280));
    }

    @media (max-width: 1023px) {
        margin-right: calc(100vw * (14 / 428));
    }
`;

const CheckBoxInput = styled.input`
    width: 15px;
    height: 15px;
    border: 1px solid #444444;
    border-radius: 3px;
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:checked {
        border: 1px solid #005aa2;
        background-color: #005aa2;
    }

    &:checked::after {
        background: url(${CheckImg}) no-repeat center / cover;
    }

    @media (max-width: 1297px) {
        width: calc(100vw * (15 / 1280));
        height: calc(100vw * (15 / 1280));

        &::after {
            width: calc(100vw * (15 / 1280));
            height: calc(100vw * (15 / 1280));
        }
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (13 / 428));
        height: calc(100vw * (13 / 428));

        &::after {
            width: calc(100vw * (13 / 428));
            height: calc(100vw * (13 / 428));
        }
    }
`;

const MenuCon = styled.div`
    border: 1px solid #dddddd;
    border-radius: 50px;
    padding: 7px;

    @media (max-width: 1297px) {
        padding: calc(100vw * (7 / 1280));
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (5 / 428));
    }
`;

const MenuWrap = styled.ul`
    display: flex;
`;

const MenuList = styled.li`
    font-size: 14px;
    width: 130px;
    padding: 7px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    cursor: pointer;

    ${({ active }) => {
        return active
            ? `
        background-color: #005AA2;
        color: #fff;
        font-weight: bold;
        `
            : `
        background-color: #fff;
        color: #000;
        `;
    }}

    @media(max-width: 1297px) {
        font-size: calc(100vw * (14 / 1280));
        width: calc(100vw * (130 / 1280));
        padding: calc(100vw * (7 / 1280)) calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (11 / 428));
        width: calc(100vw * (85 / 428));
        padding: calc(100vw * (9 / 428)) calc(100vw * (18 / 428));
    }
`;

const SearchCon = styled.div`
    display: flex;
    position: relative;
    margin-right: 16px;

    @media (max-width: 1297px) {
        margin-right: calc(100vw * (16 / 1280));
    }

    @media (max-width: 1023px) {
        margin-right: calc(100vw * (20 / 428));
    }
`;

const SearchIconBox = styled(SearchCon)`
    position: absolute;
    top: 50%;
    right: 21px;
    transform: translateY(-50%);
    width: 17px;
    height: 17px;
    background: url(${SearchImg}) no-repeat center / contain;

    @media (max-width: 1297px) {
        right: calc(100vw * (21 / 1280));
        width: calc(100vw * (17 / 1280));
        height: calc(100vw * (17 / 1280));
    }

    @media (max-width: 1023px) {
        right: calc(100vw * (20 / 428));
        width: calc(100vw * (17 / 428));
        height: calc(100vw * (17 / 428));
    }
`;

const SearchInput = styled.input`
    font-family: 'Syncopate';
    width: 275px;
    font-size: 14px;
    padding: 14px 40px 14px 17px;
    border: 1px solid #dddddd;
    border-radius: 50px;

    @media (max-width: 1297px) {
        width: calc(100vw * (275 / 1280));
        font-size: calc(100vw * (14 / 1280));
        padding: calc(100vw * (14 / 1280)) calc(100vw * (40 / 1280)) calc(100vw * (14 / 1280)) calc(100vw * (17 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (234 / 428));
        font-size: calc(100vw * (12 / 428));
        padding: calc(100vw * (12 / 428)) calc(100vw * (37 / 428)) calc(100vw * (12 / 428)) calc(100vw * (20 / 428));
    }
`;

const TrashBtn = styled.button`
    width: 18px;
    height: 20px;

    @media (max-width: 1297px) {
        width: calc(100vw * (18 / 1280));
        height: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (18 / 428));
        height: calc(100vw * (20 / 428));
    }
`;

const TrashBtnImg = styled.img``;

const LibraryCon = styled.div`
    margin-top: 80px;

    @media (max-width: 1297px) {
        margin-top: calc(100vw * (80 / 1280));
    }

    @media (max-width: 1023px) {
        margin-top: calc(100vw * (14 / 428));
    }
`;

const AvailableText = styled.div`
    height: calc(100vh - 384px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #aaaaaa;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (18 / 1280));
        height: calc(100vh - calc(100vw * (384 / 1280)));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (12 / 428));
        height: calc(100vh - calc(100vw * (334 / 428)));
    }
`;

const ListWrap = styled.ul`
    overflow: auto;
    width: 100%;
    box-sizing: border-box;
    height: fit-content;
`;

const List = styled.li`
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    position: relative;
    padding-top: 40px;

    &:not(:last-child) {
        border-bottom: 1px solid #dddddd;
    }

    @media (max-width: 1297px) {
        padding-bottom: calc(100vw * (20 / 1280));
        padding-top: calc(100vw * (40 / 1280));
    }

    @media (max-width: 1023px) {
        flex-direction: column;
        padding-bottom: calc(100vw * (20 / 428));
        padding-top: calc(100vw * (20 / 428));
    }
`;

const ListLeft = styled.div`
    margin-right: 20px;
    position: relative;
    width: 50%;

    &div {
        padding-top: 21px;
    }

    @media (max-width: 1297px) {
        margin-right: calc(100vw * (30 / 1280));

        &div {
            padding-top: calc(100vw * (21 / 1280));
        }
    }

    @media (max-width: 1023px) {
        margin-right: 0;
        margin-bottom: calc(100vw * (21 / 428));
        width: 100%;

        &div {
            padding-top: 0;
        }
    }
`;

const ListCheckBox = styled.div`
    position: absolute;
    top: -18px;

    @media (max-width: 1297px) {
        top: calc(100vw * (-18 / 1280));
    }

    @media (max-width: 1023px) {
        top: calc(100vw * (0 / 428));
    }
`;

const ListCheckBoxInput = styled(CheckBoxInput)``;

const MusicCon = styled.div`
    padding-top: 21px;

    @media (max-width: 1297px) {
        padding-top: calc(100vw * (21 / 1280));
    }

    @media (max-width: 1023px) {
        padding-top: calc(100vw * (40 / 428));
    }
`;

const VideoCon = styled.video`
    width: 630px;
    margin-top: 21px;
    height: 153px;
    overflow: hidden;
    background-color: #000;

    @media (max-width: 1297px) {
        width: calc(100vw * (630 / 1280));
        margin-top: calc(100vw * (21 / 1280));
        height: calc(100vw * (153 / 1280));
    }

    @media (max-width: 1023px) {
        width: 82vw;
        margin-top: calc(100vw * (40 / 428));
        height: calc(100vw * (184 / 428));
    }
`;

const ListRight = styled.div`
    font-size: 16px;
    width: 100%;
    position: relative;
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (9 / 428));
    }
`;

const ListInfoWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1297px) {
    }

    @media (max-width: 1023px) {
    }
`;
const ListName = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* gap: 30px; */
    margin-right: 20px;
    margin-bottom: 20px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (10 / 428));
    }
`;

const ListTit = styled.div`
    font-weight: bold;
    text-transform: uppercase;
    color: #444444;
    min-width: 90px;

    @media (max-width: 1297px) {
        min-width: calc(100vw * (100 / 1280));
        /* width: calc(100vw * (64 / 1280)); */
        /* margin-right: calc(100vw * (24 / 1280)); */
    }

    @media (max-width: 1023px) {
        min-width: calc(100vw * (160 / 1280));
        /* width: calc(100vw * (45 / 428)); */
        /* margin-right: calc(100vw * (6 / 428)); */
    }
`;

const ListCon = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const ListText = styled.div`
    &.name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; /* 말줄임 적용 */
        width: 470px;
    }
    &.tag {
        color: #005aa2;
        word-break: break-all;
        padding-right: 10px;
    }
    @media (max-width: 1297px) {
        &.name {
            width: calc(100vw * (400 / 1297));
        }
    }

    @media (max-width: 1023px) {
        &.name {
            width: calc(100vw * (700 / 1023));
        }
    }
`;

const NameInput = styled.input`
    /* position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); */
    /* width: 90%; */
    border: 1px solid #dddddd;
    padding: 4px 16px;
    font-size: 16px;
    font-family: 'Syncopate';
    color: #444444;
    width: 470px;

    ${({ active }) => {
        return active ? `display: block;` : `display: none;`;
    }}

    @media(max-width: 1297px) {
        width: calc(100vw * (400 / 1297));
        padding: calc(100vw * (2.5 / 1280)) calc(100vw * (16 / 1280));
        font-size: calc(100vw * (16 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (700 / 1023));
        padding: calc(100vw * (2 / 428)) calc(100vw * (7 / 428));
        font-size: calc(100vw * (12 / 428));
    }
`;

const ListType = styled(ListName)``;

const ListDate = styled(ListName)`
    /* & span {
        margin-left: 12px;
    }

    @media (max-width: 1297px) {
        & span {
            margin-left: calc(100vw * (12 / 1280));
        }
    }

    @media (max-width: 1023px) {
        & span {
            margin-left: calc(100vw * (10 / 428));
        }
    } */
`;

const ListTag = styled(ListName)`
    margin-bottom: 0;

    & span {
        display: inline-block;
        margin: 0 10px 7px 0;
        font-size: 16px;
        color: #005aa2;
    }

    @media (max-width: 1297px) {
        & span {
            margin: 0 calc(100vw * (10 / 1280)) calc(100vw * (7 / 1280)) 0;
            font-size: calc(100vw * (16 / 1280));
        }
    }

    @media (max-width: 1023px) {
        & span {
            margin: 0 calc(100vw * (5 / 428)) calc(100vw * (7 / 428)) 0;
            font-size: calc(100vw * (9 / 428));
        }
    }
`;

const TagInput = styled.textarea`
    width: 470px;
    /* width: 100%;
    height: 100%; */
    min-height: 38px;
    /* position: absolute;
    top: 0;
    left: 0; */
    border: 1px solid #dddddd;
    padding: 9px 13px;
    font-size: 16px;
    font-family: 'Syncopate';
    color: #666666;

    &::-webkit-scrollbar {
        width: 14px;
    }

    &::-webkit-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: #eee;
    }

    ${({ active }) => {
        return active ? `display: block;` : `display: none;`;
    }}

    @media(max-width: 1297px) {
        width: calc(100vw * (400 / 1297));
        min-height: calc(100vw * (38 / 1280));
        padding: calc(100vw * (9 / 1280)) calc(100vw * (13 / 1280));
        font-size: calc(100vw * (16 / 1280));

        &::-webkit-scrollbar {
            width: calc(100vw * (14 / 1280));
        }
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (700 / 1023));
        min-height: calc(100vw * (27 / 428));
        padding: calc(100vw * (6 / 428));
        font-size: calc(100vw * (12 / 428));

        &::-webkit-scrollbar {
            width: calc(100vw * (14 / 428));
        }
    }
`;

const TagBtn = styled.button`
    width: 27px;
    height: 27px;
    /* position: absolute;
    right: 0px;
    top: 0px; */

    /* ${({ active }) => {
        return active
            ? `bottom: 0;`
            : `
        top: 50%;
        transform: translateY(-50%);
        `;
    }} */

    @media (max-width: 1297px) {
        width: calc(100vw * (27 / 1280));
        height: calc(100vw * (27 / 1280));
        right: 0px;
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (20 / 428));
        height: calc(100vw * (20 / 428));
        right: calc(100vw * (-40 / 428));
    }
`;

const TagMoreImg = styled.img`
    width: 27px;
    height: 27px;
    @media (max-width: 1297px) {
        width: calc(100vw * (27 / 1280));
        height: calc(100vw * (27 / 1280));
    }
    @media (max-width: 1023px) {
        width: calc(100vw * (20 / 428));
        height: calc(100vw * (20 / 428));
    }
`;

const OptionCon = styled.div`
    /* position: absolute;
    right: 0px;
    top: 0px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* z-index: 9999; */

    @media (max-width: 1297px) {
        /* right: 0; */
        /* top : 0; */
        /* top: calc(100vw * (20 / 1280)); */
    }

    @media (max-width: 1023px) {
        /* right: 0; */
        /* top: calc(100vw * (20 / 428)); */
        flex-direction: column;
    }
`;

const OptionBtn = styled.button`
    width: 27px;
    height: 27px;

    @media (max-width: 1297px) {
        width: calc(100vw * (27 / 1280));
        height: calc(100vw * (27 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (20 / 428));
        height: calc(100vw * (20 / 428));
    }
`;

const OptionBtnImg = styled.img``;

const AcceptBtn = styled(OptionBtn)`
    position: absolute;
    right: 0px;
    top: 50px;
    /* margin-top: 10px;

    @media (max-width: 1297px) {
        margin-top: calc(100vw * (10 / 1280));
    }

    @media (max-width: 1023px) {
        margin-top: 0;
        margin-top: calc(100vw * (10 / 1280));
        margin-right: calc(100vw * (5 / 428));
    } */
`;

const AcceptBtnImg = styled.img``;

const OptionListCon = styled.div`
    z-index: 9999;
    position: absolute;
    right: 2px;
    top: 38px;

    ${({ active }) => {
        return active ? `display: block;` : `display: none;`;
    }}

    @media(max-width: 1297px) {
        top: calc(100vw * (38 / 1280));
    }

    @media (max-width: 1023px) {
        top: calc(100vw * (35 / 428));
    }
`;

const OptionListWrap = styled.ul`
    z-index: 9999;
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
`;

const OptionList = styled.li`
    display: flex;
    padding: 10px 13px 10px 9px;
    color: #444444;
    font-size: 11px;
    background-color: #fff;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 90, 162, 0.05);
    }

    @media (max-width: 1297px) {
        padding: calc(100vw * (10 / 1280)) calc(100vw * (13 / 1280)) calc(100vw * (10 / 1280)) calc(100vw * (9 / 1280));
        font-size: calc(100vw * (11 / 1280));
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (10 / 428)) calc(100vw * (13 / 428)) calc(100vw * (10 / 428)) calc(100vw * (10 / 428));
        font-size: calc(100vw * (11 / 428));
    }
`;

const OptionListImg = styled.img`
    margin-right: 10px;
    width: 17px;
    height: 15px;

    @media (max-width: 1297px) {
        margin-right: calc(100vw * (9 / 1280));
        width: calc(100vw * (17 / 1280));
        height: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        margin-right: calc(100vw * (10 / 428));
        width: calc(100vw * (17 / 428));
        height: calc(100vw * (15 / 428));
    }
`;

const DownloadCon = styled.div`
    position: absolute;
    left: -68px;
    top: 0;

    @media (max-width: 1297px) {
        left: calc(100vw * (-68 / 1280));
    }

    @media (max-width: 1023px) {
        left: calc(100vw * (-68 / 428));
    }
`;

const DownloadWrap = styled.ul`
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
`;

const DownloadList = styled.li`
    font-size: 12px;
    color: #666666;
    padding: 10px 13px;
    transition: 0.3s;

    &:hover {
        background-color: rgba(0, 90, 162, 0.05);
    }

    @media (max-width: 1297px) {
        font-size: calc(100vw * (12 / 1280));
        padding: calc(100vw * (10 / 1280)) calc(100vw * (13 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (12 / 428));
        padding: calc(100vw * (10 / 428)) calc(100vw * (13 / 428));
    }
`;

const DownloadLink = styled.button`
    font-family: 'Syncopate';
`;
