//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

//css
import styled from 'styled-components';

//component
import Modal from '../../../server/Modal';

export default function BridgePopup({ data }) {
    const { mainState, setMainState, isSub, setIsSub, popText, blueText, SubBtnText, btnText } = data;

    return (
        <Container>
            <PopWrap>
                <PopText>{popText}</PopText>
                {blueText && <BlueText>{blueText}</BlueText>}
                <PopBtnWrap>
                    <SubBtn
                        type="button"
                        onClick={() => {
                            setIsSub(true);
                            setMainState(false);
                        }}
                    >
                        {SubBtnText}
                    </SubBtn>
                    <MainBtn
                        type="button"
                        onClick={() => {
                            setMainState(false);
                            Modal.modalClosed();
                        }}
                    >
                        {btnText}
                    </MainBtn>
                </PopBtnWrap>
            </PopWrap>
        </Container>
    );
}

const Container = styled.div`
    font-family: 'Pretendard';
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;

const PopWrap = styled.div`
    width: 460px;
    padding: 45px 0;
    text-align: center;
    border-radius: 3px;
    background-color: #fff;

    @media (max-width: 1297px) {
        width: calc(100vw * (460 / 1280));
        padding: calc(100vw * (45 / 1280)) 0;
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (378 / 428));
        padding: calc(100vw * (38 / 428)) calc(100vw * (32 / 428));
    }
`;

const PopText = styled.p`
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 500;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (10 / 1280));
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (10 / 428));
        font-size: calc(100vw * (13 / 428));
    }
`;

const BlueText = styled.p`
    font-family: 'Pretendard';
    font-size: 13px;
    font-weight: 500;
    color: #005aa2;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (13 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (11 / 428));
    }
`;

const PopBtnWrap = styled.div`
    display: flex;
    justify-content: center;
`;

const MainBtn = styled.button`
    background-color: #005aa2;
    border-radius: 3px;
    color: #fff;
    padding: 10px 68px;
    font-weight: bold;
    font-size: 15px;
    border: 1px solid transparent;
    margin-top: 20px;

    @media (max-width: 1297px) {
        padding: calc(100vw * (10 / 1280)) calc(100vw * (69 / 1280));
        font-size: calc(100vw * (15 / 1280));
        margin-top: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100% - calc(100vw * (15 / 428)) / 2);
        padding: calc(100vw * (12 / 428)) 0;
        font-size: calc(100vw * (13 / 428));
        margin-top: calc(100vw * (20 / 428));
    }
`;

const SubBtn = styled(MainBtn)`
    border: 1px solid #005aa2;
    background-color: #fff;
    color: #005aa2;
    margin-right: 10px;

    @media (max-width: 1297px) {
        margin-right: calc(100vw * (10 / 1280));
    }

    @media (max-width: 1023px) {
        margin-right: calc(100vw * (15 / 428));
    }
`;
