//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

//css
import styled from 'styled-components';

//component
import BasicBtn from '../common/Btn/BasicBtn';
import DefaultPopup from '../common/popup/DefaultPopup';

//img
import FindpwBg from '../../img/login/findpw_bg.png';

import serverController from '../../server/serverController';

export default function FindPwBody() {
    //링크 이동
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [findPw, setFindPw] = useState(false);

    // 이메일 fetch 성공하고 유저에게도 이메일 코드 전송 성공했다는 걸 알려주기 위함
    const SuccessFindPw = {
        isState: findPw,
        setIsState: setFindPw,
        popText: 'Your code has been sent successfully.',
        blueText: '*Please check your email.',
        btnText: 'CLOSE',
        url: '/login',
    };

    const emailFetch = () => {
        serverController.connectFetchController(`api/mypage/find/pwd?userEmail=${email}`, 'POST', null, null, (res) => {
            if (res.result === 1) {
                setFindPw(true);
            } else {
                alert('No matching information found.');
            }
        });
    };

    return (
        <>
            {findPw && <DefaultPopup data={SuccessFindPw} />}
            <Container>
                <Left></Left>
                <Right>
                    <Title>FORGOT PASSWORD?</Title>
                    <TextWrap>
                        <Text>
                            Enter the email address you used when you joined and <br /> we’ll send you instructions to reset your password.
                        </Text>
                        <Text>
                            For security reasons, we do NOT store your password. <br /> So rest assured that we will never send your password via email.
                        </Text>
                    </TextWrap>
                    <Form>
                        <EmailInput
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                        <BasicBtn onClick={emailFetch} color={false} bg={true} text="Send reset instructions" />
                    </Form>
                </Right>
            </Container>
        </>
    );
}

const Container = styled.div`
    padding-top: 100px;
    height: 1052px;
    display: flex;
    align-items: center;

    @media (max-width: 1297px) {
        padding-top: calc(100vw * (100 / 1280));
        height: calc(100vw * (1052 / 1280));
    }

    @media (max-width: 1023px) {
        min-height: 100vh;
        height: auto;
        justify-content: center;
        align-items: flex-start;
        padding-top: calc(100vw * (132 / 428));
        padding-bottom: calc(100vw * (321 / 428));
    }
`;

const Left = styled.div`
    width: calc(100vw * (950 / 1920));
    height: 100%;
    background: url(${FindpwBg}) no-repeat center / cover;

    @media (max-width: 1023px) {
        display: none;
    }
`;

const Right = styled.div`
    margin-left: calc(100vw * (117 / 1920));
    width: 472px;
    text-align: center;

    @media (max-width: 1297px) {
        margin-left: calc(100vw * (117 / 1280));
        width: calc(100vw * (472 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
        margin-left: 0;
        padding-left: calc(100vw * (25 / 428));
        padding-right: calc(100vw * (25 / 428));
    }
`;

const Title = styled.div`
    color: #005aa2;
    font-size: 60px;
    line-height: 79px;
    margin-bottom: 82px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (60 / 1280));
        line-height: calc(100vw * (79 / 1280));
        margin-bottom: calc(100vw * (82 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (40 / 428));
        line-height: calc(100vw * (45 / 428));
        margin-bottom: calc(100vw * (83 / 428));
    }
`;

const TextWrap = styled.div`
    margin-bottom: calc(100vw * (25 / 428));
`;

const Text = styled.p`
    font-family: 'Pretendard';
    font-size: 16px;
    font-weight: 500;
    color: #aaaaaa;
    text-align: left;
    margin-bottom: 30px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (16 / 1280));
        margin-bottom: calc(100vw * (30 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (14 / 428));
        margin-bottom: calc(100vw * (15 / 428));
        & br {
            display: none;
        }
    }
`;

const Form = styled.div`
    width: 428px;

    @media (max-width: 1297px) {
        width: calc(100vw * (428 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
    }
`;

const EmailInput = styled.input`
    width: 100%;
    background-color: #fafafa;
    font-size: 15px;
    border-radius: 3px;
    padding: 11px 0;
    margin-bottom: 20px;
    text-align: center;

    &::placeholder {
        color: #aaaaaa;
    }

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        padding: calc(100vw * (11 / 1280)) 0;
        margin-bottom: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        text-align: left;
        font-size: calc(100vw * (15 / 428));
        padding: calc(100vw * (11 / 428)) calc(100vw * (20 / 428));
        margin-bottom: calc(100vw * (20 / 428));
    }
`;
