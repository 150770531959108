//react
import { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Mobile, PC } from '../../media/MediaQuery';
import serverController from '../../server/serverController';

import { fileActionType } from '../../store/actionCreator';

// localStorage
import { setLocalS } from '../../utils/localStorageController';

//css
import styled from 'styled-components';

//component
import DefaultPopup from '../common/popup/DefaultPopup';
import LoadingPopup from './LoadingPopup';

// img
import MasteringBg from '../../img/mastering/mastering_bg.png';
import UploadImg from '../../img/mastering/mastering_box_img.png';
import PlusImg from '../../img/icon/icon_plus.svg';

export default function MasteringUploadBody() {
    // 링크 이동
    const history = useHistory();
    const { country } = useParams();

    const tokenData = sessionStorage.getItem('TOKEN');

    const [isOnClick, setIsOnClick] = useState(null);

    // 지원하는 형식이 아닐경우
    const [notFormat, setNotFormat] = useState(false);

    // 최대 지원용량 초과일경우 (pc만, 모바일에서는 해당 팝업 x)
    const [capacity, setCapacity] = useState(false);

    // 마스터링 실패 팝업 노출
    const [failMastering, setFailMastering] = useState(false);
    const [failMasteringMsg, setFailMasteringMsg] = useState('unload failed.');

    // 로그인 하지 않은 경우
    const [notUser, setNotUser] = useState(false);

    // 구독여부 팝업 핸들
    const [notPlan, setNotPlan] = useState(true);
    const [notPlanClick, setNotPlanClick] = useState(false);

    // 마스터링 1회권 사용가능여부: 사용불가(UNABLE) 사용가능(ABLE) -> 더이상 사용되지않는 값
    // const [useMastering, setUseMastering] = useState(null);
    // const [useSubscribe, setUseSubscribe] = useState(null);

    // HYUNHA : 마스터링 가능 여부 0:불가 1:가능(구독권) 2:가능(1회권) 3:가능(체험권)
    const [canMastering, setCanMastering] = useState(0);

    // background
    const [BgOn, setBgOn] = useState(false);
    const onClickInput = useRef(false); // 창이 켜졌는지 관리

    // 파일 업로드
    const [mainFile, setMainFile] = useState(null); // file upload

    // user
    const [userId, setUserId] = useState(null);

    //useFreePlan
    // - 0: 일반 구독권을 사용한 마스터링파일일 경우
    // - 1: 체험권을 사용한 마스터링파일일 경우
    const [useFreePlan, setUseFreePlan] = useState(0);

    // 파일이 업로드 될 때
    const [fileUploading, setFileUploading] = useState(false);

    // 1. 최초에 유저 id 값을 가져오기
    useEffect(() => {
        if (tokenData) {
            serverController.connectFetchController(
                `api/mypage/info`,
                'POST',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                        // console.log('1. 유저 ID setting', res);
                        setUserId(res.user.userId);
                    }
                },
            );
        }
    }, []);

    // 2. 마스터링 가능 여부 체크하기
    useEffect(() => {
        if (tokenData) {
            serverController.connectFetchController(
                `api/mypage/checkMastering`,
                'POST',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    // HYUNHA : 마스터링 체크 API 응답값에 따라 마스터링 여부 판별
                    // res.result : 0 => 1회권/구독권 없음 : 마스터링 불가
                    // res.result : 1 => 구독권 : 마스터링 가능
                    // res.result : 2 => 1회권 : 마스터링 가능
                    // console.log(
                    //     '2. 마스터링 가능 여부 체크  =>',
                    //     res.result === 0 ? '1회권/구독권 없음 : 마스터링 불가' : res.result === 1 ? '구독권 : 마스터링 가능' : '1회권 : 마스터링 가능',
                    // );
                    setCanMastering(res.result);
                },
            );
        } else {
            setNotUser(true);
            document.body.style.overflowY = 'hidden';
        }
    }, []);

    // 3. 체험권 소유 여부 체크하기
    useEffect(() => {
        if (tokenData) {
            serverController.connectFetchController(
                `api/user/hasFreeplan`,
                'GET',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    // console.log('3. 체험권 소유 여부 체크', res.data.hasFreePlan === 0 ? '체험권 사용 (마스터링 불가)' : '체험권 미사용 (마스터링 가능)');
                    // HYUNHA : 체험권 소유 여부 판별
                    // res.data.hasFreePlan : 0 => 체험권 사용 (마스터링 불가)
                    // res.data.hasFreePlan : 1 => 체험권 미사용 (마스터링 가능)
                    if (res.data.hasFreePlan === 1) {
                        //useFreePlan
                        // - 0: 일반 구독권을 사용한 마스터링파일일 경우
                        // - 1: 체험권을 사용한 마스터링파일일 경우
                        setUseFreePlan(1);
                        setCanMastering(3);
                    }
                },
            );
        } else {
            setNotUser(true);
            document.body.style.overflowY = 'hidden';
        }
    }, []);

    // s : popup-----------------------------------------------
    // 로그인 하지 않고 마스터링 페이지로 왔을 경우, 로그인 페이지로 이동 유도
    const notUserData = {
        isState: notUser,
        setIsState: setNotUser,
        popText: 'You need to log in.',
        // blueText: '* You need to log in.',
        btnText: 'LOGIN',
        url: '/login',
    };
    // 팝업 데이터
    const notFormatData = {
        isState: notFormat,
        setIsState: setNotFormat,
        popText: 'File format is not supported.',
        blueText: '*Please try with wav, aiff, mp4, mp3, aac, m4a or mov file.',
        btnText: 'close',
    };

    const capacityData = {
        isState: capacity,
        setIsState: setCapacity,
        popText: 'File size has been exceeded.',
        blueText: (
            <div>
                Individual file sizes are up to 200mb
                <br /> for audio files and 700mb for video files.
            </div>
        ),
        btnText: 'close',
    };

    // 플랜 구독, 1회권이 없는 경우
    const notPlanData = {
        isState: notPlan,
        setIsState: setNotPlan,
        popText: 'Use of mastering is only available to plan users.',
        blueText: 'Please try after purchasing the plan.',
        btnText: 'PLAN',
        url: '/payment/terms',
    };
    // 마스터링 실패
    const masteringErr = {
        isState: failMastering,
        setIsState: setFailMastering,
        popText: 'mastering error',
        blueText: failMasteringMsg,
        btnText: 'close',
    };
    // e : popup-----------------------------------------------

    // s : input focus(input창이 켜졌을 때 background 깔아주기, input 취소 버튼 눌렀을 때 background 꺼주기)-----------------------------------------------
    function action_open() {
        onClickInput.current = true;
        /* file dialog 켜졌을때 동작 함수 */
        setBgOn(true);
    }
    function action_close() {
        onClickInput.current = false;
        /* file dialog 껴졌을때 동작 함수 */
        setBgOn(false);
    }
    function onFocusWin() {
        /* 윈도우에 포커스가 갈때 이벤트 발생 함수 */
        if (onClickInput.current) {
            action_close();
        }
    }

    useEffect(() => {
        /* 컴포넌트가 마운트 되면 window에 focus 이벤트 부여 */
        window.addEventListener('focus', onFocusWin);
        return () => {
            /* 컴포넌트가 마운트 해제되면 window에 이벤트 제거 */
            window.removeEventListener('focus', onFocusWin);
        };
    }, []);
    // e : input focus(input창이 켜졌을 때 background 깔아주기, input 취소 버튼 눌렀을 때 background 꺼주기)-----------------------------------------------

    // s : drag and drop file upload----------------------------------
    const dragRef = useRef(null);

    const handleDrag = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        e.type === 'drop' && uploadHandler(e.dataTransfer.files);
    }, []);

    // mainFile에 유효성 검사(포맷,용량)를 거친 파일들이 담겼을 경우 진행
    useEffect(() => {
        // useEffect 최초 실행 방지 if문
        if (mainFile !== false && mainFile !== undefined && mainFile !== null) {
            // 파일의 개수만큼 하나하나 유효성 검사 실시
            // (1) 포맷 - aiff, wav, mp4, mov, mp3, acc (=vnd.dlna.adts), m4a
            // (2) 용량 - 음원(aiff, wav) 200mb, 영상(mp4,mov) 700mb
            for (let i = 0; i < mainFile.length; i++) {
                // console.log(mainFile[i].type);
                if (
                    mainFile[i].type !== 'audio/wav' &&
                    mainFile[i].type !== 'audio/x-wav' &&
                    mainFile[i].type !== 'audio/aiff' &&
                    mainFile[i].type !== 'audio/x-aiff' &&
                    mainFile[i].type !== 'audio/mp3' &&
                    mainFile[i].type !== 'audio/mpeg' &&
                    mainFile[i].type !== 'audio/aac' &&
                    mainFile[i].type !== 'audio/vnd.dlna.adts' &&
                    mainFile[i].type !== 'audio/x-m4a' &&
                    mainFile[i].type !== 'audio/mp4' &&
                    mainFile[i].type !== 'video/mp4' &&
                    mainFile[i].type !== 'video/quicktime'
                ) {
                    // mainFile 초기화
                    setMainFile(null);
                    return setNotFormat(true); // 포맷 경고 문구 팝업 노출
                } else if (
                    (mainFile[i].type === 'audio/wav' && mainFile[i].size > 200000000) ||
                    (mainFile[i].type === 'audio/x-wav' && mainFile[i].size > 200000000) ||
                    (mainFile[i].type === 'audio/aiff' && mainFile[i].size > 200000000) ||
                    (mainFile[i].type === 'audio/x-aiff' && mainFile[i].size > 200000000) ||
                    (mainFile[i].type === 'audio/mp3' && mainFile[i].size > 200000000) ||
                    (mainFile[i].type === 'audio/mpeg' && mainFile[i].size > 200000000) ||
                    (mainFile[i].type === 'audio/aac' && mainFile[i].size > 200000000) ||
                    (mainFile[i].type === 'audio/vnd.dlna.adts' && mainFile[i].size > 200000000) ||
                    (mainFile[i].type === 'audio/x-m4a' && mainFile[i].size > 200000000) ||
                    (mainFile[i].type === 'audio/mp4' && mainFile[i].size > 200000000) ||
                    (mainFile[i].type === 'video/mp4' && mainFile[i].size > 700000000) ||
                    (mainFile[i].type === 'video/quicktime' && mainFile[i].size > 700000000)
                ) {
                    // mainFile 초기화
                    setMainFile(null);
                    return setCapacity(true); // 용량 경고 문구 팝업 노출
                }
            } // 파일 유효성 검사 끝
            if (userId !== null) {
                // redux에 fileList 저장
                fileActionType.upFilesModify({
                    fileArray: mainFile,
                });
                setFileUploading(true); // 파일 업로드 모달 띄워주기 위함
                document.body.style.overflowY = 'hidden';

                const formData = new FormData();
                const myHeaders = new Headers();
                myHeaders.append('Authorization', `Bearer ${tokenData}`);

                const fileList = [...mainFile];

                // console.log("mainFile 배열화 =>", fileList)
                fileList.map((item) => formData.append('up_files', item));
                formData.append('user_id', userId);
                formData.append('use_free_plan', useFreePlan);
                // console.log('formData에 담긴 useFreePlan 값', useFreePlan);

                // use_free_plan 응답값
                // - 0: 일반 구독권을 사용한 마스터링파일일 경우
                // - 1: 체험권을 사용한 마스터링파일일 경우

                const requestOptions = {
                    method: 'POST',
                    body: formData,
                    headers: myHeaders,
                    redirect: 'follow',
                    timeout: 10 * 60 * 1000,
                };

                fetch('https://warpaiaudio.com:5002/ai_mastering_form2?', requestOptions)
                    .finally(() => {
                        // 2023.08.17 HYUNHA : log 저장 로직 추가
                        var object = {};
                        requestOptions.body.forEach(function (value, key) {
                            object[key] = value;
                        });
                        var now = new Date();
                        var newOBJ = {
                            time: now,
                            data: object,
                        };
                        var json = JSON.stringify(newOBJ);
                        myHeaders.append('Content-Type', 'application/json');
                        const requestOPT = {
                            method: 'POST',
                            body: JSON.stringify({ msg: json }),
                            headers: myHeaders,
                            redirect: 'follow',
                            timeout: 10 * 60 * 1000,
                        };
                        fetch('https://warpmastering.com:8091/api/mastering/log', requestOPT).then((response) => response.json());
                    })
                    // fetch('http://warpaiaudio.com:5004/ai_mastering_form?', requestOptions)
                    // fetch('https://warpaiaudio.com:5004/ai_mastering_form?', requestOptions)
                    // fetch('http://61.97.243.126:5004/ai_mastering_form?', requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        // console.log('result', result);
                        // 2023.08.17 HYUNHA : log 저장 로직 추가
                        var now = new Date();
                        var newOBJ = {
                            time: now,
                            data: result,
                        };
                        var json = JSON.stringify(newOBJ);
                        const requestOPT = {
                            method: 'POST',
                            body: JSON.stringify({ msg: json }),
                            headers: myHeaders,
                            redirect: 'follow',
                            timeout: 10 * 60 * 1000,
                        };
                        fetch('https://warpmastering.com:8091/api/mastering/log', requestOPT).then((response) => response.json());
                        // HYUNHA 2023.03.30 무한로딩에러처리
                        // result.result 응답값 Success / Fail
                        // result.work_files [업로드경로, duration]
                        if (result.result === 'Success') {
                            if (canMastering === 2) {
                                // 1회권 소진 처리
                                serverController.connectFetchController(
                                    `api/mypage/usingMastering`,
                                    'POST',
                                    null,
                                    {
                                        'Content-Type': `application/json`,
                                        Authorization: `Bearer ${tokenData}`,
                                    },
                                    (res) => {
                                        if (res.result === 2) {
                                            // 업로드 경로를 local에 저장
                                            setLocalS(
                                                'uploadFiles',
                                                result.work_files.map((el) => el[0]),
                                            );
                                            setFileUploading(false);
                                            history.push(`/${country}/mastering`); // 페이지 이동 (MasteringBody.js 파일)
                                        }
                                    },
                                );
                            } else {
                                // 업로드 경로를 local에 저장
                                setLocalS(
                                    'uploadFiles',
                                    result.work_files.map((el) => el[0]),
                                );
                                setFileUploading(false);
                                history.push(`/${country}/mastering`);
                                // 페이지 이동 (MasteringBody.js 파일)
                            }
                        }
                        if (result.results === 'Fail') {
                            // console.log(result);
                            setFileUploading(false);
                            setFailMastering(true);
                            setFailMasteringMsg(result.message);
                        }
                    })
                    .catch((error) => {
                        // 2023.08.17 HYUNHA : log 저장 로직 추가
                        console.warn('ERROR :', error);
                        var now = new Date();
                        var newOBJ = {
                            time: now,
                            data: error,
                        };
                        var json = JSON.stringify(newOBJ);
                        const requestOPT = {
                            method: 'POST',
                            body: JSON.stringify({ msg: json }),
                            headers: myHeaders,
                            redirect: 'follow',
                            timeout: 10 * 60 * 1000,
                        };
                        fetch('https://warpmastering.com:8091/api/mastering/log', requestOPT).then((response) => response.json());
                        setFileUploading(false);
                        setFailMastering(true);
                        setFailMasteringMsg('[mastering server error] please refresh the page and try again');
                    });
            }
        }
    }, [mainFile]);

    const initDragEvents = useCallback(() => {
        if (dragRef.current !== null) {
            dragRef.current.addEventListener('dragenter', handleDrag);
            dragRef.current.addEventListener('dragleave', handleDrag);
            dragRef.current.addEventListener('dragover', handleDrag);
            dragRef.current.addEventListener('drop', handleDrop);
        }
    }, [handleDrag, handleDrop]);

    const resetDragEvents = useCallback(() => {
        if (dragRef.current !== null) {
            dragRef.current.removeEventListener('dragenter', handleDrag);
            dragRef.current.removeEventListener('dragleave', handleDrag);
            dragRef.current.removeEventListener('dragover', handleDrag);
            dragRef.current.removeEventListener('drop', handleDrop);
        }
    }, [handleDrag, handleDrop]);

    useEffect(() => {
        initDragEvents();
        return () => resetDragEvents();
    }, [initDragEvents, resetDragEvents]);
    // e : drag and drop file upload----------------------------------

    // [HYUNHA] 마스터링 시 조건 정리 :
    // 이미 구독중이거나 1회권을 가진 유저는
    // 체험권이 있어도 1회권(구독권)으로 마스터링 진행되며
    // 마스터링 후 1회권은 자동 소멸된다.
    // 즉,
    // 체험권을 가지며 구독(1회)권이 없는 상태인 최초의 유저만 체험권 사용이 가능하다.
    // 체험권을 사용하지 않고 구독(1회)권을 구매하게되면 체험권을 사용할 기회를 잃게됨.
    const uploadHandler = (e) => {
        // e : input file click upload
        serverController.connectFetchController(
            `api/user/hasFreeplan`,
            'GET',
            null,
            {
                'Content-Type': `application/json`,
                Authorization: `Bearer ${tokenData}`,
            },
            (res) => {
                // 로그인 세션이 끊긴 상태에서 시도했을 경우
                if (res.msg === 'Access is denied' || res.msg === '접근이 거부되었습니다.') {
                    setNotUser(true);
                }
                // HYUNHA : 체험권 소유 여부 판별
                // hasFreePlan : 0 => 체험권 사용 (마스터링 불가)
                // hasFreePlan : 1 => 체험권 미사용 (마스터링 가능)
                else if (res.data.hasFreePlan === 1) {
                    // console.log('HYUNHA : 체험권 소유한 User가 타는 로직');
                    if (e.length > 1) {
                        alert('You can only upload up to 1 file.');
                    } else {
                        // HYUNHA : 체험권이 있어도 구독권(+1회권)을 가진 사람이면 체험권 사용이 아닌 일반 마스터링 진행을 위해 로직추가
                        serverController.connectFetchController(
                            `api/mypage/checkMastering`,
                            'POST',
                            null,
                            {
                                'Content-Type': `application/json`,
                                Authorization: `Bearer ${tokenData}`,
                            },
                            (res) => {
                                if (res.result === 1 || res.result === 2) {
                                    // console.log('체험권이 있어도 구독권(1회권) 보유 유저인 경우 체험권은 사용되지 않는다.');
                                    //useFreePlan
                                    // - 0: 일반 구독권을 사용한 마스터링파일일 경우
                                    // - 1: 체험권을 사용한 마스터링파일일 경우
                                    setUseFreePlan(0);
                                }
                                setMainFile(e);
                            },
                        );
                    }
                } else {
                    // console.log('HYUNHA : 체험권이 없는  User가 타는 로직');
                    serverController.connectFetchController(
                        `api/mypage/checkMastering`,
                        'POST',
                        null,
                        {
                            'Content-Type': `application/json`,
                            Authorization: `Bearer ${tokenData}`,
                        },
                        (res) => {
                            // HYUNHA : 1회권/구독권 소유 여부 판별
                            // checkMastering : 0 => 1회권/구독권 없음 : 마스터링 불가
                            // checkMastering : 1 => 구독권 : 마스터링 가능
                            // checkMastering : 2 => 1회권 : 마스터링 가능
                            if (res.result === 0) {
                                setNotPlanClick(true);
                            } else if (res.result === 1) {
                                if (e.length > 100) {
                                    // 파일들이 한번에 100개까지만 올릴 수 있어서, 100개 이상인 경우 튕겨주기
                                    alert('You can only upload up to 100 files.');
                                } else {
                                    setMainFile(e);
                                }
                            } else if (res.result === 2) {
                                if (e.length > 100) {
                                    alert('You can only upload up to 1 file.');
                                } else {
                                    setMainFile(e);
                                }
                            }
                        },
                    );
                }
            },
        );
    };

    return (
        <>
            {notFormat && <DefaultPopup data={notFormatData} />}
            {capacity && <DefaultPopup data={capacityData} />}
            {notUser && <DefaultPopup data={notUserData} />}
            {notPlanClick && <DefaultPopup data={notPlanData} />}
            {failMastering && <DefaultPopup data={masteringErr} />}
            {fileUploading && mainFile && <LoadingPopup fileName={mainFile} />}
            <Background active={BgOn} />
            <Container active={isOnClick}>
                <Wrap>
                    <MasteringTit>Mastering</MasteringTit>
                    <MasteringCon ref={dragRef}>
                        <UploadCon>
                            {isOnClick &&
                                (canMastering === 2 || canMastering === 3 ? (
                                    <UploadInput
                                        type="file"
                                        multiple={false}
                                        id="upload"
                                        accept=".wav, .aiff, .mp4, .mov, .mp3, .aac, .m4a"
                                        onChange={(e) => {
                                            setIsOnClick(true);
                                            uploadHandler(e.target.files);
                                        }}
                                    />
                                ) : (
                                    <UploadInput
                                        type="file"
                                        multiple={true}
                                        id="upload"
                                        accept=".wav, .aiff, .mp4, .mov, .mp3, .aac, .m4a"
                                        onChange={(e) => {
                                            if (canMastering !== 0) {
                                                setIsOnClick(true);
                                                uploadHandler(e.target.files);
                                            } else if (canMastering === 0) {
                                                setIsOnClick(false);
                                                setNotPlanClick(true);
                                            }
                                        }}
                                    />
                                ))}
                            <UploadLabel
                                htmlFor="upload"
                                onClick={() => {
                                    // HYUNHA : 마스터링 체크 api 변경
                                    if (canMastering !== 0) {
                                        setIsOnClick(true);
                                        action_open();
                                    } else if (canMastering === 0) {
                                        setIsOnClick(false);
                                        setNotPlanClick(true);
                                    }
                                }}
                            >
                                <UploadBtnImg src={PlusImg} />
                            </UploadLabel>
                        </UploadCon>
                        <PC>
                            <HereText>
                                Drop your track here <br />
                                Or click the ‘+’ to create a master
                            </HereText>
                        </PC>
                        <Mobile>
                            <HereText>click the ‘+’ to create a master</HereText>
                        </Mobile>
                        <FormatText>
                            Avoid over-compressing your track’s master bus to ensure that there’s enough headroom. <br /> Upload a high-quality file format
                            (such as WAV or AIFF) for best results.
                        </FormatText>
                    </MasteringCon>
                </Wrap>
            </Container>
        </>
    );
}

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    content: '';
    background-color: #000;
    opacity: 0.5;
    z-index: 999;
    ${({ active }) => (active ? 'display:block;' : 'display:none;')}
`;
const Container = styled.div`
    padding: 200px 0 296px;
    background: url(${MasteringBg}) no-repeat center / cover;
    position: relative;

    /* &::before {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    content: "";
    background-color: #000;
    opacity: 0.5;
    z-index: 999;

    ${({ active }) => {
        return active ? `display: block;` : 'display: none;';
    }} */

    @media (max-width: 1297px) {
        padding: calc(100vw * (200 / 1280)) 0 calc(100vw * (296 / 1280));
        width: 100%;
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (107 / 428)) 0 calc(100vw * (144 / 428));
    }
`;

const Wrap = styled.div`
    width: 1280px;
    margin: 0 auto;

    @media (max-width: 1297px) {
        padding: 0 calc(100vw * (25 / 1280));
        width: 100%;
    }

    @media (max-width: 1023px) {
        padding: 0 calc(100vw * (25 / 428));
    }
`;

const MasteringTit = styled.p`
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 76px;
    text-transform: uppercase;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (40 / 1280));
        margin-bottom: calc(100vw * (76 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (25 / 428));
        margin-bottom: calc(100vw * (145 / 428));
    }
`;

const MasteringCon = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const UploadCon = styled.div`
    width: 500px;
    height: 500px;
    background: url(${UploadImg}) no-repeat center / cover;
    position: relative;
    margin-bottom: 25px;

    @media (max-width: 1297px) {
        width: calc(100vw * (500 / 1280));
        height: calc(100vw * (500 / 1280));
        margin-bottom: calc(100vw * (25 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (266 / 428));
        height: calc(100vw * (266 / 428));
        margin-bottom: calc(100vw * (51 / 428));
    }
`;

const UploadInput = styled.input`
    width: 47px;
    height: 47px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;

    @media (max-width: 1297px) {
        width: calc(100vw * (47 / 1280));
        height: calc(100vw * (47 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (26 / 428));
        height: calc(100vw * (26 / 428));
    }
`;

const UploadLabel = styled.label`
    width: 47px;
    height: 47px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    @media (max-width: 1297px) {
        width: calc(100vw * (47 / 1280));
        height: calc(100vw * (47 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (26 / 428));
        height: calc(100vw * (26 / 428));
    }
`;

const UploadBtnImg = styled.img``;

const HereText = styled.p`
    font-size: 21px;
    line-height: 28px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (21 / 1280));
        line-height: calc(100vw * (28 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (16 / 428));
        line-height: calc(100vw * (23 / 428));
    }
`;

const FormatText = styled.p`
    font-size: 16px;
    font-weight: 500;
    font-family: 'Pretendard';
    line-height: 23px;
    margin-top: 20px;
    color: #666666;
    text-align: center;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (16 / 1280));
        line-height: calc(100vw * (23 / 1280));
        margin-top: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (14 / 428));
        line-height: calc(100vw * (23 / 428));
        margin-top: calc(100vw * (30 / 428));
    }
`;
