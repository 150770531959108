//react
import { useEffect, useState, useRef } from 'react';
import HtmlParser from 'react-html-parser';
import { Link, useHistory, useParams } from 'react-router-dom';
import serverController, { ip } from '../../server/serverController';

//css
import styled, { css } from 'styled-components';

//img
import MainBg from '../../img/main/main_bg.jpg';
import MainBgMb from '../../img/main/main_bg_mb.png';

//lang
import { useLangText, useLangStyle } from '../../utils/langugeController/langController';
import { KR, EN, CN, ES } from './language/MainBigBanner/combLang';

export default function MainBigBanner() {
    const langText = useLangText(KR, EN, CN, ES);
    const langStyle = useLangStyle;
    const { country } = useParams();
    const tokenData = sessionStorage.getItem('TOKEN');
    const [userId, setUserId] = useState(null);
    const history = useHistory();

    const movePage = () => {
        if (userId) {
            // 로그인 상태 > 마스터링 페이지 이동
            history.push(`/${country}/mastering/upload`);
        } else {
            // 로그인 안된 경우 > 회원가입 페이지 이동
            history.push(`/${country}/signup`);
        }
    };
    useEffect(() => {
        // console.log("useEffect1 : 유저 id 값 세팅")
        if (tokenData) {
            document.body.style.overflowY = 'auto';
            serverController.connectFetchController(
                `api/mypage/info`,
                'POST',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                        setUserId(res.user.userId);
                    }
                },
            );
        }
    }, []);

    return (
        <>
            <Container>
                <TextCon>
                    <TopText>Unleash Your creativity</TopText>
                    <MainText>
                        {`The Ai Audio Mastering,`} <br />
                        {`Warp`}
                    </MainText>
                    <SubText langStyle={langStyle} onClick={movePage}>
                        {langText.TrialText}
                    </SubText>
                    <StartBtn to={`/${country}/mastering/upload`}>START NOW</StartBtn>
                </TextCon>
            </Container>
        </>
    );
}

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    background: url(${MainBg}) no-repeat center / cover;
    z-index: 8;
    @media (max-width: 1279px) {
        height: 768px;
    }
    @media (max-width: 767px) {
        background: url(${MainBgMb}) no-repeat center / cover;
        height: 560px;
    }
`;

const TextCon = styled.div`
    max-width: 1280px;
    height: 100%;
    margin: 0 auto;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 100px;

    @media (max-width: 1279px) {
        width: 100%;
        /* padding: calc(100vw * (100 / 1280)) calc(100vw * (25 / 1280)) 0; */
        padding-left: 62px;
        padding-right: 40px;
    }

    @media (max-width: 767px) {
        padding: 0 20px;
        /* justify-content: flex-start; */
        /* padding: calc(100vh * (273 / 869)) calc(100vw * (25 / 428)) 0; */
    }
`;

const MainText = styled.p`
    font-size: 50px;
    font-weight: 700;
    /* text-shadow: 0 0 10px rgba(116, 154, 172, 0.76); */

    @media (max-width: 1279px) {
        font-size: 32px;
    }

    @media (max-width: 767px) {
        font-size: 24px;
    }
`;

const TopText = styled.p`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;

    @media (max-width: 1279px) {
        font-size: 18px;
        margin: calc(100vw * (27 / 1280)) 0 calc(100vw * (62 / 1280));

    }

    @media (max-width: 767px) {
        font-size: 16px;
        margin: calc(100vw * (21 / 428)) 0 calc(100vw * (30 / 428));
    }
`;

const SubText = styled.p`
    font-size: 24px;
    font-weight: 400;
    margin: 48px 0;

    @media (max-width: 1279px) {
        font-size: calc(100vw * (15 / 1280));
        margin: 32px 0;

        ${({ langStyle }) =>
            langStyle({
                kr: css``,
                en: css``,
                cn: css``,
                es: css``,
            })}
    }

    @media (max-width: 767px) {
        font-size: 16px;
        margin: 24px 0;
    }
`;

const StartBtn = styled(Link)`
    color: #000;
    background-color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 15px 26px;
    border-radius: 50px;
    min-width: 128px;

    @media (max-width: 1279px) {
        font-size: 14px;
        padding: 14px 26px;
        min-width: 164px;
    }

    @media (max-width: 767px) {
        font-size: 12px;
        padding: 12px 20px;
        min-width: 96px;
    }
`;
