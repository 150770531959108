export const EN = {
  TermsListData: [
    {
      Tit: `Use and Fair Use Policies`,
      Text: `WARP (hereinafter “WARP”)’s services, client software, and website (hereinafter “services”) were designed to be used by millions of users, and we believe that our users use the services responsibly. Read the Use and Fair Use Policies with WARP’s service policies.`,
    },
    {
      Tit: `The reader agrees to not misuse the services. For example, it is forbidden to engage in or even attempt the following actions with the services.`,
      Text: `Investigate, search, and test vulnerabilities in the system or network Avoid or violate security or certification measures Access, modify, or use unauthorized computer systems of WARP (or WARP’s service providers) or other areas to share services Hinder other users, hosts, or the network by sending viruses, overloading, flooding, spamming, or delivering an excess number of e-mails Plant malicious programs or distribute malicious programs with services Access or look up services in ways other than the openly supported interface (i.e., “scraping”) Communications, promotions, advertisements, or spam that are not authorized Publishing content that may be considered fraud, misunderstood, or infringe the rights of others Promote or advertise certain products or services without consent Publish and share illegally pornography or other data that show narrow-mindedness or are in favor of religious or racial hate Infringe the privacy of others or dishonor them Falsely or wrongfully explain a relationship with an individual or a group Attempt to decode, decipher, or go through reverse engineering of the software used to provide services Hinder the access of users, hosts, or networks in forms including, but not limited to, sending a virus, overloading, flooding, spamming, or delivering an excess number of e-mails, or attempting to hinder by inflicting harm or risk on others Provide the purchase information of a third party Abuse the services beyond the intended use, manuals, or rational guidelines of WARP Audio If a user provides or shares data with services or licenses without copyright, WARP may delete the data without warning. For detailed information, please refer to the service agreements.`,
    },
    {
      Tit: `Limitations on Use`,
      Text: `WARP has prepared these sets of policies for users to understand the intended use of the services, and to prevent the misuse and abuse of the unlimited availability of services as planned by the company. The services provided by the company may be used normally and rationally by individuals and experts, and are suited for general and rational individuals or experts. “Unlimited” refers to the type and level of use of the services. The scope does not extend to mastering an abnormally high number of tracks (commissions or non-use) compared to ordinary users, for individual or expert purposes in business. The range of “unlimited” only refers to general and rational individual and expert purposes.`,
    },
    {
      Tit: `Evaluations`,
      Text: `WARP assesses the use of users by comparing the amount of use with the general amount of use of other users. Based on these assessments, WARP may decide that a particular user is using the services abnormally and irrationally compared to general users. Although we would like to refrain from setting certain limits, regularly mastering eight or more tracks per day, or mastering 240 or more tracks per month, or releasing 20 or more tracks per month, may be considered an abnormal or irrational use of our services. We understand that you may irregularly use our services when you do not need to master or release a number of tracks. This is considered when assessing and reviewing your use of the services.`,
    },
    {
      Tit: `Notifications`,
      Text: `When it is considered that there is an abnormal or irrational use of WARP services by a particular user, the company will reach you (by e-mail on your WARP account) to notify you of your amount of use, to edit the amount of use or provide you with an opportunity to sign up for greater services. WARP also has additional rights such as immediately blocking you and your account, or even closing down your account. WARP has prepared these sets of policies for users to understand the intended use of the services, and to prevent the misuse and abuse the unlimited availability of services as planned by the company.`,
    },
    {
      Tit: `WARP is for general and rational individuals or experts`,
      Text: `The services provided based on the plans of the company are for general and rational individual or expert use only. “Unlimited” refers to the type and level of use of the services. The scope does not extend to mastering an abnormally high number of tracks (commissions or non-use) compared to ordinary users, for individual or expert purposes in business. The range of “unlimited” only refers to general and rational individual and expert purposes.`,
    },
    {
      Tit: `Results`,
      Text: `The results of audio mastering may vary in quality according to the quality of the mix. If the mix has poor quality, the results of mastering will also have poor quality. To ensure the best quality, please refer to the tutorial on the website. The noise elimination feature for the video and dialogue may vary in quality based on the quality of the original sound source. The noise elimination service is not necessarily able to completely eliminate all noise in the original sound source, but rather, it will eliminate the average types of noise that may occur in generic video recordings. The final mastering results may also vary based on the quality of the original video.`,
    },
  ],
  CopyRight: `Copyright information © 
WARP Inc. 2022 
No part or content of this website may be reproduced, copied, or edited without written agreement from the author.
You may share this website in one of the following ways. Use the “Share” icon on the WARPmastering.com website, provide a back link or URL for the content to be distributed, or quote an excerpt from the website WARPmastering.com. For other sharing inquiries, please contact contact@warpmastering.com.You may not commercially use or distribute the contents on the website without written consent from the author.`,
};
