export const KR = {
    MainText: '오디오에 관한 최상의 솔루션',
    MainSubText: '음악, 오디오북, 비디오 등 오디오가 포함된 모든 컨텐츠에 대한<br/>고품질의 쾌적한 오디오 솔루션을 경험하세요.',
    NoiseText: '노이즈 제거와 마스터링이 통합된 체험',
    NoiseSubText: '업로드와 동시에 컨텐츠의 오디오를 분석하여 노이즈를 제거하고<br/>마스터링까지 한 번에 실행하는 쾌적한 작업 흐름을 체험해 보세요.',
    EveryoneText: '누구나 사용 가능한 편리함',
    EveryoneSubText: '전문적인 지식과 훈련 없이도 누구나 최적의 결과물을 만들어 낼 수 있습니다.<br/> 워프의 진보된 AI가 당신의 작품을 완성합니다.',
    IdeaText: '크리에이터의 상상이 바로 작품으로',
    IdeaSubText: '전세계 모든 크리에이터들을 위해 워프가 선사하는<br/>인공지능 오디오 퍼포먼스를 경험해 보세요.',
    AiText: '프로페셔널을 위한 AI audio mastering',
    AiSubText:
        '업계 표준의 규격으로 마스터링을 하는 워프의 AI 마스터링은 전문가 수준의 마스터링 서비스를 실현합니다. <br/>프로를 위한 인공지능 마스터링 서비스를 경험해 보세요.',
    UserText: '사용자의 생각 그대로',
    UserSubText:
        '사용자가 선택 가능한 다양한 옵션으로 결과물의 성격을 조정합니다.<br/> HIGH BOOST, BALANCED, PUNCH에서 작품의 성향대로 선택하세요. <br/>또한, 워프에서 지원하는 레퍼런스 마스터링을 통해<br/> 좀 더 구체적인 커스터마이즈도 가능합니다.',
    EasyText: '즉각적이고, 쉽고, 저렴하게',
    EasySubText:
        '마스터링 스튜디오 작업을 위한 비용과 시간은 더 이상 필요 없습니다. 복잡한 절차 없이 바로<br/>결과물을 받아보세요. 누구나 쉽게 <br/>사용할 수 있는 간편한 사용법으로, 파일을 올리기만 하면 끝입니다.',
    MasterText: '당신의 MASTER PIECE를 한 곳에',
    MasterSubText: '워프는 당신의 Master Piece를 위한 라이브러리를 제공합니다. <br/>당신의 라이브러리에서 결과물을 관리하고 공유할 수 있습니다.',
    AudioText: '오디오가 포함된 모든 컨텐츠',
    AudioSubText: '비디오, 팟캐스트, 오디오북 등 오디오가 포함된 모든 컨텐츠를 위한 최적의 오디오 솔루션을 제공합니다.',
    LevelText: '한 번에 잡음을 제거하고<br/>레벨을 맞추는 놀라운 체험',
    LevelSubText:
        '오디오에서 불필요한 주변음과 잡음을 제거하고<br/> 중요한 내용은 <br/>더 선명하게 만들어 최적의 레벨로 맞춰줍니다. <br/>이 모든 것을 한 번에 할 수 있습니다.',
    OvercomeText: '복잡한 오디오 장비를 극복 할 수 있습니다.',
    OvercomeSubText:
        '고가의 장비를 구비하거나 복잡한 프로그램을 공부 할 필요 없습니다. <br/>당장 가지고 있는 휴대폰으로 테스트 해 보세요. 워프는 가능한 최고의 결과물을 만들어 냅니다.',
};
