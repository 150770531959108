//react
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//css
import styled from 'styled-components';

// uploadingListCon
import trashImg from '../../img/icon/icon_trash.svg';
import UploadImg from '../../img/icon/icon_uploading.svg';

//serverController
import serverController from '../../server/serverController';

// localStorage
import { getLocalS } from '../../utils/localStorageController';
import DefaultPopup from './popup/DefaultPopup';

export default function UploadToolBar(props) {
    const { uploadFiles } = props;

    const [serverErrPop, setServerErrPop] = useState(false); //서버에러팝업
    const [uploadingClick, setUploadingClick] = useState(true);
    const file = useSelector((state) => state.uploadFiles); // 파일 가져오기

    const getUpFile = getLocalS('uploadFiles');

    const lang = useSelector((state) => state.language.lang);
    const history = useHistory();
    //cookies

    const TokenData = sessionStorage.getItem('TOKEN');
    // 파일 업로드 버튼 클릭시 배경 어둡게, 파일 업로드 후 / 업로드 취소 버튼 클릭시 배경 원래대로
    // upload 컨테이너 fixed
    const [scrollActive, setScrollActive] = useState(true);

    const [uploadProgressFiles, setUploadProgressFiles] = useState([]);

    const uploadingListClick = (index) => {
        history.push({
            pathname: `/${lang}/mastering`,
            state: { mainFile: file?.file?.resultArr[index] },
        });
    };
    // HYUNHA: 서버 에러 팝업
    const serverErr = {
        isState: serverErrPop,
        setIsState: setServerErrPop,
        popText: 'please refresh the page and try again.',
        btnText: 'close',
    };
    // s : percent api fetch
    useEffect(() => {
        if (TokenData) {
            serverController.connectFetchController(
                `api/mypage/info`,
                'POST',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${TokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                        if (uploadFiles.length > 0 && res.user.userId) {
                            const timer = setInterval(async () => {
                                const ress = await getProgress(res.user.userId);
                                setUploadProgressFiles(ress?.work_files);
                                if (!ress?.work_files.length) clearInterval(timer);
                            }, 1000);
                        }
                    } else {
                        console.log(res)
                    }
                },
            );
        }
    }, [uploadFiles]);

    const getProgress = (userId) => {
        const formData = new FormData();
        formData.append('user_id', userId);
        for (var i = 0; getUpFile.length > i; i++) {
            formData.append('work_file', getUpFile[i]);
        }

        const requestOptions = {
            method: 'POST',
            body: formData,
            redirect: 'follow',
        };

        return fetch('https://warpaiaudio.com:5002/get_progress2', requestOptions)
            .then((response) => response.json())
            .then((result) => result)
            .catch((error) => {
                console.log('error', error);
                    setServerErrPop(true)
                    // alert('[mastering error] please refresh the page and try again');
            });
    };

    return (
        <>
            {serverErrPop && <DefaultPopup data={serverErr} />}
            {uploadProgressFiles?.length > 0 && TokenData ? (
                <UploadingCon scrollOn={scrollActive} className="uploadingCon">
                    <UploadingTitCon onClick={() => setUploadingClick(!uploadingClick)}>
                        <UploadingTit active={uploadingClick}>
                            {uploadingClick ? 'Previews ready' : `Mastering “file ${uploadProgressFiles?.length}”`}
                            <UploadingNum>
                                ({uploadProgressFiles?.length}/{file?.file?.typeArray?.length})
                            </UploadingNum>
                        </UploadingTit>
                        <UploadingImg scrollOn={scrollActive} active={uploadingClick} src={UploadImg} alt="upload" />
                    </UploadingTitCon>
                    <UploadingListCon active={uploadingClick}>
                        <UploadingListWrap>
                            {uploadingClick &&
                                uploadProgressFiles?.map((item, index) => (
                                    <UpladingList key={index} onClick={() => uploadingListClick(index)}>
                                        <ListName>{item[0].slice(0, 20)}...</ListName>
                                        <ListStateCon>
                                            <ListLoading>{item[2] !== 100 ? 'mastering...' : null}</ListLoading>
                                            <ListPer>{item[2]}%</ListPer>
                                            {/* <TrashBtn type="button">
                                                <ListTrash src={trashImg} alt="trash" />
                                            </TrashBtn> */}
                                        </ListStateCon>
                                    </UpladingList>
                                ))}
                        </UploadingListWrap>
                    </UploadingListCon>
                </UploadingCon>
            ) : null}
        </>
    );
}

const UploadingListCon = styled.div`
    padding: 0 20px 22px;

    ${({ active }) => {
        return active ? `display: block;` : `display: none;`;
    }}

    @media(max-width: 1297px) {
        padding: 0 calc(100vw * (20 / 1280)) calc(100vw * (22 / 1280));
    }

    @media (max-width: 1023px) {
        padding: 0 calc(100vw * (20 / 428)) calc(100vw * (19 / 428));
    }
`;

const UploadingListWrap = styled.ul`
    max-height: 310px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        border-radius: 10px;
    }

    @media (max-width: 1297px) {
        max-height: calc(100vw * (310 / 1280));

        &::-webkit-scrollbar {
            width: calc(100vw * (5 / 1280));
        }
    }

    @media (max-width: 1023px) {
        max-height: calc(100vw * (310 / 428));

        &::-webkit-scrollbar {
            width: calc(100vw * (5 / 428));
        }
    }
`;

const UpladingList = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 38px;
    }

    @media (max-width: 1297px) {
        &:not(:last-child) {
            margin-bottom: calc(100vw * (38 / 1280));
        }
    }

    @media (max-width: 1023px) {
        &:not(:last-child) {
            margin-bottom: calc(100vw * (38 / 428));
        }
    }
`;

const ListName = styled.p`
    font-size: 14px;
    text-transform: uppercase;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (14 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
    }
`;

const ListStateCon = styled.div`
    display: flex;
    align-items: center;
`;

const ListLoading = styled.p`
    font-family: 'Pretendard';
    font-size: 11px;
    color: #666666;
    margin-right: 27px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (11 / 1280));
        margin-right: calc(100vw * (27 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (11 / 428));
        margin-right: calc(100vw * (29 / 428));
    }
`;

const ListPer = styled.p`
    font-size: 13px;
    font-weight: bold;
    color: #25599d;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (13 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
    }
`;

const TrashBtn = styled.button`
    width: 18px;
    height: 20px;

    @media (max-width: 1297px) {
        width: calc(100vw * (18 / 1280));
        height: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (18 / 428));
        height: calc(100vw * (20 / 428));
    }
`;

const ListTrash = styled.img``;

const UploadingCon = styled.div`
    transition: 0.3s;
    position: fixed;
    visibility: visible;
    z-index: 999;
    //right: calc(100vw * (404 / 1920));
    right: calc(100vw * (104 / 1920));
    bottom: 0;
    width: 499px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #f5f5f5;
    background-color: #fff;

    ${({ scrollOn }) => {
        return scrollOn
            ? `opacity: 1;
        `
            : `opacity: 0;
        `;
    }}

    &.hide {
        visibility: hidden;
    }

    @media (max-width: 1297px) {
        right: calc(100vw * (81 / 1280));
        width: calc(100vw * (499 / 1280));
    }

    @media (max-width: 1023px) {
        right: calc(100vw * (25 / 428));
        width: calc(100% - calc(100vw * (50 / 428)));
    }
`;

const UploadingTitCon = styled.div`
    font-size: 15px;
    padding: 17px 20px;
    font-weight: bold;
    color: #005aa2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        padding: calc(100vw * (17 / 1280)) calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
        padding: calc(100vw * (17 / 428)) calc(100vw * (20 / 428));
    }
`;

const UploadingTit = styled.p`
    text-transform: uppercase;
`;

const UploadingNum = styled.span`
    margin-left: 8px;

    @media (max-width: 1297px) {
        margin-left: calc(100vw * (8 / 1280));
    }

    @media (max-width: 1023px) {
        margin-left: calc(100vw * (10 / 428));
    }
`;

const UploadingImg = styled.img`
    width: 24px;
    height: 24px;

    ${({ active }) => {
        return active ? `transform: rotate(180deg);` : `transform: rotate(0deg);`;
    }}

    @media(max-width: 1297px) {
        width: calc(100vw * (24 / 1280));
        height: calc(100vw * (24 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (24 / 428));
        height: calc(100vw * (24 / 428));
    }
`;
