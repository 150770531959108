import { useEffect } from "react";
import { Mobile, PC } from "../../media/MediaQuery"

//css
import styled from "styled-components"

// component
import HeaderWhite from "../../component/common/HeaderWhite"
import HeaderMb from "../../component/common/HeaderMb"
import TermsBody from "../../component/terms/TermsBody"
import Footer from "../../component/common/Footer"

export default function TermsPage(){

    return(
        <>
            <PC>
                <HeaderWhite/>
            </PC>
            <Mobile>
                <HeaderMb/>
            </Mobile>
            <TermsBody/>            
            <Footer/>
        </>
    );

}