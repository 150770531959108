//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import serverController from '../../server/serverController';

//css
import styled from 'styled-components';

//component
import TermsList from '../../component/terms/TermsList';

// lang
import { useLangText, useLangList, useLangAPI } from '../../utils/langugeController/langController';
import { KR, EN, CN, ES } from './language/PrivacyBody/combLang';
import HtmlParser from 'react-html-parser';

export default function PrivacyBody() {
    const termList = useLangList('TermsListData', { KR, EN, CN, ES });
    const langText = useLangText(KR, EN, CN, ES);
    const langAPI = useLangAPI({ path: `api/policy/privacy/list` });
    return (
        <Container>
            <TermsTit>Privacy policy</TermsTit>
            <TermsList item={langAPI} />
            <Info>{langText.info}</Info>
        </Container>
    );
}

const Container = styled.div`
    padding: 200px 0;
    width: 1280px;
    margin: 0 auto;

    @media (max-width: 1297px) {
        width: 100%;
        padding: calc(100vw * (200 / 1280)) calc(100vw * (25 / 1280));
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (111 / 428)) calc(100vw * (25 / 428)) calc(100vw * (100 / 428));
    }
`;

const TermsTit = styled.div`
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 100px;
    text-transform: uppercase;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (40 / 1280));
        margin-bottom: calc(100vw * (100 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (20 / 428));
        margin-bottom: calc(100vw * (55 / 428));
    }
`;

const Info = styled.p`
    color: #005aa2;
    font-size: 16px;
    margin-top: 124px;
    line-height: 24px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (16 / 1280));
        margin-top: calc(100vw * (124 / 1280));
        line-height: calc(100vw * (24 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (11 / 428));
        margin-top: calc(100vw * (57 / 428));
        line-height: calc(100vw * (18 / 428));
    }
`;
