//modal
const body = document.body;

function modalOpen() {
    body.style.overflow = 'hidden';
}

function modalClosed() {
    body.style.overflow = 'auto';
}

export default { modalOpen, modalClosed };