export const EN = {
    MainText: 'The Best Solution for Audio',
    MainSubText: 'Experience high-quality, excellent audio solutions for all audio content,<br/> such as music, audio books, and videos.',
    NoiseText: 'Experience Integrated<br/> Noise Cancelling <br/>and Mastering',
    NoiseSubText: 'Experience a convenient workflow with analysis of the audio<br/> of the content as soon as it is uploaded, along with mastering.',
    EveryoneText: 'Convenience that<br/> Anyone Can Enjoy',
    EveryoneSubText: 'Experience a convenient workflow with analysis<br/>  of the audio of the content as soon as <br/> it is uploaded, along with mastering.',
    IdeaText: 'The Creator’s Imagination<br/>  Comes to Life',
    IdeaSubText: 'Experience the AI audio performance provided by WARP for all creators around the world.',
    AiText: 'AI Audio Mastering for Professionals',
    AiSubText:
        'WARP’s AI mastering follows the industry standards to provide you with expert-level mastering services.<br/> Experience AI mastering services for professionals.',
    UserText: 'Your Ideas in Front of You',
    UserSubText:
        'You can select from various options to change different parts of the results.<br/> Select from various ranges of options in HIGH BOOST, BALANCED, and PUNCH. <br/>In addition, you can use the reference mastering from WARP to customize <br/> your audio results in even more detail.<br/>',
    EasyText: 'Fast, Easy, and Cheap',
    EasySubText:
        'You no longer need to spend money and time<br/> to work on mastering in the studio.<br/> Receive results immediately without<br/> going through complex steps.<br/> It’s easy and can be used by anyone.<br/> All you need to do is upload your file.<br/>',
    MasterText: 'Your MASTER PIECE<br/> in One Place',
    MasterSubText: 'WARP provides a library for your Master Pieces.<br/> You can manage and share your works in the library.',
    AudioText: 'All Contents with Audio',
    AudioSubText: 'We provide the optimal audio solution for all types of content with audio,<br/> including videos, podcasts, and audiobooks.',
    LevelText: 'Amazing Experience of Eliminating<br/> Unwanted Sounds and Adjusting Levels',
    LevelSubText:
        'It eliminates unnecessary sounds and makes important sounds clearer<br/><br/> so that the audio reaches the optimal level.<br/> This can be done all at once.<br/>',
    OvercomeText: 'No More Complicated<br/> Audio Equipment',
    OvercomeSubText:
        'You don’t need to purchase expensive equipment or <br/>study complicated programs. Just try it out with your phone.<br/> WARP will give you the best possible results.',
};
