import { createStore } from 'redux';
import modules from './modules';

const configureStore = () => {
    const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
    const store = createStore(modules, devTools);
    // store.subscribe(() => localStorage.setItem('reduxState', JSON.stringify(store.getState())));

    return store;
};

export default configureStore;
