//react

//css
import styled from 'styled-components';

// component
import loading from '../../img/mastering/loading.gif';

export default function DownloadPopup() {
    return (
        <Container>
            <PopWrap>
                <BlueText>
                    Mastering <br /> Files downloading
                </BlueText>
                <LoadingImg src={loading} alt="loding"></LoadingImg>
                <BlueText>downloading …</BlueText>
            </PopWrap>
        </Container>
    );
}

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;

const PopWrap = styled.div`
    width: 408px;
    height: 591px;
    padding: 90px 0;
    text-align: center;
    border-radius: 10px;
    background-color: #fff;

    & p:nth-child(3) {
        font-size: 16px;

        @media (max-width: 1297px) {
            font-size: calc(100vw * (25 / 1280));
        }

        @media (max-width: 1023px) {
            font-size: calc(100vw * (11 / 428));
        }
    }
    & p:nth-child(4) {
        font-size: 30px;
        margin-top: 4px;

        @media (max-width: 1297px) {
            font-size: calc(100vw * (30 / 1280));
        }

        @media (max-width: 1023px) {
            font-size: calc(100vw * (21 / 428));
        }
    }

    @media (max-width: 1297px) {
        width: calc(100vw * (408 / 1280));
        height: calc(100vw * (591 / 1280));
        padding: calc(100vw * (90 / 1280)) 0;
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (65 / 428)) 0;
        width: calc(100vw * (285 / 428));
        height: calc(100vw * (413 / 428));
    }
`;
const BlueText = styled.p`
    font-family: 'Syncopate';
    font-size: 20px;
    font-weight: bold;
    color: #005aa2;
    line-height: 1.7;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (13 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (11 / 428));
    }
`;
const LoadingImg = styled.img`
    width: 170px;
    margin: 50px 0 70px;

    @media (max-width: 1297px) {
        width: calc(100vw * (170 / 1280));
        margin: calc(100vw * (50 / 1280)) 0 calc(100vw * (70 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (115 / 428));
        margin: calc(100vw * (40 / 428)) 0 calc(100vw * (50 / 428));
    }
`;
