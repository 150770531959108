//react
import { useEffect, useState, useRef } from 'react';

// wavesurfer
import WaveSurfer from 'wavesurfer.js';

//img
import PauseCoral from '../../../img/main/pausecoral.svg';
import PauseMint from '../../../img/main/pausemint.svg';
import PlayCoral from '../../../img/main/playcoral.svg';
import PlayMint from '../../../img/main/playmint.svg';
import MainWaveBG from '../../../img/main/wave_bg.png';

//audio
import MusicBefore from '../../../media/M_BEFORE.mp3';
import MusicAfter from '../../../media/M_AFTER.mp3';
import DialogueBefore from '../../../media/D_BEFORE_D.mp3';
import DialogueAfter from '../../../media/D_AFTER_D.mp3';

//css
import styled from 'styled-components';

export default function MainPlayer ({ id, setAllPause, allPause=false}) {
    const [playingBefore, setPlayingBefore] = useState(false);
    const [playingAfter, setPlayingAfter] = useState(false);
    const waveformA = useRef()
    const waveformB = useRef()
    
    useEffect(() => {
        waveformB.current = WaveSurfer.create({
            container: `#waveform${id}before`,
            height: 70,
            barWidth: 2,
            barRadius: 3,
            barGap: 2,
            progressColor: ['#19A9B2'],
            responsive: true,
            waveColor: 'rgba(25, 169, 178, 0.30)',
            hideScrollbar: true,
            cursorColor:'transparent'
        });
        const trackbefore = document.querySelector(`#track${id}before`);
        waveformB.current.load(trackbefore);
        waveformA.current = WaveSurfer.create({
            container: `#waveform${id}after`,
            height: 70,
            barWidth: 2,
            barRadius: 3,
            barGap: 2,
            progressColor: ['#EE6462'],
            responsive: true,
            waveColor: 'rgba(251, 186, 182, 0.68)',
            hideScrollbar: true,
            cursorColor:'transparent'
        });
        const trackafter = document.querySelector(`#track${id}after`);
        waveformA.current.load(trackafter);
    }, []);
    
    // all pause
    useEffect(() => {
        if (waveformA.current&&waveformB.current&&allPause) {
            waveformA.current.pause();
            waveformB.current.pause();
            setPlayingBefore(false);
            setPlayingAfter(false);
        }
    }, [allPause]);

    const handlePlayBefore = () => {
        const prev = playingBefore;
        setAllPause(true)
        setTimeout(() => {
            if(!prev) {
                waveformB.current.playPause();
                setAllPause(false);
                setPlayingBefore(!playingBefore);
    }
        }, 100);
    };
    const handlePlayAfter = () => {
        const prev = playingAfter;
        setAllPause(true)
        setTimeout(() => {
            if(!prev) {
                waveformA.current.playPause();
                setAllPause(false);
                setPlayingAfter(!playingAfter);
    }
        }, 100);
    };
    
    return (
        <ListCon>
            <PlayBtnCon>
                <SectionSubTitle>
                    <PlayBtnWrap>
                        <PlayTitle>original</PlayTitle>
                        <PlayBtnBefore onClick={()=>{handlePlayBefore()}}>
                            {playingBefore && !allPause ? <PlayBtnImg src={PauseMint} alt={'play pause'} />:<PlayBtnImg src={PlayMint} alt={'play pause'} />}
                            {playingBefore && !allPause ? 'Pause':'Play'}
                        </PlayBtnBefore>
                    </PlayBtnWrap>
                </SectionSubTitle>
                <SectionSubTitle onClick={()=>{handlePlayAfter()}}>
                    <PlayBtnWrap>
                        <PlayTitle>mastered </PlayTitle>
                        <PlayBtnAfter>
                            {playingAfter && !allPause ? <PlayBtnImg src={PauseCoral} alt={'play pause'} />:<PlayBtnImg src={PlayCoral} alt={'play pause'} />}
                            {playingAfter && !allPause ? 'Pause':'Play'}
                        </PlayBtnAfter>
                    </PlayBtnWrap>
                </SectionSubTitle>
            </PlayBtnCon>
            <MusicCon>
                <AudioWrap>
                    <MusicWrapAfter id={`waveform${id}after`}></MusicWrapAfter>
                    {
                        id === 'music' ?
                        <audio id={`track${id}after`} crossOrigin="anonymous" autoPlay={false} muted={true} src={MusicAfter} />
                        :
                        <audio id={`track${id}after`} crossOrigin="anonymous" autoPlay={false} muted={true} src={DialogueAfter} />
                    }
                    <MusicWrapBefore id={`waveform${id}before`}></MusicWrapBefore>
                    {
                        id === 'music' ?
                        <audio id={`track${id}before`} crossOrigin="anonymous" autoPlay={false} muted={true} src={MusicBefore} />
                        :
                        <audio id={`track${id}before`} crossOrigin="anonymous" autoPlay={false} muted={true} src={DialogueBefore} />
                    }
                </AudioWrap>
            </MusicCon>
        </ListCon>
    )
}


const ListCon = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;
const PlayTitle = styled.div`
    @media (max-width: 767px) {
        width: 130px;
    }
`
const PlayBtnBefore = styled.div`
    box-sizing: border-box;
    width: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    border-radius: 60px;
    border: 1px solid #018487;
    cursor: pointer;

    color: #018487;
    font-family: 'Pretendard';
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    @media (max-width: 1297px) {
        width: 98px;
        padding: 8px 18px;
        font-size: 12px;
        line-height: 16px;
    }

    @media (max-width: 767px) {
        width: 80px;
        padding: 6px 18px;
        font-size: 11px;
        line-height: 14px;
    }
`
const PlayBtnAfter = styled.div`
    box-sizing: border-box;
    width: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    border-radius: 60px;
    border: 1px solid #EE6462;
    cursor: pointer;

    color: #EE6462;
    font-family: 'Pretendard';
    font-size: 14px;
    font-weight: 700;
    @media (max-width: 1297px) {
        width: 98px;
        padding: 8px 18px;
        font-size: 12px;
        line-height: 16px;
    }

    @media (max-width: 767px) {
        width: 80px;
        padding: 6px 18px;
        font-size: 11px;
        line-height: 14px;
    }
`
const PlayBtnCon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 48px;
  
    @media (max-width: 1297px) {
        gap: 40px;
    }

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }
`;
const PlayBtnWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  
    @media (max-width: 1297px) {
        gap: 16px;
    }

    @media (max-width: 767px) {
        gap: 12px;
    }
`;

const PlayBtnImg = styled.img`
    width: 20px;
    height: 20px;
    @media (max-width: 767px) {
        width: 16px;
        height: 16px;
    }
 `;

const MusicCon = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    height: 160px;
    overflow: hidden;
    background: url(${MainWaveBG}) no-repeat center / cover;
    border: 1px solid #D7D7D7;
    border-radius: 12px;
    margin-top: 20px;
    padding: 18px 28px;

    @media (max-width: 1297px) {
        height: 92px;
        max-width: 100%;
    }

    @media (max-width: 767px) {
        height: 84px;
        max-width: 100%;
    }
`;

const MusicWrapBefore = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    overflow: hidden;
    width: 100%;
`;
const MusicWrapAfter = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    overflow: hidden;
    width: 100%;
`;
const AudioWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const SectionSubTitle = styled.div`
    color: #121212;
    font-size: 24px;
    font-weight: 700;

    @media (max-width: 1279px) {
        font-size: 18px;
    }

    @media (max-width: 767px) {
        font-size: 16px;
    }
`;