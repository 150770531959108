//react
import { useEffect, useState, useRef } from 'react';

//css
import styled, { css } from 'styled-components';

//lang
import { useLangText } from '../../utils/langugeController/langController';
import { KR, EN, CN, ES } from './language/MainSection12/combLang';
import MainPlayer from '../common/list/MainPlayer';

export default function MainSection10() {
    const langText = useLangText(KR, EN, CN, ES);
    const [allPause, setAllPause] = useState(false);

    return (
        <>
            <Container>
                <SectionWrap>
                    <SectionTitle>Music Mastering</SectionTitle>
                    <SectionContent>{langText.music1}<br/>{langText.music2}</SectionContent>
                    <Line/>
                    <MainPlayer id={'music'} setAllPause={setAllPause} allPause={allPause} />
                </SectionWrap>
                <SectionWrap>
                    <SectionTitle>Noise Reduction & Audio Mastering</SectionTitle>
                    <SectionContent>{langText.noise}</SectionContent>
                    <Line/>
                    <MainPlayer id={'noise'} setAllPause={setAllPause} allPause={allPause} />
                </SectionWrap>
            </Container>
        </>
    );
}

const Container = styled.div`
    position: relative;
    width: 100%;
    background-color: white;
    z-index: 5;
    padding: 100px 0px;
    @media (max-width: 1279px) {
        padding: 60px 0px;
    }

    @media (max-width: 767px) {
        padding: 66px 0px;
    }
`;
const SectionWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    
    padding: 100px 0px;

    color: #018487;

    @media (max-width: 1279px) {
        padding: 60px 40px;
    }

    @media (max-width: 767px) {
        padding: 34px 20px;
    }
`;
const SectionTitle = styled.div`
    color: #018487;
    font-size: 32px;
    font-weight: 700;

    @media (max-width: 1279px) {
        font-size: 24px;
    }

    @media (max-width: 767px) {
        font-size: 20px;
    }
`;
const SectionContent = styled.div`
    font-family: 'Pretendard';
    color: #121212;
    font-size: 24px;
    font-weight: 400;
    margin-top: 24px;
    margin-bottom: 40px;

    @media (max-width: 1279px) {
        font-size: 16px;
        margin-top: 12px;
        margin-bottom: 32px;
    }

    @media (max-width: 767px) {
        font-size: 14px;
        margin-bottom: 24px;
    }
`;
const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin-bottom: 60px;
    @media (max-width: 1279px) {
        margin-bottom: 40px;
    }
    @media (max-width: 767px) {
        margin-bottom: 24px;
    }

`;