//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

//component
import serverController, { ip } from '../../server/serverController';
import sha256 from 'sha256';
import DefaultPopup from '../common/popup/DefaultPopup';

//css
import 'swiper/swiper-bundle.css';
import styled from 'styled-components';

//img
import PlusIcon from '../../img/payment/payment_plus_icon.png';
import MinusIcon from '../../img/payment/payment_minus_icon.png';
import VerifyOIcon from '../../img/payment/payment_verify_icon.png';
import VerifyXIcon from '../../img/payment/payment_verifyX_icon.png';

import PaymentContainerImg from '../../img/payment/payment_container_img.png';
import PaymentContainerImgMb from '../../img/payment/payment_container_img_mb.png';
import CardIcon from '../../img/icon/icon_card.svg';
import CheckedImg from '../../img/icon/icon_checked.svg';
import AlertImg from '../../img/icon/icon_alert.svg';
import BridgePopup from '../common/popup/BridgePopup';
import LoadingPopup from '../common/popup/LoadingPopup';

export default function PaymentBody() {
    const { country, planId } = useParams();

    const tokenData = sessionStorage.getItem('TOKEN');

    const [isLoading, setIsLoading] = useState(false);
    const [loadingPercent, setloadingPercent] = useState(0);

    const [isChecked, setIsChecked] = useState(false);
    const [isFirstNameVal, setIsFirstNameVal] = useState('');
    const [isLastNameVal, setIsLastNameVal] = useState('');
    const [isCardActiveA, setIsCardActiveA] = useState(false);
    const [isCardActiveB, setIsCardActiveB] = useState(false);
    const [isCardNumVal, setIsCardNumVal] = useState('');
    const [isMonthVal, setIsMonthVal] = useState('');
    const [isYearVal, setIsYearVal] = useState('');
    const [isCvcVal, setIsCvcVal] = useState('');
    const [isBtnOn, setIsBtnOn] = useState(false);
    const [codeState, setCodeState] = useState(false);
    const [eventCode, setEventCode] = useState('');
    const [discount, setDiscount] = useState(0);

    const [clickSubmit, setClickSubmit] = useState(false);
    const [notAvailableCode, setNotAvailableCode] = useState(false);
    const [availableCode, setAvailableCode] = useState(false);
    const [verify, setVerify] = useState(null);
    const [noVerifyCheck, setnoVerifyCheck] = useState(false);
    const [passCodeEvent, setPassCodeEvent] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);

    //caution
    const [caution, setCaution] = useState(false);
    const [secondCaution, setSecondCaution] = useState(false);
    const [codeCaution, setCodeCaution] = useState(false);
    const [cardNumCaution, setCardNumCaution] = useState(false);
    const [monthCaution, setMonthCaution] = useState(false);
    const [yearCaution, setYearCaution] = useState(false);
    const [cvcCaution, setCvcCaution] = useState(false);

    // focus
    const [firstNameFoucus, setFirstNameFoucus] = useState(false);
    const [lastNameFocus, setLastNameFocus] = useState(false);
    const [codeFocus, setCodeFocus] = useState(false);
    const [numFocus, setNumFocus] = useState(false);
    const [monthFocus, setMonthFocus] = useState(false);
    const [yearFocus, setYearFocus] = useState(false);
    const [cvcFocus, setCvcFocus] = useState(false);

    // data
    const [planName, setPlanName] = useState(null);
    const [planPay, setPlanPay] = useState(0); // 결제될 금액
    const [currentPlanPay, setCurrentPlanPay] = useState(0); // 정가
    const [userData, setUserData] = useState([]);
    const [countryData, setCountryData] = useState(null);
    const [langData, setLangData] = useState('KR');

    // date string
    const date = new Date();

    // 월간 정기결제 코드
    const [monthMidKey, setMonthMidKey] = useState('');
    const [monthSecretKey, setMonthSecretKey] = useState('');

    // 정상 코드 입력 후 검사 했을 때 출력하는 팝업
    const AvailableCodeData = {
        isState: availableCode,
        setIsState: setAvailableCode,
        popText: 'Code has been successfully applied.',
        btnText: 'OK',
    };
    // 유효하지 않은 코드 입력 후 검사 했을 때 출력하는 팝업
    const notAvailableCodeData = {
        isState: notAvailableCode,
        setIsState: setNotAvailableCode,
        popText: 'Please check the coupon code again.',
        btnText: 'OK',
    };

    // 코드 입력 후 검사 없이 결제 시도할 때 출력하는 팝업
    const subscribeCheckData = {
        mainState: noVerifyCheck,
        setMainState: setnoVerifyCheck,
        setIsSub: setPassCodeEvent,
        popText: 'Please select the verify button to apply the code.',
        SubBtnText: `Proceed anyway`,
        btnText: 'OK',
    };

    useEffect(() => {
        if (passCodeEvent) {
            payFunc();
        }
    }, [passCodeEvent]);

    useEffect(() => {
        // country 변경 시 결제 국가 변경
        if (country === 'kr') {
            setCountryData('KRW');
            setLangData('KR');
        } else if (country === 'en') {
            setCountryData('USD');
            setLangData('EN');
        } else if (country === 'es') {
            setCountryData('EUR');
            setLangData('EN');
        } else {
            // 중국일 때
            setCountryData('CNY');
            setLangData('CN');
        }

        if (country === 'kr') {
            setMonthMidKey('11ED799565');
            setMonthSecretKey('385601286502495402D650F753B41165');
        } else {
            setMonthMidKey('1455558834');
            setMonthSecretKey('AB109FFC34021D19EAD3401126460E34');
        }
    }, [country]);

    useEffect(() => {
        if (tokenData) {
            serverController.connectFetchController(
                `api/mypage/info`,
                'POST',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                        setUserData(res.user);
                    } else {
                    }
                },
            );
        }
    }, []);

    useEffect(() => {
        paySetting();
    }, [planId]);
    // 할인

    useEffect(() => {
        if (
            isFirstNameVal !== '' &&
            isLastNameVal !== ''
            // &&
            // (isCardActiveA || isCardActiveB) &&
            // isChecked &&
            // isCardNumVal !== '' &&
            // isMonthVal !== '' &&
            // isYearVal !== '' &&
            // isCvcVal !== ''
        ) {
            if (eventCode.length > 0) {
            }
            setIsBtnOn(true);
        } else {
            setIsBtnOn(false);
        }
    }, [isFirstNameVal, isLastNameVal]);

    // 유효성
    useEffect(() => {
        if (isFirstNameVal === '' && clickSubmit) {
            setCaution(true);
        } else {
            setCaution(false);
        }
        if (isLastNameVal === '' && clickSubmit) {
            setSecondCaution(true);
        } else {
            setSecondCaution(false);
        }
        // if (isCardNumVal === '' && clickSubmit) {
        //     setCardNumCaution(true);
        // } else {
        //     setCardNumCaution(false);
        // }
        // if (isMonthVal === '' && clickSubmit) {
        //     setMonthCaution(true);
        // } else {
        //     setMonthCaution(false);
        // }
        // if (isYearVal === '' && clickSubmit) {
        //     setYearCaution(true);
        // } else {
        //     setYearCaution(false);
        // }
        // if (isCvcVal === '' && clickSubmit) {
        //     setCvcCaution(true);
        // } else {
        //     setCvcCaution(false);
        // }
    }, [isFirstNameVal, isLastNameVal]);

    // REF 고유 값 생성

    const [refData, setRefData] = useState(null);
    useEffect(() => {
        function uuidv4() {
            return 'xxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        }
        setRefData(uuidv4());
    }, []);

    const paySetting = () => {
        // 정상가
        if (planId == 1) {
            // 1회성 - One piece Price
            setPlanName('One piece Price');
            if (country === 'kr') {
                setPlanPay(10000);
                setCurrentPlanPay(10000);
            } else {
                setPlanPay(9);
                setCurrentPlanPay(9);
            }
        } else if (planId == 2) {
            // 연간 - Annual plan 2024-02-07 One year Plan 으로 이름 변경
            setPlanName('One year Plan');
            if (country === 'kr') {
                setPlanPay(170000);
                setCurrentPlanPay(170000);
            } else {
                setPlanPay(144);
                setCurrentPlanPay(144);
            }
        } else if (planId == 3) {
            // 월간 - Monthly plan
            setPlanName('Monthly plan');
            if (country === 'kr') {
                setPlanPay(47000);
                setCurrentPlanPay(47000);
            } else {
                setPlanPay(39);
                setCurrentPlanPay(39);
            }
        }
        // if (planId == 1) {
        // 30% 할인가
        //     // 1회성 - One piece Price
        //     setPlanName('One piece Price');
        //     if (country === 'kr') {
        //         setPlanPay(7000);
        //         setCurrentPlanPay(7000);
        //     } else {
        //         setPlanPay(6);
        //         setCurrentPlanPay(6);
        //     }
        // } else if (planId == 2) {
        //     // 연간 - Annual plan
        //     setPlanName('Annual plan');
        //     if (country === 'kr') {
        //         setPlanPay(119000);
        //         setCurrentPlanPay(119000);
        //     } else {
        //         setPlanPay(100);
        //         setCurrentPlanPay(100);
        //     }
        // } else if (planId == 3) {
        //     // 월간 - Monthly plan
        //     setPlanName('Monthly plan');
        //     if (country === 'kr') {
        //         setPlanPay(32900);
        //         setCurrentPlanPay(32900);
        //     } else {
        //         setPlanPay(27);
        //         setCurrentPlanPay(27);
        //     }
        // }
    };
    // 사용자가 입력한 코드의 유효성 검사
    const checkCoupon = () => {
        const rawData = JSON.stringify({
            serialNumber: eventCode,
        });

        serverController.connectFetchController(
            `api/checkCoupon`,
            'POST',
            rawData,
            {
                'Content-Type': `application/json`,
                Authorization: `Bearer ${tokenData}`,
            },
            (res) => {
                if (res.result === 1) {
                    console.log('res.result 1', res.result);
                    console.log('currentPlanPay', currentPlanPay);
                    console.log('discountRate', res.data.discountRate);
                    console.log(currentPlanPay - Math.ceil((currentPlanPay * res.data.discountRate) / 100));
                    setDiscount(res.data.discountRate);
                    setPlanPay(currentPlanPay - Math.ceil((currentPlanPay * res.data.discountRate) / 100));
                    setNotAvailableCode(false);
                    setAvailableCode(true);
                    setVerify(true);
                    setIsReadOnly(true);
                } else {
                    console.log('else', res.result);
                    setDiscount(0);
                    setEventCode('');
                    setNotAvailableCode(true);
                    setVerify(false);
                }
            },
        );
    };

    const editCode = () => {
        setIsReadOnly(false);
        setDiscount(0);
        setEventCode('');
        setNotAvailableCode(true);
        setVerify(false);
        paySetting();
    };

    const preCheck = () => {
        setIsBtnOn(false);
        if (eventCode && !verify) {
            setnoVerifyCheck(true);
            setIsBtnOn(true);
            return;
        }
        payFunc();
    };

    useEffect(() => {
        let intervalFunc = null;
        if (isLoading) {
            intervalFunc = setInterval(() => {
                setloadingPercent(loadingPercent + 5);
            }, 5000);
        } else {
            setloadingPercent(0);
        }
        return () => clearInterval(intervalFunc);
    }, [isLoading, loadingPercent]);
    // eximbay
    const payFunc = () => {
        setIsBtnOn(false);
        setIsLoading(true);
        if (planId == 1 || planId == 2) {
            var url =
                'https://secureapi.eximbay.com/Gateway/BasicProcessor.krp?ver=230&mid=218CFC6811&cur=' +
                countryData +
                '&txntype=PAYMENT&ref=' +
                planName?.replace(/ /g, '_') +
                refData +
                '&amt=' +
                planPay +
                '&buyer=' +
                isFirstNameVal +
                isLastNameVal +
                '&email=' +
                userData.userEmail +
                '&tel=00000000000&lang=' +
                langData +
                '&returnurl=' +
                `${ip}payment/complete` +
                `&statusurl=${ip}payment/complete&item_0_product=` +
                planName?.replace(/ /g, '_') +
                '&item_0_quantity=1&item_0_unitPrice=' +
                planPay +
                '&shipTo_city=' +
                langData +
                '&shipTo_country=' +
                langData +
                '&shipTo_firstName=' +
                isFirstNameVal +
                '&shipTo_lastName=' +
                isLastNameVal +
                '&shipTo_phoneNumber=00000000000&shipTo_postalCode=00000&shipTo_street1=-&displaytype=R&fgkey=' +
                hashValue +
                '&param1=' +
                `${eventCode}` +
                '&param2=' +
                userData.userEmail;

            // 1회권 결제
            // 연간 결제
            const formData = new FormData();
            formData.append('ver', '230');
            formData.append('mid', '218CFC6811');
            formData.append('cur', countryData); // * user의 나라 데이터
            formData.append('txntype', 'PAYMENT');
            formData.append('ref', planName?.replace(/ /g, '_') + refData);
            formData.append('amt', planPay); // * 플랜 가격
            formData.append('buyer', isFirstNameVal + isLastNameVal); // * user 이름
            formData.append('email', userData.userEmail); // * user 이메일
            formData.append('tel', '00000000000');
            formData.append('lang', langData); // * user의 나라
            formData.append('returnurl', `${ip}payment/complete`);
            formData.append('statusurl', `${ip}payment/complete`);
            formData.append('item_0_product', planName?.replace(/ /g, '_')); // * 플랜 이름
            formData.append('item_0_quantity', 1);
            formData.append('item_0_unitPrice', planPay);
            formData.append('shipTo_city', langData);
            formData.append('shipTo_country', langData);
            formData.append('shipTo_firstName', isFirstNameVal);
            formData.append('shipTo_lastName', isLastNameVal);
            formData.append('shipTo_phoneNumber', '00000000000');
            formData.append('shipTo_postalCode', '00000');
            formData.append('shipTo_street1', '-');
            formData.append('displaytype', 'R');
            formData.append('fgkey', hashValue);
            formData.append('url', url);
            formData.append('param1', eventCode);
            formData.append('param2', userData.userEmail);

            var requestOptions = {
                method: 'POST',
                body: formData,
                redirect: 'follow',
            };
            fetch(`${ip}api/checkOrder?`, requestOptions)
                .then((response) => {
                    return response.json();
                })
                .then((result) => {
                    if (result.result === 0) {
                        window.location.href = url;
                    }
                    if (result.result === 1) {
                        window.location.href = result.url;
                    }
                })
                .catch((error) => console.log('error', error))
                .finally(() => {
                    setIsBtnOn(true);
                    setIsLoading(false);
                });
        } else {
            // 월별 정기결제
            // 국내
            //  MID: 11ED799565
            //  시크릿키 : 385601286502495402D650F753B41165
            // 해외
            //  MID: 1455558834;
            //  시크릿키: AB109FFC34021D19EAD3401126460E34;

            const formDatam = new FormData();

            var url2 =
                // 'https://secureapi.eximbay.com/Gateway/BasicProcessor.krp?ver=230&mid=1455558834&cur=' +
                `https://secureapi.eximbay.com/Gateway/BasicProcessor.krp?ver=230&mid=${monthMidKey}&cur=` +
                countryData +
                '&txntype=PAYMENT&ref=' +
                planName?.replace(/ /g, '_') +
                refData +
                '&amt=' +
                planPay +
                '&buyer=' +
                isFirstNameVal +
                isLastNameVal +
                '&email=' +
                userData.userEmail +
                '&tel=00000000000&lang=' +
                langData +
                '&returnurl=' +
                `${ip}payment/completeSub` +
                '&statusurl=' +
                `${ip}payment/completeSub` +
                '&item_0_product=' +
                planName?.replace(/ /g, '_') +
                '&item_0_quantity=1&item_0_unitPrice=' +
                planPay +
                '&shipTo_city=' +
                langData +
                '&shipTo_country=' +
                langData +
                '&shipTo_firstName=' +
                isFirstNameVal +
                '&shipTo_lastName=' +
                isLastNameVal +
                '&shipTo_phoneNumber=00000000000&shipTo_postalCode=00000&shipTo_street1=-&displaytype=P&fgkey=' +
                hashMonthValue +
                '&recurringPayment=Y&recurringAmount=' +
                planPay +
                '&recurringURL=' +
                `${ip}payment/completeSub` +
                '&param1=' +
                `${eventCode}` +
                '&param2=' +
                userData.userEmail;

            formDatam.append('ver', '230');
            formDatam.append('mid', monthMidKey);
            // formDatam.append('mid', '1455558834');
            formDatam.append('cur', countryData);
            formDatam.append('txntype', 'PAYMENT');
            formDatam.append('ref', planName?.replace(/ /g, '_') + refData);
            formDatam.append('amt', planPay);
            formDatam.append('buyer', isFirstNameVal + isLastNameVal);
            formDatam.append('email', userData.userEmail);
            formDatam.append('tel', '00000000000');
            formDatam.append('lang', langData);
            formDatam.append('returnurl', `${ip}payment/completeSub`);
            formDatam.append('statusurl', `${ip}payment/completeSub`);
            formDatam.append('item_0_product', planName?.replace(/ /g, '_'));
            formDatam.append('item_0_quantity', 1);
            formDatam.append('item_0_unitPrice', planPay);
            formDatam.append('shipTo_city', langData);
            formDatam.append('shipTo_country', langData);
            formDatam.append('shipTo_firstName', isFirstNameVal);
            formDatam.append('shipTo_lastName', isLastNameVal);
            formDatam.append('shipTo_phoneNumber', '00000000000');
            formDatam.append('shipTo_postalCode', '00000');
            formDatam.append('shipTo_street1', '-');
            formDatam.append('displaytype', 'P');
            formDatam.append('fgkey', hashMonthValue);
            // 정기결제, 같이 fgkey 생성 해줘야함
            formDatam.append('recurringPayment', 'Y'); // 자동결제 여부
            formDatam.append('recurringAmount', planPay); // 자동결제 총 금액
            formDatam.append('recurringURL', `${ip}payment/completeSub`); // 자동결제 후 호출되는 가맹점 페이지
            formDatam.append('url', url2);
            formDatam.append('param1', eventCode);
            formDatam.append('param2', userData.userEmail);

            var requestOptionm = {
                method: 'POST',
                body: formDatam,
                redirect: 'follow',
            };

            fetch(`${ip}api/checkOrder?`, requestOptionm)
                .then((response) => {
                    return response.json();
                })
                .then((result) => {
                    if (result.result === 0) {
                        window.location.href = url2;
                    }
                    if (result.result === 1) {
                        window.location.href = result.url;
                    }
                })
                .catch((error) => console.log('error', error))
                .finally(() => {
                    setIsBtnOn(true);
                    setIsLoading(false);
                });
        }
    };

    const makeFgkey = (secretkey, params) => {
        const sortedKeys = Object.keys(params).sort(); // 요청 파라미터를 알파벳 순서로 정렬
        // A값 생성
        const query = sortedKeys.reduce((acc, key, i) => {
            const keyValue = params[key];
            const prefix = i === 0 ? '' : '&';
            return acc + `${prefix}${key}=${keyValue}`;
        }, '');

        return sha256(`${secretkey}?${query}`);
    };

    const hashValue = makeFgkey('163884E41102B73080211029E965FF11', {
        ver: 230,
        mid: '218CFC6811',
        cur: countryData,
        txntype: 'PAYMENT',
        ref: planName?.replace(/ /g, '_') + refData,
        amt: planPay,
        buyer: isFirstNameVal + isLastNameVal,
        email: userData.userEmail,
        tel: '00000000000',
        lang: langData,
        returnurl: `${ip}payment/complete`,
        statusurl: `${ip}payment/complete`,
        item_0_product: planName?.replace(/ /g, '_'),
        item_0_quantity: 1,
        item_0_unitPrice: planPay,
        shipTo_city: langData,
        shipTo_country: langData,
        shipTo_firstName: isFirstNameVal,
        shipTo_lastName: isLastNameVal,
        shipTo_phoneNumber: '00000000000',
        shipTo_postalCode: '00000',
        shipTo_street1: '-',
        displaytype: 'R',
        param1: eventCode,
        param2: userData.userEmail,
    });
    // const hashMonthValue = makeFgkey('AB109FFC34021D19EAD3401126460E34', {
    const hashMonthValue = makeFgkey(monthSecretKey, {
        ver: 230,
        mid: monthMidKey,
        // mid: '1455558834',
        cur: countryData,
        txntype: 'PAYMENT',
        ref: planName?.replace(/ /g, '_') + refData,
        amt: planPay,
        buyer: isFirstNameVal + isLastNameVal,
        email: userData.userEmail,
        tel: '00000000000',
        lang: langData,
        returnurl: `${ip}payment/completeSub`,
        statusurl: `${ip}payment/completeSub`,
        item_0_product: planName?.replace(/ /g, '_'),
        item_0_quantity: 1,
        item_0_unitPrice: planPay,
        shipTo_city: langData,
        shipTo_country: langData,
        shipTo_firstName: isFirstNameVal,
        shipTo_lastName: isLastNameVal,
        shipTo_phoneNumber: '00000000000',
        shipTo_postalCode: '00000',
        shipTo_street1: '-',
        displaytype: 'P',
        recurringPayment: 'Y',
        recurringAmount: planPay,
        recurringURL: `${ip}payment/completeSub`,
        param1: eventCode,
        param2: userData.userEmail,
    });

    return (
        <>
            {availableCode && <DefaultPopup data={AvailableCodeData} />}
            {notAvailableCode && <DefaultPopup data={notAvailableCodeData} />}
            {noVerifyCheck && <BridgePopup data={subscribeCheckData} />}
            {isLoading && <LoadingPopup percent={loadingPercent} />}
            <Container>
                <PlanTit>PLAN</PlanTit>
                <PlanCon>
                    {/* 연간 플랜 컨테이너 */}
                    <PlanContainer planName={planName} currentPlanPay={currentPlanPay} planPay={planPay} discount={discount} />
                    <PlanRight>
                        <NameCon>
                            <NameInputWrap>
                                <InputTit>First name</InputTit>
                                <Input
                                    type="text"
                                    placeholder="First name"
                                    value={isFirstNameVal}
                                    onChange={(e) => {
                                        setIsFirstNameVal(e.target.value);
                                    }}
                                    onKeyUp={(e) => {
                                        const regExp = /[^0-9a-zA-Z]/g;
                                        const ele = e.target;

                                        if (regExp.test(ele.value)) {
                                            alert('Only English can be entered.');
                                        }
                                        ele.value = ele.value.replace(regExp, '');
                                        setIsFirstNameVal(ele.value);
                                    }}
                                    caution={caution}
                                    isOnFocus={firstNameFoucus}
                                    onFocus={() => setFirstNameFoucus(true)}
                                    onBlur={() => setFirstNameFoucus(false)}
                                />
                                {caution ? (
                                    <MsgText>
                                        <AlertIcon src={AlertImg} alt="alert" />
                                        <RequiredMsg>First name is required</RequiredMsg>
                                    </MsgText>
                                ) : null}
                            </NameInputWrap>
                            <NameInputWrap>
                                <InputTit>Last name</InputTit>
                                <Input
                                    type="text"
                                    placeholder="Last name"
                                    value={isLastNameVal}
                                    onChange={(e) => {
                                        setIsLastNameVal(e.target.value);
                                    }}
                                    onKeyUp={(e) => {
                                        const regExp = /[^0-9a-zA-Z]/g;
                                        const ele = e.target;

                                        if (regExp.test(ele.value)) {
                                            alert('Only English can be entered.');
                                        }
                                        ele.value = ele.value.replace(regExp, '');
                                        setIsLastNameVal(ele.value);
                                    }}
                                    caution={secondCaution}
                                    isOnFocus={lastNameFocus}
                                    onFocus={() => setLastNameFocus(true)}
                                    onBlur={() => setLastNameFocus(false)}
                                />
                                {secondCaution ? (
                                    <MsgText>
                                        <AlertIcon src={AlertImg} alt="alert" />
                                        <RequiredMsg>Last name is required</RequiredMsg>
                                    </MsgText>
                                ) : null}
                            </NameInputWrap>
                        </NameCon>
                        <InputTit
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setCodeState(!codeState);
                            }}
                        >
                            CODE
                            {codeState ? (
                                <img src={MinusIcon} alt="code input close" style={{ width: 20, height: 20, marginLeft: 10 }} />
                            ) : (
                                <img src={PlusIcon} alt="code input open" style={{ width: 20, height: 20, marginLeft: 10 }} />
                            )}
                        </InputTit>
                        {codeState && (
                            <NameCon>
                                <NameInputWrap>
                                    <div style={{ display: 'flex', position: 'relative', width: 'fit-content' }}>
                                        <Input
                                            type="text"
                                            placeholder="Event code"
                                            disabled={isReadOnly}
                                            value={eventCode}
                                            caution={codeCaution}
                                            isOnFocus={codeFocus}
                                            onChange={(e) => {
                                                setEventCode(e.target.value);
                                            }}
                                            onKeyUp={(e) => {
                                                setEventCode(e.target.value);
                                            }}
                                            onFocus={() => setCodeFocus(true)}
                                            onBlur={() => setCodeFocus(false)}
                                        />
                                        {verify === false && <VefiryImg src={VerifyXIcon} alt="verify incorrect" />}
                                        {verify === true && <VefiryImg src={VerifyOIcon} alt="verify icon correct" />}
                                    </div>
                                </NameInputWrap>
                                <NameInputWrap>
                                    {isReadOnly ? <VerifyBtn onClick={editCode}>Edit</VerifyBtn> : <VerifyBtn onClick={checkCoupon}>verify</VerifyBtn>}
                                </NameInputWrap>
                            </NameCon>
                        )}

                        {/* <InfoCon>
                            <InputTit>Payment information</InputTit>
                            <InfoBtnWrap>
                                <VisaBtn
                                    type="button"
                                    isDisable={isCardActiveB}
                                    onClick={() => {
                                        setIsCardActiveA(true);
                                        setIsCardActiveB(false);
                                    }}
                                >
                                    <InfoIcon src={CardIcon} alt="visa" />
                                </VisaBtn>
                                <PaypalBtn
                                    type="button"
                                    isDisable={isCardActiveA}
                                    onClick={() => {
                                        setIsCardActiveB(true);
                                        setIsCardActiveA(false);
                                    }}
                                >
                                    <InfoIcon src={PaypalImg} alt="paypal" />
                                </PaypalBtn>
                            </InfoBtnWrap>
                        </InfoCon>

                        <CardInfoCon isActive={isCardActiveA || isCardActiveB}>
                            <NumCon>
                                <InputTit>Card number</InputTit>
                                <Input
                                    type="number"
                                    placeholder="1234 1234 1234 1234"
                                    value={isCardNumVal}
                                    onChange={(e) => {
                                        setIsCardNumVal(e.target.value);
                                    }}
                                    caution={cardNumCaution}
                                    isOnFocus={numFocus}
                                    onFocus={() => setNumFocus(true)}
                                    onBlur={() => setNumFocus(false)}
                                />
                                {cardNumCaution ? (
                                    <MsgText>
                                        <AlertIcon src={AlertImg} alt="alert" />
                                        <RequiredMsg>Card number is required</RequiredMsg>
                                        <InvalidMsg>The credit card number appears to be invalid</InvalidMsg>
                                    </MsgText>
                                ) : null}
                            </NumCon>

                            <DateCon>
                                <DateInputWrap>
                                    <InputTit>Month</InputTit>
                                    <Input
                                        type="number"
                                        placeholder="MM"
                                        value={isMonthVal}
                                        onChange={(e) => {
                                            setIsMonthVal(e.target.value);
                                        }}
                                        caution={monthCaution}
                                        isOnFocus={monthFocus}
                                        onFocus={() => setMonthFocus(true)}
                                        onBlur={() => setMonthFocus(false)}
                                    />
                                    {monthCaution ? (
                                        <MsgText>
                                            <AlertIcon src={AlertImg} alt="alert" />
                                            <RequiredMsg>Month is required</RequiredMsg>
                                        </MsgText>
                                    ) : null}
                                </DateInputWrap>
                                <DateInputWrap>
                                    <InputTit>Year</InputTit>
                                    <Input
                                        type="number"
                                        placeholder="YY"
                                        value={isYearVal}
                                        onChange={(e) => {
                                            setIsYearVal(e.target.value);
                                        }}
                                        caution={yearCaution}
                                        isOnFocus={yearFocus}
                                        onFocus={() => setYearFocus(true)}
                                        onBlur={() => setYearFocus(false)}
                                    />
                                    {yearCaution ? (
                                        <MsgText>
                                            <AlertIcon src={AlertImg} alt="alert" />
                                            <RequiredMsg>Year is required</RequiredMsg>
                                        </MsgText>
                                    ) : null}
                                </DateInputWrap>
                                <DateInputWrap>
                                    <InputTit>cvc</InputTit>
                                    <Input
                                        type="number"
                                        placeholder="•••"
                                        value={isCvcVal}
                                        onChange={(e) => {
                                            setIsCvcVal(e.target.value);
                                        }}
                                        caution={cvcCaution}
                                        isOnFocus={cvcFocus}
                                        onFocus={() => setCvcFocus(true)}
                                        onBlur={() => setCvcFocus(false)}
                                    />
                                    {cvcCaution ? (
                                        <MsgText>
                                            <AlertIcon src={AlertImg} alt="alert" />
                                            <RequiredMsg>cvc is required</RequiredMsg>
                                        </MsgText>
                                    ) : null}
                                </DateInputWrap>
                            </DateCon>

                            <AnotherMsgText>
                                <AlertIcon src={AlertImg} alt="alert" />
                                <AnotherMsg>Please try another payment method or review your credit card information.</AnotherMsg>
                            </AnotherMsgText>
                        </CardInfoCon>

                        <CheckBoxCon>
                            <CheckBoxInput
                                type="checkbox"
                                checked={isChecked}
                                onChange={(e) => {
                                    setIsChecked(e.target.checked);
                                }}
                                id="agree"
                            />
                            <Label htmlFor="agree">I agree with the above payment.</Label>
                        </CheckBoxCon> */}
                        <SubmitBtn
                            type="button"
                            isActive={isBtnOn}
                            disabled={!isBtnOn}
                            onClick={() => {
                                if (isBtnOn) {
                                    setClickSubmit(true);
                                    preCheck();
                                }
                            }}
                        >
                            Subscribe now
                        </SubmitBtn>
                    </PlanRight>
                </PlanCon>
            </Container>
        </>
    );
}

//PlanContainer 컴포넌트 분리
const PlanContainer = ({ planName, currentPlanPay, planPay, discount }) => {
    const { country } = useParams();
    return (
        <PlanLeft>
            <TitleCon>
                <Title>{planName}</Title>
            </TitleCon>
            <Price>
                {country === 'kr' ? '₩' : '$'}
                {planPay}
                {/* {currentPlanPay} */}
            </Price>
            {/* {discount > 0 && (
                <DiscountCon>
                    <Discount>code discount</Discount>
                    <Discount>-{discount}%</Discount>
                    <Discount>
                        -{country === 'kr' ? '₩' : '$'}
                        {currentPlanPay - planPay}
                    </Discount>
                </DiscountCon>
            )} */}
            <TotalCon>
                <TotalText>Total</TotalText>
                <TotalPrice>
                    {country === 'kr' ? '₩' : '$'}
                    {planPay}
                </TotalPrice>
            </TotalCon>
        </PlanLeft>
    );
};
const Container = styled.div`
    padding: 200px 0 154px;
    width: 1280px;
    margin: 0 auto;
    position: relative;
    min-height: 100vh;

    @media (max-width: 1297px) {
        padding: calc(100vw * (200 / 1280)) calc(100vw * (25 / 1280)) calc(100vw * (154 / 1280));
        width: 100%;
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (107 / 428)) calc(100vw * (25 / 428)) calc(100vw * (100 / 428));
    }
`;

const PlanTit = styled.p`
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 93px;
    text-transform: uppercase;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (40 / 1280));
        margin-bottom: calc(100vw * (93 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (25 / 428));
        margin-bottom: calc(100vw * (50 / 428));
    }
`;

const PlanCon = styled.div`
    display: flex;

    @media (max-width: 1023px) {
        flex-direction: column;
    }
`;

const PlanLeft = styled.div`
    width: 464px;
    height: 329px;
    padding: 60px 39px;
    margin-right: 174px;
    background: url(${PaymentContainerImg}) no-repeat center / cover;

    @media (max-width: 1297px) {
        width: calc(100vw * (464 / 1280));
        height: calc(100vw * (329 / 1280));
        padding: calc(100vw * (60 / 1280)) calc(100vw * (39 / 1280));
        margin-right: calc(100vw * (174 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
        height: calc(100vw * (250 / 428));
        padding: calc(100vw * (55 / 428)) calc(100vw * (39 / 428));
        margin-right: 0;
        margin-bottom: calc(100vw * (30 / 428));
        background: url(${PaymentContainerImgMb}) no-repeat center / contain;
    }
`;

const TitleCon = styled.div`
    display: flex;
    align-items: flex-end;
`;

const Title = styled.p`
    font-size: 25px;
    font-weight: bold;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (25 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (14 / 428));
    }
`;

const Text = styled.span`
    font-size: 15px;
    color: #444444;
    margin-left: 20px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        margin-left: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
        margin-left: calc(100vw * (15 / 428));
    }
`;

const Price = styled.p`
    font-size: 45px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (45 / 1280));
        margin-top: 25px;
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (20 / 428));
        margin-top: 25px;
    }
    @media (max-width: 500px) {
        margin-top: 20px;
    }
`;

const TotalCon = styled.div`
    display: flex;
    justify-content: space-between;
    font-family: 'Pretendard';
    color: #444444;
    font-size: 15px;
    padding-top: 24px;
    border-top: 1px solid #dddddd;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        padding-top: calc(100vw * (24 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
        padding-top: calc(100vw * (19 / 428));
    }
`;

const TotalText = styled.p`
    font-weight: bold;
`;

const TotalPrice = styled.p``;

const PlanRight = styled.div`
    width: 420px;

    @media (max-width: 1297px) {
        width: calc(100vw * (420 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
    }
`;

const NameCon = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 40px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (40 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (40 / 428));
    }
`;

const NameInputWrap = styled.div`
    position: relative;
    width: 100%;
    &:first-child {
    }

    @media (max-width: 1297px) {
        &:first-child {
        }
    }

    @media (max-width: 1023px) {
        &:first-child {
        }
    }
`;

const VerifyBtn = styled.button`
    box-sizing: border-box;
    width: 76px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    border: 1px solid rgba(1, 132, 135, 1);
    color: rgba(1, 132, 135, 1);
    font-size: 15px;
    font-family: 'Pretendard';
    &:disabled {
        color: rgba(10, 22, 38, 0.12);
        border: 1px solid rgba(10, 22, 38, 0.12);
    }

    @media (max-width: 1023px) {
        width: 100%;
        font-size: calc(100vw * (15 / 428));
    }
`;
const VefiryImg = styled.img`
    position: absolute;
    width: 20px;
    right: 14px;
    top: 9px;

    @media (max-width: 1023px) {
        width: calc(100vw * (40 / 1023));
        right: 18px;
        top: calc(100vw * (24 / 1023));
    }
`;

const InputTit = styled.p`
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 20px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (20 / 1280));
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (20 / 428));
        font-size: calc(100vw * (14 / 428));
    }
`;

const Input = styled.input`
    width: 100%;
    padding: 11px 20px;
    font-size: 15px;
    background-color: #fafafa;
    border-radius: 3px;
    &::placeholder {
        color: #aaa;
    }

    ${({ isOnFocus }) => {
        return isOnFocus ? `border: 1px solid #005AA2; box-shadow: 0 0 6px 0 #bad3fb; background-color: #fff;` : `box-shadow: none; background-color: #FAFAFA;`;
    }}

    ${({ caution }) => {
        return caution && `border: 1px solid #FF0058;`;
    }}

    @media(max-width: 1297px) {
        padding: calc(100vw * (11 / 1280)) calc(100vw * (20 / 1280));
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (11 / 428)) calc(100vw * (20 / 428));
        font-size: calc(100vw * (15 / 428));
    }
    &:disabled {
        color: #aeaeae;
        background-color: #ebebeb;
    }
`;

const MsgText = styled.div`
    position: absolute;
    left: 0;
    bottom: -23px;
    display: flex;
    align-items: center;
    font-family: 'Pretendard';
    font-size: 13px;
    color: #ff0058;
    width: 110%;

    @media (max-width: 1297px) {
        bottom: calc(100vw * (-23 / 1280));
        font-size: calc(100vw * (13 / 1280));
    }

    @media (max-width: 1023px) {
        bottom: calc(100vw * (-23 / 428));
        font-size: calc(100vw * (13 / 428));
    }
`;

const AnotherMsgText = styled(MsgText)`
    bottom: -50px;
    width: 100%;
    align-items: flex-start;

    @media (max-width: 1297px) {
        bottom: calc(100vw * (-50 / 1280));
    }

    @media (max-width: 1023px) {
        bottom: calc(100vw * (-40 / 428));
    }
`;

const AlertIcon = styled.img`
    width: 12px;
    height: 12px;
    margin-right: 10px;

    @media (max-width: 1297px) {
        width: calc(100vw * (12 / 1280));
        height: calc(100vw * (12 / 1280));
        margin-right: calc(100vw * (10 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (12 / 428));
        height: calc(100vw * (12 / 428));
        margin-right: calc(100vw * (10 / 428));
    }
`;

const RequiredMsg = styled.span``;

const InvalidMsg = styled.span``;

const AnotherMsg = styled.span``;

const InfoCon = styled.div`
    margin-bottom: 40px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (40 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (40 / 428));
    }
`;

const InfoBtnWrap = styled.div`
    display: flex;
`;

const VisaBtn = styled.button`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #005aa2;
    border-radius: 3px;
    opacity: ${(props) => (props.isDisable ? 0.5 : 1)};

    @media (max-width: 1297px) {
        height: calc(100vw * (40 / 1280));
    }

    @media (max-width: 1023px) {
        height: calc(100vw * (40 / 428));
    }
`;

const PaypalBtn = styled(VisaBtn)`
    margin-left: 20px;
    opacity: ${(props) => (props.isDisable ? 0.5 : 1)};

    @media (max-width: 1297px) {
        margin-left: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        margin-left: calc(100vw * (18 / 428));
    }
`;

const InfoIcon = styled.img`
    height: 21px;

    @media (max-width: 1297px) {
        height: calc(100vw * (21 / 1280));
    }

    @media (max-width: 1023px) {
        height: calc(100vw * (21 / 428));
    }
`;

const CardInfoCon = styled.div`
    position: relative;

    ${({ isActive }) => {
        return isActive ? `opacity: 1;` : `opacity: 0;`;
    }}
`;

const NumCon = styled(InfoCon)`
    position: relative;
`;

const DateCon = styled(NameCon)`
    margin-bottom: 89px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (89 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (50 / 428));
    }
`;

const DateInputWrap = styled.div`
    position: relative;

    &:not(:last-child) {
        margin-right: 30px;
    }

    @media (max-width: 1297px) {
        &:not(:last-child) {
            margin-right: calc(100vw * (30 / 1280));
        }
    }

    @media (max-width: 1023px) {
        &:not(:last-child) {
            margin-right: calc(100vw * (9 / 428));
        }
    }
`;

const CheckBoxCon = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (30 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (20 / 428));
    }
`;

const CheckBoxInput = styled.input`
    width: 15px;
    height: 15px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: 15px;
        box-sizing: border-box;
        border: 1px solid #444444;
        border-radius: 3px;
        background-color: #fff;
    }

    &:checked::after {
        background: url(${CheckedImg}) no-repeat center / cover;
        border: none;
    }

    @media (max-width: 1297px) {
        width: calc(100vw * (15 / 1280));
        height: calc(100vw * (15 / 1280));

        &::after {
            width: calc(100vw * (15 / 1280));
            height: calc(100vw * (15 / 1280));
        }
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (19 / 428));
        height: calc(100vw * (19 / 428));

        &::after {
            width: calc(100vw * (19 / 428));
            height: calc(100vw * (19 / 428));
        }
    }
`;

const Label = styled.label`
    padding-left: 7px;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Pretendard';
    color: #666666;
    cursor: pointer;

    @media (max-width: 1297px) {
        padding-left: calc(100vw * (7 / 1280));
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        padding-left: calc(100vw * (10 / 428));
        font-size: calc(100vw * (13 / 428));
    }
`;

const SubmitBtn = styled.button`
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    font-family: 'Syncopate';
    padding: 13px 0;
    border-radius: 3px;
    text-transform: uppercase;
    color: #fff;
    background-color: ${(props) => (props.isActive ? '#005AA2' : '#ddd')};

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        padding: calc(100vw * (13 / 1280)) 0;
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (14 / 428));
        padding: calc(100vw * (13 / 428)) 0;
    }
`;
const DiscountCon = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 0.8em;
    @media (max-width: 1023px) {
        font-size: 0.9em;
    }
    @media (max-width: 500px) {
        font-size: 0.7em;
    }
`;
const Discount = styled.p``;
