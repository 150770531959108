//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import serverController from '../../server/serverController';

//css
import styled from 'styled-components';

//component
import DefaultPopup from '../common/popup/DefaultPopup';
import Modal from '../../server/Modal';

export default function SupportAsk() {
    const [isOnClick, setIsOnClick] = useState(false);
    const [qnaTitle, setQnaTitle] = useState('');
    const [qnaContent, setQnaContent] = useState('');

    const tokenData = sessionStorage.getItem('TOKEN');

    // 팝업 데이터
    const askData = {
        isState: isOnClick,
        setIsState: setIsOnClick,
        popText: 'Sent successfully.',
        btnText: 'CLOSE',
    };

    // 초기 상태값 관리
    useEffect(() => {
        setQnaTitle('');
        setQnaContent('');
    }, [isOnClick]);

    //문의하기 api
    const writeQna = () => {
        if (tokenData) {
            serverController.connectFetchController(
                `api/support/qna/write?qnaTitle=${qnaTitle}&qnaContent=${qnaContent}`,
                'POST',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                        setIsOnClick(true);
                        Modal.modalOpen();
                    } else {
                    }
                },
            );
        } else {
            alert(' You need to log in.');
        }
    };

    //엔터 클릭 가능
    const enterSearch = (e) => {
        if (e.code === 'Enter') {
            if (qnaTitle && qnaContent) {
                writeQna();
            } else {
                alert('제목과 설명을 입력해주세요');
            }
        }
    };

    return (
        <>
            {isOnClick && <DefaultPopup data={askData} />}
            <TitleWrap>
                <TitleTit>
                    Title<Star>*</Star>
                </TitleTit>
                <TitleInput
                    type="text"
                    value={qnaTitle}
                    onChange={(e) => {
                        setQnaTitle(e.target.value);
                    }}
                    onKeyPress={enterSearch}
                />
            </TitleWrap>
            <DescriptionWrap>
                <DescriptionTit>
                    Description<Star>*</Star>
                </DescriptionTit>
                <DescriptionTextArea
                    value={qnaContent}
                    onChange={(e) => {
                        setQnaContent(e.target.value);
                    }}
                />
            </DescriptionWrap>
            <SubmitBtn
                type="button"
                onClick={() => {
                    {
                        qnaTitle && qnaContent ? writeQna() : alert('제목과 설명을 입력해주세요');
                    }
                }}
            >
                SUBMIT
            </SubmitBtn>
        </>
    );
}

const TitleWrap = styled.div``;

const TitleTit = styled.p`
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        margin-bottom: calc(100vw * (10 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (15 / 428));
        margin-bottom: calc(100vw * (10 / 428));
    }
`;

const Star = styled.span`
    color: #005aa2;
`;

const TitleInput = styled.input`
    border: 1px solid #dddddd;
    border-radius: 3px;
    width: 756px;
    padding: 11px 20px;
    font-weight: 500;
    font-size: 15px;

    @media (max-width: 1297px) {
        width: calc(100vw * (756 / 1280));
        padding: calc(100vw * (11 / 1280)) calc(100vw * (20 / 1280));
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
        padding: calc(100vw * (12 / 428));
        font-size: calc(100vw * (13 / 428));
    }
`;

const DescriptionWrap = styled(TitleWrap)`
    margin-top: 20px;

    @media (max-width: 1297px) {
        margin-top: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        margin-top: calc(100vw * (20 / 428));
    }
`;

const DescriptionTit = styled(TitleTit)``;

const DescriptionTextArea = styled.textarea`
    border: 1px solid #dddddd;
    border-radius: 3px;
    width: 756px;
    height: 250px;
    padding: 20px;
    font-weight: 500;
    font-size: 15px;

    @media (max-width: 1297px) {
        width: calc(100vw * (756 / 1280));
        height: calc(100vw * (250 / 1280));
        padding: calc(100vw * (20 / 1280));
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
        height: calc(100vw * (250 / 428));
        padding: calc(100vw * (12 / 428));
        font-size: calc(100vw * (13 / 428));
    }
`;

const SubmitBtn = styled.button`
    background-color: #005aa2;
    border-radius: 3px;
    color: #fff;
    padding: 13px 77px;
    font-family: 'Syncopate';
    font-weight: bold;
    font-size: 15px;
    margin-top: 40px;

    @media (max-width: 1297px) {
        padding: calc(100vw * (13 / 1280)) calc(100vw * (77 / 1280));
        margin-top: calc(100vw * (40 / 1280));
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (13 / 428)) calc(100vw * (64 / 428));
        margin-top: calc(100vw * (40 / 428));
        font-size: calc(100vw * (14 / 428));
    }
`;
