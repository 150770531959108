export const KR = {
  TermsListData: [
    {
      Tit: `사용 및 공정한 사용 정책`,
      Text: `주식회사 WARP (“WARP”)의 서비스, 클라이언트 소프트웨어 및 웹 사이트(“서비스”)는 수백만 명의 사용자가 사용하도록 고안되었으며, 귀하가 이 서비스를 책임감 있게 사용할 것을 믿습니다. 본 사용 및 공정 사용 정책은 당사의 서비스 약관과 함께 읽어야 합니다.`,
    },
    {
      Tit: `귀하는 서비스를 오용하지 않기로 동의합니다. 예를 들어 서비스를 사용하여 다음 작업을 수행해서는 안 되며 시도해서도 안 됩니다.`,
      Text: `시스템 또는 네트워크의 취약성을 탐색, 검색 및 테스트\n보안 또는 인증 조치를 위반 및 회피\n귀하가 초대받지 않은 서비스의 공유 영역인 Warp(또는 당사 서비스 제공업체의) 컴퓨터 시스템에 액세스, 변조 또는 사용\n바이러스 전송, 오버로드, 플러딩, 스팸 전송 또는 메일 폭탄 투하를 통해 사용자, 호스트 또는 네트워크를 방해\n악성 프로그램을 심거나 서비스를 사용하여 악성 프로그램 배포\n공개적으로 지원되는 인터페이스(예: “스크래핑”) 이외의 다른 방법으로 서비스에 액세스 및 검색\n요청되지 않은 통신, 홍보, 광고 및 스팸\n사기, 오해의 소지가 있거나 타인의 권리를 침해하는 것을 출판\n적절한 허가 없이 자신의 제품이나 서비스를 홍보 및 광고\n불법적으로 음란물이나 외설물, 편협함, 종교적, 인종적 또는 인종적 증오를 옹호하는 자료를 출판 및 공유\n다른 사람의 사생활을 침해하거나 타인의 명예를 훼손\n개인 또는 단체와의 관계를 가장하거나 잘못 표현\n서비스를 제공하는 데 사용되는 소프트웨어를 해독, 분해, 분해 또는 역엔지니어링하려고 시도\n바이러스를 전송하거나, 오버로드, 플러딩, 스팸 발송 또는 메일 폭탄 공격을 통해 제한 없이 사용자, 호스트 또는 네트워크의 액세스를 방해하거나 방해하려는 경우 다른 사용자에게 해를 입히거나 위협 제3자의 지불 정보를 제공\n서비스를 의도한 용도, 설명서 또는 워프 오디오의 합리적인 지침에 반하여 남용\n사용자가 저작권을 소유하지 않은 서비스나 라이센스를 사용하여 자료를 제공하거나 공유할 경우 WARP는 사전 통지 없이 해당 자료를 삭제할 수 있습니다. 자세한 내용은 서비스 약관을 참조하십시오.`,
    },
    {
      Tit: `사용제한`,
      Text: `WARP는 귀하가 당사의 서비스의 의도된 용도를 이해하고 당사의 계획에 제공되는 무제한 기능의 악용과 남용을 방지하기 위한 지침으로 이 정책을 준비했습니다. 정상적이고 합리적인 개인 또는 전문가 사용당사 계획에서 제공하는 서비스는 일반적이고 합리적인 개인 또는 전문가용입니다. “제한되지 않음”은 해당 유형 및 사용 수준을 나타냅니다. 서비스를 사용하여 개인 또는 전문 목적을 위해 일반 사용자에 비해 비정상적으로 많은 트랙(수수료 또는 비사용)을 마스터 하는 비즈니스 운영과 같은, 일반적이고 합리적인 개인 또는 전문적 용도는 포함되지 않습니다.`,
    },
    {
      Tit: `평가하기`,
      Text: `WARP는 다른 사용자의 일반적인 사용 수준과 비교하여 사용자의 사용을 평가합니다. WARP는 이러한 평가에 따라 일반 사용자와 비교할 때 비정상적이고 불합리한 사용이 발생하고 있다고 판단할 수 있습니다.특정 한도를 설정하고 싶지는 않지만, 일반적으로 하루 8곡 이상의 트랙을 정기적으로 마스터하거나 월 2백40곡(240곡) 이상의 트랙을 매월 20곡 이상의 트랙을 릴리스하는 경우 귀하의 사용이 비정상적이거나 불합리하다고 간주할 것입니다. 당사는 귀하가 여러 트랙과 기타 트랙을 마스터 및/또는 릴리스하지 않을 수 있는 기간에 서비스를 사용하는 것이 불규칙할 수 있음을 이해하며 이에 따라 귀하의 사용을 검토하고 평가합니다.`,
    },
    {
      Tit: `알림`,
      Text: `WARP에서 사용자의 사용이 비정상적이거나 불합리하다고 판단될 경우, 당사는 (고객님의 계정에 제공된 이메일 주소를 통해) 귀하에게 연락하여 사용 현황을 알려드리며, 귀하의 사용을 수정하거나 다른 요금제에 가입할 수 있는 기회를 제공할 수 있습니다. 당사는 또한 귀하의 계정과 서비스 사용을 즉시 중지하거나 계정을 폐쇄하는 등 추가 조치를 취할 수 있는 권리를 보유합니다.\n\nWARP는 귀하가 당사의 서비스의 의도된 용도를 이해하고 당사의 계획에 제공되는 무제한 기능의 악용과 남용을 방지하기 위한 지침으로 이 정책을 준비했습니다`,
    },
    {
      Tit: `일반적이고 합리적인 개인 또는 전문가용입니다.`,
      Text: `당사의 계획에서 제공하는 서비스는 정상적이고 합리적인 개인 또는 전문적 용도로 사용됩니다. “제한되지 않음”은 해당 유형 및 사용 수준을 나타냅니다. 서비스를 사용하여 개인 또는 전문 목적을 위해 일반 사용자에 비해 비정상적으로 많은 트랙(수수료 또는 비사용)을 마스터하는 비즈니스 운영과 같은, 일반적이고 합리적인 개인 또는 전문적 용도는 포함되지 않습니다.`,
    },
  ],
  CopyRight: `저작권 정보 ©입니다.\nWARP Inc. 2022\n본 웹 사이트의 어떤 부분이나 콘텐츠도 저자의 사전 서면 동의 없이 복제, 복사, 수정 또는 수정될 수 없습니다.<br/>다음 방법 중 하나를 사용하여 이 웹 사이트를 공유할 수 있습니다. WARPmastering.com 웹 사이트에 있는 공유 아이콘 사용, 배포하고자 하는 콘텐츠의 백링크 또는 URL 제공, WARPmastering.com 에 귀속된 웹 사이트의 발췌문을 인용할 수 있습니다. 기타 공유 모드는 contact@warpmastering.com으로 문의하십시오. 저작자의 명시적 사전 서면 동의 없이는 웹사이트의 콘텐츠를 상업적으로 사용하고 배포할 수 없습니다.`,
};
