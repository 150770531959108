import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

//css
import styled from 'styled-components';

//component
import TabBtn from '../common/Btn/TabBtn';
import SupportQnA from './SupportQnA';
import SupportAsk from './SupportAsk';
import SupportNews from './SupportNews';
import BasicBtn from '../common/Btn/BasicBtn';

//img

import serverController from '../../server/serverController';
import DateSplit from '../../utils/DateSplit';

export default function SupportNewsDetail() {
    // tab
    const [titleList, setTitleList] = useState([]);
    const [detailData, setDetailData] = useState(null);
    const [customDate, setCustomDate] = useState('');
    const { noticeId, country } = useParams();
    const history = useHistory();

    useEffect(() => {
        serverController.connectFetchController(`api/support/notice/detail?noticeId=${noticeId}`, 'GET', null, null, (res) => {
            if (res.result === 1) {
                setDetailData(res.notice);
            }
        });
    }, [noticeId]);

    useEffect(() => {
        if (detailData) {
            let customD = DateSplit(detailData?.noticeDate);
            setCustomDate(customD);
        }
    }, [detailData]);

    function handleClick() {
        history.push(`/${country}/support/news`);
    }

    useEffect(() => {
        setTitleList([
            { title: 'FAQ', paramsList: 'qna' },
            { title: 'Contact us', paramsList: 'ask' },
            { title: 'Newsletter', paramsList: 'news' },
        ]);
    }, []);

    return (
        <Container>
            <TabBtn titleText={`Support`} data={titleList} urlTitle={'support'} />
            {/* {render()} */}
            <DetailCon>
                <NewsTop>
                    <NewTit>news letter</NewTit>
                </NewsTop>
                <NewsCon>
                    <DetailTitCon>
                        <DetailTit>{detailData?.noticeTitle}</DetailTit>
                        <DateWrap>
                            <DetailWriter></DetailWriter>
                            <DetailDate>{customDate}</DetailDate>
                        </DateWrap>
                    </DetailTitCon>
                    <DetailWrap>{ReactHtmlParser(detailData?.noticeContent)}</DetailWrap>
                    <PrevBtn>
                        <BasicBtn text={'Back to List'} bg={true} onClick={handleClick} />
                    </PrevBtn>
                </NewsCon>
            </DetailCon>
        </Container>
    );
}

const Container = styled.div`
    padding: 200px 0 384px;

    @media (max-width: 1297px) {
        padding: calc(100vw * (200 / 1280)) 0;
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (107 / 428)) 0 calc(100vw * (241 / 428));
    }
`;

const DetailCon = styled.div`
    width: 1280px;
    margin: 0 auto;

    @media (max-width: 1297px) {
        width: 100%;
        padding: 0 calc(100vw * (25 / 1280)) 0;
    }

    @media (max-width: 1023px) {
        padding: 0 calc(100vw * (25 / 428)) 0;
    }
`;

const NewsTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 42px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1356px;
        height: 1px;
        background-color: #666666;
    }

    @media (max-width: 1372px) {
        &::after {
            width: 100%;
        }
    }

    @media (max-width: 1297px) {
        padding-bottom: calc(100vw * (42 / 1280));
    }

    @media (max-width: 1023px) {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: calc(100vw * (50 / 428));
    }
`;

const NewTit = styled.p`
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (25 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (16 / 428));
    }
`;

const NewsCon = styled.div``;

const DetailTitCon = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Pretendard';
    padding: 35px 0 24px;
    border-bottom: 1px solid #eeeeee;

    @media (max-width: 1297px) {
        padding: calc(100vw * (35 / 1280)) 0 calc(100vw * (24 / 1280));
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (25 / 428)) 0;
    }
`;

const DetailTit = styled.div`
    font-size: 16px;
    font-weight: bold;
    max-width: 1000px;

    @media (max-width: 1297px) {
        max-width: alc(100vw * (1000 / 1280));
        font-size: calc(100vw * (16 / 1280));
    }

    @media (max-width: 1023px) {
        max-width: alc(100vw * (140 / 428));
        font-size: calc(100vw * (14 / 428));
    }
`;

const DateWrap = styled.div`
    font-size: 15px;
    font-weight: 500;
    display: flex;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (12 / 428));
    }
`;

const DetailWriter = styled.p`
    color: #005aa2;
    margin-right: 33px;
    max-width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 1297px) {
        max-width: calc(100vw * (130 / 1280));
        margin-right: calc(100vw * (33 / 1280));
    }

    @media (max-width: 1023px) {
        max-width: calc(100vw * (90 / 428));
        margin-right: calc(100vw * (10 / 428));
    }
`;

const DetailDate = styled.p`
    color: #aaaaaa;
    max-width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 1297px) {
        max-width: calc(100vw * (130 / 1280));
    }

    @media (max-width: 1023px) {
        max-width: calc(100vw * (100 / 428));
    }
`;

const DetailWrap = styled.div`
    padding: 40px 0 60px;
    position: relative;
    margin-bottom: 40px;
    font-size: 15px;
    font-weight: 500;
    color: #444444;
    line-height: 24px;

    & img {
        width: 100%;
        height: 100%;
        /* width: fit-content;
        height: fit-content;
        max-width: 100%;
        max-height: 100%;
        min-width: 100%;
        min-height: 100%; */
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1356px;
        height: 1px;
        background-color: #666666;
    }
    @media (max-width: 1372px) {
        &::after {
            width: 100%;
        }
    }

    @media (max-width: 1297px) {
        padding: calc(100vw * (40 / 1280)) 0 calc(100vw * (60 / 1280));
        margin-bottom: calc(100vw * (40 / 1280));
        font-size: calc(100vw * (15 / 1280));
        line-height: calc(100vw * (24 / 1280));
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (25 / 428)) 0;
        margin-bottom: calc(100vw * (40 / 428));
        font-size: calc(100vw * (13 / 428));
        line-height: calc(100vw * (20 / 428));
    }
`;

const PrevBtn = styled.div`
    width: 232px;
    margin-left: auto;

    @media (max-width: 1297px) {
        width: calc(100vw * (232 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (200 / 428));
    }
`;
