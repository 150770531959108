import { audioURL } from '../../server/awsURL';
export default function fileDownload(filename, type, setLoadingPop) {
    if (filename.indexOf('.wav') !== -1) {
        filename = filename.split('.wav')[0];
    } else if (filename.indexOf('.mp3') !== -1) {
        filename = filename.split('.mp3')[0];
    } else if (filename.indexOf('.mov') !== -1) {
        filename = filename.split('.mov')[0];
    } else if (filename.indexOf('.mp4') !== -1) {
        filename = filename.split('.mp4')[0];
    }
    let file = encodeURIComponent(`${filename}.${type}`);

    // HYUNHA 불필요 한 것 같아 주석 처리
    // let file = `${filename}.${type}`;
    // console.log("filename",filename)
    // console.log("type",type)
    // if (type === 'mp3') {
    //     let req = new XMLHttpRequest();
    //     req.open('GET', filename, true);
    //     req.responseType = 'arraybuffer';
    //     req.onload = function () {
    //         console.log("FILEDOWNLOAD", req)
    //         var arrayBuffer = req.response;
    //         var blob = new Blob([arrayBuffer]);
    //         if (navigator.appVersion.toString().indexOf('.NET') > 0) window.navigator.msSaveBlob(blob, filename);
    //         else {
    //             var anchor = document.createElement('a');
    //             anchor.style = 'display: none';
    //             var url = window.URL.createObjectURL(blob);

    //             anchor.href = url;
    //             anchor.download = filename.split('convert/')[1];
    //             anchor.click();
    //             window.URL.revokeObjectURL(url);
    //             setLoadingPop(false);
    //         }
    //     };
    //     req.send();
    // } else {
    // mp4, wav

    let req = new XMLHttpRequest();
    req.open('GET', audioURL + file, true);
    req.responseType = 'arraybuffer';
    req.onload = function () {
        // console.log("FILEDOWNLOAD", req)
        var arrayBuffer = req.response;
        var blob = new Blob([arrayBuffer]);

        if (navigator.appVersion.toString().indexOf('.NET') > 0) window.navigator.msSaveBlob(blob, filename);
        else {
            var anchor = document.createElement('a');
            anchor.style = 'display: none';
            var url = window.URL.createObjectURL(blob);
            anchor.href = url;
            anchor.download = `${filename}.${type}`;
            anchor.click();
            window.URL.revokeObjectURL(url);
            setLoadingPop(false);
        }
    };
    req.send();
    // }
}
