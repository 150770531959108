//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

//css
import styled from 'styled-components';

//component
import TabBtn from '../common/Btn/TabBtn';
import SupportQnA from './SupportQnA';
import SupportAsk from './SupportAsk';
import SupportNews from './SupportNews';

//img

export default function SupportBody() {
    // tab
    const [titleList, setTitleList] = useState([]);

    const params = useParams();
    const history = useHistory();

    useEffect(() => {
        setTitleList([
            { title: 'FAQ', paramsList: 'qna' },
            { title: 'Contact us', paramsList: 'ask' },
            { title: 'Newsletter', paramsList: 'news' },
        ]);
    }, []);

    const render = () => {
        if (params.listTitle === 'qna') return <SupportQnA />;
        if (params.listTitle === 'ask') return <SupportAsk />;
        if (params.listTitle === 'news') return <SupportNews />;
    };

    return (
        <Container>
            <TabBtn titleText={`Support`} data={titleList} urlTitle={'support'} />
            <SupportCon>{render()}</SupportCon>
        </Container>
    );
}

const Container = styled.div`
    padding: 200px 0;

    @media (max-width: 1297px) {
        padding: calc(100vw * (200 / 1280)) 0;
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (107 / 428)) 0 calc(100vw * (100 / 428));
    }
`;

const SupportCon = styled.div`
    width: 1280px;
    margin: 0 auto;

    @media (max-width: 1297px) {
        width: 100%;
        padding: 0 calc(100vw * (25 / 1280)) 0;
    }

    @media (max-width: 1023px) {
        padding: 0 calc(100vw * (25 / 428)) 0;
    }
`;
