//react
import { useEffect, useState, useRef } from "react";
import { Link,useHistory } from "react-router-dom";

//css
import styled from "styled-components"

//component
import Modal from "../../../server/Modal";

export default function BasicBtn({onClick, bg, color, text, disabled}){

    const history = useHistory();
	
    return(
        <>
            <Btn type="button" onClick={onClick} bg={bg} color={color} disabled={disabled}>{text}</Btn>
        </>
    );

}

const Btn = styled.button`
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    font-family: 'Syncopate';
    padding: 13px 0;
    border: 1px solid #005AA2;
    border-radius: 3px;
    text-transform: uppercase;
    color : #005AA2;
    background-color: #ffff;
    cursor: pointer;

    :disabled {
        cursor: default;
        color : #6666;
        border: 1px solid #6666;

    }
    @media (max-width: 1297px){
        font-size: calc(100vw * (15 / 1280));
        padding: calc(100vw * (13 / 1280)) 0;
    }

    @media (max-width: 1023px){
        font-size: calc(100vw * (14 / 428));
        padding: calc(100vw * (13 / 428)) 0;
    }
`
