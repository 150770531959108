//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

//css
import styled from 'styled-components';

//component
import TutorialList from '../../component/tutorial/TutorialList';

import { useCookies } from 'react-cookie';

//img
import MusicBannerImg from '../../img/tutorial/music_banner.png';
import MusicBannerImgMb from '../../img/tutorial/music_banner_mb.png';
import VideoBannerImg from '../../img/tutorial/video_banner.png';
import VideoBannerImgMb from '../../img/tutorial/video_banner_mb.png';

import serverController from '../../server/serverController';

export default function TutorialBody() {
    // music data
    const [musicList, setMusicList] = useState([]);

    // video data
    const [videoList, setVideoList] = useState([]);

    // 로그인
    const [cookies] = useCookies();

    useEffect(() => {
        serverController.connectFetchController(`api/tutorial/list`, 'GET', null, null, (res) => {
            if (res?.result === 1) {
                let musicArr = [];
                let videoArr = [];
                for (let i = 0; i < res.tutorial.length; i++) {
                    if (res.tutorial[i].tutorialCategory === 0) {
                        musicArr.push(res.tutorial[i]);
                    } else {
                        videoArr.push(res.tutorial[i]);
                    }
                }

                setMusicList(musicArr);
                setVideoList(videoArr);
            } else {
            }
        });
    }, []);

    return (
        <Container>
            <TutorialTit>Tutorial</TutorialTit>
            <MusicBanner>
                <MusicText>
                    {`Audio mastering`}
                    <br />
                    {` on music`}
                </MusicText>
            </MusicBanner>
            <TutorialList item={musicList} />
            <VideoBanner>
                <VideoText>
                    {`Audio mastering`}
                    <br />
                    {` on Video`}
                </VideoText>
            </VideoBanner>
            <TutorialList item={videoList} />
        </Container>
    );
}

const Container = styled.div`
    padding: 200px 0;

    @media (max-width: 1297px) {
        padding: calc(100vw * (200 / 1280)) 0;
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (107 / 428)) 0 calc(100vw * (100 / 428));
    }
`;

const TutorialTit = styled.h1`
    font-size: 40px;
    font-weight: bold;
    width: 1280px;
    margin: 0 auto 58px;
    text-transform: uppercase;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (40 / 1280));
        width: 100%;
        padding: 0 calc(100vw * (25 / 1280));
        margin: 0 auto calc(100vw * (58 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (25 / 428));
        padding: 0 calc(100vw * (25 / 428));
        margin: 0 auto calc(100vw * (50 / 428));
    }
`;

const MusicBanner = styled.div`
    height: 414px;
    background: url(${MusicBannerImg}) no-repeat center / cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;

    @media (max-width: 1297px) {
        height: calc(100vw * (414 / 1280));
        margin-bottom: calc(100vw * (60 / 1280));
    }

    @media (max-width: 1023px) {
        background: url(${MusicBannerImgMb}) no-repeat center / cover;
        height: calc(100vw * (215 / 428));
        margin-bottom: calc(100vw * (25 / 428));
    }
`;

const MusicText = styled.p`
    text-transform: uppercase;
    width: 1280px;
    color: #fff;
    font-size: 25px;

    & br {
        display: none;
    }

    @media (max-width: 1297px) {
        width: 100%;
        padding: 0 calc(100vw * (25 / 1280));
        font-size: calc(100vw * (25 / 1280));
    }

    @media (max-width: 1023px) {
        padding: 0 calc(100vw * (25 / 428));
        font-size: calc(100vw * (20 / 428));
        line-height: calc(100vw * (23 / 428));

        & br {
            display: block;
        }
    }
`;

const VideoBanner = styled(MusicBanner)`
    background: url(${VideoBannerImg}) no-repeat center / cover;
    margin-top: 100px;
    margin-bottom: 120px;

    @media (max-width: 1297px) {
        margin-top: calc(100vw * (100 / 1280));
        margin-bottom: calc(100vw * (120 / 1280));
    }

    @media (max-width: 1023px) {
        background: url(${VideoBannerImgMb}) no-repeat center / cover;
    }
`;

const VideoText = styled(MusicText)``;
