import { useEffect } from "react";
import { Mobile, PC } from "../../media/MediaQuery"

//css
import styled from "styled-components"

// component
import HeaderWhite from "../../component/common/HeaderWhite"
import HeaderMb from "../../component/common/HeaderMb"
import SupportBody from "../../component/support/SupportBody";
import Footer from "../../component/common/Footer"

export default function SupportPage(){

    return(
        <>
            <PC>
                <HeaderWhite/>
            </PC>
            <Mobile>
                <HeaderMb/>
            </Mobile>
            <SupportBody/>
            <Footer/>
        </>
    );

}