//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Mobile, PC } from '../../media/MediaQuery';

//css
import styled from 'styled-components';

//component
import BasicBtn from '../common/Btn/BasicBtn';
import DefaultPopup from '../common/popup/DefaultPopup';
import BridgePopup from '../common/popup/BridgePopup';
import Modal from '../../server/Modal';

import serverController, { ip } from '../../server/serverController';

//img
import LineImg from '../../img/icon/icon_line.svg';
import OnePlanImg from '../../img/mypage/one_plan_img.png';
import MonthPlanImg from '../../img/mypage/month_plan_img.png';
import YearPlanImg from '../../img/mypage/year_plan_img.png';
import CardIcon from '../../img/icon/icon_card.svg';
import AlertImg from '../../img/icon/icon_alert.svg';
import OvercomeSectionImg from '../../img/intro/overcome_img.png';

// lang
import { useLangText } from '../../utils/langugeController/langController';
import { KR, EN, CN, ES } from './language/MySubscription/combLang';

import DateSplit from '../../utils/DateSplit';
import DateSplitNextBill from '../../utils/DateSplitNextBill';
import DateSplitUse from '../../utils/DateSplitUse';

export default function MySubsctiption() {
    const langText = useLangText(KR, EN, CN, ES);
    const history = useHistory();
    const { country } = useParams();

    const [orderId, setOrderId] = useState(0);
    const [isOnClick, setIsOnClick] = useState(false);
    const [caution, setCaution] = useState(false);
    const [isCanceled, setIsCanceled] = useState(false);
    const [isAnnual, setIsAnnual] = useState(false);
    const [active, setActive] = useState(false);
    const [moreClick, setMoreClick] = useState(false);
    const [moreState, setMoreState] = useState(false);
    const [leftSectionActive, setLeftSectionActive] = useState(true); // 왼쪽 구독 정보
    const [changeData, setChangeData] = useState(false);

    const [planData, setPlanData] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [showHistoryData, setShowHistoryData] = useState([]);
    // token
    // const tokenData = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJtcmhhbjkyQG1vbWVudGkuYml6IiwiYXV0aCI6IlJPTEVfVVNFUiIsInVzZXJJZCI6MTY2LCJleHAiOjE2ODAxNDMzNDl9.gHR5jWAsVTHfnEZWyA2I2cGR3T7VKMRmI43GUk5dHWD9hkB6ZuT1qRPvbyBUBIAW0OnJqajKGBPfnFcIbYmniw';
    const tokenData = sessionStorage.getItem('TOKEN');
    // 팝업
    const [serverErrPop, setServerErrPop] = useState(false); //서버에러팝업
    const [upgradeClick, setUpgradeClick] = useState(false); //쓰이지않는것같음
    const [UpComClick, setUpComClick] = useState(false); //쓰이지않는것같음
    const [cancelClick, setCancelClick] = useState(false); // 구독권 취소 버튼 클릭시 true
    const [cancelOneClick, setCancelOneClick] = useState(false); // 1회권 취소 버튼 클릭시 true
    const [cancelComClick, setCancelComClick] = useState(false); // 구독 취소 완료 시 true
    const [cancelComOneClick, setCancelComOneClick] = useState(false); // 1회권 취소 완료 시 true
    const [cancelComplete, setCancelComplete] = useState(false); // 구독권 취소 완료시 true
    const [cancelOneComplete, setCancelOneComplete] = useState(false); // 1회권 취소 완료시 true
    const [orderDate, setOrderDate] = useState(null);

    // upgrade 팝업 데이터 쓰이지 않는것같음
    const upgradeData = {
        isState: UpComClick,
        setIsState: setUpComClick,
        popText: '플랜을 업그레이드했습니다.',
        blueText: '*144$ 가 이전 계정 요금에서 7일 이내에서 지불합니다.',
        btnText: '닫기',
    };
    const upgradeComData = {
        mainState: upgradeClick,
        setMainState: setUpgradeClick,
        isSub: UpComClick,
        setIsSub: setUpComClick,
        popText: '계정을 연간계정으로 업그레이드 하시겠습니까?',
        blueText: '*144$ 가 이전 계정 요금에서 7일 이내에서 지불합니다.',
        SubBtnText: `업그레이드`,
        btnText: '아니오',
    };

    // HYUNHA: 구독권 취소시 확인 팝업
    const cancelComData = {
        mainState: cancelClick,
        setMainState: setCancelClick,
        isSub: cancelComClick,
        setIsSub: setCancelComClick,
        popText: 'Are you sure you want to cancel your plan?',
        blueText: (
            <div>
                * Even if the subscription has been canceled, <br /> it will be available until <span>{DateSplitUse(planData.subscribePlanDateEnd)}.</span>
            </div>
        ),
        SubBtnText: `Unsubscribe`,
        btnText: 'close',
    };
    // HYUNHA: 구독권 취소 완료 팝업
    const cancelData = {
        isState: cancelComplete,
        setIsState: setCancelComplete,
        popText: 'Your subscription has been canceled.',
        blueText: (
            <div>
                * Even if the subscription has been canceled, <br /> it will be available until <span>{DateSplitUse(planData.subscribePlanDateEnd)}.</span>
            </div>
        ),
        btnText: 'close',
    };

    // HYUNHA: 1회권 취소시 확인 팝업
    const cancelOneComData = {
        mainState: cancelOneClick,
        setMainState: setCancelOneClick,
        isSub: cancelComOneClick,
        setIsSub: setCancelComOneClick,
        popText: 'Are you sure you want to cancel your plan?',
        blueText: <div>* Your one piece mastering plan will be canceled.</div>,
        SubBtnText: `Refund`,
        btnText: 'close',
    };
    // HYUNHA: 1회권 취소 완료 팝업
    const cancelOneData = {
        isState: cancelOneComplete,
        setIsState: setCancelOneComplete,
        popText: 'Your plan has been canceled.',
        btnText: 'close',
    };
    // HYUNHA: 서버 에러 팝업
    const serverErr = {
        isState: serverErrPop,
        setIsState: setServerErrPop,
        popText: 'please refresh the page and try again.',
        btnText: 'close',
    };

    useEffect(() => {
        // HYUNHA: 월구독 cancel 버튼 누를 시 구독 취소 function
        if (cancelComClick) {
            if (isAnnual) {
                // console.log('년 구독 취소:orderId', orderId);
                var myHeaders = new Headers();
                myHeaders.append('Authorization', `Bearer ${tokenData}`);
                myHeaders.append('Content-Type', 'application/json');

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                };

                fetch(`${ip}payment/cancelannual/${orderId}`, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.result === 1) {
                            setCancelOneComplete(true);
                            setChangeData(!changeData);
                        } else {
                            // setServerErrPop(true)
                            alert(`[cancel error] ${result.msg}`);
                        }
                    })
                    .catch((error) => {
                        setServerErrPop(true);
                        // alert('[cancel error] please refresh the page and try again');
                    });
            } else {
                // console.log('월 구독 취소:orderId', orderId);
                var myHeaders = new Headers();
                myHeaders.append('Authorization', `Bearer ${tokenData}`);
                myHeaders.append('Content-Type', 'application/json');

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                };

                fetch(`${ip}payment/cancelsub/${orderId}`, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.result === 1) {
                            setCancelOneComplete(true);
                            setChangeData(!changeData);
                        } else {
                            // setServerErrPop(true)
                            alert(`[cancel error] ${result.msg}`);
                        }
                    })
                    .catch((error) => {
                        setServerErrPop(true);
                        // alert('[cancel error] please refresh the page and try again');
                    });
            }
        }
    }, [cancelComClick]);
    useEffect(() => {
        // HYUNHA: 1회권 cancel 버튼 누를 시 구독 취소 function
        if (cancelComOneClick) {
            // console.log('1회권 취소 요청');
            var myHeaders = new Headers();
            myHeaders.append('Authorization', `Bearer ${tokenData}`);
            myHeaders.append('Content-Type', 'application/json');

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
            };
            fetch(`${ip}payment/cancelone/${orderId}`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.result === 1) {
                        setCancelOneComplete(true);
                        setChangeData(!changeData);
                    } else {
                        // setServerErrPop(true)
                        alert(`[cancel error] ${result.msg}`);
                    }
                })
                .catch((error) => {
                    setServerErrPop(true);
                    // alert('[cancel error] please refresh the page and try again');
                });
        }
    }, [cancelComOneClick]);

    // 마이페이지 구독 정보 api
    useEffect(() => {
        serverController.connectFetchController(
            `api/mypage/subscription`,
            'GET',
            null,
            {
                'Content-Type': `application/json`,
                Authorization: `Bearer ${tokenData}`,
            },
            (res) => {
                if (res.result === 1) {
                    // subscription API 응답값 정보
                    // res.data.subscribeBillingList : 결제 내역 [Array{Obj}]
                    // res.data.subscribePlanTitle : 구독중인 플랜 "String"
                    // :: Annual Plan 1년 정기구독
                    // :: Monthly Plan 월 정기구독
                    // :: One Piece 1회 사용권
                    // console.log('HYUNHA: 구독정보 응답값', res);
                    // console.log('결제내역', res.data.subscribeBillingList);
                    setShowHistoryData(res.data.subscribeBillingList);
                    setPlanData(res.data);
                    // 결제 내역이 있으면 mypage 화면 노출
                    if (res.data.subscribeBillingList.length > 0) {
                        setActive(true);
                    } else {
                        setActive(false);
                    }
                }
            },
        );
        serverController.connectFetchController(
            `api/mypage/checkSub`,
            'POST',
            null,
            {
                'Content-Type': `application/json`,
                Authorization: `Bearer ${tokenData}`,
            },
            (res) => {
                // checkSub API 응답값 정보
                // :: 0 보유한 구독권 존재
                // :: 1 구독권 없음
                if (res.result === 1) {
                    // :: 1 구독권 없음
                    setLeftSectionActive(false);
                } else {
                    // :: 0 보유한 구독권 존재
                    setLeftSectionActive(true);
                    setOrderId(res.subOne[0].orderId);
                    if (res.subOne[0].planName === 'One Year Plan') {
                        setIsAnnual(true);
                    } else {
                        setIsAnnual(false);
                    }
                    if (res.subOne[0].planStatus === 'complete') {
                        setIsCanceled(false);
                    } else {
                        setIsCanceled(true);
                    }
                }
            },
        );
    }, [changeData]);

    useEffect(() => {
        if (planData) {
            setOrderDate(planData.orderDate);
        }
    }, [planData]);
    return (
        <>
            {upgradeClick && <BridgePopup data={upgradeComData} />}
            {UpComClick && <DefaultPopup data={upgradeData} />}
            {cancelClick && <BridgePopup data={cancelComData} />}
            {cancelComplete && <DefaultPopup data={cancelData} />}
            {cancelOneClick && <BridgePopup data={cancelOneComData} />}
            {cancelOneComplete && <DefaultPopup data={cancelOneData} />}
            {serverErrPop && <DefaultPopup data={serverErr} />}
            {active ? (
                <SubscriptCon>
                    {leftSectionActive ? (
                        <SubscriptLeft>
                            <TopCon>
                                <Title>{planData.subscribePlanTitle}</Title>
                            </TopCon>
                            <MiddleCon>
                                <PlanImgCon>
                                    {planData.subscribePlanTitle === 'One Year Plan' ? (
                                        <PlanImg src={YearPlanImg} alt="YearPlanImg" />
                                    ) : planData.subscribePlanTitle === 'Monthly Plan' ? (
                                        <PlanImg src={MonthPlanImg} alt="MonthPlanImg" />
                                    ) : (
                                        <PlanImg src={OnePlanImg} alt="OnePlanImg" />
                                    )}
                                </PlanImgCon>
                            </MiddleCon>
                            <BottomCon>
                                {!isCanceled && planData.subscribePlanTitle === 'Monthly Plan' ? (
                                    <>
                                        {planData.subscribeFree !== 'free' && (
                                            <PaymentCon>
                                                Your next payment is <Price> $ {planData.planPrice}</Price>, <br />
                                                to be charged on {orderDate && <Date>{DateSplitNextBill(planData.subscribeNextBill)}</Date>}
                                            </PaymentCon>
                                        )}
                                        <LastDateCon>
                                            Last payment:
                                            <LastDate>{orderDate && DateSplit(orderDate)}</LastDate>
                                        </LastDateCon>
                                    </>
                                ) : (
                                    <PaymentCon></PaymentCon>
                                )}
                                {planData.subscribePlanTitle === 'Monthly Plan' && (
                                    <CancelBtn>
                                        <BasicBtn
                                            // HYUNHA 구독 상태에 따른 버튼 처리
                                            text={isCanceled ? 'Canceled' : 'Cancel'}
                                            disabled={isCanceled}
                                            onClick={() => {
                                                setCancelClick(true);
                                                Modal.modalOpen();
                                            }}
                                        />
                                    </CancelBtn>
                                )}
                            </BottomCon>
                        </SubscriptLeft>
                    ) : (
                        <SubscriptLeft className="monthly">
                            <TopCon>
                                <Title>No Plan</Title>
                            </TopCon>
                            <MiddleCon className="monthly">
                                <PlanImgCon className="monthly">
                                    <PlanImg src={OvercomeSectionImg} alt="MonthPlanImg" />
                                </PlanImgCon>
                            </MiddleCon>
                            <BottomCon>
                                <PaymentCon>{/* <Price>You can got plan</Price> */}</PaymentCon>

                                <CancelBtn>
                                    <BasicBtn
                                        text={'Subscribe now'}
                                        color={true}
                                        onClick={() => {
                                            history.push(`/${country}/payment/terms`);
                                        }}
                                    />
                                </CancelBtn>
                            </BottomCon>
                        </SubscriptLeft>
                    )}
                    <SubscriptRight>
                        {/* <MethodCon>
                            <MethodTit>
                                Payment <br /> Method
                            </MethodTit>
                            {updateClick ? (
                                <>
                                    <BeforeCardCon>
                                        <CardCon>
                                            <CardImg src={CardIcon} alt="card" />
                                            <CardNum>•••• •••• •••• ••••</CardNum>
                                        </CardCon>
                                        <PC>
                                            <UpdateBtn>
                                                <BasicBtn text={'Update card'} bg={false} color={true} onClick={() => setUpdateClick(false)} />
                                            </UpdateBtn>
                                        </PC>
                                    </BeforeCardCon>
                                    <Mobile>
                                        <UpdateBtn>
                                            <BasicBtn text={'Update card'} bg={false} color={true} onClick={() => setUpdateClick(false)} />
                                        </UpdateBtn>
                                    </Mobile>
                                </>
                            ) : (
                                <AfterCardCon>
                                    <CardInfo>
                                        <CardNumCon>
                                            <CardInfoTit>Card number</CardInfoTit>
                                            <CardNumInput type="number" placeholder="1234 1234 1234 1234" caution={caution} />
                                            {caution ? (
                                                <CardNumMsgText>
                                                    <AlertIcon src={AlertImg} alt="alert" />
                                                    <RequiredMsg>Card number is required</RequiredMsg>
                                                    <InvalidMsg>The credit card number appears to be invalid</InvalidMsg>
                                                </CardNumMsgText>
                                            ) : null}
                                        </CardNumCon>
                                        <CardDateCon>
                                            <MonthCon>
                                                <CardInfoTit>Month</CardInfoTit>
                                                <CardInfoInput type="text" placeholder="MM" caution={caution} />
                                                {caution ? (
                                                    <CardInfoMsgText>
                                                        <AlertIcon src={AlertImg} alt="alert" />
                                                        <RequiredMsg>Month is required</RequiredMsg>
                                                    </CardInfoMsgText>
                                                ) : null}
                                            </MonthCon>
                                            <YearCon>
                                                <CardInfoTit>Year</CardInfoTit>
                                                <CardInfoInput type="text" placeholder="YY" caution={caution} />
                                                {caution ? (
                                                    <CardInfoMsgText>
                                                        <AlertIcon src={AlertImg} alt="alert" />
                                                        <RequiredMsg>Year is required</RequiredMsg>
                                                    </CardInfoMsgText>
                                                ) : null}
                                            </YearCon>
                                            <CvcCon>
                                                <CardInfoTit>cvc</CardInfoTit>
                                                <CardInfoInput type="password" placeholder="•••" caution={caution} />
                                                {caution ? (
                                                    <CardInfoMsgText>
                                                        <AlertIcon src={AlertImg} alt="alert" />
                                                        <RequiredMsg>cvc is required</RequiredMsg>
                                                    </CardInfoMsgText>
                                                ) : null}
                                            </CvcCon>
                                            <AnotherMsgText>
                                                <AlertIcon src={AlertImg} alt="alert" />
                                                <AnotherMsg>Please try another payment method or review your credit card information.</AnotherMsg>
                                            </AnotherMsgText>
                                        </CardDateCon>
                                    </CardInfo>
                                    <CardBtnWrap>
                                        <CardCancelBtn>
                                            <BasicBtn text={'CANCEL'} color={true} onClick={() => setUpdateClick(true)} />
                                        </CardCancelBtn>
                                        <ChangeBtn>
                                            <BasicBtn text={'CHANGE'} bg={true} onClick={() => setUpdateClick(true)} />
                                        </ChangeBtn>
                                    </CardBtnWrap>
                                </AfterCardCon>
                            )}
                        </MethodCon> */}
                        <HistoryCon>
                            <HistoryTit>
                                Check billing <br /> history
                            </HistoryTit>
                            <HistoryListCon>
                                <ListTitWrap>
                                    <ListTit>Product</ListTit>
                                    <ListTit>Date</ListTit>
                                    <ListTit>Status</ListTit>
                                </ListTitWrap>
                                <ListWrap>
                                    {showHistoryData.map((item) => {
                                        return (
                                            // planStatus
                                            // 결제완료
                                            // 기간만료
                                            // 구독취소
                                            // 1회권 환불
                                            // 1회권사용완료
                                            <List key={item.orderId}>
                                                <ListProduct>
                                                    <PlanHistory>{item.planName}</PlanHistory>
                                                </ListProduct>
                                                <ListDate>
                                                    {item.planDate}
                                                    {item.planDateEnd}
                                                </ListDate>
                                                {item.planStatus === '(Expired)' ||
                                                item.planStatus === '(Canceled)' ||
                                                item.planStatus === '(Refunded)' ||
                                                item.planStatus === '(Used)' ? (
                                                    <ListStatus>{item.planStatus}</ListStatus>
                                                ) : item.planName === 'One Piece' ? (
                                                    <ListCancelBtn
                                                        onClick={() => {
                                                            setOrderId(item.orderId);
                                                            setCancelOneClick(true);
                                                            Modal.modalOpen();
                                                        }}
                                                    >
                                                        Cancel
                                                    </ListCancelBtn>
                                                ) : item.planName === 'One Year Plan' ? (
                                                    // <ListCancelBtn
                                                    //     onClick={() => {
                                                    //         setIsAnnual(true);
                                                    //         setOrderId(item.orderId);
                                                    //         setCancelClick(true);
                                                    //         Modal.modalOpen();
                                                    //     }}
                                                    // >
                                                    //     Cancel
                                                    // </ListCancelBtn>
                                                    <div></div>
                                                ) : (
                                                    <ListCancelBtn
                                                        onClick={() => {
                                                            setIsAnnual(false);
                                                            setOrderId(item.orderId);
                                                            setCancelClick(true);
                                                            Modal.modalOpen();
                                                        }}
                                                    >
                                                        Cancel
                                                    </ListCancelBtn>
                                                )}
                                            </List>
                                        );
                                    })}
                                </ListWrap>
                            </HistoryListCon>
                            {moreClick && (
                                <MoreBtn>
                                    {/* todo HYUNHA MORE버튼 눌렀을때 > 무한스크롤 후순위작업 */}
                                    <BasicBtn
                                        text={'More'}
                                        color={true}
                                        onClick={() => {
                                            setMoreState(!moreState);
                                        }}
                                    />
                                </MoreBtn>
                            )}
                        </HistoryCon>
                    </SubscriptRight>
                </SubscriptCon>
            ) : (
                <NotActive>
                    You don’t have any <br /> active subscriptions.
                </NotActive>
            )}
        </>
    );
}

const SubscriptCon = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 190px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (190 / 1280));
    }

    @media (max-width: 1023px) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
    }
`;

const SubscriptLeft = styled.div`
    width: 307px;
    height: 561px;
    padding: 39px 25px 16px;
    box-shadow: 0 0 6px 0 #bad3fb;
    border: 1px solid #005aa2;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (max-width: 1297px) {
        width: calc(100vw * (307 / 1280));
        height: calc(100vw * (561 / 1280));
        padding: calc(100vw * (39 / 1280)) calc(100vw * (25 / 1280)) calc(100vw * (16 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (220 / 428));
        min-height: calc(100vw * (430 / 428));
        height: auto;
        padding: calc(100vw * (17 / 428)) 0;
        margin-bottom: calc(100vw * (60 / 428));

        &.monthly {
            margin-bottom: calc(100vw * (20 / 428));
        }
    }
`;

const TopCon = styled.div`
    width: 100%;
`;

const Title = styled.p`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (20 / 1280));
        margin-bottom: calc(100vw * (5 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
        margin-bottom: calc(100vw * (5 / 428));
    }
`;

const SubTitle = styled.p`
    font-size: 15px;
    font-weight: #444444;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
    }
`;

const MiddleCon = styled(TopCon)`
    margin-top: 28px;

    &.monthly {
        margin-top: 10px;
    }

    @media (max-width: 1297px) {
        margin-top: calc(100vw * (28 / 1280));

        &.monthly {
            margin-top: calc(100vw * (10 / 1280));
        }
    }

    @media (max-width: 1023px) {
        margin-top: calc(100vw * (2 / 428));

        &.monthly {
            margin-top: calc(100vw * (19 / 428));
        }
    }
`;

const PlanImgCon = styled.div`
    width: 196px;
    height: 196px;
    margin: 0 auto;

    @media (max-width: 1297px) {
        width: calc(100vw * (196 / 1280));
        height: calc(100vw * (196 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (175 / 428));
        height: calc(100vw * (175 / 428));
        margin-bottom: calc(100vw * (16 / 428));

        &.monthly {
            width: calc(100vw * (149 / 428));
            height: calc(100vw * (149 / 428));
            margin-bottom: calc(100vw * (25 / 428));
        }
    }
`;

const PlanImg = styled.img``;

const Price = styled.span`
    font-weight: bold;
`;

const Date = styled(Price)``;

const BottomCon = styled(TopCon)`
    margin-top: auto;
`;

const PaymentCon = styled.p`
    font-family: 'Pretendard';
    font-size: 15px;
    color: #444444;
    line-height: 23px;
    padding-bottom: 24px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        line-height: calc(100vw * (23 / 1280));
        padding-bottom: calc(100vw * (24 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
        line-height: calc(100vw * (23 / 428));
        padding-bottom: calc(100vw * (25 / 428));
    }
`;

const LastDateCon = styled.p`
    position: relative;
    font-family: 'Pretendard';
    color: #aaaaaa;
    font-size: 15px;
    padding-top: 24px;
    margin-bottom: 43px;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0%;
        background: url(${LineImg}) no-repeat center / cover;
        width: 100%;
        height: 4px;
    }

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        padding-top: calc(100vw * (24 / 1280));
        margin-bottom: calc(100vw * (43 / 1280));

        &::after {
            height: calc(100vw * (4 / 1280));
        }
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
        padding-top: calc(100vw * (25 / 428));
        margin-bottom: calc(100vw * (18 / 428));

        &::after {
            height: calc(100vw * (4 / 428));
        }
    }
`;

const LastDate = styled.span``;

const UpgradeBtn = styled.div`
    width: 100%;

    @media (max-width: 1023px) {
        padding: 0 calc(100vw * (20 / 428));
    }
`;

const CancelBtn = styled(UpgradeBtn)`
    margin-top: 10px;

    @media (max-width: 1297px) {
        margin-top: calc(100vw * (10 / 1280));
    }

    @media (max-width: 1023px) {
        margin-top: calc(100vw * (10 / 428));
    }
`;

const SubscriptRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 890px;

    @media (max-width: 1297px) {
        width: calc(100vw * (890 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
    }
`;

const MethodCon = styled.div`
    display: flex;
    padding: 35px 28px;
    background-color: #fafafa;
    border-radius: 3px;
    margin-bottom: 40px;

    ${({ updateClick }) => {
        return updateClick ? `align-items: center;` : `align-items: fle-start;`;
    }}

    @media(max-width: 1297px) {
        padding: calc(100vw * (35 / 1280)) calc(100vw * (28 / 1280));
        margin-bottom: calc(100vw * (40 / 1280));
    }

    @media (max-width: 1023px) {
        flex-wrap: wrap;
        padding: calc(100vw * (20 / 428)) calc(100vw * (25 / 428));
        margin-bottom: calc(100vw * (20 / 428));

        ${({ updateClick }) => {
            return updateClick ? `flex-direction: column;` : `flex-direction: normal;`;
        }}
    }
`;

const MethodTit = styled.p`
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (18 / 1280));
        line-height: calc(100vw * (23 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (14 / 428));
        line-height: calc(100vw * (18 / 428));
    }
`;

const BeforeCardCon = styled.div`
    flex-grow: 1;
    margin-left: 103px;
    display: flex;
    justify-content: space-between;
    font-size: 15px;

    @media (max-width: 1297px) {
        margin-left: calc(100vw * (103 / 1280));
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        margin-left: calc(100vw * (55 / 428));
        font-size: calc(100vw * (15 / 428));
    }
`;

const CardCon = styled.div`
    display: flex;
    align-items: center;
`;

const CardImg = styled.img`
    width: 41px;
    height: 31px;
    margin-right: 7px;

    @media (max-width: 1297px) {
        width: calc(100vw * (41 / 1280));
        height: calc(100vw * (31 / 1280));
        margin-right: calc(100vw * (7 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (41 / 428));
        height: calc(100vw * (31 / 428));
        margin-right: calc(100vw * (7 / 428));
    }
`;

const CardNum = styled.p``;

const UpdateBtn = styled.div`
    width: 258px;
    margin-left: auto;

    @media (max-width: 1297px) {
        width: calc(100vw * (258 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (258 / 428));
        margin-right: auto;
        margin-top: calc(100vw * (50 / 428));
    }
`;

const AfterCardCon = styled(BeforeCardCon)`
    margin-bottom: 46px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (46 / 1280));
    }

    @media (max-width: 1023px) {
        flex-direction: column;
        margin-bottom: 0;
        margin-left: 0;
        margin-top: calc(100vw * (40 / 428));
    }
`;

const CardInfo = styled.div`
    flex-grow: 1;
    margin-right: 48px;

    @media (max-width: 1297px) {
        margin-right: calc(100vw * (48 / 1280));
    }

    @media (max-width: 1023px) {
        margin-right: 0;
    }
`;

const CardNumCon = styled.div`
    position: relative;
    margin-bottom: 40px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (40 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (40 / 428));
    }
`;

const CardInfoTit = styled.p`
    font-size: 15px;
    font-weight: bold;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (14 / 428));
    }
`;

const CardNumInput = styled.input`
    width: 100%;
    font-size: 15px;
    padding: 11px 20px;
    margin-top: 20px;
    border-radius: 3px;
    background-color: #fff;

    &::placeholder {
        color: #aaa;
    }

    ${({ caution }) => {
        return caution && `border: 1px solid #FF0058;`;
    }}

    @media(max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        padding: calc(100vw * (11 / 1280)) calc(100vw * (20 / 1280));
        margin-top: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (14 / 428));
        padding: calc(100vw * (12 / 428)) calc(100vw * (14 / 428));
        margin-top: calc(100vw * (20 / 428));
    }
`;

const RequiredMsg = styled.span``;

const InvalidMsg = styled.span``;

const AnotherMsg = styled.span``;

const CardDateCon = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
`;

const MonthCon = styled.div`
    position: relative;
`;

const YearCon = styled(MonthCon)``;

const CvcCon = styled(MonthCon)``;

const CardInfoInput = styled(CardNumInput)`
    width: 120px;

    @media (max-width: 1297px) {
        width: calc(100vw * (120 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (120 / 428));
    }
`;

const CardNumMsgText = styled.p`
    position: absolute;
    left: 0;
    bottom: -20px;
    display: flex;
    align-items: center;
    font-family: 'Pretendard';
    font-size: 13px;
    color: #ff0058;

    @media (max-width: 1297px) {
        bottom: calc(100vw * (-20 / 1280));
        font-size: calc(100vw * (13 / 1280));
    }

    @media (max-width: 1023px) {
        bottom: calc(100vw * (-25 / 428));
        font-size: calc(100vw * (13 / 428));
    }
`;

const AlertIcon = styled.img`
    width: 12px;
    height: 12px;
    margin-right: 10px;

    @media (max-width: 1297px) {
        width: calc(100vw * (12 / 1280));
        height: calc(100vw * (12 / 1280));
        margin-right: calc(100vw * (10 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (12 / 428));
        height: calc(100vw * (12 / 428));
        margin-right: calc(100vw * (10 / 428));
    }
`;

const CardInfoMsgText = styled(CardNumMsgText)`
    width: 110%;
`;

const AnotherMsgText = styled(CardNumMsgText)`
    align-items: flex-start;
    bottom: -50px;

    @media (max-width: 1297px) {
        bottom: calc(100vw * (-50 / 1280));
    }

    @media (max-width: 1023px) {
        bottom: calc(100vw * (-43 / 428));
    }
`;

const CardBtnWrap = styled.div`
    width: 155px;
    margin-top: 34px;

    @media (max-width: 1297px) {
        width: calc(100vw * (155 / 1280));
        margin-top: calc(100vw * (34 / 1280));
    }

    @media (max-width: 1023px) {
        width: 100%;
        margin-top: calc(100vw * (50 / 428));
        display: flex;
    }
`;

const CardCancelBtn = styled.div`
    margin-bottom: 20px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (20 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(calc(100% - calc(100vw * (18 / 428))) / 2);
        margin-right: calc(100vw * (18 / 428));
    }
`;

const ChangeBtn = styled.div`
    @media (max-width: 1023px) {
        width: calc(calc(100% - calc(100vw * (18 / 428))) / 2);
    }
`;

const HistoryCon = styled.div`
    padding: 35px 28px;
    background-color: #fafafa;
    border-radius: 3px;

    @media (max-width: 1297px) {
        padding: calc(100vw * (35 / 1280)) calc(100vw * (28 / 1280));
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (20 / 428)) calc(100vw * (25 / 428));
    }
`;

const HistoryTit = styled(MethodTit)`
    width: 100%;
    margin-bottom: 54px;

    @media (max-width: 1297px) {
        margin-bottom: calc(100vw * (54 / 1280));
    }

    @media (max-width: 1023px) {
        margin-bottom: calc(100vw * (30 / 428));
    }
`;

const HistoryListCon = styled.div`
    font-size: 15px;
    font-weight: 500;
    font-family: 'Pretendard';
    text-align: center;
    width: 100%;
    margin-bottom: 49px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (15 / 1280));
        margin-bottom: calc(100vw * (49 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
        margin-bottom: calc(100vw * (30 / 428));
    }
`;

const ListTitWrap = styled.ul`
    display: flex;
    border-bottom: 1px solid #aaaaaa;
    padding-bottom: 10px;

    @media (max-width: 1297px) {
        padding-bottom: calc(100vw * (10 / 1280));
    }

    @media (max-width: 1023px) {
        padding-bottom: calc(100vw * (15 / 428));
    }
`;

const ListTit = styled.li`
    width: calc(100% / 3);

    @media (max-width: 1023px) {
        width: 30%;

        &:first-child {
            width: 40%;
        }
    }
`;

const ListWrap = styled.ul`
    padding-top: 17px;
    overflow-y: hidden;
    // 주석이유: more클릭이 오로지 css 로만 처리 되어있음.
    /* ${({ moreClick }) => {
        return moreClick ? `max-height: auto;` : `max-height: 114px;`;
    }} */

    @media (max-width: 1297px) {
        padding-top: calc(100vw * (17 / 1280));

        /* ${({ moreClick }) => {
            return moreClick ? `max-height: auto;` : `max-height: calc(100vw * (114 / 1280));`;
        }} */
    }

    @media (max-width: 1023px) {
        padding-top: calc(100vw * (17 / 428));

        /* ${({ moreClick }) => {
            return moreClick ? `max-height: auto;` : `max-height: calc(100vw * (132 / 428));`;
        }} */
    }
`;

const List = styled.li`
    display: flex;
    align-items: center;
    color: #666666;
    box-sizing: border-box;
    padding: 10px 0;

    &:not(:last-child) {
        // margin-bottom: 20px;
    }

    @media (max-width: 1297px) {
        &:not(:last-child) {
            // margin-bottom: calc(100vw * (20 / 1280));
        }
    }

    @media (max-width: 1023px) {
        &:not(:last-child) {
            margin-bottom: calc(100vw * (24 / 428));
        }
    }
`;

const ListProduct = styled.p`
    width: calc(100% / 3);

    @media (max-width: 1023px) {
        width: 30%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-child {
            width: 40%;
        }
    }
`;

const PlanHistory = styled.span`
    font-size: 16px;
    font-weight: bold;
    margin-right: 7px;

    @media (max-width: 1297px) {
        font-size: calc(100vw * (16 / 1280));
        margin-right: calc(100vw * (7 / 1280));
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (13 / 428));
        margin-right: calc(100vw * (7 / 428));
    }
`;

const ListDate = styled(ListProduct)``;
const ListStatus = styled(ListProduct)`
    height: 35px;
    color: #e0e0e0;
`;

const ListCancelBtn = styled.div`
    margin: auto;
    padding: 8px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px
    font-weight: 400;
    color: rgba(1, 132, 135, 1);
    border-radius: 20px;
    background-color: white;
    border: 1px solid rgba(1, 132, 135, 1);
    cursor: pointer;
    &:hover {
        background-color: rgba(235, 245, 245, 1);
    }
`;

const ListAmount = styled(ListProduct)``;

const MoreBtn = styled.div`
    width: 258px;
    margin: 0 auto;

    @media (max-width: 1297px) {
        width: calc(100vw * (258 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (258 / 428));
    }
`;

const NotActive = styled.div`
    min-height: 751px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 241px;
    font-size: 18px;
    font-weight: bold;
    color: #aaaaaa;

    &br {
        display: none;
    }

    @media (max-width: 1297px) {
        min-height: calc(100vw * (751 / 1280));
        padding-top: calc(100vw * (241 / 1280));
        font-size: calc(100vw * (18 / 1280));
    }

    @media (max-width: 1023px) {
        min-height: calc(100vw * (617 / 428));
        padding-top: calc(100vw * (293 / 428));
        font-size: calc(100vw * (14 / 428));
        line-height: calc(100vw * (18 / 428));

        &br {
            display: block;
        }
    }
`;
