import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export default function SocialLoginPage() {
    const history = useHistory();
    const { country } = useParams();

    const [token, setToken] = useState('');
    useEffect(() => {
        const Authorization = window.location.search;
        const params = new URLSearchParams(Authorization);
        setToken(params.get('Authorization'));
    }, []);

    useEffect(() => {
        if (token) {
            sessionStorage.setItem('TOKEN', token);
            sessionStorage.setItem('LOGGED', Date.now()); // 토큰 발행 시간
            history.push(`/`);
        }
    }, [token]);

    return <h1>Login</h1>;
}
