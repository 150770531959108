//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

//css
import styled from 'styled-components';

//img
import ListArrowImg from '../../img/icon/icon_arrow_down.svg';

export default function TutorialList({ item }) {
    return (
        <ListWrap>
            {item.map((current, index) => {
                return <TutorialListItem current={current} index={index} key={index + 'current'} />;
            })}
        </ListWrap>
    );
}

const TutorialListItem = ({ current, index }) => {
    const [isOnClick, setIsOnClick] = useState(false);
    const { country } = useParams();
    return (
        <List
            key={index}
            onClick={() => {
                setIsOnClick(!isOnClick);
            }}
        >
            <ListCon>
                <ListNum>{current.tutorialSeq}</ListNum>
                <ListTitle>{current.tutorialTitle}</ListTitle>
            </ListCon>
            <DetailCon isOnClick={isOnClick}>
                <Detail>
                    {country === 'kr'
                        ? current.tutorial_content_kr
                        : country === 'cn'
                        ? current.tutorial_content_cn
                        : country === 'en'
                        ? current.tutorial_content_en
                        : country === 'es'
                        ? current.tutorial_content_es
                        : null}
                </Detail>
            </DetailCon>
        </List>
    );
};

const ListWrap = styled.ul`
    width: 1280px;
    margin: 0 auto;

    @media (max-width: 1297px) {
        width: 100%;
        padding: 0 calc(100vw * (25 / 1280));
    }

    @media (max-width: 1023px) {
        padding: 0 calc(100vw * (25 / 428));
    }
`;

const List = styled.li`
    border: 1px solid #dddddd;
    border-radius: 3px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    @media (max-width: 1297px) {
        font-size: calc(100vw * (18 / 1280));

        &:not(:last-child) {
            margin-bottom: calc(100vw * (20 / 1280));
        }
    }

    @media (max-width: 1023px) {
        font-size: calc(100vw * (12 / 428));

        &:not(:last-child) {
            margin-bottom: calc(100vw * (10 / 428));
        }
    }
`;

const ListCon = styled.div`
    display: flex;
    align-items: center;
    padding: 41px 35px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 44px;
        transform: translateY(-50%);
        background: url(${ListArrowImg}) no-repeat center / contain;
        width: 19px;
        height: 10px;
    }

    @media (max-width: 1297px) {
        padding: calc(100vw * (41 / 1280)) calc(100vw * (35 / 1280));

        &::after {
            right: calc(100vw * (44 / 1280));
            width: calc(100vw * (19 / 1280));
            height: calc(100vw * (10 / 1280));
        }
    }

    @media (max-width: 1023px) {
        padding: calc(100vw * (18 / 428)) calc(100vw * (20 / 428));

        &::after {
            right: calc(100vw * (25 / 428));
            width: calc(100vw * (11 / 428));
            height: calc(100vw * (6 / 428));
        }
    }
`;

const ListNum = styled.p`
    color: #005aa2;
    width: 155px;

    @media (max-width: 1297px) {
        width: calc(100vw * (155 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (46 / 428));
    }
`;

const ListTitle = styled.p`
    width: 1080px;

    @media (max-width: 1280px) {
        width: calc(100vw * (1080 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (248 / 428));
        line-height: calc(100vw * (14 / 428));
    }
`;

const DetailCon = styled.div`
    padding: 0 35px;
    ${({ isOnClick }) => {
        return isOnClick ? `display: block;` : `display: none;`;
    }}

    @media (max-width: 1297px) {
        padding: 0 calc(100vw * (35 / 1280));
    }

    @media (max-width: 1023px) {
        padding: 0 calc(100vw * (20 / 428));
    }
`;

const Detail = styled.p`
    width: 1018px;
    padding: 41px 0;
    margin-left: 155px;
    border-top: 1px solid #dddddd;
    font-size: 16px;
    font-family: 'Pretendard';
    font-weight: 500;
    line-height: 24px;

    @media (max-width: 1297px) {
        width: calc(100vw * (1018 / 1280));
        padding: calc(100vw * (41 / 1280)) 0;
        margin-left: calc(100vw * (155 / 1280));
        font-size: calc(100vw * (16 / 1280));
        line-height: calc(100vw * (24 / 1280));
    }

    @media (max-width: 1023px) {
        width: calc(100vw * (276 / 428));
        padding: calc(100vw * (18 / 428)) 0;
        margin-left: calc(100vw * (46 / 428));
        font-size: calc(100vw * (12 / 428));
        line-height: calc(100vw * (18 / 428));
    }
`;
