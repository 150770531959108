//react
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';

//css
import styled from 'styled-components';

//img
import IconLogo from '../../img/icon/logo.svg';
import IconBell from '../../img/icon/icon_bell.svg';
import IconBellOn from '../../img/icon/icon_bell_on.svg';
import BellMusicImg from '../../img/icon/icon_music.svg';
import BellNewsImg from '../../img/icon/icon_news.svg';
import BellSubscriptionImg from '../../img/icon/icon_subscription.svg';

import serverController from '../../server/serverController';

import { getLocalS } from '../../utils/localStorageController';
import { BellListComp } from './BellListComp';

export default function HeaderWhite() {
    const history = useHistory();
    const location = useLocation();
    const { country } = useParams();

    // 로그인
    const [getToken, setGetToken] = useState(null);
    // 알림
    const [bellState, setBellState] = useState(false);
    const [isOnClick, setIsOnClick] = useState(false);
    const [bellListData, setBellListData] = useState([]);
    const [readClick, setReadClick] = useState(false);
    const tokenData = sessionStorage.getItem('TOKEN');

    useEffect(() => {
        if (tokenData) {
            // 토큰 세팅
            setGetToken(tokenData);
            // 알림 count
            serverController.connectFetchController(
                `api/push/notReadCount`,
                'POST',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                    }
                },
            );
            //알림 api
            serverController.connectFetchController(
                `api/push/list?offset=${1}&limit=${5}`,
                'GET',
                null,
                {
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${tokenData}`,
                },
                (res) => {
                    if (res.result === 1) {
                        if (res.pushList.length > 0) {
                            setBellState(true);
                            setBellListData(res.pushList.reverse());
                        } else {
                            setBellListData([]);
                            setBellState(false);
                        }
                    }
                },
            );
        }
    }, [readClick]);

    useEffect(() => {
        /* 컴포넌트가 마운트 되면 window에 focus 이벤트 부여 */
        let lastPathName = document.location.pathname.split('/')[2];

        if (lastPathName === 'mastering') {
            setBgOn(true);
        } else {
            setBgOn(false);
        }
    }, []);

    // URL 변경될 때마다 헤더 컴포넌트에서 logout 여부 체크
    useEffect(() => {
        // console.log('HeaderWhite', location);
        checkLoginSession();
    }, [location]);

    const logoutHandle = () => {
        logout();
        window.location.href = `/${country}/main`;
        // history.push(`/${country}/main`);
    };

    const logout = () => {
        const tokenData = sessionStorage.getItem('TOKEN');
        if (tokenData) {
            sessionStorage.clear();
        }

        serverController.connectFetchController(
            `api/logout`,
            'POST',
            null,
            {
                'Content-Type': `application/json`,
                Authorization: `Bearer ${tokenData}`,
            },
            (res) => {},
        );

        setGetToken(null);
        localStorage.removeItem('completeData');
        localStorage.removeItem('uploadFiles');
    };

    const checkLoginSession = () => {
        // 사용자 로그인 후 60분(JWT 유효 시간)이 지나면 라우팅 변경 시 로그아웃 시킵니다.
        const [now, logged] = [Date.now(), Number(sessionStorage.getItem('LOGGED'))];
        if (now - logged > 1000 * 60 * 60) {
            // console.log('HeaderWhite', location);
            logout();
        }
    };

    const goMastering = () => {
        checkLoginSession();

        if (getLocalS('uploadFiles')) history.push(`/${country}/mastering`);
        else history.push(`/${country}/mastering/upload`);
    };

    const goLink = (link) => {
        checkLoginSession();
        const linkTo = link.includes('mypage') && !sessionStorage.getItem('TOKEN') ? 'login' : link;
        history.push(`/${country}/${linkTo}`);
    };

    // background
    const [BgOn, setBgOn] = useState(false);

    return (
        <Container active={BgOn}>
            <HeaderWrap>
                <HeaderLeft>
                    <LogoLink to={`/${country}/main`} onClick={checkLoginSession}>
                        <LogoImg src={IconLogo} alt="logo" />
                    </LogoLink>
                    <MenuWrap>
                        <MenuList>
                            <MenuButton onClick={goMastering}>Mastering</MenuButton>
                        </MenuList>
                        <MenuList>
                            <MenuButton
                                onClick={() => {
                                    goLink('library');
                                }}
                            >
                                Library
                            </MenuButton>
                        </MenuList>
                        <MenuList>
                            <MenuButton
                                onClick={() => {
                                    goLink('tutorial');
                                }}
                            >
                                Tutorial
                            </MenuButton>
                        </MenuList>
                        <MenuList>
                            {/* <Sales>Sale</Sales> */}
                            <MenuButton
                                onClick={() => {
                                    goLink('payment/terms');
                                }}
                            >
                                Plan
                            </MenuButton>
                        </MenuList>
                        {/* <MenuList>
                            <MenuButton
                                onClick={() => {
                                    goLink('intro');
                                }}
                            >
                                About us
                            </MenuButton>
                        </MenuList> */}
                    </MenuWrap>
                </HeaderLeft>
                <HeaderRight>
                    <NoticeIconWrap>
                        <NoticeIcon></NoticeIcon>
                    </NoticeIconWrap>
                    <UtilWrap>
                        {getToken ? (
                            <BellStatus>
                                <BellIconWrap
                                    onClick={() => {
                                        setIsOnClick(!isOnClick);
                                    }}
                                >
                                    {bellState ? <BellIcon src={IconBellOn} alt="bellicon" /> : <BellIcon src={IconBell} alt="bellicon" />}
                                </BellIconWrap>
                                {bellState ? (
                                    <BellListCon isOnClick={isOnClick}>
                                        <BellListWrap>
                                            {bellListData.map((item, value) => {
                                                return <BellListComp item={item} key={value} setReadClick={setReadClick} readClick={readClick} />;
                                            })}
                                        </BellListWrap>
                                    </BellListCon>
                                ) : (
                                    <BellListCon isOnClick={isOnClick}>
                                        <Link to={`/${country}/support/news`} onClick={checkLoginSession}>
                                            <BellListWrap>
                                                <BellListNo style={{ paddingBottom: 10 }}>
                                                    No new alarm
                                                    <br />
                                                    <span style={{ fontSize: 12 }}>Move to Newsletter</span>
                                                </BellListNo>
                                            </BellListWrap>
                                        </Link>
                                    </BellListCon>
                                )}
                            </BellStatus>
                        ) : null}
                        {getToken ? (
                            <LoginStatus>
                                <MenuButton onClick={logoutHandle}>Log Out</MenuButton>
                            </LoginStatus>
                        ) : (
                            <LoginStatus>
                                <MenuButton
                                    onClick={() => {
                                        goLink('login');
                                    }}
                                >
                                    Log in
                                </MenuButton>
                            </LoginStatus>
                        )}
                        {getToken ? (
                            <MypageBtnWrap>
                                <MenuButton
                                    onClick={() => {
                                        goLink('mypage/myinfo');
                                    }}
                                >
                                    My page
                                </MenuButton>
                            </MypageBtnWrap>
                        ) : (
                            <MypageBtnWrap>
                                <MenuButton
                                    onClick={() => {
                                        goLink('signup');
                                    }}
                                >
                                    Sign Up
                                </MenuButton>
                            </MypageBtnWrap>
                        )}
                    </UtilWrap>
                </HeaderRight>
            </HeaderWrap>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    height: 100px;
    position: fixed;
    top: 0;
    z-index: 9;
    //background-color: #fff;

    @media (max-width: 1297px) {
        height: calc(100vw * (100 / 1280));
    }
    //rgba(255, 255, 255, 0.72);
    ${({ active }) => (active ? 'background-color: rgba(255, 255, 255, 0);' : 'background-color: #fff;')}
`;

const HeaderWrap = styled.div`
    width: 1280px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Pretendard';
    font-weight: 500;
    font-size: 17px;

    @media (max-width: 1297px) {
        width: 100%;
        padding: 0 calc(100vw * (25 / 1280));
        font-size: calc(100vw * (17 / 1280));
    }
`;

const HeaderLeft = styled.div`
    display: flex;
`;

const LogoLink = styled(Link)`
    width: 183px;
    height: 30px;

    @media (max-width: 1297px) {
        width: calc(100vw * (183 / 1280));
        height: calc(100vw * (30 / 1280));
    }
`;

const LogoImg = styled.img`
    width: 100%;
    object-fit: contain;
`;

const MenuWrap = styled.ul`
    display: flex;
    align-items: center;
    margin-left: 79px;

    @media (max-width: 1297px) {
        margin-left: calc(100vw * (79 / 1280));
    }
`;

const MenuList = styled.li`
    &:not(:last-child) {
        margin-right: 50px;
    }

    @media (max-width: 1297px) {
        margin-right: calc(100vw * (50 / 1280));
    }

    position: relative;
`;

const MenuButton = styled.div`
    cursor: pointer;
    position: relative;
`;

// 할인
const Sales = styled.div`
    position: absolute;
    font-size: 14px;
    font-weight: bold;
    color: #aa0000;
    top: -13px;
    right: -15px;
`;
// 할인

const HeaderRight = styled.div``;

const NoticeIconWrap = styled.div``;

const NoticeIcon = styled.div``;

const UtilWrap = styled.ul`
    display: flex;
    align-items: center;
`;

const LoginStatus = styled.li`
    cursor: pointer;
    margin-left: 30px;

    @media (max-width: 1297px) {
        margin-left: calc(100vw * (30 / 1280));
    }
`;

const MypageBtnWrap = styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 117px;
    height: 50px;
    border-radius: 25px;
    background-image: linear-gradient(to bottom, #0054a6, #008c82);
    margin-left: 57px;
    cursor: pointer;
    color: #fff;

    @media (max-width: 1297px) {
        width: calc(100vw * (117 / 1280));
        height: calc(100vw * (50 / 1280));
        margin-left: calc(100vw * (57 / 1280));
    }
`;

const BellStatus = styled.li`
    cursor: pointer;
    position: relative;
`;

const BellIconWrap = styled.div``;

const BellIcon = styled.img`
    width: 19px;
    height: 23px;
    object-fit: contain;

    @media (max-width: 1297px) {
        width: calc(100vw * (19 / 1280));
        height: calc(100vw * (23 / 1280));
    }
`;
const BellListCon = styled.div`
    position: absolute;
    top: 46px;
    left: 50%;
    transform: translateX(-50%);
    width: 363px;

    &::after {
        content: '';
        position: absolute;
        top: -13px;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 13px solid #fff;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
    }

    @media (max-width: 1297px) {
        width: calc(100vw * (363 / 1280));
        top: calc(100vw * (46 / 1280));

        &::after {
            top: calc(100vw * (-13 / 1280));
            border-bottom: calc(100vw * (13 / 1280)) solid #fff;
            border-right: calc(100vw * (10 / 1280)) solid transparent;
            border-left: calc(100vw * (10 / 1280)) solid transparent;
        }
    }

    ${({ isOnClick }) => {
        return isOnClick ? `display: block;` : `display: none;`;
    }}
`;

const BellListNo = styled.li`
    padding: 19px;
    background-color: #fff;
    color: #000;
    text-align: center;

    &:hover {
        background-color: #f8fafd;
    }

    @media (max-width: 1297px) {
        padding: calc(100vw * (19 / 1280));
    }
`;

const BellListWrap = styled.ul`
    max-height: 400px;
    border-radius: 3px;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);

    &::-webkit-scrollbar {
        width: 14px;
    }

    &::-webkit-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: #eee;
    }

    @media (max-width: 1297px) {
        max-height: calc(100vw * (400 / 1280));

        &::-webkit-scrollbar {
            width: calc(100vw * (14 / 1280));
        }
    }
`;
