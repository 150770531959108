//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import serverController from '../../server/serverController';

//css
import styled, { keyframes } from 'styled-components';

// lang
import { useLangText, useLangStyle } from '../../utils/langugeController/langController';
import { KR, EN, CN, ES } from './language/MainSlider/combLang';

//img
import MainMasterArrow from '../../img/main/master_arrow.svg';
import MainWaveBG from '../../img/main/wave_bg.png';

//audio
import MusicBefore from '../../media/M_BEFORE.mp3';
import MusicAfter from '../../media/M_AFTER.mp3';
import DialogueBefore from '../../media/D_BEFORE.mp3';
import DialogueAfter from '../../media/AFTER_D_Re.mp3';
// import DialogueAfter from '../../media/D_AFTER.mp3';

//wave
import MainWave from '../../component/common/list/MainWave';
export default function MainBannerSmall() {
    const langText = useLangText(KR, EN, CN, ES);
    const [allPause, setAllPause] = useState(false);

    return (
        <>
            <Container>
                <div>
                    <BeforeAfterTitle>Music mastering</BeforeAfterTitle>
                    <DivBar />
                    <InfoText>{langText.MusicMastering}</InfoText>
                    <BeforeAfterDisplay>
                        <MusicBoxBefore>
                            <MainWave id={'masterbefore'} type={'before'} item={MusicBefore} setAllPause={setAllPause} allPause={allPause} />
                            <BadgeBefore>Original</BadgeBefore>
                        </MusicBoxBefore>
                        <BeforeAfterArrow src={MainMasterArrow} alt={'arrow'} />
                        <MusicBoxAfter>
                            <MainWave id={'masterafter'} type={'after'} item={MusicAfter} setAllPause={setAllPause} allPause={allPause} />
                            <BadgeAfter>Mastered</BadgeAfter>
                        </MusicBoxAfter>
                    </BeforeAfterDisplay>
                </div>
                <div style={{ width: '100%' }}>
                    <BeforeAfterTitle>Noise reduction</BeforeAfterTitle>
                    <DivBar />
                    <InfoText>{langText.NoiseReduction}</InfoText>
                    <BeforeAfterDisplay>
                        <MusicBoxBefore>
                            <MainWave id={'noisebefore'} type={'before'} item={DialogueBefore} setAllPause={setAllPause} allPause={allPause} />
                            <BadgeBefore>Original</BadgeBefore>
                        </MusicBoxBefore>
                        <BeforeAfterArrow src={MainMasterArrow} alt={'arrow'} />
                        <MusicBoxAfter>
                            <MainWave id={'noiseafter'} type={'after'} item={DialogueAfter} setAllPause={setAllPause} allPause={allPause} />
                            <BadgeAfter>Denoised & Mastered</BadgeAfter>
                        </MusicBoxAfter>
                    </BeforeAfterDisplay>
                </div>
            </Container>
        </>
    );
}

const Container = styled.div`
    font-family: 'Pretendard';
    box-sizing: border-box;
    width: 100%;
    max-width: 1280px;
    background: white;
    display: flex;
    flex-direction: column;
    gap: 300px;
    padding-bottom: 500px;
    font-weight: 700;
    margin: auto;
    padding-left: calc(100vw * (25 / 1280));
    padding-right: calc(100vw * (25 / 1280));

    @media (max-width: 1297px) {
        gap: 100px;
        padding-bottom: 100px;
    }
`;
const BeforeAfterTitle = styled.div`
    font-family: 'Syncopate';
    font-size: 26px;
    font-weight: 700;
`;
const DivBar = styled.div`
    background-color: rgba(224, 224, 224, 1);
    height: 1px;
    margin: 24px auto;
`;
const InfoText = styled.p`
    font-family: 'Pretendard';
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
`;
const BeforeAfterArrow = styled.img`
    width: 24px;
    height: 24px;
    margin: 16px;

    @media (max-width: 1297px) {
        display: none;
    }
`;
const BeforeAfterDisplay = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1297px) {
        flex-direction: column;
    }
    @media (max-width: 1023px) {
        flex-direction: column;
    }
`;
const MusicBoxBefore = styled.div`
    box-sizing: border-box;
    width: 100%;
    min-width: 500px;
    padding: 30px 40px;
    border-radius: 12px;
    background-color: #f4f4f4;
    background: url(${MainWaveBG}) no-repeat center / cover;
    border: 1px solid #d7d7d7;
    position: relative;
    @media (max-width: 1297px) {
        margin-bottom: 100px;
    }
    @media (max-width: 1023px) {
        margin-bottom: 150px;
        padding: 10px 20px;
        min-width: 300px;
    }
`;
const MusicBoxAfter = styled.div`
    box-sizing: border-box;
    width: 100%;
    min-width: 500px;
    padding: 30px 40px;
    border-radius: 12px;
    background: linear-gradient(180deg, rgba(0, 85, 164, 0.75) 0%, rgba(0, 137, 131, 0.75) 100%);
    position: relative;
    @media (max-width: 1297px) {
        margin-bottom: 100px;
    }
    @media (max-width: 1023px) {
        margin-bottom: 150px;
        padding: 10px 20px;
        min-width: 300px;
    }
`;
const BadgeBefore = styled.div`
    box-sizing: border-box;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 40px;
    background: #e1edf4;
    color: rgba(5, 104, 166, 1);
    font-family: 'Pretendard';
    font-weight: 600;
    font-size: 16px;
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 1023px) {
        font-size: 12px;
    }
`;
const BadgeAfter = styled.div`
    box-sizing: border-box;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 40px;
    background: rgba(5, 104, 166, 1);
    color: white;
    font-family: 'Pretendard';
    font-weight: 600;
    font-size: 16px;
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 1023px) {
        font-size: 12px;
    }
`;
