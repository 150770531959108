export const CN = {
  TermsListData: [
    {
      Tit: `使用和合理使用政策`,
      Text: `WARP 株式会社（“WARP”）的服务、客户端软件和网站（“服务”）旨在给数百万用户提供使用，我们相信您会负责任地使用这些服务。本使用和合理使用政策应与我们的服务条款一起阅读。`,
    },
    {
      Tit: `您同意不滥用我司服务。例如，您不得尝试使用该服务来执行以下操作：`,
      Text: `探索、发现和测试系统或网络中的漏洞
违反和规避安全或认证措施
访问、篡改或使用 Warp（或我们的服务提供商的）计算机系统，这些计算机系统是您未被邀请使用的服务的共享区域。
通过发送病毒、过载、泛洪、垃圾邮件或发送邮件炸弹来扰乱用户、主机或网络。
植入恶意软件或使用该服务分发恶意软件
从公共支持的接口以外的任何方法来访问和检索服务（例如“抓取”）
未经请求的通信、宣传、广告和垃圾邮件
发布具有欺诈性、误导性或侵犯他人权利的内容
未经适当许可宣传和宣传自己的产品或服务
非法发布和分享宣扬色情或淫秽、偏执、宗教、种族或拥护种族仇恨的材料
侵犯他人隐私或损害他人名誉
冒充或歪曲您与个人或实体的关系
尝试对用于提供服务的软件进行解密、反汇编或逆向工程
通过发送病毒或试图通过过载、泛洪、垃圾邮件或邮件轰炸的方式来攻击不受限制地破坏或干扰任何用户、主机或网络的访问来伤害或威胁其他用户
提供第三方支付信息
滥用服务的本身用途、说明书以及WARP音频的合理指南
如果用户使用他们不拥有版权的服务或许可来提供或共享材料，WARP 可能会删除这些材料，恕不另行通知。有关更多详细信息，请参阅我们的服务条款。`,
    },
    {
      Tit: `使用限制`,
      Text: `WARP为理解我方服务的意图用途，并防止恶意利用和滥用本公司计划中的无限制功能，准备了该政策。 正常合理的个人以及专业人士在使用本公司计划提供的服务时，属于一般合理的个人和专业使用的范畴。 "无限制" 表示的是相应类型和使用水平。在使用服务的过程，与按照个人或专业目的的一般使用者相比，不正常地大量使用音轨（手续费或未使用）来进行母带处理，并以此进行类似商业运营的行为不包含在一般合理的个人以及专业使用范畴。`,
    },
    {
      Tit: `评价`,
      Text: `WARP 根据其他用户的正常使用水平评估用户的使用情况。 根据这些评估，WARP 可能会判断出与正常用户相比存在的异常和不合理的使用情况。我们不想设置具体限制，但一般情况下，每天固定进行8首以上的母带处理，或将每月240首以上的歌曲按照每月20首以上的数量来持续发布时，我们将认为您正在不正常或不合理使用。对于您可能一次性处理多种分轨和其他分轨的母带处理，或者有一段期间不做任何发布的情形，我们可能会将其理解成不规则使用服务行为。因此，我们会对您的使用进行检查和评价。`,
    },
    {
      Tit: `提醒`,
      Text: `如果 WARP 确定您的使用异常或不合理，我司将与您联系（通过您帐户中提供的电子邮件地址）通知相关使用情况，并为您提供修改使用或转换加入其他费用套餐的机会。我司还保留采取例如立即暂停您的帐户和使用服务，或关闭您的帐户等进一步措施的权利。

    WARP为理解我司服务的意图用途，并防止恶意利用和滥用本公司计划中的无限制功能，而准备了该政策。 `,
    },
    {
      Tit: `用于一般和合理的个人或专业用途。`,
      Text: `我司计划提供的服务仅供正常和合理的个人或专业使用。 “无限制”是指其使用类型和级别。在使用服务的过程，与按照个人或专业目的的一般使用者相比，不正常地大量使用音轨（手续费或未使用）来进行母带处理，并以此进行类似商业运营的行为不包含在一般合理的个人以及专业使用范畴。`,
    },
    {
      Tit: `产品水平`,
      Text: `音乐母带制作的结果可能会因混音的完成度而有很大差异。 如果混合文件的完整度不高，母带制作结果的完成度也会大大降低。 可以通过网站上的教程检查获取最佳结果的准备情况。
    视频和对话降噪功能可能会因原始音频的音质而产生差异。 并不是说我司的去噪服务是可以完全去除原始音频的所有噪音，而是说它是针对一般视频录制结果进行的一种平均类型的去噪服务。
    此外，根据原始音频的音质，最终母带制作结果可能会有所不同。`,
    },
  ],
  CopyRight: `著作权信息©. 
  WARP Inc. 2022 
  未经作者事先书面同意，不得复制、修改或修正本网站的任何部分或内容。
  您可以通过下列任何一种方法来分享本网站，您可以使用 WARPmastering.com 网站上的共享图标，提供您希望分享的内容的反向链接或URL，或引用属于 WARPmastering.com 网站的摘录。 对于其他共享模式，请通过 contact@warpmastering.com 与我们联系。 未经作者事先明确书面同意，不得以商业目的使用和转发本网站的内容。`,
};
