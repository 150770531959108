//react
import { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import serverController from '../../server/serverController';

//css
import styled, { keyframes } from 'styled-components';

//img
import MainBannerImg from '../../img/main/main_banner.jpg';
import BtnArrow from '../../img/icon/icon_arrow_bottom.svg';
import TrialImg from '../../img/main/trial_img.png';
import mainarrow from '../../img/main/arrow.svg';

export default function MainBannerSmall() {
    const [trialBtn, setTrialBtn] = useState(false);
    const { country } = useParams();
    const [newsData, setNewsData] = useState([]);
    
    useEffect(() => {
        serverController.connectFetchController(
            `api/support/notice/list?offset=1&limit=3&keyword=all`,
            'GET',
            null,
            null,
            (res) => {
                const data = res.notice ?? [];
                if (res.result === 1) {
                    setNewsData(res.notice);
                } else {
                    setNewsData([]);
                }
            },
        );
    }, []);

    function convertTag(value) {
        const reg = /<[^>]*>?/g
        const reg2 = /&[^;]*;?/g
        const result1 = value.replace(reg, '');
        const result2 = result1.replace(reg2, '');
        return result2;
    }
    return (
        <>
            <Container>
                    <MoreBtn to={`/${country}/support/news`}>
                        <span>More</span>
                        <img src={mainarrow} alt={'more'} style={{width:16, height:16, marginLeft:2}} />
                    </MoreBtn>
                <LetterWrap>
                    {
                        newsData.length > 0 ?
                        newsData.map((v)=>{
                            return(
                                <Card key={v.noticeId} to={`/${country}/support/detail/news/${v.noticeId}`}>
                                    <LetterTitle>{v.noticeTitle}</LetterTitle>
                                    <LetterContents>{convertTag(v.noticeContent)}</LetterContents>
                                    <LetterDate>{v.noticeDate[0] + '-' + (v.noticeDate[1] < 10 ? '0' + v.noticeDate[1]:v.noticeDate[1]) + '-' + (v.noticeDate[2] < 10 ? '0' + v.noticeDate[2]:v.noticeDate[2])}</LetterDate>
                                    <NewsLabel>Newsletter</NewsLabel>
                                </Card> 
                            )
                        })
                        :
                        <Card>
                            <LetterTitle>No News Letter</LetterTitle>
                                <NewsLabel>Newsletter</NewsLabel>
                        </Card>
                    }
                </LetterWrap>
            </Container>
        </>
    );
}

const Container = styled.div`
    font-family: 'Pretendard';
    box-sizing: border-box;
    width: 100%;
    min-height: 480px;
    max-height: 100%;
    background: url(${MainBannerImg}) no-repeat center / cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10%;

`;
const MoreBtn = styled(Link)`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 40px;
`
const LetterWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media (max-width: 1297px) {
        flex-direction: column;
        gap: 55px;
    }
`
const Card = styled(Link)`
    width: 412px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    max-height: 233px;
    border-radius: 12px;
    background-color: #ffffff;
    gap: 20px;
    padding: 32px;
    @media (max-width: 1297px) {
        padding: 8%;
        min-width: 300px;
        max-width: 230px;
    }
`
const LetterTitle = styled.div`
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 22px;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
    margin-top: 4px;
    @media (max-width: 1297px) {
        font-size: 18px;
    }
`
const LetterContents = styled.div`
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    @media (max-width: 1297px) {
        font-size: 12px;
    }
`
const LetterDate = styled.div`
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 4px;
    @media (max-width: 1297px) {
        font-size: 12px;
    }
`
const NewsLabel = styled.div`
    width: fit-content;
    padding: 8px 10px;
    background-color: #E1EDF4;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(5, 104, 166, 1);
    position: absolute;
    top: -18px;
    left: 34px;
    @media (max-width: 1297px) {
        font-size: 14px;
    }
`

// const BannerTextCon = styled.div`
//     text-align: center;
//     color: #fff;
// `;

// const BannerText = styled.p`
//     font-size: 60px;
//     font-weight: bold;
//     margin-bottom: 34px;

//     @media (max-width: 1297px) {
//         font-size: calc(100vw * (60 / 1280));
//         margin-bottom: calc(100vw * (34 / 1280));
//     }

//     @media (max-width: 1023px) {
//         font-size: calc(100vw * (16 / 428));
//         margin-bottom: calc(100vw * (13 / 428));
//     }
// `;

// const TryBtn = styled(Link)`
//     display: block;
//     width: 185px;
//     margin: 0 auto;
//     font-family: 'Pretendard';
//     font-size: 23px;
//     font-weight: bold;
//     position: relative;
//     padding-left: 50px;

//     &::after {
//         content: '';
//         position: absolute;
//         left: 0;
//         width: 15px;
//         height: 14px;
//         background: url(${BtnArrow}) no-repeat center / contain;
//     }

//     &:hover span::after {
//         opacity: 1;
//     }

//     @media (max-width: 1297px) {
//         width: calc(100vw * (170 / 1280));
//         font-size: calc(100vw * (23 / 1280));
//         padding-left: calc(100vw * (50 / 1280));

//         ::after {
//             width: calc(100vw * (15 / 1280));
//             height: calc(100vw * (14 / 1280));
//         }
//     }

//     @media (max-width: 1023px) {
//         width: calc(100vw * (90 / 428));
//         font-size: calc(100vw * (12 / 428));
//         padding-left: calc(100vw * (19 / 428));

//         ::after {
//             width: calc(100vw * (7 / 428));
//             height: calc(100vw * (7 / 428));
//         }
//     }
// `;

// const TryBtnText = styled.span`
//     position: relative;

//     ::after {
//         content: '';
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         width: 81px;
//         height: 73px;
//         background: url(${TrialImg}) no-repeat center / contain;
//         mix-blend-mode: difference;
//         background-blend-mode: difference;
//         opacity: 0;
//         transition: 0.3s;
//     }

//     @media (max-width: 1297px) {
//         ::after {
//             width: calc(100vw * (81 / 1280));
//             height: calc(100vw * (73 / 1280));
//         }
//     }

//     @media (max-width: 1023px) {
//         ::after {
//             width: calc(100vw * (56 / 428));
//             height: calc(100vw * (45 / 428));
//         }
//     }
// `;
